export function generatePassword(length = 8) {
  const upper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lower = 'abcdefghijklmnopqrstuvwxyz';
  const numbers = '0123456789';

  // Ensure we have at least one of each type
  const allCharacters = upper + lower + numbers;

  // Function to get a random character from a string
  const getRandomCharacter = (chars: string) =>
    chars.charAt(crypto.getRandomValues(new Uint32Array(1))[0] % chars.length);

  let passwordArray = [];

  // Add at least one character from each set
  passwordArray.push(getRandomCharacter(upper));
  passwordArray.push(getRandomCharacter(lower));
  passwordArray.push(getRandomCharacter(numbers));

  // Fill the rest of the password length with random characters from all sets
  for (let i = 3; i < length; i++) {
    passwordArray.push(getRandomCharacter(allCharacters));
  }

  // Shuffle the password to avoid predictable patterns
  return passwordArray.sort(() => 0.5 - Math.random()).join('');
}

export function resizeImage(file: File): Promise<Blob | null> {
  return new Promise(resolve => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      const scale = 500 / img.width;
      canvas.width = 500;
      canvas.height = img.height * scale;
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      canvas.toBlob(blob => {
        if (blob) {
          resolve(blob);
        } else {
          resolve(null);
        }
      }, file.type);
    };
  });
}

export function validateName(formData: any, setNameError: any) {
  const name = formData.name.trim();
  const nameRegex = /^[a-zA-Z.\s]{2,40}$/;
  if (name.length < 4 || name.length > 40 || !nameRegex.test(name)) {
    setNameError('Name must be between 4 to 25 characters.');
    return false;
  } else {
    setNameError('');
    return true;
  }
}

export function validatePhone(formData: any, setPhoneError: any) {
  const phoneNo = formData?.phone.trim();
  const regexForPhoneNo = /^\d{10}$/;
  if (!regexForPhoneNo.test(phoneNo)) {
    setPhoneError('Phone number must be of minimum 10 characters.');
    return false;
  } else {
    setPhoneError('');
    return true;
  }
}

export function validatePassword(formData: any, setPasswordError: any) {
  const password = formData?.password?.trim() || '';
  if (password.length < 8) {
    setPasswordError('Password must be at least 8 characters.');
    return false;
  } else {
    setPasswordError('');
    return true;
  }
}

export function validateEmail(formData: any, setEmailError: any) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(formData.email)) {
    setEmailError('Email is invalid.');
    return false;
  } else {
    setEmailError('');
    return true;
  }
}
