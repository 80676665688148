import { Button, Divider, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import dayjs from 'dayjs';
import useIsTablet from '../../utilities/customHooks/useIsTablet';
import { makeStyles } from '@mui/styles';
import useIsMobile from '../../utilities/customHooks/useIsMobile';
import { useState } from 'react';

const styles = {
  shiftListContainer: {
    maxHeight: '170px',
    overflow: 'scroll',
    boxSizing: 'border-box',
    background: 'white',
    scrollbarWidth: 'none',
    width: '100%',
    '&::-webkit-scrollbar': {
      width: '0', // You can adjust this value to control the width of the scrollbar
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent', // Hide the scrollbar thumb
    },
  },
  PositiveButtonStyle: {
    height: '42px',
    padding: '16px 40px',
    backgroundColor: '#3271A9',
    borderRadius: '4px',
    cursor: 'pointer',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#265d85',
      boxShadow: 'none', // Ensure no shadow on hover
    },
    '&:active': {
      boxShadow: 'none', // Ensure no shadow on active
    },
    '&:focus': {
      boxShadow: 'none', // Ensure no shadow on focus
    },
    flex: '1 0 0',
  },
};

interface ShiftDetailsComponentProps {
  flocDetectorShiftsData: any[];
  selectedTime: any;
  setSelectedTime: React.Dispatch<React.SetStateAction<any>>;
  isStartTimeFocused: boolean;
  setIsStartTimeFocused: React.Dispatch<React.SetStateAction<boolean>>;
  isEndTimeFocused: boolean;
  setIsEndTimeFocused: React.Dispatch<React.SetStateAction<boolean>>;
  flocDetectorShifts: {
    serialNumber: string;
    shiftName: string;
    startTime: string;
    endTime: string;
  };
  openDialog: boolean;
  errorStartTime: string;
  error24hrs: string;
  newShiftStartTimeError: boolean;
  fillAllFieldsError: boolean;
  editSerialNumber: any;
  handleChangeFlocDetectorShifts: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleTimePickerChange: (newTime: any) => void;
  previousEndTime: string;
  previousStartTime: string;
  totalShift24hrs: boolean;
  handleClickOpenDialog: () => void;
  handleCloseDialog: () => void;
  handleEditItem: (serialNumber: any) => void;
  addFlocDetectorShifts: (widgetClusterIndex: string, widgetIndex: number) => void;
  widgetClusterIndex: string;
  widgetIndex: number;
  settingDialogue: boolean;
  leftToolBar: boolean;
}

const useStyles = makeStyles({
  customInputRoot: {
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      paddingRight: '0px',
    },
    '& .MuiOutlinedInput-input ': {
      paddingTop: '16px !important',
      paddingBottom: '16px !important',
      paddingLeft: '12px !important',
      paddingRight: '0px !important',
      fontSize: '12px',
    },
  },
});

const ShiftDetailsComponent = ({
  flocDetectorShiftsData,
  setSelectedTime,
  setIsStartTimeFocused,
  setIsEndTimeFocused,
  flocDetectorShifts,
  errorStartTime,
  error24hrs,
  newShiftStartTimeError,
  fillAllFieldsError,
  editSerialNumber,
  handleChangeFlocDetectorShifts,
  totalShift24hrs,
  handleClickOpenDialog,
  handleEditItem,
  addFlocDetectorShifts,
  widgetClusterIndex,
  widgetIndex,
  settingDialogue,
  leftToolBar,
}: ShiftDetailsComponentProps) => {
  const isTablet = useIsTablet();
  const isMobile = useIsMobile({ breakpointValue: 'sm' });
  const classes = useStyles();
  const [labelFontSizeStartTime, setLabelFontSizeStartTime] = useState(12);
  const [labelFontSizeEndTime, setLabelFontSizeEndTime] = useState(12);
  return (
    <Grid
      container
      direction="column"
      xs={12}
      sm={4}
      md={4.5}
      lg={3.3}
      border="1px solid #E5E8EC"
      m={settingDialogue || leftToolBar ? 0 : 2}
      mt={settingDialogue || leftToolBar ? 2 : 0}
      p={3}
      borderRadius="12px"
      sx={{
        background: '#FFF',
        display: 'flex',
        flexDirection: 'column',
        flex: '1', // Allow the container to grow
        maxWidth: 'unset !important',
        height: 'fit-content',
        ...(settingDialogue && {
          position: 'absolute',
          bottom: '100%',
          left: '50%',
          transform: 'translateX(-50%)',
          marginBottom: '1rem',
          zIndex: 10,
        }),
        ...(leftToolBar && {
          position: 'absolute',
          top: '100%',
          left: '50%',
          transform: 'translateX(-50%)',
          marginBottom: '1rem',
          zIndex: 10,
        }),
      }}
    >
      <Grid
        container
        direction="column"
        sx={flocDetectorShiftsData.length > 0 ? styles.shiftListContainer : null}
        flexWrap="nowrap"
      >
        {flocDetectorShiftsData.length > 0 ? (
          flocDetectorShiftsData.map((flocDetectorShift: any) => {
            let serialNumber = flocDetectorShift.serialNumber;
            let shiftName = flocDetectorShift.shiftName;
            let startTime = flocDetectorShift.startTime;
            let endTime = flocDetectorShift.endTime;
            return (
              <Grid
                container
                item
                display="flex"
                justifyContent="flex-start"
                sx={{
                  '&:hover': {
                    borderRadius: '1px solid #D4E4F2',
                    backgroundColor: '#EDF3F9',
                  },
                  cursor: 'pointer',
                }}
                py={1}
                px={1}
                borderRadius="4px"
                gap={1}
                onClick={() => handleEditItem(serialNumber)}
                flexWrap="nowrap"
              >
                <Grid item>
                  <Typography
                    sx={{
                      whiteSpace: 'nowrap',
                      fontSize:
                        settingDialogue || leftToolBar ? '16px' : isMobile ? '12px' : '16px',
                    }}
                  >
                    {serialNumber}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      whiteSpace: 'nowrap',
                      fontSize:
                        settingDialogue || leftToolBar ? '16px' : isMobile ? '12px' : '16px',
                    }}
                  >
                    |
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      whiteSpace: 'nowrap',
                      fontSize:
                        settingDialogue || leftToolBar ? '16px' : isMobile ? '12px' : '16px',
                      marginTop: '1px',
                    }}
                  >
                    {startTime}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      whiteSpace: 'nowrap',
                      fontSize:
                        settingDialogue || leftToolBar ? '16px' : isMobile ? '12px' : '16px',
                      marginTop: isMobile ? '1px' : '',
                    }}
                  >
                    {' '}
                    -{' '}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      whiteSpace: 'nowrap',
                      fontSize:
                        settingDialogue || leftToolBar ? '16px' : isMobile ? '12px' : '16px',
                      marginTop: '1px',
                    }}
                  >
                    {endTime}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      whiteSpace: 'nowrap',
                      fontSize:
                        settingDialogue || leftToolBar ? '16px' : isMobile ? '12px' : '16px',
                    }}
                  >
                    |
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      whiteSpace: 'nowrap',
                      fontSize:
                        settingDialogue || leftToolBar ? '16px' : isMobile ? '12px' : '16px',
                      marginTop: isMobile ? '1px' : '',
                    }}
                  >
                    {shiftName}
                  </Typography>
                </Grid>
              </Grid>
            );
          })
        ) : (
          <Grid item>-</Grid>
        )}
      </Grid>
      <Divider />
      {flocDetectorShiftsData.length === 0 && (
        <Grid item>
          <Typography
            variant="caption"
            sx={{
              color: '#757575',
              fontSize: '10px',
              fontWeight: 400,
            }}
          >
            No shift added
          </Typography>
        </Grid>
      )}
      {newShiftStartTimeError && (
        <Grid item mb={3}>
          <Typography
            variant="caption"
            sx={{
              color: '#E64A23',
              fontSize: '10px',
              fontWeight: 400,
            }}
          >
            {errorStartTime}
          </Typography>
        </Grid>
      )}

      {(!totalShift24hrs || editSerialNumber !== null) && (
        <Grid mt={3} mb={3} container justifyContent="space-between" flexWrap="nowrap" gap="1rem">
          <TextField
            type="text"
            autoComplete="off"
            variant="outlined"
            label="Sr. No"
            name="serialNumber"
            sx={{
              width: isTablet && flocDetectorShiftsData.length === 0 ? '60px' : '80px',
              '& input': { textAlign: 'center' },
            }}
            value={flocDetectorShifts.serialNumber}
            onChange={handleChangeFlocDetectorShifts}
            disabled
          />
          <TextField
            type="text"
            autoComplete="off"
            variant="outlined"
            label="Name"
            fullWidth
            name="shiftName"
            value={flocDetectorShifts.shiftName}
            onChange={handleChangeFlocDetectorShifts}
            {...(isTablet && flocDetectorShiftsData.length === 0
              ? {
                  sx: {
                    width: '25vw',
                  },
                }
              : {})}
          />
        </Grid>
      )}
      {(!totalShift24hrs || editSerialNumber !== null) && (
        <Grid container flexWrap="nowrap" gap="1rem" mb={totalShift24hrs ? 3 : 0}>
          <TextField
            type="text"
            autoComplete="off"
            variant="outlined"
            label="Start Time"
            value={flocDetectorShifts.startTime}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ marginLeft: '0px !important' }}>
                  <AccessTimeIcon
                    fontSize="small"
                    sx={{
                      color: '#C2C2C3',
                      height: isMobile ? '13px' : null,
                      marginRight: isMobile ? '8px' : null,
                    }}
                    onClick={() => {
                      setSelectedTime(dayjs(flocDetectorShifts.startTime, 'hh:mm A'));
                      setIsStartTimeFocused(true);
                      setIsEndTimeFocused(false);
                    }}
                  />
                </InputAdornment>
              ),
            }}
            name="startTime"
            onChange={handleChangeFlocDetectorShifts}
            onFocus={() => {
              setSelectedTime(dayjs(flocDetectorShifts.startTime, 'hh:mm A'));
              setIsStartTimeFocused(true);
              setIsEndTimeFocused(false);
            }}
            onClick={() => {
              if (isMobile) {
                handleClickOpenDialog();
                setLabelFontSizeStartTime(16);
              } else {
                handleClickOpenDialog();
              }
            }}
            classes={{ root: isMobile ? classes.customInputRoot : undefined }}
            InputLabelProps={isMobile ? { style: { fontSize: labelFontSizeStartTime + 'px' } } : {}}
          />
          <TextField
            type="text"
            autoComplete="off"
            variant="outlined"
            label="End Time"
            value={flocDetectorShifts.endTime}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AccessTimeIcon
                    fontSize="small"
                    sx={{
                      color: '#C2C2C3',
                      height: isMobile ? '13px' : null,
                      marginRight: isMobile ? '8px' : null,
                    }}
                    onClick={() => {
                      setSelectedTime(dayjs(flocDetectorShifts.endTime, 'hh:mm A'));
                      setIsStartTimeFocused(false);
                      setIsEndTimeFocused(true);
                    }}
                  />
                </InputAdornment>
              ),
            }}
            name="endTime"
            onChange={handleChangeFlocDetectorShifts}
            onFocus={() => {
              setSelectedTime(dayjs(flocDetectorShifts.endTime, 'hh:mm A'));
              setIsStartTimeFocused(false);
              setIsEndTimeFocused(true);
            }}
            onClick={() => {
              if (isMobile) {
                handleClickOpenDialog();
                setLabelFontSizeEndTime(16);
              } else {
                handleClickOpenDialog();
              }
            }}
            classes={{ root: isMobile ? classes.customInputRoot : undefined }}
            InputLabelProps={isMobile ? { style: { fontSize: labelFontSizeEndTime + 'px' } } : {}}
          />
        </Grid>
      )}

      {!totalShift24hrs && (
        <Grid item mb={3}>
          <Typography
            variant="caption"
            sx={{
              color: '#E64A23',
              fontSize: '10px',
              fontWeight: 400,
            }}
          >
            {error24hrs}
          </Typography>
        </Grid>
      )}
      {editSerialNumber ? (
        <Button
          variant="contained"
          fullWidth
          sx={styles.PositiveButtonStyle}
          onClick={() => addFlocDetectorShifts(widgetClusterIndex, widgetIndex)}
          disabled={!!errorStartTime || fillAllFieldsError}
        >
          Save
        </Button>
      ) : (
        <Button
          variant="contained"
          fullWidth
          sx={styles.PositiveButtonStyle}
          onClick={() => addFlocDetectorShifts(widgetClusterIndex, widgetIndex)}
          disabled={
            !!errorStartTime || fillAllFieldsError || totalShift24hrs || newShiftStartTimeError
          }
        >
          {totalShift24hrs ? 'Done' : 'Save'}
        </Button>
      )}
    </Grid>
  );
};

export default ShiftDetailsComponent;
