import {
  Box,
  Breadcrumbs,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import CheckboxList from '../../../core-components/theme/components/List/List';
import SearchBox from '../../../core-components/theme/components/Searchbox/Searchbox';
import { Neutrals, Primary } from '../../../core-components/theme/color-palette/colors';
import LayoutComponent from '../../../components/LayoutComponent';
import TwoRowLayout from '../../../core-components/theme/layouts/TwoLayout/TwoLayoutComponent';
import Button from '../../../core-components/theme/components/Button/Button';
import { getWorkspaceDetails, saveWorkspace } from '../../../services/newWorkspaces';
import { isValidNameFormat } from '../../../utilities/name.utils';
import { isDescriptionProperlyFormatted } from '../../../utilities/description.utils';

const descriptionLimit = 200;
const WorkspaceCreatePage = () => {
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/workspaceList">
      <Typography key="3" color="#666666">
        Workspace List
      </Typography>
    </Link>,
    <Typography key="3" color="#666666">
      Create Workspace
    </Typography>,
  ];
  const [query, setQuery] = useState('');
  const [isDetailOpen, setIsDetailOpen] = useState(true);
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { id: workspaceId } = useParams();
  const [formData, setFormData] = useState<any>({
    workspaceId,
    name: '',
    description: '',
    assets: [],
  });
  const navigateToWorkspaceList = () => {
    navigate('/workspaceList');
  };
  const { data: workspaceDetails, status } = useQuery({
    queryKey: ['getWorkspaceData', workspaceId],
    queryFn: () => getWorkspaceDetails(workspaceId || ''),
    enabled: !!workspaceId,
    refetchOnWindowFocus: false,
  });

  function handleStateChange(val: string[]) {
    setFormData((prevData: any) => ({
      ...prevData,
      assets: val,
    }));
  }

  const handleSearch = (query: string) => {
    setQuery(query);
  };

  const getFilteredWorkspaces = () => {
    const filtered: any = workspaceDetails?.assets.filter((item: any) =>
      item.name.toLowerCase().includes(query.toLowerCase())
    );
    return filtered;
  };

  const handleClear = () => {
    setQuery('');
  };

  function handleFormData(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  const handleToggle = () => {
    setIsDetailOpen(!isDetailOpen);
  };
  const saveWorkspaceData = () => {
    formData.name = formData.name.trim();
    if (!isValidNameFormat(formData.name)) {
      toast.error('Invalid name');
      return;
    }
    formData.description = formData.description?.trim() || '';
    if (!isDescriptionProperlyFormatted(formData.description)) {
      toast.error(`Description cannot be this long, max length is ${descriptionLimit} characters`);
      return;
    }
    mutation.mutate();
  };
  const mutation = useMutation({
    mutationFn: () => saveWorkspace(formData),
    onSuccess: data => {
      // Check if the response indicates success or failure
      if (data !== undefined) {
        if (workspaceId === 'null') {
          toast.success(`Workspace created`);
        } else {
          toast.success(`Workspace edited`);
        }
        navigate('/workspaceList'); // Redirect to another page
      } else {
        throw new Error('Failed to save workspace');
      }
    },
    onError: error => {
      toast.error(`Problem in saving workspace`);
      console.error('Error saving workspace:', error);
    },
  });

  useEffect(() => {
    if (status === 'success' && workspaceDetails) {
      // setFilteredData(workspaceDetails.assets);
      setFormData((prevData: any) => ({
        ...prevData,
        workspaceId,
        name: workspaceDetails.name,
        description: workspaceDetails.description,
        assets: workspaceDetails.assets
          .filter((item: any) => item.checked)
          .map((item: any) => item._id),
      }));
    } else if (status === 'error') {
      // console.error('Error fetching workspace data:', error);
    }
  }, [status, workspaceDetails, workspaceId]);

  return (
    <LayoutComponent factoryResetContainer={true}>
      <TwoRowLayout
        style={{
          pl: isMobile ? 2 : 4,
          pr: isMobile ? 2 : 4,
        }}
        child1={
          <Stack direction="column" width="100%">
            <Breadcrumbs
              separator={<ArrowForwardIcon fontSize="small" sx={{ color: '#666666' }} />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
            <Box
              sx={{
                mt: 2,
                mb: 2,
                backgroundColor: `${Primary[50]}`,
                borderRadius: 1,
                padding: isMobile ? '16px 24px' : '33px 23px',
                border: '1px solid #bdbdbd',
              }}
            >
              <Grid container direction="column" borderRadius="12px">
                <Grid
                  container
                  onClick={handleToggle}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {isMobile && (
                    <Typography
                      variant="body"
                      letterSpacing="0.15px"
                      color="#666"
                      lineHeight="24px"
                    >
                      Workspace Details
                    </Typography>
                  )}
                  {isMobile && (
                    <IconButton>{isDetailOpen ? <ExpandLess /> : <ExpandMore />}</IconButton>
                  )}
                </Grid>
                {isMobile && isDetailOpen && (
                  <Divider variant="fullWidth" sx={{ mt: 1, mb: '24px', color: '#bdbdbd' }} />
                )}
                <Collapse in={isDetailOpen} timeout="auto" unmountOnExit>
                  <Grid
                    container
                    direction={isMobile ? 'column' : 'row'}
                    justifyContent="space-around"
                    alignItems="center"
                    gap={7}
                  >
                    <Grid item xs={12} sm={4} width={isMobile ? '100%' : undefined}>
                      <TextField
                        label="Workspace Name"
                        placeholder="Workspace Name"
                        value={formData.name}
                        name="name"
                        onChange={handleFormData}
                        variant="outlined"
                        required
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={7} width={isMobile ? '100%' : undefined}>
                      <TextField
                        label="Description"
                        name="description"
                        value={formData.description}
                        placeholder="Workspace Description"
                        onChange={handleFormData}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </Box>
            <Box sx={{ width: '100%' }}>
              <SearchBox
                placeholder="Search for assets"
                sx={{
                  width: '100%',
                  flexGrow: 1,
                  mt: 2,
                  mb: 2,
                  height: '52px',
                  borderColor: `${Neutrals[500]}`,
                  borderRadius: '4px',
                }}
                onSearch={handleSearch}
                onClear={handleClear}
              />
            </Box>
          </Stack>
        }
        child2={
          <Box p={0} height="50vh" overflow="auto">
            <CheckboxList
              initialChecked={formData.assets}
              show={true}
              heading="Asset List"
              items={getFilteredWorkspaces() || []}
              showAvatar={false}
              onToggle={handleStateChange}
            />
            <Box
              pt={4}
              gap={6}
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: isMobile ? 'center' : 'flex-end',
              }}
            >
              <Button
                variant="contained"
                sx={{
                  border: `1px solid ${Neutrals[500]}`,
                  borderRadius: '4px',
                  padding: isMobile ? '10px 40px' : '10px 70px',
                  backgroundColor: 'white',
                  color: `${Neutrals[500]}`,
                  flex: isMobile ? 1 : 0,
                  boxShadow: 'none',
                  ':hover': { background: 'none', boxShadow: 'none' },
                }}
                onClick={navigateToWorkspaceList}
              >
                <Typography variant="body" lineHeight="24px" color="#8B8B8B">
                  Cancel
                </Typography>
              </Button>
              <Button
                disabled={formData.name?.length === 0 || mutation.isPending}
                variant="contained"
                sx={{
                  padding: isMobile ? '10px 40px' : '10px 70px',
                  flex: isMobile ? 1 : 0,
                  borderRadius: '4px',
                  backgroundColor: `${Primary[500]}`,
                  boxShadow: 'none',
                  ':hover': { boxShadow: 'none' },
                }}
                onClick={saveWorkspaceData}
              >
                <Typography variant="body" lineHeight="24px" color="#fff">
                  Save
                </Typography>
              </Button>
            </Box>
          </Box>
        }
      />
    </LayoutComponent>
  );
};

export default WorkspaceCreatePage;
