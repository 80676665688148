const flocDetectorDefaultSamplesData: any = [
  {
    '14 Dec 2023': {
      Morning: {
        'Total Sample': 14,
        'Default Sample': 7,
        '30 minutes': {
          'Detected Time': '09:45 AM',
          value: '620 ml',
          thumbnail: {
            url: 'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
            imageId: '6631049c4398b43e0f2ef608',
          },
          floatingSludgeIcon: {
            url: 'https://svgshare.com/i/16aS.svg',
            imageId: '6631049c4398b43e0f2ef608',
          },
        },
        '60 minutes': {
          'Detected Time': '10:15 AM',
          value: '310 ml',
        },
        '90 minutes': {
          'Detected Time': '10:45 AM',
          value: '160 ml',
          thumbnail: {
            url: 'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
            imageId: '6631049c4398b43e0f2ef608',
          },
          falseDetectionIcon: {
            url: 'https://svgshare.com/i/16_6.svg',
            imageId: '6631049c4398b43e0f2ef608',
          },
        },
      },
      Afternoon: {
        'Total Sample': 12,
        'Default Sample': 6,
        '30 minutes': {
          'Detected Time': '01:05 PM',
          value: '570 ml',
          thumbnail: {
            url: 'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
            imageId: '6631049c4398b43e0f2ef608',
          },
          noSludgeIcon: {
            url: 'https://svgshare.com/i/16ZN.svg',
            imageId: '6631049c4398b43e0f2ef608',
          },
        },
        '60 minutes': {
          'Detected Time': '01:35 PM',
          value: '285 ml',
        },
        '90 minutes': {
          'Detected Time': '02:05 PM',
          value: '150 ml',
          thumbnail: {
            url: 'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
            imageId: '6631049c4398b43e0f2ef608',
          },
          floatingSludgeIcon: {
            url: 'https://svgshare.com/i/16aS.svg',
            imageId: '6631049c4398b43e0f2ef608',
          },
        },
      },
      Evening: {
        'Total Sample': 10,
        'Default Sample': 5,
        '30 minutes': {
          'Detected Time': '07:10 PM',
          value: '520 ml',
          thumbnail: {
            url: 'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
            imageId: '6631049c4398b43e0f2ef608',
          },
          falseDetectionIcon: {
            url: 'https://svgshare.com/i/16_6.svg',
            imageId: '6631049c4398b43e0f2ef608',
          },
        },
        '60 minutes': {
          'Detected Time': '07:40 PM',
          value: '310 ml',
        },
        '90 minutes': {
          'Detected Time': '08:10 PM',
          value: '160 ml',
          thumbnail: {
            url: 'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
            imageId: '6631049c4398b43e0f2ef608',
          },
          noSludgeIcon: {
            url: 'https://svgshare.com/i/16ZN.svg',
            imageId: '6631049c4398b43e0f2ef608',
          },
        },
      },
    },
  },
  {
    '15 Dec 2023': {
      Morning: {
        'Total Sample': 9,
        'Default Sample': 4,
        '30 minutes': {
          'Detected Time': '08:00 AM',
          value: '630 ml',
          thumbnail: {
            url: 'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
            imageId: '6631049c4398b43e0f2ef608',
          },
          floatingSludgeIcon: {
            url: 'https://svgshare.com/i/16aS.svg',
            imageId: '6631049c4398b43e0f2ef608',
          },
        },
        '60 minutes': {
          'Detected Time': '08:30 AM',
          value: '315 ml',
        },
        '90 minutes': {
          'Detected Time': '09:00 AM',
          value: '165 ml',
          thumbnail: {
            url: 'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
            imageId: '6631049c4398b43e0f2ef608',
          },
          falseDetectionIcon: {
            url: 'https://svgshare.com/i/16_6.svg',
            imageId: '6631049c4398b43e0f2ef608',
          },
        },
      },
      Afternoon: {
        'Total Sample': 13,
        'Default Sample': 6,
        '30 minutes': {
          'Detected Time': '01:25 PM',
          value: '580 ml',
          thumbnail: {
            url: 'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
            imageId: '6631049c4398b43e0f2ef608',
          },
          noSludgeIcon: {
            url: 'https://svgshare.com/i/16ZN.svg',
            imageId: '6631049c4398b43e0f2ef608',
          },
        },
        '60 minutes': {
          'Detected Time': '01:55 PM',
          value: '290 ml',
        },
        '90 minutes': {
          'Detected Time': '02:25 PM',
          value: '155 ml',
          thumbnail: {
            url: 'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
            imageId: '6631049c4398b43e0f2ef608',
          },
          floatingSludgeIcon: {
            url: 'https://svgshare.com/i/16aS.svg',
            imageId: '6631049c4398b43e0f2ef608',
          },
        },
      },
      Evening: {
        'Total Sample': 11,
        'Default Sample': 7,
        '30 minutes': {
          'Detected Time': '07:15 PM',
          value: '630 ml',
          thumbnail: {
            url: 'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
            imageId: '6631049c4398b43e0f2ef608',
          },
          falseDetectionIcon: {
            url: 'https://svgshare.com/i/16_6.svg',
            imageId: '6631049c4398b43e0f2ef608',
          },
        },
        '60 minutes': {
          'Detected Time': '07:45 PM',
          value: '315 ml',
        },
        '90 minutes': {
          'Detected Time': '08:15 PM',
          value: '165 ml',
          thumbnail: {
            url: 'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
            imageId: '6631049c4398b43e0f2ef608',
          },
          noSludgeIcon: {
            url: 'https://svgshare.com/i/16ZN.svg',
            imageId: '6631049c4398b43e0f2ef608',
          },
        },
      },
    },
  },
  {
    '16 Dec 2023': {
      Morning: {
        'Total Sample': 8,
        'Default Sample': 3,
        '30 minutes': {
          'Detected Time': '07:40 AM',
          value: '640 ml',
          thumbnail: {
            url: 'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
            imageId: '6631049c4398b43e0f2ef608',
          },
          floatingSludgeIcon: {
            url: 'https://svgshare.com/i/16aS.svg',
            imageId: '6631049c4398b43e0f2ef608',
          },
        },
        '60 minutes': {
          'Detected Time': '08:10 AM',
          value: '320 ml',
        },
        '90 minutes': {
          'Detected Time': '08:40 AM',
          value: '170 ml',
          thumbnail: {
            url: 'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
            imageId: '6631049c4398b43e0f2ef608',
          },
          falseDetectionIcon: {
            url: 'https://svgshare.com/i/16_6.svg',
            imageId: '6631049c4398b43e0f2ef608',
          },
        },
      },
      Afternoon: {
        'Total Sample': 12,
        'Default Sample': 5,
        '30 minutes': {
          'Detected Time': '01:30 PM',
          value: '590 ml',
          thumbnail: {
            url: 'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
            imageId: '6631049c4398b43e0f2ef608',
          },
          noSludgeIcon: {
            url: 'https://svgshare.com/i/16ZN.svg',
            imageId: '6631049c4398b43e0f2ef608',
          },
        },
        '60 minutes': {
          'Detected Time': '02:00 PM',
          value: '295 ml',
        },
        '90 minutes': {
          'Detected Time': '02:30 PM',
          value: '160 ml',
          thumbnail: {
            url: 'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
            imageId: '6631049c4398b43e0f2ef608',
          },
          floatingSludgeIcon: {
            url: 'https://svgshare.com/i/16aS.svg',
            imageId: '6631049c4398b43e0f2ef608',
          },
        },
      },
      Evening: {
        'Total Sample': 10,
        'Default Sample': 6,
        '30 minutes': {
          'Detected Time': '07:05 PM',
          value: '640 ml',
          thumbnail: {
            url: 'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
            imageId: '6631049c4398b43e0f2ef608',
          },
          falseDetectionIcon: {
            url: 'https://svgshare.com/i/16_6.svg',
            imageId: '6631049c4398b43e0f2ef608',
          },
        },
        '60 minutes': {
          'Detected Time': '07:35 PM',
          value: '320 ml',
        },
        '90 minutes': {
          'Detected Time': '08:05 PM',
          value: '170 ml',
          thumbnail: {
            url: 'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
            imageId: '6631049c4398b43e0f2ef608',
          },
          noSludgeIcon: {
            url: 'https://svgshare.com/i/16ZN.svg',
            imageId: '6631049c4398b43e0f2ef608',
          },
        },
      },
    },
  },
  {
    '17 Dec 2023': {
      Morning: {
        'Total Sample': 11,
        'Default Sample': 5,
        '30 minutes': {
          'Detected Time': '07:25 AM',
          value: '650 ml',
          thumbnail: {
            url: 'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
            imageId: '6631049c4398b43e0f2ef608',
          },
          floatingSludgeIcon: {
            url: 'https://svgshare.com/i/16aS.svg',
            imageId: '6631049c4398b43e0f2ef608',
          },
        },
        '60 minutes': {
          'Detected Time': '07:55 AM',
          value: '325 ml',
        },
        '90 minutes': {
          'Detected Time': '08:25 AM',
          value: '175 ml',
          thumbnail: {
            url: 'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
            imageId: '6631049c4398b43e0f2ef608',
          },
          falseDetectionIcon: {
            url: 'https://svgshare.com/i/16_6.svg',
            imageId: '6631049c4398b43e0f2ef608',
          },
        },
      },
      Afternoon: {
        'Total Sample': 14,
        'Default Sample': 7,
        '30 minutes': {
          'Detected Time': '01:10 PM',
          value: '600 ml',
          thumbnail: {
            url: 'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
            imageId: '6631049c4398b43e0f2ef608',
          },
          noSludgeIcon: {
            url: 'https://svgshare.com/i/16ZN.svg',
            imageId: '6631049c4398b43e0f2ef608',
          },
        },
        '60 minutes': {
          'Detected Time': '01:40 PM',
          value: '300 ml',
        },
        '90 minutes': {
          'Detected Time': '02:10 PM',
          value: '150 ml',
          thumbnail: {
            url: 'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
            imageId: '6631049c4398b43e0f2ef608',
          },
          floatingSludgeIcon: {
            url: 'https://svgshare.com/i/16aS.svg',
            imageId: '6631049c4398b43e0f2ef608',
          },
        },
      },
      Evening: {
        'Total Sample': 12,
        'Default Sample': 8,
        '30 minutes': {
          'Detected Time': '07:20 PM',
          value: '650 ml',
          thumbnail: {
            url: 'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
            imageId: '6631049c4398b43e0f2ef608',
          },
          falseDetectionIcon: {
            url: 'https://svgshare.com/i/16_6.svg',
            imageId: '6631049c4398b43e0f2ef608',
          },
        },
        '60 minutes': {
          'Detected Time': '07:50 PM',
          value: '325 ml',
        },
        '90 minutes': {
          'Detected Time': '08:20 PM',
          value: '175 ml',
          thumbnail: {
            url: 'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
            imageId: '6631049c4398b43e0f2ef608',
          },
          noSludgeIcon: {
            url: 'https://svgshare.com/i/16ZN.svg',
            imageId: '6631049c4398b43e0f2ef608',
          },
        },
      },
    },
  },
  {
    '18 Dec 2023': {
      Morning: {
        'Total Sample': 10,
        'Default Sample': 5,
        '30 minutes': {
          'Detected Time': '08:15 AM',
          value: '660 ml',
          thumbnail: {
            url: 'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
            imageId: '6631049c4398b43e0f2ef608',
          },
          floatingSludgeIcon: {
            url: 'https://svgshare.com/i/16aS.svg',
            imageId: '6631049c4398b43e0f2ef608',
          },
        },
        '60 minutes': {
          'Detected Time': '08:45 AM',
          value: '330 ml',
        },
        '90 minutes': {
          'Detected Time': '09:15 AM',
          value: '180 ml',
        },
      },
      Afternoon: {
        'Total Sample': 13,
        'Default Sample': 6,
        '30 minutes': {
          'Detected Time': '01:35 PM',
          value: '610 ml',
          thumbnail: {
            url: 'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
            imageId: '6631049c4398b43e0f2ef608',
          },
          falseDetectionIcon: {
            url: 'https://svgshare.com/i/16_6.svg',
            imageId: '6631049c4398b43e0f2ef608',
          },
        },
        '60 minutes': {
          'Detected Time': '02:05 PM',
          value: '305 ml',
        },
        '90 minutes': {
          'Detected Time': '02:35 PM',
          value: '165 ml',
        },
      },
      Evening: {
        'Total Sample': 11,
        'Default Sample': 7,
        '30 minutes': {
          'Detected Time': '07:35 PM',
          value: '660 ml',
          thumbnail: {
            url: 'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
            imageId: '6631049c4398b43e0f2ef608',
          },
          noSludgeIcon: {
            url: 'https://svgshare.com/i/16ZN.svg',
            imageId: '6631049c4398b43e0f2ef608',
          },
        },
        '60 minutes': {
          'Detected Time': '08:05 PM',
          value: '330 ml',
        },
        '90 minutes': {
          'Detected Time': '08:35 PM',
          value: '180 ml',
        },
      },
    },
  },
  {
    '19 Dec 2023': {
      Morning: {
        'Total Sample': 9,
        'Default Sample': 4,
        '30 minutes': {
          'Detected Time': '08:10 AM',
          value: '670 ml',
        },
        '60 minutes': {
          'Detected Time': '08:40 AM',
          value: '335 ml',
        },
        '90 minutes': {
          'Detected Time': '09:10 AM',
          value: '185 ml',
        },
      },
      Afternoon: {
        'Total Sample': 12,
        'Default Sample': 6,
        '30 minutes': {
          'Detected Time': '01:45 PM',
          value: '620 ml',
        },
        '60 minutes': {
          'Detected Time': '02:15 PM',
          value: '310 ml',
        },
        '90 minutes': {
          'Detected Time': '02:45 PM',
          value: '170 ml',
        },
      },
      Evening: {
        'Total Sample': 10,
        'Default Sample': 5,
        '30 minutes': {
          'Detected Time': '07:45 PM',
          value: '670 ml',
        },
        '60 minutes': {
          'Detected Time': '08:15 PM',
          value: '335 ml',
        },
        '90 minutes': {
          'Detected Time': '08:45 PM',
          value: '185 ml',
        },
      },
    },
  },
  {
    '20 Dec 2023': {
      Morning: {
        'Total Sample': 10,
        'Default Sample': 5,
        '30 minutes': {
          'Detected Time': '08:05 AM',
          value: '680 ml',
        },
        '60 minutes': {
          'Detected Time': '08:35 AM',
          value: '340 ml',
        },
        '90 minutes': {
          'Detected Time': '09:05 AM',
          value: '190 ml',
        },
      },
      Afternoon: {
        'Total Sample': 14,
        'Default Sample': 7,
        '30 minutes': {
          'Detected Time': '01:55 PM',
          value: '630 ml',
        },
        '60 minutes': {
          'Detected Time': '02:25 PM',
          value: '315 ml',
        },
        '90 minutes': {
          'Detected Time': '02:55 PM',
          value: '180 ml',
        },
      },
      Evening: {
        'Total Sample': 11,
        'Default Sample': 6,
        '30 minutes': {
          'Detected Time': '07:50 PM',
          value: '680 ml',
        },
        '60 minutes': {
          'Detected Time': '08:20 PM',
          value: '340 ml',
        },
        '90 minutes': {
          'Detected Time': '08:50 PM',
          value: '190 ml',
        },
      },
    },
  },
  {
    '21 Dec 2023': {
      Morning: {
        'Total Sample': 12,
        'Default Sample': 6,
        '30 minutes': {
          'Detected Time': '08:20 AM',
          value: '690 ml',
        },
        '60 minutes': {
          'Detected Time': '08:50 AM',
          value: '345 ml',
        },
        '90 minutes': {
          'Detected Time': '09:20 AM',
          value: '185 ml',
        },
      },
      Afternoon: {
        'Total Sample': 10,
        'Default Sample': 5,
        '30 minutes': {
          'Detected Time': '01:45 PM',
          value: '640 ml',
        },
        '60 minutes': {
          'Detected Time': '02:15 PM',
          value: '320 ml',
        },
        '90 minutes': {
          'Detected Time': '02:45 PM',
          value: '170 ml',
        },
      },
      Evening: {
        'Total Sample': 14,
        'Default Sample': 7,
        '30 minutes': {
          'Detected Time': '07:55 PM',
          value: '690 ml',
        },
        '60 minutes': {
          'Detected Time': '08:25 PM',
          value: '345 ml',
        },
        '90 minutes': {
          'Detected Time': '08:55 PM',
          value: '185 ml',
        },
      },
    },
  },
];

export default flocDetectorDefaultSamplesData;
