import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Autocomplete,
  Checkbox,
  CircularProgress,
  IconButton,
  Typography,
  Stack,
  Paper,
  PaperProps,
  FormHelperText,
  useTheme,
  ListSubheader,
  Chip,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { toast } from 'react-toastify';
import { makeStyles, createStyles } from '@mui/styles';

import { IStore, IUser, IPlant } from '../../Interfaces/storeConfigurationInterfaces';
import { updateStore, getAllClients, getSelectedClientAssets } from '../../services/stores';
import styles from './StoreConfigurationComponents.module.css';
import StoreAbbreviationSelection from './StoreAbbreviationSelection';

interface EditStoreDialogProps {
  open: boolean;
  onClose: () => void;
  store: IStore;
  refreshData: () => void;
}

const useStyles = makeStyles(theme =>
  createStyles({
    option: {
      '&[data-focus="true"]': {
        backgroundColor: 'white !important',
      },
      '&[aria-selected="true"]': {
        backgroundColor: 'white !important',
      },
    },
  })
);

const CustomPaper: React.FC<PaperProps> = props => {
  const theme = useTheme();

  return (
    <Paper
      {...props}
      sx={{
        '& .MuiListSubheader-root': {
          fontSize: '16px',
          color: 'rgba(0, 0, 0, 0.54)',
          paddingTop: theme.spacing(1),
          paddingBottom: '0 !important',
          marginBottom: '0 !important',
        },
        '& .MuiAutocomplete-listbox': {
          paddingTop: '0 !important',
        },
      }}
    >
      <ListSubheader>List of Assets</ListSubheader>
      {props.children}
    </Paper>
  );
};

const EditStoreDialog: React.FC<EditStoreDialogProps> = ({ open, onClose, store, refreshData }) => {
  const classes = useStyles();
  const [storeName, setStoreName] = useState(store.name);
  const [storeNameOnceBlur, setStoreNameOnceBlur] = useState(store.name);
  const [clients, setClients] = useState<IUser[]>([]);
  const [selectedClient, setSelectedClient] = useState<IUser | null>(null);
  const [assets, setAssets] = useState<IPlant[]>([]);
  const [selectedAssets, setSelectedAssets] = useState<IPlant[]>(store.assets || []);
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState('');
  const [areAssetsDisabled, setAreAssetsDisabled] = useState(false);
  const [storeNameError, setStoreNameError] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<IUser[]>([]);
  const [abbr, setAbbr] = useState(store.storeAbbr || '');
  const [isInputValueFromUser, setIsInputValueFromUser] = useState(false);
  const [abbrError, setAbbrError] = useState(false);
  const [showStoreAbbr, setShowStoreAbbr] = useState(true);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const clientsData = await getAllClients();
        setClients(clientsData);
        let selectedClientDetails = clientsData.find(
          (client: IUser) => client._id === store.clients[0]?._id
        );
        setSelectedClient(selectedClientDetails || null);
        setInputValue(`${selectedClientDetails?.name} | ${selectedClientDetails?.email}`);
        const assetsData = await getSelectedClientAssets(store.clients[0]._id);
        setAssets(assetsData);
      } catch (err) {
        console.error('Error fetching initial data:', err);
        setError('Failed to load clients or assets.');
      }
    };
    fetchInitialData();
  }, [store]);

  useEffect(() => {
    const fetchAssets = async () => {
      if (selectedClient) {
        try {
          const assetsData = await getSelectedClientAssets(selectedClient._id);
          if (assetsData.length === 0) {
            setError('No assets present for this client.');
            setAssets([]);
            setSelectedAssets([]);
            setAreAssetsDisabled(true);
          } else {
            setError('');
            setAssets(assetsData);
            setAreAssetsDisabled(false);
          }
        } catch (err) {
          console.error('Error fetching assets:', err);
          setError('Failed to load assets for selected client.');
          setAreAssetsDisabled(true);
        }
      } else {
        setAssets([]);
        // setSelectedAssets([]);
        setAreAssetsDisabled(true);
      }
    };
    fetchAssets();
  }, [selectedClient]);

  const handleUpdateStore = async () => {
    if (
      !storeName ||
      !selectedClient ||
      selectedAssets.length === 0 ||
      !abbr ||
      abbr === '' ||
      abbr === undefined ||
      abbr === null ||
      abbr.length === 0
    ) {
      toast.error('Please fill all the required fields.');
      return;
    }
    try {
      setIsUpdating(true);
      await updateStore(store._id, {
        name: storeName,
        assets: selectedAssets.map(asset => asset._id),
        clients: [selectedClient._id],
        storeAbbr: abbr,
      });
      toast.success('Store updated successfully');
      refreshData();
      onClose();
    } catch (error) {
      toast.error('Error updating store');
      console.error('Error updating store:', error);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleClientChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: IUser | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<IUser>
  ) => {
    setSelectedClient(newValue || null);
  };

  const handleAssetChange = (event: React.ChangeEvent<{}>, newValue: IPlant[]) => {
    // Create a map to store unique assets
    const uniqueAssetsMap = new Map();

    // Iterate over newValue and add items to the map
    // The map will automatically ensure each _id is unique
    newValue.forEach(asset => {
      uniqueAssetsMap.set(asset._id, asset);
    });

    // Convert the map values back to an array
    const uniqueAssets = Array.from(uniqueAssetsMap.values());

    setSelectedAssets(uniqueAssets);
  };

  const handleStoreNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setStoreName(newValue);
  };

  const handleStoreNameBlur = () => {
    // Check for alphanumeric characters
    const isAlphanumeric = /^[a-z0-9\s]*$/i.test(storeName);

    if (!isAlphanumeric && storeName !== '') {
      setStoreNameError(`Store name can't contain special characters`);
      setShowStoreAbbr(false);
    } else if (storeName.length > 0 && storeName.length < 4) {
      setStoreNameError(
        `Store name should be at least 4 characters long to get store abbreviation`
      );
      setShowStoreAbbr(false);
    } else {
      setStoreNameError('');
      setStoreNameOnceBlur(storeName);
      if (storeName.length > 3) setShowStoreAbbr(true);
    }
  };

  const handleInputFocus = () => {
    setIsInputValueFromUser(true);
  };

  const handleInputChange = (event: React.ChangeEvent<{}>, newInputValue: string) => {
    if (isInputValueFromUser) {
      setInputValue(newInputValue);
      setSelectedAssets([]);
    }
    setFilteredOptions(
      clients.filter(
        (option: any) =>
          option?.name.toLowerCase().includes(newInputValue?.toLowerCase()) ||
          option?.email.toLowerCase().includes(newInputValue?.toLowerCase())
      )
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        className: styles.CustomDialogPaper,
      }}
    >
      <DialogTitle>
        <Typography variant="h4">Edit Store</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={styles.DialogContent}>
        <Stack spacing={3}>
          <Stack>
            <TextField
              label="Store Name"
              value={storeName}
              onChange={handleStoreNameChange}
              onBlur={handleStoreNameBlur}
              fullWidth
              required
              error={storeNameError.length > 0}
            />
            {showStoreAbbr && (
              <StoreAbbreviationSelection
                storeName={storeNameOnceBlur}
                abbr={abbr}
                setAbbr={setAbbr}
                setAbbrError={setAbbrError}
                component="edit"
              />
            )}
            {storeNameError && (
              <FormHelperText className={styles.FormHelperText} error>
                {storeNameError}
              </FormHelperText>
            )}
          </Stack>
          <Autocomplete
            value={selectedClient || null} // Ensure value is not undefined
            onChange={handleClientChange}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            onFocus={handleInputFocus}
            options={filteredOptions}
            getOptionLabel={option => {
              return `${option.name} | ${option.email}` || '';
            }}
            renderOption={(props, option: IUser) => (
              <li {...props}>
                <Stack direction="column" alignItems="flex-start">
                  <Typography variant="body1">{option.name}</Typography>
                  <Typography variant="body2" style={{ fontSize: '0.8rem', color: '#C4C4C4' }}>
                    {option.email}
                  </Typography>
                </Stack>
              </li>
            )}
            renderInput={params => <TextField {...params} label="User Group Name" required />}
            fullWidth
            isOptionEqualToValue={(option, value) => option._id === value._id}
          />
          <Autocomplete
            multiple
            options={assets}
            value={selectedAssets || []} // Ensure value is not undefined
            onChange={handleAssetChange}
            getOptionLabel={option => option.plantName}
            renderOption={(props, option: IPlant) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  checked={selectedAssets.map(asset => asset._id).includes(option._id)}
                />
                {option.plantName}
              </li>
            )}
            renderTags={(value: IPlant[], getTagProps) => {
              const extraCount = value.length > 2 ? `+${value.length - 2}` : null;
              return (
                <>
                  {value.slice(0, 2).map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.plantName}
                      {...getTagProps({ index })}
                      key={option._id}
                    />
                  ))}
                  {extraCount && (
                    <span style={{ marginLeft: 8, verticalAlign: 'middle' }}>{extraCount}</span>
                  )}
                </>
              );
            }}
            renderInput={params => (
              <TextField {...params} label="Assets" required fullWidth margin="normal" />
            )}
            fullWidth
            PaperComponent={CustomPaper}
            sx={{
              marginTop: 'unset !important',
              '&.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input': {
                padding: '7.5px 0 !important',
                minWidth: '0 !important',
              },
            }}
            classes={{
              option: classes.option,
            }}
            disabled={areAssetsDisabled}
            disableCloseOnSelect
          />
          {error && (
            <FormHelperText className={styles.FormHelperText} error>
              {error}
            </FormHelperText>
          )}
        </Stack>
      </DialogContent>
      <DialogActions className={styles.DialogActions}>
        <Button
          onClick={handleUpdateStore}
          disabled={
            isUpdating ||
            !storeName.trim() ||
            storeNameError.length > 0 ||
            !selectedClient ||
            selectedAssets.length === 0 ||
            areAssetsDisabled ||
            abbrError
          }
          className={styles.DialogCreateActionButton}
          fullWidth
        >
          {isUpdating ? <CircularProgress size={24} /> : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditStoreDialog;
