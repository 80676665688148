import {
  Typography,
  TextField,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
  Grid,
  Tooltip,
} from '@mui/material';
import DialogBox from '../../../core-components/theme/components/DialogBox/DialogBox';
import { useEffect, useState } from 'react';
import {
  MANUAL_TICKET_TYPE_OPTIONS,
  TICKET_SUB_TYPE_OPTIONS,
} from '../constants/tickets.constants';
import { createManualAlert } from '../../../services/tickets';
import { getPlantsForUser } from '../../../services/tickets';
import TagSelector from '../../../globalComponents/tagSystem/TagSelector';
import { PRIORITY_TYPES } from '../../../constants/constants';
import { toast } from 'react-toastify';
import { rephraseTextThroughAi } from '../../../services/ai.services';
import { Neutrals, Premium, Primary } from '../../../core-components/theme/color-palette/colors';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import useIsMobile from '../../../utilities/customHooks/useIsMobile';

export default function AddTicketBox({ open, handleClose, maxWidth = '90%' }: any) {
  const [plantList, setPlantList] = useState<any[]>([]);
  const [selectedPlant, setSelectedPlant] = useState<any>('');
  const [heading, setHeading] = useState('');
  const [description, setDescription] = useState('');
  const [ticketType, setTicketType] = useState('');
  const [ticketSubType, setTicketSubType] = useState('');
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [priorityType, setPriorityType] = useState<any>(PRIORITY_TYPES[1]);
  const [loadingForAiButton, setLoadingForAiButton] = useState(false);

  const [errors, setErrors] = useState({
    plant: false,
    heading: false,
    description: false,
    priority: false,
    type: false,
    subType: false,
  });

  useEffect(() => {
    if (open) {
      getPlantsForUser('null').then(data => {
        setPlantList(data);
      });
    }
    if (!open) {
      setSelectedPlant('');
      setHeading('');
      setDescription('');
      setSelectedTags([]);
      setPriorityType(PRIORITY_TYPES[1]);
      setTicketSubType('');
      setTicketType('');
      setErrors({
        plant: false,
        heading: false,
        description: false,
        priority: false,
        type: false,
        subType: false,
      });
    }
  }, [open]);

  const handlePlantChange = (event: any) => {
    setSelectedPlant(event.target.value);
  };

  const handleTicketTypeChange = (event: any) => {
    setTicketType(event.target.value);
  };

  const handleTicketSubTypeChange = (event: any) => {
    setTicketSubType(event.target.value);
  };

  const handlePriorityChange = (event: any) => {
    setPriorityType(event.target.value);
  };

  const addTicket = () => {
    const newErrors = {
      plant: !selectedPlant,
      heading: !heading.trim(),
      description: !description.trim(),
      priority: !priorityType,
      type: !ticketType,
      subType: !ticketSubType,
    };

    if (Object.values(newErrors).some(error => error)) {
      setErrors(newErrors);
      return;
    }

    createManualAlert(selectedPlant, {
      heading: heading,
      description: description,
      equipments: [],
      issueType: ticketType,
      subIssueType: ticketSubType,
      priority: priorityType,
      tags: selectedTags?.map((tag: any) => tag._id?.toString()),
    }).then(data => {
      toast('New ticket added!');
      handleClose();
    });
  };

  const rephrasText = () => {
    const backup = description;
    setLoadingForAiButton(true);
    rephraseTextThroughAi(description)
      .then((data: any) => {
        if (data != '') setDescription(data);
        setLoadingForAiButton(false);
      })
      .catch(err => {
        setDescription(backup);
        setLoadingForAiButton(false);
      });
  };

  return (
    <DialogBox
      title={<Typography variant="h5">Create Manual Entry</Typography>}
      closeIconSize={'small'}
      open={open}
      handleClose={handleClose}
      maxWidth={maxWidth}
      PaperProps={{
        style: {
          width: '75%', // Increase the width
          maxWidth: '75%', // Ensure the dialog doesn't grow beyond this width
          borderRadius: '15px',
          maxHeight: '90%',
        },
      }}
      // actions={[{ text: 'cancel' }, { text: 'submit', handler: addTicket }]}
    >
      <Stack direction="column" gap={3}>
        <Grid container sx={{ width: '100%', boxSizing: 'border-box' }}>
          <Grid item xs={12} sm={6} md={3} lg={3} p={1}>
            <FormControl fullWidth error={errors.plant}>
              <InputLabel id="plant-select-label">Plant Name</InputLabel>
              <Select
                labelId="plant-select-label"
                value={selectedPlant}
                label="Plant Name"
                onChange={handlePlantChange}
              >
                {plantList?.map((plant: any) => (
                  <MenuItem key={plant.plantId} value={plant.plantId}>
                    {plant.plantName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} p={1}>
            <FormControl fullWidth error={errors.priority}>
              <InputLabel id="priority-select-label">Priority</InputLabel>
              <Select
                labelId="priority-select-label"
                value={priorityType}
                label="Priority"
                onChange={handlePriorityChange}
              >
                {PRIORITY_TYPES?.map((type: any) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} p={1}>
            <FormControl fullWidth error={errors.type}>
              <InputLabel id="type-select-label">Type</InputLabel>
              <Select
                labelId="type-select-label"
                value={ticketType}
                label="Type"
                onChange={handleTicketTypeChange}
              >
                {MANUAL_TICKET_TYPE_OPTIONS?.map((type: any) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} p={1}>
            <FormControl fullWidth error={errors.subType}>
              <InputLabel id="subtype-select-label">Sub Type</InputLabel>
              <Select
                labelId="subtype-select-label"
                value={ticketSubType}
                label="Sub Type"
                onChange={handleTicketSubTypeChange}
              >
                {TICKET_SUB_TYPE_OPTIONS?.map((type: any) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.displayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {/* Use TagSelector Component */}

        <TextField
          id="heading-field"
          label="Issue Name"
          fullWidth
          variant="outlined"
          multiline
          rows={1}
          defaultValue={heading || ''}
          error={errors.heading}
          helperText={errors.heading ? 'Issue Name is required' : ''}
          onBlur={(event: any) => {
            setHeading(event.target.value);
          }}
        />

        <Stack
          direction="column"
          sx={{ border: `1px solid ${Neutrals[200]}`, boxSizing: 'border-box' }}
          p={2}
          borderRadius={3}
        >
          {loadingForAiButton ? (
            <Box sx={{ height: '150px', color: Neutrals[500] }} p={2}>
              Working our magic!
            </Box>
          ) : (
            <TextField
              id="description-field"
              fullWidth
              variant="outlined"
              placeholder="Type your text here!"
              multiline
              rows={5}
              defaultValue={description || ''}
              error={errors.description}
              helperText={errors.description ? 'Description is required' : ''}
              onBlur={(event: any) => {
                setDescription(event.target.value);
              }}
              sx={{
                background: 'white',
                '& fieldset': { border: 'none' },
              }}
            />
          )}
          <Stack direction="row" sx={{ width: '100%' }} gap={4}>
            <Box sx={{ width: '80%' }}>
              <TagSelector
                selectedTags={selectedTags}
                onChange={(newTags: any) => {
                  setSelectedTags(newTags);
                }}
              />
            </Box>
            {!useIsMobile({ breakpointValue: 'md' }) ? (
              <Button
                variant="outlined"
                sx={{
                  width: '20%',
                  color: Premium[700],
                  borderColor: Premium[700],
                  '&:hover': {
                    borderColor: Premium[700], // Change border color on hover
                    backgroundColor: Premium[50], // Add background color on hover
                  },
                }}
                disabled={loadingForAiButton}
                onClick={rephrasText}
              >
                Rephrase Using Ai
              </Button>
            ) : (
              <Tooltip title={'AI Widget'}>
                <Button
                  sx={{
                    width: { xs: 44, md: 48, lg: 56 },
                    background: '#fff',
                    border: '1px solid #451AB2',
                  }}
                  onClick={rephrasText}
                >
                  <AutoAwesomeIcon fontSize="medium" sx={{ color: '#451AB2' }} />
                </Button>
              </Tooltip>
            )}
          </Stack>
        </Stack>
        <Stack direction="row" sx={{ width: '100%' }} gap={2}>
          <Button
            variant="outlined"
            sx={{
              width: !useIsMobile({ breakpointValue: 'md' }) ? '15%' : '50%',
              marginLeft: 'auto',
              marginRight: '1rem',
              borderColor: Neutrals[200],
              color: Neutrals[400],
              '&:hover': {
                borderColor: Neutrals[400], // Change border color on hover
                backgroundColor: Neutrals[100], // Add background color on hover
                color: Neutrals[500], // Change text color on hover
              },
            }}
            disabled={loadingForAiButton}
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              width: !useIsMobile({ breakpointValue: 'md' }) ? '15%' : '50%',
              background: Primary[500],
              color: '#fff',
              '&:hover': {
                background: Primary[700], // Darker background on hover
              },
            }}
            disabled={loadingForAiButton}
            onClick={addTicket}
          >
            Submit
          </Button>
        </Stack>
      </Stack>
    </DialogBox>
  );
}
