import { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import useIsMobile from '../../../utilities/customHooks/useIsMobile';
import { Box } from '@mui/material';
import {
  calculateMaxHeight,
  extractThumbnails,
  getDataByDate,
  preloadImages,
  prepareChartData,
} from '../Helper';
import { ChartOptions } from 'chart.js/auto';

interface Thumbnail {
  url: string;
  imageId: string;
  minuteValue: string;
}

interface Icon {
  url: string;
  imageId: string;
  minuteValue: string;
  floatingSludgeIcon?: boolean;
  falseDetectionIcon?: boolean;
  noSludgeIcon?: boolean;
}

interface ImageDetails {
  img: HTMLImageElement;
  x: number;
  y: number;
  imgWidth: number;
  imgHeight: number;
  imageId: string;
  label: string;
  shift: string;
  minuteValue: string;
  thumbnail: boolean;
}

interface Chart {
  ctx: CanvasRenderingContext2D;
  data: {
    labels: string[];
    datasets: {
      label: string;
      stack: string;
      hidden: boolean;
      thumbnails: Thumbnail[];
      floatingSludgeIcon: Icon[];
      falseDetectionIcon: Icon[];
      noSludgeIcon: Icon[];
    }[];
  };
  canvas: HTMLCanvasElement;
  config: {
    data: {
      datasets: { [key: string]: { stack: string; label: string } };
    };
  };
  getDatasetMeta: (datasetIndex: number) => {
    data: { x: number; y: number }[];
  };
}

interface Props {
  handleClickOpen: (id: string) => void;
  issueIcon: { [key: string]: boolean };
  hiddenLabels: any;
  options?: ChartOptions;
  barGraphData: any;
}

const StackBarChartFlocDetector: React.FC<Props> = ({
  handleClickOpen,
  issueIcon,
  hiddenLabels,
  barGraphData,
  options,
}) => {
  const issueIconRef = useRef(issueIcon); // Create a ref to store the issueIcon prop

  const isMobile = useIsMobile({ breakpointValue: 'sm' });
  const isTablet = useIsMobile({ breakpointValue: 'md' });

  const imageCache: Record<string, HTMLImageElement> = {};

  const chartOptions1: any = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        bottom: 24.5,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        afterFit: (c: any) => {
          c.width = 60;
        },
        stacked: true,
        ticks: {
          stepSize: 100,
          callback: function (value: any, index: number, values: any[]) {
            const min = values[0].value;
            const max = values[values.length - 1].value;
            const mid = (min + max) / 2;
            if (value === min || value === max || value === mid) {
              return `${value} ml`;
            } else {
              return `${value}`;
            }
          },
          color: function (value: any, index: number, values: any[]) {
            const min = 0;
            const max = calculateMaxHeight(hiddenLabels, barGraphData);
            const mid = (min + max) / 2;
            return value.tick.value === min || value.tick.value === max || value.tick.value === mid
              ? '#989DA6'
              : '#989DA64C';
          },
        },
        max: calculateMaxHeight(hiddenLabels, barGraphData),
      },
      x: {
        display: false,
        stacked: true,
        ticks: {
          autoSkip: false,
        },
        grid: {
          display: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: 'top',
        align: isMobile ? 'start' : 'end',
      },
      tooltip: {
        backgroundColor: '#6D6D6D',
        callbacks: {
          label: function (context: any) {
            const date = context.label;
            const getAllShiftDetails = getDataByDate(date, barGraphData);
            const shiftName = context.dataset.stack;
            const minuteValue = context.dataset.label;
            const detectedTime = getAllShiftDetails[shiftName][minuteValue]['Detected Time'];
            const defaultSample = getAllShiftDetails[shiftName][`Default Sample`];
            const totalSample = getAllShiftDetails[shiftName][`Total Sample`];
            const value = getAllShiftDetails[shiftName][minuteValue].value;
            return [
              `Detected Time: ${detectedTime}`,
              `Date: ${date}`,
              `Value: ${value}`,
              `Sample: ${defaultSample} / ${totalSample}`,
            ];
          },
        },
      },
      zoom: {
        // <-- Add this configuration
        pan: {
          enabled: true, // Enable panning
          mode: 'xy', // Allow panning in both directions
        },
        zoom: {
          wheel: {
            enabled: true, // Enable zooming with the mouse wheel
          },
          pinch: {
            enabled: true, // Enable zooming with pinch gestures
          },
          mode: 'xy', // Allow zooming in both directions
        },
      },
    },
    barPercentage: isMobile ? 0.85 : isTablet ? 0.8 : 0.8,
    categoryPercentage: isMobile ? 0.65 : isTablet ? 0.4 : 0.4,
  };

  const chartOptions: any = {
    layout: {
      padding: {
        top: 11,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        stacked: true,
        ticks: {
          grid: {
            drawTicks: false,
          },
          display: false,
          stepSize: 100,
          callback: function (value: any, index: number, values: any[]) {
            const min = values[0].value;
            const max = values[values.length - 1].value;
            const mid = (min + max) / 2;
            if (value === min || value === max || value === mid) {
              return `${value} ml`;
            } else {
              return `${value}`;
            }
          },
          color: function (value: any, index: number, values: any[]) {
            const min = 0;
            const max = calculateMaxHeight(hiddenLabels, barGraphData);
            const mid = (min + max) / 2;
            return value.tick.value === min || value.tick.value === max || value.tick.value === mid
              ? '#989DA6'
              : '#989DA64C';
          },
        },
        max: calculateMaxHeight(hiddenLabels, barGraphData),
      },
      x: {
        stacked: true,
        ticks: {
          autoSkip: false,
        },
        grid: {
          display: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: 'top',
        align: isMobile ? 'start' : 'end',
      },
      tooltip: {
        backgroundColor: '#6D6D6D',
        callbacks: {
          label: function (context: any) {
            const date = context.label;
            const getAllShiftDetails = getDataByDate(date, barGraphData);
            const shiftName = context.dataset.stack;
            const minuteValue = context.dataset.label;
            const detectedTime = getAllShiftDetails[shiftName][minuteValue]['Detected Time'];
            const defaultSample = getAllShiftDetails[shiftName][`Default Sample`];
            const totalSample = getAllShiftDetails[shiftName][`Total Sample`];
            const value = getAllShiftDetails[shiftName][minuteValue].value;
            return [
              `Detected Time: ${detectedTime}`,
              `Date: ${date}`,
              `Value: ${value}`,
              `Sample: ${defaultSample} / ${totalSample}`,
            ];
          },
        },
      },
      zoom: {
        // <-- Add this configuration
        pan: {
          enabled: true,
          mode: 'xy',
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy',
        },
      },
    },
    barPercentage: isMobile ? 0.85 : isTablet ? 0.8 : 0.8,
    categoryPercentage: isMobile ? 0.65 : isTablet ? 0.4 : 0.4,
  };

  const drawThumbnailsPlugin: any = {
    id: 'drawThumbnails',
    afterDatasetsDraw: (chart: Chart) => {
      const ctx = chart.ctx;
      const datasets = chart.data.datasets;
      const canvas = chart.canvas;
      const images: ImageDetails[] = [];

      // Create custom tooltip element
      let tooltipEl = document.getElementById('chartjs-tooltip') as HTMLDivElement;
      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.backgroundColor = '#6D6D6D';
        tooltipEl.style.color = '#fff';
        tooltipEl.style.borderRadius = '4px';
        tooltipEl.style.padding = '12px';
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.opacity = '0';
        tooltipEl.style.fontSize = '12px';
        document.body.appendChild(tooltipEl);
      }

      // Hide the tooltip when not hovering over an image
      canvas.addEventListener('mouseout', () => {
        tooltipEl.style.opacity = '0';
      });

      canvas.onclick = (evt: MouseEvent) => {
        const mouseX = evt.offsetX;
        const mouseY = evt.offsetY;

        const clickedImageIds = images
          .filter(
            ({ x, y, imgWidth, imgHeight }) =>
              mouseX >= x && mouseX <= x + imgWidth && mouseY >= y && mouseY <= y + imgHeight
          )
          .map(({ imageId }) => imageId);

        if (clickedImageIds.length > 0) {
          handleClickOpen(clickedImageIds[0]);
        }
      };

      canvas.onmousemove = (evt: any) => {
        const mouseX = evt.offsetX;
        const mouseY = evt.offsetY;
        let hovering = false;
        images.forEach(({ x, y, imgWidth, imgHeight }) => {
          if (mouseX >= x && mouseX <= x + imgWidth && mouseY >= y && mouseY <= y + imgHeight) {
            hovering = true;
          }
        });
        canvas.style.cursor = hovering ? 'pointer' : 'default';
      };

      chart.data.labels.forEach((label, index) => {
        const shiftWiseThumbnails: { [key: string]: Thumbnail[] } = {};
        const shiftWiseIcons: { [key: string]: Icon[] } = {};
        datasets.forEach(dataset => {
          const minuteValue = dataset.label;
          const shift = dataset.stack;
          if (!shiftWiseThumbnails[shift]) {
            shiftWiseThumbnails[shift] = [];
          }
          if (!shiftWiseIcons[shift]) {
            shiftWiseIcons[shift] = [];
          }

          if (!dataset.hidden && dataset.thumbnails[index]) {
            shiftWiseThumbnails[shift].push({
              ...dataset.thumbnails[index],
              minuteValue,
            });
          }

          if (
            !issueIconRef.current.floatingSludgeIcon &&
            !dataset.hidden &&
            dataset.floatingSludgeIcon[index]
          ) {
            shiftWiseIcons[shift].push({
              ...dataset.floatingSludgeIcon[index],
              minuteValue,
              floatingSludgeIcon: true,
            });
          }
          if (
            !issueIconRef.current.falseDetectionIcon &&
            !dataset.hidden &&
            dataset.falseDetectionIcon[index]
          ) {
            shiftWiseIcons[shift].push({
              ...dataset.falseDetectionIcon[index],
              minuteValue,
              falseDetectionIcon: true,
            });
          }
          if (
            !issueIconRef.current.noSludgeIcon &&
            !dataset.hidden &&
            dataset.noSludgeIcon[index]
          ) {
            shiftWiseIcons[shift].push({
              ...dataset.noSludgeIcon[index],
              minuteValue,
              noSludgeIcon: true,
            });
          }
        });

        Object.keys(shiftWiseThumbnails).forEach(shift => {
          const barIndex = Object.keys(chart.config.data.datasets).findIndex(
            key =>
              chart.config.data.datasets[key].stack === shift &&
              chart.config.data.datasets[key].label === '30 minutes'
          );

          // Add this check to ensure barIndex is valid
          if (barIndex !== -1) {
            const bar = chart.getDatasetMeta(barIndex).data[index];
            const barX = bar?.x; // Optional chaining to prevent accessing undefined properties
            const barTopY = bar?.y;

            if (barX !== undefined && barTopY !== undefined) {
              const thumbnails = shiftWiseThumbnails[shift].reverse();
              const icons = shiftWiseIcons[shift].reverse();
              let cumulativeHeight = 0;
              const marginBottom = 5;

              thumbnails.forEach((thumbnailSrc, thumbnailIndex) => {
                const img = imageCache[thumbnailSrc.url];
                const imgWidth = isMobile || isTablet ? 16 : 18;
                const imgHeight = isMobile || isTablet ? 16 : 18;
                const gap = 3;
                const x = barX - imgWidth / 2;
                const y = barTopY - imgHeight - cumulativeHeight - gap * (thumbnailIndex + 1);

                if (img.complete) {
                  ctx.drawImage(img, x, y, imgWidth, imgHeight);
                  cumulativeHeight += imgHeight + gap;
                } else {
                  img.onload = () => {
                    ctx.drawImage(img, x, y, imgWidth, imgHeight);
                    cumulativeHeight += imgHeight + gap;
                  };
                }

                images.push({
                  img,
                  x,
                  y,
                  imgWidth,
                  imgHeight,
                  imageId: thumbnailSrc.imageId,
                  label: label,
                  shift: shift,
                  minuteValue: thumbnailSrc.minuteValue,
                  thumbnail: true,
                });
              });

              icons.forEach((iconSrc, iconIndex) => {
                const img = imageCache[iconSrc.url];
                let imgWidth = 16;
                let imgHeight = 16;

                if (iconSrc.floatingSludgeIcon) {
                  imgWidth = 8;
                  imgHeight = 17;
                }

                const gap = 2;
                const x = barX - imgWidth / 2;
                const y =
                  barTopY - imgHeight - cumulativeHeight - gap * (iconIndex + 1) - marginBottom;

                if (img.complete) {
                  ctx.drawImage(img, x, y, imgWidth, imgHeight);
                  cumulativeHeight += imgHeight + gap;
                } else {
                  img.onload = () => {
                    ctx.drawImage(img, x, y, imgWidth, imgHeight);
                    cumulativeHeight += imgHeight + gap;
                  };
                }

                images.push({
                  img,
                  x,
                  y,
                  imgWidth,
                  imgHeight,
                  imageId: iconSrc.imageId,
                  label: label,
                  shift: shift,
                  minuteValue: iconSrc.minuteValue,
                  thumbnail: false,
                });
              });
            }
          }
        });
      });

      canvas.addEventListener('mousemove', (evt: MouseEvent) => {
        const mouseX = evt.offsetX;
        const mouseY = evt.offsetY;

        const hoveredImages = images.filter(
          ({ x, y, imgWidth, imgHeight }) =>
            mouseX >= x && mouseX <= x + imgWidth && mouseY >= y && mouseY <= y + imgHeight
        );

        if (hoveredImages.length > 0) {
          const hoveredImage = hoveredImages[0];
          const getAllShiftDetails = getDataByDate(hoveredImage.label, barGraphData);
          const detectedTime =
            getAllShiftDetails[hoveredImage.shift][hoveredImage.minuteValue]['Detected Time'];
          tooltipEl.style.opacity = '1';
          tooltipEl.style.left = `${evt.pageX}px`;
          tooltipEl.style.top = `${evt.pageY}px`; // Adjust as needed
          if (hoveredImage.thumbnail) {
            tooltipEl.innerHTML = `
            <div>
              <div style="font-weight: 700;">Sludge Color</div>
              <div> <span style="font-weight: 700;">Detected Time:</span> ${detectedTime}</div>
              <div> <span style="font-weight: 700;">Date: </span>${hoveredImage.label}</div>
            </div>
          `;
          } else {
            tooltipEl.innerHTML = `
            <div>
              <div> <span style="font-weight: 700;">Issue Name: </span> Aliquet sit id quis pulvinar</div>
              <div> <span style="font-weight: 700;">Date: </span>${hoveredImage.label}</div>
            </div>
          `;
          }
        } else {
          tooltipEl.style.opacity = '0';
        }
      });
    },
  };

  const [chartData, setChartData] = useState(prepareChartData(barGraphData, hiddenLabels));

  useEffect(() => {
    setChartData(prepareChartData(barGraphData, hiddenLabels));
  }, [hiddenLabels]);

  useEffect(() => {
    preloadImages(imageCache, extractThumbnails(barGraphData));
  }, [imageCache]);

  useEffect(() => {
    issueIconRef.current = issueIcon;
  }, [issueIcon]);

  return (
    <Box sx={{ display: 'flex', overflowX: 'hidden' }}>
      <Box sx={{ width: '50px' }}>
        <Bar
          data={prepareChartData(barGraphData, hiddenLabels)}
          options={chartOptions1} // <-- Use the updated `chartOptions1`
          height="200px"
          id="myChart"
        />
      </Box>
      <Box sx={{ maxWidth: '100vw', overflowX: 'scroll', scrollbarWidth: 'none' }}>
        <Box sx={{ width: isMobile ? '200vw' : isTablet ? '150vw' : '100vw' }}>
          <Bar
            data={chartData}
            options={chartOptions} // <-- Use the updated `chartOptions`
            plugins={[drawThumbnailsPlugin]}
            height="300px"
            id="myChart"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default StackBarChartFlocDetector;
