import axiosInstance from '../constants/axiosInstance';

const rephraseTextThroughAi = async (text: string) => {
  try {
    const { data } = await axiosInstance().post(`/ai/rephraseTextThroughAi`, { text });
    return data?.data;
  } catch (err) {}
};

export { rephraseTextThroughAi };
