const UNIT_OPTION_LIST = [
  { label: 'Litres', value: 'l' },
  { label: 'Kilo Litres', value: 'kl' },
  { label: 'Kl/h', value: 'kl/h' },
  { label: 'Metres', value: 'm' },
  { label: '%', value: '%' },
  { label: 'Kg', value: 'kg' },
  { label: 'NTU', value: 'NTU' },
  { label: 'None', value: '' },
  { label: 'pH', value: 'pH' },
];

const UNIT_OPTION_LIST_2 = [
  { label: 'Litres', value: 'L' },
  { label: 'Kilo Litres', value: 'KL' },
  { label: 'Kl/h', value: 'KL/H' },
  { label: 'Metres', value: 'M' },
  { label: '%', value: '%' },
  { label: 'Kg', value: 'KG' },
  { label: 'NTU', value: 'NTU' },
  { label: 'None', value: '' },
  { label: 'pH', value: 'pH' },
];

export { UNIT_OPTION_LIST, UNIT_OPTION_LIST_2 };
