function calculateTotalSeconds(startTime: string, endTime: string) {
  const startDate = new Date(`2000-01-01 ${startTime}`);
  const endDate = new Date(`2000-01-01 ${endTime}`);

  // Calculate the difference in milliseconds
  let timeDiff = endDate.getTime() - startDate.getTime();

  // Convert milliseconds to seconds
  let totalSeconds = timeDiff / 1000;

  // Ensure the result is positive
  if (totalSeconds < 0) {
    // If the end time is before the start time, add 24 hours to the difference
    totalSeconds += 24 * 60 * 60; // 24 hours * 60 minutes * 60 seconds
  }

  return totalSeconds;
}

export default function Calculate24HrCycle(data: any) {
  let totalTime = 0;
  for (let i = 0; i < data.length; i++) {
    let start = data[i].startTime;
    let end = data[i].endTime;
    totalTime += calculateTotalSeconds(start, end);
  }
  return totalTime;
}
