import { useState } from 'react';
import LayoutComponent from '../components/LayoutComponent';

import {
  Box,
  Button,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogContent,
} from '@mui/material';
import { useEffect } from 'react';
// import { useParams } from "react-router-dom";
import FilterListIcon from '@mui/icons-material/FilterList';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import closeTicketIcon from '../constants/Images/closeTicket.png';
import openTicketIcon from '../constants/Images/openTicket.png';
import IssueTrackerComponent from '../components/IssueTrackerComponent/IssueTrackerComponent';
import CreateIssuePopup from '../components/CreateIssuePopup/CreateIssuePopup';
import { capitalize } from 'lodash';
import {
  getPlantsForUser,
  getOpenAlertsForClient,
  getClosedAlertsForClient,
} from '../services/tickets';
import { alertInterface } from '../Interfaces/ticketsv2Interfaces';

import usePermissionCheck from '../utilities/customHooks/usePermissionCheck';
import { PERMISSION_TAGS } from '../constants/permissions';

const styles = {
  ticketsPageHeader: {
    fontWeight: 'normal',
    fontSize: 24,
    color: '#193458',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    verticalAlign: 'middle',
  },
  createTicketButton: {
    textTransform: 'none',
    background: '#C1E561',
    fontSize: '14px',
    color: '#053F5C',
    fontFamily: 'Roboto',
  },
  summaryContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-between',
    justifyContent: 'space-between',
    width: '100%',
    gap: '15px',
    flexWrap: 'wrap',
  },
  ticketSummary: {
    height: '100px',
    border: '1px solid #D3DCEB',
    cursor: 'pointer',
    // borderBottom: showOpenTickets
    //   ? "10px solid #193458"
    //   : "1px solid #D3DCEB",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    gap: '20px',
    flex: '1 1 0',
  },
  summaryIconContainer: {
    background: '#C1E561',
    borderRadius: '50%',
    color: '#193458',
    padding: '5px',
    width: '40px',
    height: '40px',
    display: 'grid',
    placeItems: 'center',
  },
  summaryNumberContainer: {
    fontSize: '28px',
    color: '#282828',
    fontWeight: 500,
    marginBottom: '-10px',
  },
  summaryHeadingText: {
    fontSize: '14px',
    color: '#282828',
    fontWeight: 'bold',
  },

  issueTypeSummary: {
    display: 'flex',
    // flexDirection: { xs: "column", sm: "row" },
    flexDirection: 'row',
    cursor: 'pointer',
    alignItems: 'stretch',
    justifyContent: 'space-around',
    height: { xs: 'auto', sm: '100px' },
    gap: '20px',
    border: '1px solid #D3DCEB',
    background: '#F5FBFB',
    flex: '1.5 1 0',
  },
  issueTypeBreakdownNumber: {
    width: '18px',
    height: '18px',
    textAlign: 'center' as 'center',
    borderRadius: '50%',
    background: '#EDF0F4',
    color: '#0F1F34',
    fontWeight: 'bold',
  },
};
interface IProps {
  plants: any;
  filterOpen: boolean;
  setFilterOpen: Function;
  selectedPlants: string[];
  setSelectedPlants: Function;
}

function PlantFilterDialog(props: IProps) {
  const { plants, filterOpen, setFilterOpen, selectedPlants, setSelectedPlants } = props;

  // const [selectedPlantIds, setSelectedPlantIds] = useState<string[]>([]);
  const [isAllSelected, setIsAllSelected] = useState(true);

  useEffect(() => {
    // Update the selected plants when the "All" checkbox state changes

    setSelectedPlants(getAllPlantIds);
  }, [plants]);

  const handleClose = () => {
    setFilterOpen((prevState: boolean) => !prevState);
  };

  const handlePlantsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const plantId = event.target.id;

    if (plantId === 'All') {
      // If "All" checkbox is selected, update the state accordingly
      setIsAllSelected(isChecked);
      setSelectedPlants(isChecked ? getAllPlantIds() : []);
    } else {
      // If an individual plant is selected or deselected
      setSelectedPlants((prevSelectedPlantIds: string[]) => {
        if (isChecked) {
          return [...prevSelectedPlantIds, plantId];
        } else if (isAllSelected) {
          setIsAllSelected(false);
        }
        return prevSelectedPlantIds?.filter(
          (selectedPlantId: string) => selectedPlantId !== plantId
        );
      });
    }
  };
  const getAllPlantIds = () => plants.map((plant: any) => plant.plantId);

  return (
    <Dialog open={filterOpen} onClose={handleClose}>
      <DialogContent>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox id="All" name="All" checked={isAllSelected} onChange={handlePlantsChange} />
            }
            label={'All'}
          />

          {plants?.map((plant: any) => (
            <FormControlLabel
              key={plant.plantId}
              control={
                <Checkbox
                  onChange={handlePlantsChange}
                  id={plant.plantId}
                  checked={selectedPlants?.includes(plant?.plantId)}
                  name={plant.plantName}
                />
              }
              label={plant.plantName}
            />
          ))}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
}

export default function Tickets() {
  // const { id } = useParams();
  const [filterOpen, setFilterOpen] = useState(false);
  const userId = localStorage.getItem('userId');
  const [plants, setPlants] = useState([]);
  const [selectedPlants, setSelectedPlants] = useState<any[]>([]);
  const [openAlerts, setOpenAlerts] = useState<alertInterface[]>([]);
  const [closedAlerts, setClosedAlerts] = useState<alertInterface[]>([]);
  //@ts-ignore
  const [plantName, setPlantName] = useState(''); // ~ Making it capital case
  const [showOpenTickets, setShowOpenTickets] = useState(true);
  const [openCreateNewIssuePopup, setOpenCreateNewIssuePopup] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openAlertsBreakdown, setOpenAlertsBreakdown] = useState<any>({});
  const [closedAlertsBreakdown, setClosedAlertsBreakdown] = useState<any>({});

  const { hasPermission: canCreateManualTicket, loading: loadingAddFixedTaskButton } = usePermissionCheck(
    PERMISSION_TAGS.ManualTickets_Configuration_WE
  );


  const handleFilterIconClick = () => {
    setFilterOpen(true);
  };
  //@ts-ignore
  const [issueTypes, setIssueTypes] = useState([
    'Admin',
    'Electrical',
    'Lab',
    'Process',
    'Dp System',
    'Mechanical',
  ]);

  const handleAlertsBreakdown = (alerts: any, alertType: string) => {
    let temp = {
      Process: 0,
      Admin: 0,
      Electrical: 0,
      Lab: 0,
      'Dp System': 0,
      Mechanical: 0,
      critical: 0,
      overDue: 0,
      escalated: 0,
    };
    alerts?.forEach((alert: any) => {
      let issueType = alert.issueType;
      if (issueType) {
        if (issueType.includes('Process')) temp['Process']++;
        if (issueType.includes('Admin')) temp['Admin']++;
        if (issueType.includes('Electrical')) temp['Electrical']++;
        if (issueType.includes('Lab')) temp['Lab']++;
        if (issueType.includes('Dp System')) temp['Dp System']++;
        if (issueType.includes('Mechanical')) temp['Mechanical']++;
      }
      if (alert.critical) temp.critical++;
      if (alert.lastTicket?.overdue) temp['overDue']++;
      if (alert.lastTicket?.isEscalated) temp['escalated']++;
    });
    if (alertType === 'openAlerts') setOpenAlertsBreakdown(temp);
    else setClosedAlertsBreakdown(temp);
  };

  useEffect(() => {
    getPlantsForUser(userId!).then(data => {
      setPlants(data);
    });
  }, []);

  useEffect(() => {
    if (plants?.length > 0 && selectedPlants?.length > 0) {
      getOpenAlertsForClient(userId as string, selectedPlants).then((data: any) => {
        setOpenAlerts(data);
      });

      getClosedAlertsForClient(userId as string, selectedPlants).then((data: alertInterface[]) => {
        setClosedAlerts(data);
      });
    } else if (selectedPlants.length === 0) {
      setOpenAlerts([]);
      setClosedAlerts([]);
    }
  }, [selectedPlants, refresh]);

  useEffect(() => {
    handleAlertsBreakdown(openAlerts, 'openAlerts');
    handleAlertsBreakdown(closedAlerts, 'closedAlerts');

    return () => {};
  }, [openAlerts, closedAlerts]);

  return (
    <LayoutComponent
      factoryResetContainer={true}
      style={{
        pl: 2,
        pr: 2,
      }}
    >
      <Box m={1} p={1} style={{ width: '100%', boxSizing: 'border-box', fontFamily: 'Roboto' }}>
        <div style={styles.ticketsPageHeader}>
          <span>{plantName} Tickets</span>
          <span style={{ float: 'right' }}>
            {plants.length > 1 && (
              <IconButton
                style={{ marginLeft: 'auto' }}
                color="inherit"
                aria-label="filterButton"
                onClick={handleFilterIconClick}
              >
                <FilterListIcon />
              </IconButton>
            )}

            {canCreateManualTicket && <Button
              variant="contained"
              sx={styles.createTicketButton}
              onClick={() => {
                setOpenCreateNewIssuePopup(true);
              }}
            >
              <span>Create New Ticket</span>
            </Button>}
          </span>
        </div>
      </Box>

      <Box className="summaryContainer" sx={styles.summaryContainer}>
        <Box
          className="openTicketSummary"
          p={1}
          px={2}
          borderRadius={2}
          sx={{
            ...styles.ticketSummary,
            borderBottom: showOpenTickets ? '10px solid #193458' : '1px solid #D3DCEB',
          }}
          onClick={() => {
            setShowOpenTickets(true);
          }}
        >
          <Box sx={styles.summaryIconContainer}>
            <img src={openTicketIcon} width="30px" height="30px" />
          </Box>
          <Stack spacing={1}>
            <span className="totalTicketCount" style={styles.summaryNumberContainer}>
              {openAlerts.length}
            </span>
            <span className="ticketSummaryHeading" style={styles.summaryHeadingText}>
              Open Tickets
            </span>
            <Stack
              className="summaryBreakDown"
              direction="row"
              spacing={3}
              divider={<Divider orientation="vertical" flexItem />}
            >
              {['critical', 'overDue', 'escalated'].map(type => {
                return (
                  <Stack className="breakdownDiv" style={{ fontSize: '12px', fontWeight: 500 }}>
                    <div>{openAlertsBreakdown[type]}</div>
                    <div
                      style={{
                        color: '#6B7D9B',
                      }}
                    >
                      {capitalize(type)}
                    </div>
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </Box>
        <Box
          className="closeTicketSummary"
          p={1}
          px={2}
          borderRadius={2}
          sx={{
            ...styles.ticketSummary,
            borderBottom: !showOpenTickets ? '10px solid #193458' : '1px solid #D3DCEB',
          }}
          onClick={() => {
            setShowOpenTickets(false);
          }}
        >
          <Box sx={styles.summaryIconContainer}>
            <img src={closeTicketIcon} width="35px" height="35px" />
          </Box>
          <Stack spacing={1} style={{}}>
            <span className="totalTicketCount" style={styles.summaryNumberContainer}>
              {closedAlerts.length}
            </span>
            <span className="ticketSummaryHeading" style={styles.summaryHeadingText}>
              Close Tickets
            </span>
            <Stack
              className="summaryBreakDown"
              direction="row"
              spacing={3}
              divider={<Divider orientation="vertical" flexItem />}
            >
              {['critical', 'overDue', 'escalated'].map(type => {
                return (
                  <Stack className="breakdownDiv" style={{ fontSize: '12px', fontWeight: 500 }}>
                    <div>{closedAlertsBreakdown[type]}</div>
                    <div
                      style={{
                        color: '#6B7D9B',
                      }}
                    >
                      {capitalize(type)}
                    </div>
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </Box>
        <Box
          className="issueTypeSummary"
          p={1}
          px={2}
          borderRadius={2}
          sx={styles.issueTypeSummary}
        >
          <Stack className="openTicketIssueTypeBreakDown" direction="column" spacing={1} sx={{}}>
            <div style={{ color: '#36A2C7', fontWeight: 'bold', fontSize: '14px' }}>
              Open Tickets
            </div>
            <Stack
              direction="column"
              spacing={1}
              sx={{
                minWidth: { xs: '100px', sm: '180px' },
                height: { xs: 'auto', sm: '70px' },
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              {issueTypes.map(issueType => {
                return (
                  <Stack
                    className="breakdownDiv"
                    direction="row"
                    spacing={1}
                    style={{ fontSize: '12px' }}
                  >
                    <span style={styles.issueTypeBreakdownNumber}>
                      {openAlertsBreakdown[issueType]}
                    </span>
                    <div style={{ color: '#383D4A' }}>{issueType}</div>
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
          <Divider orientation="vertical" flexItem />
          <Stack className="openTicketIssueTypeBreakDown" direction="column" spacing={1} style={{}}>
            <div style={{ color: '#91BA22', fontWeight: 'bold', fontSize: '14px' }}>
              Closed Tickets
            </div>
            <Stack
              direction="column"
              spacing={1}
              sx={{
                minWidth: { xs: '100px', sm: '180px' },
                height: { xs: 'auto', sm: '70px' },
                flexWrap: 'wrap',
              }}
            >
              {issueTypes.map(issueType => {
                return (
                  <Stack
                    className="breakdownDiv"
                    direction="row"
                    spacing={1}
                    style={{ fontSize: '12px' }}
                  >
                    <span style={styles.issueTypeBreakdownNumber}>
                      {closedAlertsBreakdown[issueType]}
                    </span>
                    <div style={{ color: '#383D4A' }}>{issueType}</div>
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </Box>
      </Box>

      <Box className="ticketsContainer" mt={3}>
        {showOpenTickets && (
          <Stack direction="column" style={{ width: '100%' }} spacing={2} mt={2}>
            {openAlerts.map((alert, index) => {
              return (
                <Box
                  style={{
                    width: '100%',
                  }}
                >
                  <IssueTrackerComponent
                    key={alert._id + index}
                    plants={plants}
                    alert={alert}
                    refreshStateOfParent={refresh}
                    setRefreshStateOfParent={setRefresh}
                  />
                </Box>
              );
            })}
          </Stack>
        )}
        {!showOpenTickets && (
          <Stack direction="column" style={{ width: '100%' }} spacing={1} mt={1}>
            {closedAlerts.map(alert => {
              return (
                <Box
                  style={{
                    width: '100%',
                  }}
                >
                  <IssueTrackerComponent
                    plants={plants}
                    alert={alert}
                    refreshStateOfParent={refresh}
                    setRefreshStateOfParent={setRefresh}
                  />
                </Box>
              );
            })}
          </Stack>
        )}
      </Box>
      <PlantFilterDialog
        plants={plants}
        filterOpen={filterOpen}
        setFilterOpen={setFilterOpen}
        selectedPlants={selectedPlants}
        setSelectedPlants={setSelectedPlants}
      />
      {openCreateNewIssuePopup ? (
        <CreateIssuePopup
          plants={plants}
          setOpenCreateNewIssuePopup={setOpenCreateNewIssuePopup}
          refreshStateOfParent={refresh}
          setRefreshStateOfParent={setRefresh}
        />
      ) : null}
    </LayoutComponent>
  );
}
