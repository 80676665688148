import axiosInstance from '../constants/axiosInstance';

const getTagsForUser = async () => {
  try {
    const { data } = await axiosInstance().post(`/tags/getTagsByUserId`);
    console.log('tagsd : ', data);
    return data.data;
  } catch (err) {
    console.error(`ERROR | getTagsForUser`, err);
  }
};

const createTag = async (name: string, type: string) => {
  try {
    const { data } = await axiosInstance().post(`/tags/createTag`, { name, type });
    return data.data;
  } catch (err) {
    console.error(`ERROR | getChemicals`, err);
  }
};

export { getTagsForUser, createTag };
