import axiosInstance from '../constants/axiosInstance';

const saveRole = async (role: any) => {
  try {
    const { data } = await axiosInstance().post(`/newUsers/saveRole`, role);
    return data;
  } catch (error) {
    console.error('Error saving Role :', error);
    throw error;
  }
};

const getPermissionListForUser = async (payload: any) => {
  try {
    const { data } = await axiosInstance().post(`/newUsers/getPermissionListForUser`, payload);
    return data;
  } catch (error) {
    console.error('Error fetching Permissions :', error);
    throw error;
  }
};

const getRoleList = async () => {
  try {
    const { data } = await axiosInstance().post(`/newUsers/roleList`);
    return data?.data;
  } catch (error) {
    console.error('Error fetching Roles :', error);
    throw error;
  }
};

const getRoleDetails = async (roleId: string) => {
  try {
    const { data } = await axiosInstance().post(`/newUsers/getRoleDetails`, { roleId });
    return data?.data;
  } catch (error) {
    console.error('Error fetching Role Details :', error);
    throw error;
  }
};

const deleteRole = async (roleId: string) => {
  try {
    const { data } = await axiosInstance().post(`/newUsers/deleteRole`, { roleId });
    return data;
  } catch (error) {
    console.error('Error deleting Role :', error);
    throw error;
  }
};


// & This is to fetch the user groups for the user if, nothing it sent it's going to fetch users else edit it
const getUserGroupsForUser = async (
  mode?: string | undefined,
  userIdUpdated?: string | undefined
) => {
  try {
    const { data } = await axiosInstance().post(`/newUsers/getUserGroupsForUser`, {
      mode,
      userIdUpdated,
    });
    return data?.data;
  } catch (error) {
    console.error('Error getUserGroupsForUser :', error);
    throw error;
  }
};

export {
  saveRole,
  getPermissionListForUser,
  getRoleList,
  getRoleDetails,
  deleteRole,
  getUserGroupsForUser,
};
