import React, { useEffect, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DialogBox from '../../../core-components/theme/components/DialogBox/DialogBox';
import DeleteFilledIcon from '../../../core-components/assets/icons/DeleteIconFilled';
import EditIcon from '../../../core-components/assets/icons/EditIcon';
import CustomIconButton from '../../../core-components/theme/components/Button/Iconbutton';
import { Box, Chip, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import CustomTreeComponent from '../../../core-components/theme/components/Tree/CustomTree';
import TwoRowLayout from '../../../core-components/theme/layouts/TwoLayout/TwoLayoutComponent';
import ConfirmationDialog from '../../../core-components/theme/components/DialogBox/ConfirmationDialog';
import { Neutrals } from '../../../core-components/theme/color-palette/colors';
import { getWorkspaceItemlabel } from '../../workspace-management/helpers/customtreehelpers';
import CustomUserTreeItem from './CustomUserTree';
import { useQuery } from '@tanstack/react-query';
import { getUserDetails } from '../../../services/newUsers';
import DetailIcon from '../../../core-components/assets/icons/DetailIcon';

const OptionsMenu: React.FC<{ row: any; handleDeleteUser: any; handleEditUser: any }> = ({
  row,
  handleDeleteUser,
  handleEditUser,
}) => {
  const [userDetails, setUserDetails] = useState<any>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  //@ts-ignore
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const {
    data: usersListData,
    status: usersListStatus,
    refetch,
  } = useQuery({
    queryKey: ['getUsersList'],
    queryFn: () => getUserDetails(row._id),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  useEffect(() => {
    if (usersListStatus === 'success') {
      setUserDetails(usersListData);
    }
  }, [usersListData, usersListStatus]);

  function handleOpen() {
    setUserDetails([]);
    setOpen(true);
    refetch();
  }
  function handleClose() {
    setOpen(false);
    handleMenuClose();
  }
  function handleConfirmationOpen() {
    setConfirmationOpen(true);
  }
  function handleConfirmationClose() {
    setConfirmationOpen(false);
    handleMenuClose();
  }
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CustomIconButton onClick={handleMenuOpen} icon={MoreVertIcon} />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        slotProps={{
          paper: {
            style: {
              boxShadow: '0px 2px 8px 0px #1A335626',
            },
          },
        }}
        MenuListProps={{
          sx: {
            border: `1px solid ${Neutrals[300]}`,
            py: 0,
            backgroundColor: '#ffffff',
            borderRadius: '4px',
          },
        }}
      >
        {/* for details */}
        <MenuItem
          sx={{
            height: '36px',
            paddingLeft: '8px',
            borderBottom: `1px solid ${Neutrals[300]}`,
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={handleOpen}
        >
          <DetailIcon />
          <Typography ml={2} pr={2} color={Neutrals[500]}>
            Details
          </Typography>
        </MenuItem>
        {/* for edit page */}
        <MenuItem
          sx={{
            height: '36px',
            borderBottom: `1px solid ${Neutrals[300]}`,
            display: 'flex',
            paddingLeft: '8px',
            alignItems: 'center',
          }}
          onClick={() => handleEditUser(row._id)}
        >
          <EditIcon />
          <Typography ml={2} pr={2} color={Neutrals[500]}>
            Edit
          </Typography>
        </MenuItem>
        {/* for delete */}
        <MenuItem
          sx={{ height: '36px', paddingLeft: '8px', display: 'flex', alignItems: 'center' }}
          onClick={handleConfirmationOpen}
        >
          <DeleteFilledIcon />
          <Typography ml={2} pr={2} color={Neutrals[500]}>
            Delete
          </Typography>
        </MenuItem>
        {/* for details */}
        <DialogBox open={open} title={row?.name?.name + ' - Details'} handleClose={handleClose}>
          <Box>
            <TwoRowLayout
              gap={0}
              style={{ height: '60vh' }}
              child1={
                <>
                  <Stack direction="column" pb={2} width="100%">
                    <Stack direction="row" mb={2} gap={4}>
                      <Typography variant="h4">Email ID: {userDetails?.email}</Typography>
                      <Typography variant="h4">Phone Number: {userDetails?.number}</Typography>
                    </Stack>
                    <Stack direction="column" mb={2}>
                      <Typography variant="h4" mb={2}>
                        User Group
                      </Typography>
                      <Stack direction="row" gap={2}>
                        {userDetails?.userGroups?.map((group: any) => (
                          <Tooltip title={group?.name}>
                            <Chip
                              sx={{
                                maxWidth: 200,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                cursor: 'pointer',
                              }}
                              label={group?.name}
                            />
                          </Tooltip>
                        ))}
                      </Stack>
                    </Stack>
                    <Stack>
                      <Typography variant="h4" mb={2}>
                        Administrative Role
                      </Typography>
                      <Stack direction="row" gap={2}>
                        {userDetails?.administrativeRoles?.map((role: any) => (
                          <Tooltip title={role?.roleId?.name}>
                            <Chip
                              sx={{
                                maxWidth: 200,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                cursor: 'pointer',
                              }}
                              label={role?.roleId?.name}
                            />
                          </Tooltip>
                        ))}
                      </Stack>
                    </Stack>
                  </Stack>
                </>
              }
              child2={
                <CustomTreeComponent
                  show={true}
                  heading="Workspace / Asset"
                  getItemId={(item: any) => item.itemId}
                  getItemlabel={getWorkspaceItemlabel}
                  CustomTreeItem={CustomUserTreeItem}
                  items={userDetails?.permissionTree || []}
                />
              }
            />
          </Box>
        </DialogBox>
        {/* for delete */}
        <ConfirmationDialog
          open={confirmationOpen}
          handleClose={handleConfirmationClose}
          title="Delete User"
          description={
            <Typography variant="h4">
              Are you sure you want to delete{' '}
              <Typography component="span" variant="highlight">
                {row?.name?.name}
              </Typography>
              ? You can’t undo this action
            </Typography>
          }
          actions={[
            {
              text: 'Cancel',
              color: 'white',
              textColor: '#8B8B8B',
              handler: () => console.log('cancel clicked'), // Todo: handle cancel
            },
            {
              text: 'Delete',
              color: 'SemanticError.dark',
              textColor: 'white',
              handler: () => handleDeleteUser(row._id),
            },
          ]}
        ></ConfirmationDialog>
      </Menu>
    </>
  );
};

export default OptionsMenu;
