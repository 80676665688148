import { useState, useEffect } from 'react';
import {
  Box,
  DialogTitle,
  Dialog,
  Button,
  TextField,
  DialogActions,
  DialogContent,
  ThemeProvider,
  InputLabel,
  Typography,
  OutlinedInput,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Chip,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { ToastContainer, toast } from 'react-toastify';
import { DataGrid, GridColDef, GridCellParams } from '@mui/x-data-grid';
import dpTheme from '../../styles/theme/dpTheme';
import { PrimaryButton } from '../../styles/global/components/dpButtons';
import { addHmi, getHmiList, deleteHmi, getPlcList } from '../../services/Hmi';
import usePermissionCheck from '../../utilities/customHooks/usePermissionCheck';
import { PERMISSION_TAGS } from '../../constants/permissions';

interface IProps {
  setShowHmiPopup: Function;
  showHmiPopup: boolean;
  plantId: string;
}

function getStyles(name: string, selectedPlcNames: readonly string[], theme: any) {
  return {
    fontWeight:
      selectedPlcNames.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function AddHmiPopup({ setShowHmiPopup, showHmiPopup, plantId }: IProps) {
  const [hmiName, setHmiName] = useState<string>('');
  const [hmiList, setHmiList] = useState<any>([]);
  const [plcList, setPlcList] = useState<any>();
  const [selectedPlcs, setSelectedPlcs] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const theme = useTheme();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    fetchHmiList();
    fetchPlcList();
  }, []);

  const fetchHmiList = () => {
    getHmiList(plantId).then(data => {
      setHmiList(data);
      setLoading(false);
    });
  };

  const fetchPlcList = () => {
    getPlcList(plantId).then(data => {
      setPlcList(data);
    });
  };

  const addHmiToPlant = () => {
    if (hmiName === '') {
      toast('Please Add an HMI name ');
    } else {
      console.log(selectedPlcs);
      const selectedPlcIds = selectedPlcs.map(plc => {
        return plc.split(' ')[2];
      });
      console.log(selectedPlcIds);
      addHmi(hmiName, selectedPlcIds, plantId).then(data => {
        toast(data);
        fetchHmiList();
      });
    }
  };

  const handleChange = (event: SelectChangeEvent<typeof selectedPlcs>) => {
    const {
      target: { value },
    } = event;
    setSelectedPlcs(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleClose = () => {
    setShowHmiPopup(false);
  };

  const removeHmi = (hmiId: any) => {
    deleteHmi(hmiId).then(data => {
      toast(data);
      fetchHmiList();
    });
  };

  const { hasPermission: canAddHMI, loading: loadingAddHMI } = usePermissionCheck(
    PERMISSION_TAGS.HMI_Configuration_A
  );

  const { hasPermission: canDeleteHMI, loading: loadingDeleteHMI } = usePermissionCheck(
    PERMISSION_TAGS.HMI_Configuration_D
  );

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'HMI ID',
      width: 250,
    },
    {
      field: 'hmiName',
      headerName: 'HMI Name',
      width: 150,
    },
    {
      field: 'plantId',
      headerName: 'Plant ID',
      width: 250,
    },
    {
      field: 'plcIds',
      headerName: 'PLC IDs',
      width: 300,
    },
  ];

  if (canDeleteHMI) {
    columns.push({
      field: 'deleteHmi',
      headerName: 'Delete HMI',
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <Button
              onClick={() => {
                removeHmi(params.id);
              }}
            >
              <DeleteIcon />
            </Button>
          </>
        );
      },
      width: 100,
    });
  }

  return (
    <ThemeProvider theme={dpTheme}>
      <div>
        <Dialog open={showHmiPopup} onClose={handleClose} maxWidth={'md'}>
          <DialogTitle>
            <Typography variant={'h2'}>Add HMI</Typography>
            <Box
              mt={3}
              p={1}
              sx={{
                display: 'flex',
                flexFlow: 'row nowrap',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              {canAddHMI && (
                <TextField
                  required
                  value={hmiName}
                  label="HMI name"
                  onChange={e => {
                    setHmiName(e.target.value);
                  }}
                />
              )}
              {plcList && canAddHMI && (
                <FormControl sx={{ m: 1, width: 300 }}>
                  <InputLabel id="demo-multiple-chip-label">Select PLCs</InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={selectedPlcs}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Select PLCs" />}
                    renderValue={selected => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected!.map(value => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {plcList.map((plc: any) => (
                      <MenuItem
                        key={plc.id}
                        value={`${plc.nickName}  ${plc.id}`}
                        style={getStyles(plc.nickName, selectedPlcs!, theme)}
                      >
                        {plc.nickName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {canAddHMI && (
                <PrimaryButton
                  variant="contained"
                  onClick={() => {
                    addHmiToPlant();
                  }}
                >
                  Add
                </PrimaryButton>
              )}
            </Box>
          </DialogTitle>
          <DialogContent>
            <div style={{ height: 420 }}>
              <DataGrid
                // rows={dummyData}
                rows={hmiList ? hmiList : []}
                columns={columns}
                loading={loading}
              />
            </div>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </div>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default AddHmiPopup;
