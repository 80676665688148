import { useEffect, useState } from 'react';
import { Button, Box, TextField } from '@mui/material';
import './styles.css';
import DeleteIcon from '@mui/icons-material/Delete';
import { ToastContainer, toast } from 'react-toastify';
import axiosInstance from '../../constants/axiosInstance';
import { DataGrid } from '@mui/x-data-grid';
import usePermissionCheck from '../../utilities/customHooks/usePermissionCheck';
import { PERMISSION_TAGS } from '../../constants/permissions';
export default function AddPlcPopup(props: { handleClose: any; plantId: any }) {
  console.log(props);
  const { handleClose, plantId } = props;
  const [plcNickname, setPlcNickname] = useState('');
  const [plantPlcs, setPlantPlcs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataAvailabe, setDataAvailabe] = useState(false);

  const { hasPermission: canAddPlc, loading: loadingAddPlc } = usePermissionCheck(
    PERMISSION_TAGS.PLC_Configuration_AE
  );

  const { hasPermission: canDeletePlc, loading: loadingDeletePlc } = usePermissionCheck(
    PERMISSION_TAGS.PLC_Configuration_D
  );
  const columns: any = [
    {
      field: 'id',
      headerName: 'Id',
      width: 250,
    },
    {
      field: 'nickName',
      headerName: 'Plc Nick Name',
      width: 250,
    },
    {
      field: 'plantId',
      headerName: 'Plant ID',
      width: 300,
    },
  ];

  if (canDeletePlc) {
    columns.push({
      field: 'deletePlc',
      headerName: 'Delete PLC',
      renderCell: (params: { id: String }) => {
        return (
          <>
            <Button
              onClick={() => {
                deletePlc(params.id);
              }}
            >
              <DeleteIcon />
            </Button>
          </>
        );
      },
      width: 300,
    });
  }

  const deletePlc = (plcId: String) => {
    axiosInstance()
      .delete(`/plantManagement/deletePlc?id=${plcId}`)
      .then(reponse => {
        fetchPlcList();
      })
      .catch();
  };

  useEffect(() => {
    fetchPlcList();
  }, []);

  const fetchPlcList = () => {
    setLoading(true);
    axiosInstance()
      .get(`/plantManagement/getPlcList?id=${plantId}`)
      .then(({ data }) => {
        console.log(data);
        if (data.data.plcArr) {
          setPlantPlcs(data.data.plcArr);
          setDataAvailabe(true);
        } else {
          setDataAvailabe(false);
        }

        setLoading(false);
        console.log(plantPlcs);
      })
      .catch();
  };

  const addPlcToPlant = () => {
    if (plcNickname.length >= 1) {
      setLoading(true);

      axiosInstance()
        .post(`/plantManagement/addPlc`, {
          plantId: plantId,
          nickName: plcNickname,
        })
        .then(({ data }) => {
          toast(data.message, {
            position: 'bottom-right',
          });
          fetchPlcList();
        })
        .catch();
    } else {
      toast.error('Please add Plc nickname', {
        position: 'bottom-right',
      });
    }
  };

  return (
    <div className="popup-box">
      <div className="box">
        <span className="close-icon" onClick={handleClose}>
          x
        </span>
        <form>
          {canAddPlc && (
            <Box m={1} p={1}>
              <TextField
                required
                value={plcNickname}
                label="Plc Nickname"
                onChange={e => {
                  setPlcNickname(e.target.value);
                }}
              />
              <Button
                variant="contained"
                onClick={() => {
                  addPlcToPlant();
                }}
              >
                Add
              </Button>
            </Box>
          )}

          <Box m={1} p={1}>
            {dataAvailabe ? (
              <div style={{ height: 420 }}>
                <DataGrid rows={plantPlcs} columns={columns} loading={loading} />
              </div>
            ) : (
              <p>No Plc Added to this plant</p>
            )}
          </Box>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
