import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import { Box, Typography } from '@mui/material';
import { Primary } from '../../color-palette/colors';
import CustomTreeHeader from '../Tree/Header/Header';
import { useEffect } from 'react';

interface CheckboxListProps {
  items: {
    _id: string;
    text: string;
    name?: string;
    email?: string;
    avatarSrc?: string;
  }[];
  initialChecked?: string[];
  onToggle?: (checked: string[]) => void;
  showCheckbox?: boolean;
  showAvatar?: boolean;
  show?: boolean;
  heading?: string;
}

const CheckboxList: React.FC<CheckboxListProps> = ({
  items,
  onToggle,
  showCheckbox = true,
  showAvatar = true,
  show,
  heading,
  initialChecked = [],
}) => {
  const [checked, setChecked] = React.useState<string[]>(initialChecked);
  useEffect(() => {
    setChecked(initialChecked);
  }, [initialChecked, items]);
  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    if (onToggle) {
      onToggle(newChecked);
    }
  };

  return (
    <Box
      sx={{
        border: `1px solid ${Primary[100]}`,
        borderRadius: '8px',
      }}
    >
      {show ? <CustomTreeHeader heading={heading} /> : null}
      <List
        sx={{
          overflowY: 'auto ',
          p: 0,
        }}
      >
        {items?.map(item => {
          const labelId = `checkbox-list-secondary-label-${item._id}`;
          return (
            <ListItem
              key={item._id}
              disablePadding
              sx={{
                borderBottom: `1px solid ${Primary[100]}`,
                '&:last-child': {
                  borderBottom: 'none',
                },
              }}
            >
              <ListItemButton
                sx={{
                  pl: '20px',
                  pr: '32px',
                  pt: 0,
                  pb: 0,
                  ':hover': { backgroundColor: Primary[50] },
                }}
                onClick={handleToggle(item._id)}
                disableRipple
              >
                {showCheckbox && (
                  <Checkbox
                    edge="end"
                    onChange={handleToggle(item._id)}
                    checked={checked.includes(item._id)}
                    inputProps={{ 'aria-labelledby': labelId }}
                    // sx={{ m: 2 }}
                  />
                )}
                {showAvatar && (
                  // ! DON'T DO SUCH THINGS IN PRODUCTION
                  // Todo: Change the placeholder image URL
                  <ListItemAvatar>
                    <Avatar
                      sx={{ margin: 2 }}
                      alt={item.text}
                      src={item.avatarSrc ? item.avatarSrc : 'https://via.placeholder.com/'} 
                    />
                  </ListItemAvatar>
                )}
                <ListItemText
                  id={labelId}
                  primary={
                    <Typography
                      lineHeight="20px"
                      ml={!item.email ? '1em' : 'undefined'}
                      variant="h4"
                    >
                      {item.name}
                    </Typography>
                  }
                  secondary={
                    <Typography color="#999" variant="caption">
                      {item.email}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default CheckboxList;
