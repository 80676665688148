import { create } from 'zustand';

interface WorkspaceAssetRoleStore {
  workspaceAssetRoles: any[]; // Use a specific type instead of `any` if possible
  setWorkspaceAssetRoles: (roles: any[]) => void;
  resetWorkspaceAssetRoles: () => void;
}

export const useWorkspaceAssetRoleStore = create<WorkspaceAssetRoleStore>(set => ({
  workspaceAssetRoles: [],

  // Action to set workspaceAssetRoles
  setWorkspaceAssetRoles: (roles: any) => set({ workspaceAssetRoles: roles }),

  // Optional: Action to reset roles (if needed)
  resetWorkspaceAssetRoles: () => set({ workspaceAssetRoles: [] }),
}));
