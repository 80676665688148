function getUserItemlabel(item: any) {
  return `${item.name}-${item.description}`;
}
function getUserItemId(item: any) {
  return item.itemId;
}

function getModuleItemId(item: any) {
  return item.itemId;
}

export { getUserItemId, getUserItemlabel, getModuleItemId };
