export default function convertISOToLocalTime(isoString: string) {
  const date = new Date(isoString);

  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0'); // Get minutes and pad with leading zero if necessary
  const meridiem = hours >= 12 ? 'PM' : 'AM'; // Determine AM or PM
  const displayHours = hours % 12 || 12; // Convert 24-hour time to 12-hour format

  const localTime = `${displayHours}:${minutes} ${meridiem}`; // Format time as HH:MM AM/PM
  return localTime;
}
