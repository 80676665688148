type Node = {
  itemId: string;
  children?: Node[];
};

const isIndeterminate = (parent: { children: any[] }, ids: string | any[]) => {
  const countCheckedChildren = (children: Node[]): number => {
    let totalChecked = 0;

    children.forEach(child => {
      if (ids.includes(child.itemId)) {
        totalChecked++;
      }
      if (child.children && child.children.length > 0) {
        totalChecked += countCheckedChildren(child.children);
      }
    });

    return totalChecked;
  };

  const countTotalChildren = (children: Node[]): number => {
    let total = children.length;

    children.forEach(child => {
      if (child.children && child.children.length > 0) {
        total += countTotalChildren(child.children);
      }
    });

    return total;
  };

  if (!parent.children || parent.children.length === 0) {
    return false; // No children means it can't be indeterminate
  }

  const totalChecked = countCheckedChildren(parent.children);
  const totalChildren = countTotalChildren(parent.children);

  return totalChecked > 0 && totalChecked < totalChildren;
};


export default isIndeterminate;
