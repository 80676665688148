import { useEffect, useState, useRef } from "react";
import {
  TextField,
  CircularProgress,
  ThemeProvider,
  Button,
  Box,
  Stack,
  Checkbox,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import dpTheme from "../../styles/theme/dpTheme";
import { loginData } from "../../Interfaces/loginInterfaces";
import { addCachedLoginEntry } from "../../utilities/cachedLoginEntries.utils";
import useUserStore from "../../store/UserStore";
import OTPPopup from "./OTPPopup";
import {
  loginUsing2StepVerification,
  postLoginDetailV2,
} from "../../services/Login";
import { decodeToken, isJwtExpired } from "../../utils/jwt.utils";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { OTP_RESEND_TIMER } from "../../constants/constants";
import { getOTPForLogin } from "../../services/Login";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import useLoginEntryStore from "../../store/loginEntryStore";
import { getCachedLoginToken } from "../../utilities/cachedLoginEntries.utils";

const styles = {
  container: {
    minWidth: "300px",
  },
  forgotPasswordLink: {
    fontSize: "14px",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
  },
};

export default function EmailPasswordLogin() {
  let navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [showOtpPopup, setShowOtpPopup] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [tempStorageOfOneFaToken, setTempStorageOfOneFaToken] = useState("");
  const { selectedLoginEntry } = useLoginEntryStore();
  const passwordInputRef: any = useRef();

  useEffect(() => {
    if (selectedLoginEntry) {
      let selectedJwtToken = getCachedLoginToken(selectedLoginEntry?.userId!);
      if (selectedJwtToken && isJwtExpired(selectedJwtToken)) {
        passwordInputRef?.current?.focus();
        form.setValue("email", selectedLoginEntry?.email);
        form.setValue("password", "");
      }
    }
  }, [selectedLoginEntry]);

  const {
    keepCredentials,
    setToken,
    setLanguage,
    setSideBar,
    setUserId,
    setUserName,
    setLandingPage,
    setAllowAction,
    setKeepCredentials,
  } = useUserStore();

  const [loginCredentialsCheckBox, setLoginCredentialsCheckBox] =
    useState<boolean>(keepCredentials ? true : false);

  interface LoginForm {
    email: string;
    password: string;
  }

  const formSchema = yup.object().shape({
    email: yup
      .string()
      .email("Invalid email address")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2})?$/i,
        "Invalid email address!"
      )
      .required("Email is required"),
    password: yup.string().required("Password is required"),
  });

  const form = useForm<LoginForm>({
    resolver: yupResolver(formSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  const storeCurrentUserInfo = (data: loginData) => {
    const { role, userId, userName } = decodeToken(data.token);
    localStorage.setItem("token", data?.token || "");
    localStorage.setItem("userid", userId); // currently used in birection controle components

    setToken(data?.token);
    setLanguage("English");
    console.log("sidebar  : ", data.layoutInfo.sideBar);
    setSideBar(data.layoutInfo.sideBar);
    setUserId(userId);
    setUserName(userName);

    setLandingPage(data.layoutInfo.landingPage);

    if (role === "Operator Group") {
      setAllowAction(false);
    } else {
      setAllowAction(true);
    }
  };

  const thingsToDoIfUserTokenAvailable = (data: loginData) => {
    storeCurrentUserInfo(data);
    const { userId } = decodeToken(data.token);
    if (loginCredentialsCheckBox)
      addCachedLoginEntry(userId, data?.token || "");


    navigate(data.layoutInfo.landingPage, { replace: false });

  };

  const onSubmit = (formData: LoginForm) => {
    setLoading(true);

    postLoginDetailV2(
      formData.email,
      formData.password,
      localStorage.getItem("FireBase")!
    )
      .then((loginData: loginData) => {
        if (loginData.success) {
          if (loginData.oneFactorAuthToken?.length! > 0) {
            let { userId } = decodeToken(loginData.oneFactorAuthToken);
            setTempStorageOfOneFaToken(loginData?.oneFactorAuthToken);
            if (loginCredentialsCheckBox)
              addCachedLoginEntry(userId, loginData?.oneFactorAuthToken || "");
            setShowOtpPopup(true);
          } else if (loginData.token?.length! > 0) {
            thingsToDoIfUserTokenAvailable(loginData);
          }
        }

        setLoading(false);
        toast(loginData.message);
      })
      .catch((err) => {
        console.log(err);
        toast(
          "Something Went wrong while logging In, please try again in sometime"
        );
        setLoading(false);
      });
  };

  const handleKeepLoginCredentialsChange = (value: boolean) => {
    setLoginCredentialsCheckBox(value);
    setKeepCredentials(value);
  };

  async function handleOTPVerification(
    oneFactorAuthToken: string,
    otp: number
  ) {
    try {
      const data: loginData = await loginUsing2StepVerification(
        oneFactorAuthToken,
        otp
      );

      if (data?.token) {
        storeCurrentUserInfo(data);
        navigate(data.layoutInfo.landingPage);
      } else {
        toast(data.message);
      }
    } catch (error) {
      toast("Otp verification failed");
    }
  }

  return (
    <ThemeProvider theme={dpTheme}>
      <Box sx={styles.container}>
        {/* <FormControl onSubmit={handleSubmit(onSubmit)} fullWidth={true}> */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="column" alignItems="flex-start" gap={3}>
            <TextField
              required
              fullWidth
              id="email"
              label="Email"
              variant="outlined"
              InputProps={{ style: { fontFamily: "Montserrat" } }}
              error={errors.email ? true : false}
              helperText={errors.email?.message}
              {...register("email")}
            />

            <TextField
              fullWidth
              required
              type={showPassword ? "text" : "password"}
              id="password"
              label="Password"
              variant="outlined"
              error={errors.password ? true : false}
              helperText={errors.password?.message}
              inputRef={passwordInputRef}
              {...register("password")}
              InputProps={{
                style: { fontFamily: "Montserrat" },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowPassword((prevSatate) => !prevSatate);
                      }}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Stack direction="row" alignItems="center">
              <Checkbox
                checked={loginCredentialsCheckBox}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleKeepLoginCredentialsChange(e.target.checked);
                }}
              />{" "}
              <Typography
                fontWeight="400"
                fontFamily="Roboto"
                fontSize="14px"
                variant="body2"
              >
                Keep Credentials
              </Typography>
            </Stack>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={loading}
            >
              Login{"  "}
              {loading ? (
                <CircularProgress
                  size={20}
                  sx={{ color: "inherit", marginLeft: "10px" }}
                />
              ) : null}
            </Button>
            <Box sx={styles.forgotPasswordLink}>
              <Link to="/ForgotPasswordV2?token=init">Forgot Password?</Link>
            </Box>
          </Stack>
          {/* </FormControl> */}
        </form>
      </Box>
      <OTPPopup
        showOtpPopup={showOtpPopup}
        onClose={() => {
          setShowOtpPopup(false);
        }}
        onResendOTP={async () => {
          if (tempStorageOfOneFaToken) {
            let result = await getOTPForLogin(tempStorageOfOneFaToken);
            toast(result?.message);
          }
        }}
        onSubmit={async (otp: number) => {
          console.log("onsubmit pressed ");
          if (tempStorageOfOneFaToken)
            await handleOTPVerification(tempStorageOfOneFaToken, otp);
        }}
        timerDurationSeconds={OTP_RESEND_TIMER}
      />
    </ThemeProvider>
  );
}
