const TICKET_CATEGORY_OPTIONS = [
  { displayName: 'Temporary', value: 'temporary' },
  { displayName: 'Permanent', value: 'permanent' },
];

const MANUAL_TICKET_TYPE_OPTIONS = [
  'Retrofit Needs',
  'Plant Process Issues',
  'Plant Equipment Issues',
  'DP Sensor Issues',
  'Other Concerns',
];

const TICKET_SUB_TYPE_OPTIONS = [
  { displayName: 'Equipment', value: 'equipment', dependsOn: 'plant_equipment_issues' },
  { displayName: 'Sensor', value: 'sensor', dependsOn: 'dp_sensor_issues' },
  { displayName: 'NA', value: 'na', dependsOn: 'all_other_types' },
];

export { TICKET_CATEGORY_OPTIONS, TICKET_SUB_TYPE_OPTIONS, MANUAL_TICKET_TYPE_OPTIONS };
