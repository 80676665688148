import { Autocomplete, Chip, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { createTag, getTagsForUser } from '../../services/tags.service';

interface TagSelectorProps {
  selectedTags: any[];
  onChange: (newTags: any[]) => void;
}

export default function TagSelector({ selectedTags, onChange }: TagSelectorProps) {
  const [tags, setTags] = useState<any[]>([]);

  useEffect(() => {
    // Fetch tags for the user on component mount
    getTagsForUser().then((tags: any) => {
      setTags(tags);
    });
  }, []);

  const handleTagChange = async (event: any, newValue: any[]) => {
    const lastValue = newValue[newValue.length - 1];

    if (typeof lastValue === 'string') {
      const newTagName = lastValue.replace('Create new tag ', '').trim();
      if (newTagName) {
        // Create a new tag and update the tag list
        const createdTag = await createTag(newTagName, '');
        setTags(prevTags => [...prevTags, createdTag]);
        onChange([...newValue.slice(0, -1), createdTag]);
      }
    } else {
      onChange(newValue);
    }
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      options={tags} // List of available tags
      getOptionLabel={option => (typeof option === 'string' ? option : option.name)} // Show the tag name
      value={selectedTags} // Current selected tags
      onChange={handleTagChange} // Handle tag changes
      renderTags={(value: any[], getTagProps) =>
        value.map((option: any, index: number) => (
          <Chip label={option.name} {...getTagProps({ index })} /> // Render selected tags as Chips
        ))
      }
      renderInput={params => <TextField {...params} label="Tags" />} // Render input field for tags
      filterOptions={(options, { inputValue }) => {
        const filtered = options.filter(option =>
          option.name.toLowerCase().includes(inputValue.toLowerCase())
        );

        // Suggest the option to create a new tag if input doesn't match existing ones
        if (inputValue !== '' && !options.map(option => option.name).includes(inputValue)) {
          filtered.push(`Create new tag ${inputValue}`);
        }

        return filtered;
      }}
    />
  );
}
