import {
  Box,
  Chip,
  Dialog,
  DialogTitle,
  Divider,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useIsMobile from '../../../utilities/customHooks/useIsMobile';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BugReportIcon from '@mui/icons-material/BugReport';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
const ImageDialogueBox = (props: any) => {
  const {
    open,
    handleClose,
    imageDetails,
    handleClick,
    anchorEl,
    openComment,
    handleCloseComment,
    allComments,
  } = props;
  const isMobile = useIsMobile({ breakpointValue: 'sm' });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        borderRadius: '12px',
        '.MuiDialog-paper': {
          borderRadius: '12px !important',
          scrollbarWidth: 'none',
        },
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          minHeight: 'unset !important',
          paddingLeft: '15px',
          paddingRight: '15px',
          paddingTop: '5px',
        }}
      >
        <DialogTitle
          sx={{
            color: '#000000',
            fontSize: '1rem',
            padding: '0',
            paddingLeft: '5px',
          }}
        >
          Floating Sludge
        </DialogTitle>
        <IconButton edge="end" onClick={handleClose}>
          <CloseIcon fontSize="small" sx={{ color: '#000000', paddingRight: '2px' }} />
        </IconButton>
      </Toolbar>

      <img
        src={imageDetails?.fullImageUrl}
        alt="logo"
        height={isMobile ? '355px' : '400px'}
        style={{
          marginLeft: '18px',
          marginRight: '18px',
        }}
      />

      <Box display="flex" flexDirection="column" sx={{ padding: '20px' }}>
        <Box display="flex" justifyContent="space-between" alignItems="flex-start">
          <Box
            display="flex"
            alignItems={isMobile ? 'flex-start' : 'center'}
            flexDirection={isMobile ? 'column' : 'row'}
            gap={isMobile ? 1 : 0}
          >
            <Box display="flex" alignItems="center">
              <Typography variant="h6" color="#666" fontWeight="800" fontSize="12px">
                Detected Time:
              </Typography>
              <Typography
                variant="h6"
                sx={{ marginLeft: '10px' }}
                fontSize="12px"
                color="#666"
                fontWeight="400"
              >
                {imageDetails?.time}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" ml={isMobile ? 0 : 3} mb={isMobile ? 2 : 0}>
              <Typography variant="h6" color="#666" fontWeight="800" fontSize="12px">
                Date:
              </Typography>
              <Typography
                variant="h6"
                sx={{ marginLeft: '10px' }}
                fontSize="12px"
                color="#666"
                fontWeight="400"
              >
                {imageDetails?.date}
              </Typography>
            </Box>
          </Box>
          <Box>
            <IconButton
              edge="end"
              sx={{
                justifyContent: 'flex-end',
                marginRight: '2px',
              }}
              onClick={handleClick}
            >
              <MoreVertIcon fontSize="medium" sx={{ color: '#3EA4A8' }} />
            </IconButton>
            <Menu
              id="fade-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button',
              }}
              anchorEl={anchorEl}
              open={openComment}
              onClose={handleCloseComment}
              TransitionComponent={Fade}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={
                isMobile
                  ? {
                      vertical: 'top',
                      horizontal: 'right',
                    }
                  : undefined
              }
              sx={{
                '.MuiMenu-paper': {
                  border: '1px solid #5C6066',
                  marginLeft: '-22px',
                },
                '.MuiMenu-list': {
                  padding: 0,
                },
              }}
            >
              <MenuItem
                onClick={e => handleCloseComment(e, 'False Detection')}
                sx={{
                  alignItems: 'center',
                  color: '#5C6066',
                  fontWeight: 400,
                  gap: '10px',
                  minHeight: 'unset !important',
                  fontSize: '14px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                }}
              >
                <QuestionMarkIcon fontSize="small" sx={{ color: '#999999' }} />
                False Detection (Hide)
              </MenuItem>
              <Divider sx={{ color: '#0000001A', margin: '0 !important' }} />
              <MenuItem
                onClick={e => handleCloseComment(e, 'Report Bug')}
                sx={{
                  alignItems: 'center',
                  color: '#5C6066',
                  fontWeight: 400,
                  gap: '10px',
                  minHeight: 'unset !important',
                  fontSize: '14px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                }}
              >
                <BugReportIcon fontSize="small" sx={{ color: '#999999' }} />
                Report bug
              </MenuItem>
              <Divider sx={{ color: '#0000001A', margin: '0 !important' }} />
              <MenuItem
                onClick={e => handleCloseComment(e, 'Add New Comment')}
                sx={{
                  alignItems: 'center',
                  color: '#5C6066',
                  fontWeight: 400,
                  gap: '10px',
                  minHeight: 'unset !important',
                  fontSize: '14px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                }}
              >
                <ChatBubbleIcon fontSize="small" sx={{ color: '#999999' }} />
                Add comments
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-start" marginLeft="-8px">
          <Chip label="Comments" sx={{ color: '#666' }} />
        </Box>
        {allComments.map((comment: any) => {
          return (
            <Box display="flex" alignItems="flex-start" mt={allComments[0] === comment ? 2 : 1}>
              <Typography
                variant="h6"
                color="#666"
                fontWeight="800"
                fontSize="12px"
                whiteSpace="nowrap"
              >
                {comment.title || comment.commentTitle}
              </Typography>
              <Typography
                variant="h6"
                sx={{ marginLeft: '10px' }}
                fontSize="12px"
                color="#666"
                fontWeight="400"
              >
                {comment.comment}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Dialog>
  );
};

export default ImageDialogueBox;
