import {
  Box,
  TextField,
  Checkbox,
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { UNIT_OPTION_LIST_2 } from '../../../constants/units.contants';
import { arrayMove } from '../../../utilities/array.utils';
import { useDrag, useDrop } from 'react-dnd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Tooltip from '@mui/material/Tooltip';
import { MAX_ALLOWED_SENSOR_VALUE, MIN_ALLOWED_SENSOR_VALUE } from '../../../constants/constants';
import { useRef } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

interface IProps {
  categoryName: string;
  formHelperText: {
    name: string;
    count: number;
  };
  sensorsTagData: any[];
  componentIndex: number;
  updateSensorTagValue: Function;
  hmiData?: any;
  updateValue: Function;
}

const SensorTag = ({
  sensor,
  sensorIndex,
  updateSensorTagValue,
  categoryName,
  componentIndex,
  hmiData,
  sensorsTagData,
  updateValue,
}: any) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'ITEM',
    item: { sensor, sensorIndex },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: 'ITEM',
    drop: (item: any) => {
      //@ts-ignore
      const { sensor: draggedSensor, sensorIndex: draggedIndex } = item;
      const updatedSensors = arrayMove(sensorsTagData, draggedIndex, sensorIndex);
      // updateSensorTagValue(categoryName, componentIndex, 'sensors', updatedSensors);
      updateValue({
        category: categoryName,
        index: componentIndex,
        valuefor: 'sensors',
        value: updatedSensors,
      });
    },
  });

  const maxValueRef = useRef(null);
  const minValueRef = useRef(null);

  return (
    <Box ref={drop} sx={{ opacity: isDragging ? 0.5 : 1 }}>
      <Accordion
        disableGutters
        elevation={0}
        sx={{
          marginTop: '10px',
          marginBottom: '10px',
          border: '1px solid black',
          borderRadius: '4px',
        }}
      >
        <AccordionSummary
          ref={drag}
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Stack direction="row" gap={1}>
            {(sensor['maxValue'] || sensor['minValue']) && (
              <Box>
                <FilterAltIcon fontSize="small" sx={{ color: 'green' }} />
              </Box>
            )}
            {sensor['sensorNickName'] && sensor['sensorNickName']?.length > 1 ? (
              <Tooltip title={sensor['sensorTag']}>
                <Box sx={{ color: 'green' }}>{sensor['sensorNickName']}</Box>
              </Tooltip>
            ) : (
              <Box>{sensor['sensorTag']}</Box>
            )}
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Box border="0.7px solid black" borderRadius={2} mb={2} p={2}>
            <Box mb={2}>
              <TextField
                fullWidth
                type="String"
                value={sensor['sensorTag']}
                variant="outlined"
                label={sensor['sensorName']}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  updateSensorTagValue(
                    categoryName,
                    componentIndex,
                    'sensorTag',
                    sensorIndex,
                    e.target.value
                  )
                }
              />
            </Box>
            <Box mb={2}>
              <TextField
                fullWidth
                type="String"
                value={sensor['sensorNickName']}
                variant="outlined"
                label={sensor['sensorName'] + ' Nickname'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  updateSensorTagValue(
                    categoryName,
                    componentIndex,
                    'sensorNickName',
                    sensorIndex,
                    e.target.value
                  )
                }
              />
            </Box>
            <Box mb={2}>
              <Stack direction="row" gap={1}>
                <TextField
                  fullWidth
                  type="number"
                  inputRef={minValueRef}
                  defaultValue={sensor['minValue'] ?? MIN_ALLOWED_SENSOR_VALUE}
                  variant="outlined"
                  label={sensor['sensorName'] + ' Min Value'}
                  onBlur={(e: any) =>
                    updateSensorTagValue(
                      categoryName,
                      componentIndex,
                      'minValue',
                      sensorIndex,
                      e.target.value,
                      minValueRef
                    )
                  }
                ></TextField>
                <TextField
                  fullWidth
                  type="number"
                  inputRef={maxValueRef}
                  defaultValue={sensor['maxValue'] ?? MAX_ALLOWED_SENSOR_VALUE}
                  variant="outlined"
                  label={sensor['sensorName'] + ' Max Value'}
                  onBlur={(e: any) =>
                    updateSensorTagValue(
                      categoryName,
                      componentIndex,
                      'maxValue',
                      sensorIndex,
                      e.target.value,
                      maxValueRef
                    )
                  }
                ></TextField>
              </Stack>
            </Box>
            {sensor['sensorName'] === 'ON/OFF Status' &&
              hmiData &&
              hmiData.length > 0 &&
              hmiData.map((hmi: any) => (
                <Box m={1}>
                  <TextField
                    type="String"
                    value={hmi['hmiTag']}
                    variant="outlined"
                    label="HMI Tag"
                  />
                </Box>
              ))}
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              value={
                sensor['unit']
                  ? UNIT_OPTION_LIST_2.find(option => option.value === sensor['unit'].value)
                  : { label: 'None', value: '' }
              }
              options={UNIT_OPTION_LIST_2}
              getOptionLabel={option => option.label!}
              onChange={(event: any, newValue: any) =>
                updateSensorTagValue(
                  categoryName,
                  componentIndex,
                  'unit',
                  sensorIndex,
                  newValue ? newValue : null
                )
              }
              renderInput={params => <TextField {...params} label="Unit" />}
            />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              sx={{ width: '100%' }}
            >
              <Typography>Display on node</Typography>
              <Checkbox
                checked={sensor['display']}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  updateSensorTagValue(
                    categoryName,
                    componentIndex,
                    'display',
                    sensorIndex,
                    event.target.checked
                  )
                }
              />
            </Stack>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

function SensorTagArray({
  formHelperText,
  categoryName,
  componentIndex,
  sensorsTagData,
  updateSensorTagValue,
  hmiData,
  updateValue,
}: IProps) {
  return (
    <DndProvider backend={HTML5Backend}>
      <>
        {sensorsTagData.length >= 1 && (
          <>
            <FormLabel component="legend">
              Sensor Tags in {formHelperText.name} {formHelperText.count}
            </FormLabel>
            <Box>
              {sensorsTagData.map((sensor, index) => (
                <SensorTag
                  key={`sensor-${index}`}
                  sensor={sensor}
                  sensorIndex={index}
                  updateSensorTagValue={updateSensorTagValue}
                  categoryName={categoryName}
                  componentIndex={componentIndex}
                  hmiData={hmiData}
                  sensorsTagData={sensorsTagData}
                  updateValue={updateValue}
                />
              ))}
            </Box>
          </>
        )}
      </>
    </DndProvider>
  );
}

export default React.memo(SensorTagArray);
