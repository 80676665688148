import { Box, Button, Dialog, DialogTitle, IconButton, TextField, Toolbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
const CommentDialogueBox = (props: any) => {
  const {
    openCommentDialogue,
    handleCloseCommentDialogueOutside,
    commentDialogueHeaderText,
    handleCloseCommentDialogue,
    handleOnChangeComment,
    addComment,
    errorTitle,
    setErrorTitle,
    errorComment,
    setErrorComment,
    handleAddComment,
  } = props;
  return (
    <Dialog
      open={openCommentDialogue}
      onClose={handleCloseCommentDialogueOutside}
      sx={{
        borderRadius: '12px',
        '.MuiDialog-paper': { borderRadius: '12px !important' },
      }}
      fullWidth={true}
      maxWidth="xs"
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingLeft: '12px',
          paddingRight: '20px',
        }}
      >
        <DialogTitle
          sx={{
            paddingLeft: '8px !important',
            color: '#000000',
            fontSize: '1rem',
          }}
        >
          {commentDialogueHeaderText}
        </DialogTitle>
        <IconButton edge="end" onClick={handleCloseCommentDialogue}>
          <CloseIcon fontSize="small" sx={{ color: '#000000' }} />
        </IconButton>
      </Toolbar>
      <Box display="flex" flexDirection="column" sx={{ padding: '0px 20px', gap: '1rem' }}>
        <TextField
          id="outlined-basic"
          label="Title"
          variant="outlined"
          name="title"
          onChange={handleOnChangeComment}
          value={addComment.title}
          error={errorTitle}
          onFocus={() => setErrorTitle(false)}
          helperText={
            errorTitle
              ? 'Title should not be less then 4 Characters and not more than 20 Characters'
              : ''
          }
          FormHelperTextProps={{
            style: { marginLeft: 0 }, // Override default style
          }}
        />
        <TextField
          id="outlined-basic"
          label="Comment"
          variant="outlined"
          name="comment"
          value={addComment.comment}
          onChange={handleOnChangeComment}
          error={errorComment}
          helperText={errorComment ? 'Comments should be minimum 5 words long' : ''}
          FormHelperTextProps={{
            style: { marginLeft: 0 }, // Override default style
          }}
          onFocus={() => setErrorComment(false)}
        />
        <Button
          variant="contained"
          sx={{
            padding: '1rem',
            background: '#3271A9',
            marginBottom: '1rem',
          }}
          onClick={handleAddComment}
          disabled={!addComment.title || !addComment.comment}
        >
          Save Comment
        </Button>
      </Box>
    </Dialog>
  );
};

export default CommentDialogueBox;
