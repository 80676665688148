import { useEffect, useState } from 'react';
import {
  Stack,
  TextField,
  Dialog,
  Typography,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  IconButton as MuiIconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

interface propsInterface {
  handleClose: any;
  open: boolean;
  widgetConfig: any;
  setRefresh: Function;
}

const DialogTitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2), // Adjusted padding
}));

const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2),
  paddingRight: 0, // Adjust padding right for close button alignment
  display: 'flex',
  alignItems: 'center',
}));

export default function ManualEntryConfigPopup({
  handleClose,
  open,
  widgetConfig,
  setRefresh,
}: propsInterface) {
  // Error states
  const [widgetNameError, setWidgetNameError] = useState(false);

  const updateWidgetValue = (property: string, value: any) => {
    widgetConfig[property] = value;
    setRefresh((state: boolean) => !state);
  };

  // Validation checks
  const validateField = (property: string, value: any) => {
    if (property === 'widgetName') {
      setWidgetNameError(value.trim() === '');
      return !(value.trim() === '');
    } else return true;
  };

  useEffect(() => {
    if (open) setWidgetNameError(false);
  }, [open]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      PaperProps={{
        style: {
          width: '80%', // Increase the width
          maxWidth: '80%', // Ensure the dialog doesn't grow beyond this width
        },
      }}
    >
      <Stack direction="column" gap={3} p={3}>
        <Stack direction="row" justifyContent="space-between">
          <DialogTitle sx={{ display: 'flex', alignItems: 'center' }} id="customized-dialog-title">
            <Typography variant="h5">Ticket Widget Configuration</Typography>
          </DialogTitle>
          <MuiIconButton aria-label="close" onClick={handleClose}>
            <CloseIcon sx={{ color: 'black', fontSize: '20px' }} />
          </MuiIconButton>
        </Stack>

        <TextField
          id="heading-field"
          label="Widget Name"
          fullWidth
          variant="outlined"
          multiline
          rows={1}
          defaultValue={widgetConfig.widgetName || ''}
          error={widgetNameError} // Display error state
          helperText={widgetNameError ? 'Widget Name is required' : ''} // Display error message
          onBlur={(event: any) => {
            const value = event.target.value;
            if (validateField('widgetName', value)) {
              updateWidgetValue('widgetName', value);
            }
          }}
        />
        <TextField
          id="description-field"
          fullWidth
          variant="outlined"
          placeholder="Type your text here!"
          multiline
          rows={2}
          defaultValue={widgetConfig.widgetDescription || ''}
          onBlur={(event: any) => {
            const value = event.target.value;
            updateWidgetValue('widgetDescription', value);
          }}
        />
        <TextField
          fullWidth
          type="text"
          defaultValue={widgetConfig.aiHelperText}
          variant="outlined"
          label="AI Helper Text"
          placeholder="AI helper text"
          multiline
          rows={6}
          onBlur={(e: any) => {
            updateWidgetValue('aiHelperText', e.target.value); // Optional, no validation needed
          }}
        />
      </Stack>
    </Dialog>
  );
}
