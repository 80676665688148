import axiosInstance from "../constants/axiosInstance";

const getDataInputCategoryList = async () => {
  try {
    const { data } = await axiosInstance().get(
      `/InventoryManagement/getDataInputCategoryList`
    );
    return data.data;
  } catch (err) {
    console.error("ERROR | getDataInputCategoryList | ", err);
  }
};

export { getDataInputCategoryList };
