import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Stack,
  Divider,
  OutlinedInput,
  IconButton,
  FormControl,
  InputLabel,
} from '@mui/material';
import {
  analyseWidgetData,
  deleteWidgetAnalysisHistory,
  getWidgetAnalysisHistoriesForWidgetId,
  updateWidgetAnalysisHistory,
} from '../../../services/dashboard/widget';
import EditableHeading from '../../Common/EditableHeading';
import moment from 'moment';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import DeleteIcon from '@mui/icons-material/Delete';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import PsychologyIcon from '@mui/icons-material/Psychology';
// import useMouseOutsideWindow from "../../../utilities/customHooks/useMouseOutsideWindow";
import Popover from '@mui/material/Popover';
import { toast } from 'react-toastify';
import { getPlantFeatureAuthDataByPlantId } from '../../../services/plantFeatureAuth';
import useIsMobile from '../../../utilities/customHooks/useIsMobile';
import useIsTablet from '../../../utilities/customHooks/useIsTablet';

export default function WidgetAnalyser(props: any) {
  const { open, anchorEl, handleClose, widgetData, widgetTimeFrame } = props;
  const [loading, setLoading] = useState(false);
  const [analysedData, setAnalysedData] = useState<any>(null);
  const [widgetAnalysisHistory, setWidgetAnalysisHistory] = useState<any[]>([]);
  const [refreshHistory, setRefreshHistory] = useState<boolean>(false);
  const [showOnlyBookMarkedData, setShowOnlyBookMarkedData] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [currentSelectedAnalysis, setCurrentSelectedAnalysis] = useState<string>('');
  const [analysedDataSuccessfully, setAnalysedDataSuccessfully] = useState<boolean>(false);
  const [creditsAvailable, setCreditsAvailable] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const { plantId: tempPlantId } = useParams();

  let plantId = '';

  if (widgetData?.widgetType == 'MapWidget') {
    plantId = tempPlantId ? tempPlantId : widgetData?.plants?.[0]?.plantId;
  } else {
    plantId = tempPlantId ? tempPlantId : widgetData?.plantIds?.[0];
  }

  const isMobile = useIsMobile({ breakpointValue: 'sm' });
  const isTablet = useIsTablet();

  //   const isMouseOutside = useMouseOutsideWindow();

  async function analyseWidget() {
    setLoading(true);
    try {
      if (!checkIfTooManyDataPoints()) {
        console.log(widgetData);
        let newWidgetData = { ...widgetData, timeData: widgetTimeFrame };
        let result = await analyseWidgetData(newWidgetData, plantId);
        console.log('result : ', result);
        if (result.data) {
          setAnalysedData(result.data);
          setCurrentSelectedAnalysis(result.data?._id);
          setAnalysedDataSuccessfully(true);
          setRefreshHistory(!refreshHistory);
        } else {
          console.log('inside else');
          setAnalysedData({
            heading: 'There seems to be a issue!',
            description: result.message,
          });
          setAnalysedDataSuccessfully(false);
        }

      } else {
        setAnalysedData({
          heading: 'Failed',
          description:
            'There are too many data points in this widget to analyse, maybe try choosing hours or days as granularity !',
          bookMarked: false,
        });
        setAnalysedDataSuccessfully(false);
      }
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }

  async function getAvailableCredits() {
    getPlantFeatureAuthDataByPlantId(plantId!).then(data => {
      if (data) setCreditsAvailable(data.featureList?.[0]?.creditsAvailable);
    });
  }

  function checkIfTooManyDataPoints() {
    let dataPoints = 0;
    if (widgetData.metricsData) {
      dataPoints = widgetData.metricsData.labels.length * widgetData.metricsData.datasets.length;
    }

    console.log('datapoitns : ', dataPoints);

    if (dataPoints < 200) return false;
    else return true;
  }

  const handleUpdateWidgetAnalysisHistory = async (historyId: string, updateData: any) => {
    console.log('analysedWidget inside handleupdate ');
    await updateWidgetAnalysisHistory(historyId, updateData);
    console.log('analysedWidget : ', analysedData);
  };

  const startUpFunction = async () => {
    let data = await getWidgetAnalysisHistoriesForWidgetId(widgetData?._id);
    setWidgetAnalysisHistory(data);
    let currentSelectedHistory = data.find(
      (history: any) => history._id == currentSelectedAnalysis
    );
    if (currentSelectedHistory) {
      setAnalysedData(currentSelectedHistory);
      setCurrentSelectedAnalysis(currentSelectedHistory._id);
      setAnalysedDataSuccessfully(true);
    }
    if (data.length > 0 && !currentSelectedHistory) {
      setAnalysedData(data[0]);
      setCurrentSelectedAnalysis(data[0]._id);
      setAnalysedDataSuccessfully(true);
      await getAvailableCredits();
    } else if (data.length === 0) {
      await analyseWidget();
      await getAvailableCredits();
      setRefreshHistory(!refreshHistory);
    }
  };

  useEffect(() => {
    if (open) {
      startUpFunction();
      console.log('inside startupfunction');
    }
  }, [open]);

  useEffect(() => {
    if (widgetData && open) {
      getWidgetAnalysisHistoriesForWidgetId(widgetData?._id).then(data => {
        setWidgetAnalysisHistory(data);
        let currentSelectedHistory = data.find(
          (history: any) => history._id == currentSelectedAnalysis
        );
        if (currentSelectedHistory) {
          console.log('inside current Selected history : ', currentSelectedHistory);
          setAnalysedData(currentSelectedHistory);
          setCurrentSelectedAnalysis(currentSelectedHistory._id);
          setAnalysedDataSuccessfully(true);
          setRefresh(!refresh);
        }
      });
    }
  }, [refreshHistory]);

  //   useEffect(() => {
  //     if (isMouseOutside) handleClose();
  //   }, [isMouseOutside]);
  return (
    <Box>
      <Popover
        id={anchorEl ? 'simple-popover' : undefined}
        open={open}
        disableRestoreFocus
        anchorEl={anchorEl}
        onClose={() => {
          setLoading(false);
          setAnalysedData(null);
          setAnalysedDataSuccessfully(false);
          handleClose();
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          elevation: 0, // You can adjust the elevation for shadow effect
          sx: {
            border: '1px solid #BDBDBD', // Here you can set border style

            width: { xs: '100%', md: '700px' },
            // minHeight: '200px',
            maxHeight: { xs: '80%', md: '450px' },
            borderRadius: '11px',
            boxSizing: 'border-box',
            overflowY: 'hidden',
          },
        }}
      >
        <Box sx={{ width: 'inherit', height: 'inherit' }}>
          <Stack
            direction={{ xs: 'column-reverse', md: 'row' }}
            sx={{ height: 'inherit', maxHeight: { xs: 'auto', md: '450px' } }}
          >
            <Box
              borderRight={{ xs: 'none', md: '1px solid #BDBDBD' }}
              borderTop={{ xs: '1px solid #BDBDBD', md: 'none' }}
              sx={{ height: 'inherit', width: { xs: '100%', md: '350px' } }}
            >
              <Stack direction="row" alignItems="center" gap={4} p={3}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel size="small" sx={{ fontSize: 14 }}>
                    Search
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-basic"
                    label="Outlined"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton aria-label="search" edge="end">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    inputProps={{ style: { fontSize: '14px' } }}
                    size="small"
                    onChange={event => {
                      setSearchQuery(event.target.value);
                    }}
                    sx={{ minWidth: '200px' }}
                  />
                </FormControl>
                <BookmarksIcon
                  sx={{
                    color: showOnlyBookMarkedData ? '#333' : '#999999',
                    fontSize: '22px',
                    marginLeft: 'auto',
                    '& :hover': { cursor: 'pointer' },
                  }}
                  onClick={() => {
                    setShowOnlyBookMarkedData(!showOnlyBookMarkedData);
                  }}
                />
              </Stack>
              <Divider sx={{ width: '100%' }} />
              <Box sx={{ overflowY: 'scroll', height: '380px' }}>
                {widgetAnalysisHistory.map((history: any) => {
                  if (!showOnlyBookMarkedData || (showOnlyBookMarkedData && history.bookMarked)) {
                    if (
                      searchQuery.length == 0 ||
                      history.heading?.toLowerCase().includes(searchQuery.toLowerCase())
                    )
                      return (
                        <Box
                          sx={{
                            background: history?._id === analysedData?._id ? '#E6EFFA' : '#ffffff',
                            '& :hover': {
                              background: '#eee',
                              cursor: 'pointer',
                            },
                            width: '100%',
                          }}
                          onClick={() => {
                            setAnalysedData(history);
                            setCurrentSelectedAnalysis(history._id);
                            setAnalysedDataSuccessfully(true);
                          }}
                        >
                          <Box py={3}>
                            <Stack direction="row" alignItems="center" px={3}>
                              <EditableHeading
                                text={history.heading}
                                handleValueChange={async (newValue: string) => {
                                  console.log('history  :', history);
                                  await handleUpdateWidgetAnalysisHistory(history._id, {
                                    heading: newValue,
                                  });
                                  setRefreshHistory(!refreshHistory);
                                }}
                                fontSize={14}
                                fontWeight={400}
                                color="#000000"
                                fullWidth={false}
                                minWidth={isMobile ? '89%' : isTablet ? '95%' : 190}
                                maxWidth={isMobile ? '89%' : isTablet ? '95%' : 190}
                                editIconProps={{ fontSize: 14 }}
                              />
                              <BookmarksIcon
                                sx={{
                                  color: history.bookMarked ? '#333333' : '#99999966',
                                  fontSize: '15px',
                                  marginLeft: 'auto',
                                }}
                              />
                            </Stack>
                            <Box
                              sx={{
                                fontSize: '10px',
                                color: '#7D8188',
                                paddingTop: '5px',
                              }}
                              px={3}
                            >
                              {`${moment(history.timeFrame.startTime).format(
                                'DD MMM HH:mm'
                              )} - ${moment(history.timeFrame.endTime).format('DD MMM HH:mm')}`}
                            </Box>
                            <Box
                              sx={{
                                fontSize: '10px',
                                color: '#7D8188',
                                paddingTop: '5px',
                              }}
                              px={3}
                            >
                              <Stack direction="row" rowGap={1}>
                                {history.infoHeaders?.map((header: any) => {
                                  return <Box>{header.value}</Box>;
                                })}
                              </Stack>
                            </Box>
                          </Box>
                          <Divider sx={{ width: '100%' }} />
                        </Box>
                      );
                    else return null;
                  } else return null;
                })}
              </Box>
            </Box>
            <Stack
              direction="column"
              alignItems="center"
              gap={1}
              sx={{
                height: 'inherit',
                width: { xs: '100%', md: '500px' },
                boxSizing: 'border-box',
                minHeight: '300px',
                maxHeight: '450px',
                // border: "1px solid green",
              }}
              p={3}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyConten: 'flex-start',
                  width: '100%',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: '20px',
                    fontWeight: 500,
                  }}
                >
                  {loading ? 'Analysing...' : analysedData?.heading}
                </Typography>
                {analysedDataSuccessfully && (
                  <Stack direction="row" gap={1} sx={{ marginLeft: 'auto' }} alignSelf="flex-start">
                    <IconButton
                      onClick={() => {
                        deleteWidgetAnalysisHistory(analysedData?._id).then(data => {
                          toast(`Deleted widget analysis history`);
                          setRefreshHistory(!refreshHistory);
                          let filteredHistory = widgetAnalysisHistory.filter(
                            history => history._id != analysedData?._id
                          );
                          if (filteredHistory.length > 0)
                            setCurrentSelectedAnalysis(filteredHistory[0]?._id);
                          else {
                            setCurrentSelectedAnalysis('');
                            setAnalysedData({
                              heading: 'No more analysis history remaining!',
                              description: ' No more analysis history remaining!',
                              bookMarked: false,
                            });
                            setAnalysedDataSuccessfully(false);
                          }
                        });
                      }}
                    >
                      <DeleteIcon sx={{ color: '#333333' }} />
                    </IconButton>

                    {analysedData?.bookMarked ? (
                      <IconButton
                        onClick={async () => {
                          await handleUpdateWidgetAnalysisHistory(analysedData?._id, {
                            bookMarked: false,
                          });
                          setRefreshHistory(!refreshHistory);
                        }}
                      >
                        <BookmarkIcon sx={{ color: '#333333' }} />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={async () => {
                          await handleUpdateWidgetAnalysisHistory(analysedData?._id, {
                            bookMarked: true,
                          });
                          setRefreshHistory(!refreshHistory);
                        }}
                      >
                        <BookmarkBorderIcon sx={{ color: '#333333' }} />
                      </IconButton>
                    )}
                  </Stack>
                )}
              </Box>
              {analysedDataSuccessfully && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    fontSize: '12px',
                    color: '#7D8188',
                    alignSelf: 'flex-start',
                    marginTop: '-5px',
                  }}
                  px={1}
                >
                  <Box>{`${moment(analysedData?.timeFrame?.startTime).format(
                    'DD MMM HH:mm'
                  )} - ${moment(analysedData?.timeFrame?.endTime).format('DD MMM HH:mm')}`}</Box>
                  <Box
                    sx={{
                      fontSize: '11px',
                      color: '#999999DE',
                      border: '1px solid #999999DE',
                      borderRadius: '100px',
                    }}
                    px={3}
                    py={1}
                  >
                    {analysedData?.timeFrame?.granularity}
                  </Box>
                  {analysedData.infoHeaders?.map((header: any) => {
                    return (
                      <Box
                        sx={{
                          fontSize: '11px',
                          color: '#999999DE',
                          border: '1px solid #999999DE',
                          borderRadius: '100px',
                        }}
                        px={3}
                        py={1}
                      >
                        {header?.value}
                      </Box>
                    );
                  })}
                </Box>
              )}
              <Box
                my={1}
                sx={{
                  display: 'grid',
                  placeItems: 'center',
                  height: { xs: '95%', sm: '95%', md: '65%' },
                  width: '100%',
                }}
              >
                {loading ? (
                  <CircularProgress sx={{ color: 'black' }} />
                ) : (
                  <Box
                    sx={{
                      height: '100%',
                      width: '100%',
                      overflowY: 'scroll',
                      fontSize: '12px',
                      color: '#3C4247',
                      lineHeight: '18px',
                    }}
                  >
                    {analysedData?.description}
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  marginTop: 'auto',
                  marginLeft: 'auto',
                  justifySelf: 'flex-end',
                  fontSize: '12px',
                  color: '#7D8188',
                }}
              >{`${creditsAvailable} analysis left`}</Box>
              <Button
                variant="contained"
                fullWidth
                disableElevation
                sx={{
                  marginTop: '15px',
                  justifySelf: 'flex-end',
                  background: '#3271A9',
                  padding: '10px',
                  '&:hover': {
                    background: '#4483BB',
                  },
                }}
                onClick={() => {
                  analyseWidget();
                  getAvailableCredits();
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '5px',
                  }}
                >
                  <PsychologyIcon sx={{ fontSize: '20px' }} /> <span> {'Analyse'}</span>
                </Box>
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Popover>
    </Box>
  );
}
