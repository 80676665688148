interface Sample {
  thumbnail?: { url: string };
  noSludgeIcon?: { url: string };
  falseDetectionIcon?: { url: string };
  'Detected Time': string;
  value: string;
}

interface TimeSamples {
  [minute: string]: Sample;
}

interface TimeOfDaySamples {
  [timeOfDay: string]: TimeSamples;
}

interface DateSamples {
  [date: string]: TimeOfDaySamples;
}

export function getDayMonthYear(dateString: string) {
  const date = new Date(dateString);
  const day = date.getDate();
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();
  let days = null;
  if (day == 1) days = day + 'st';
  else if (day == 2) days = day + 'nd';
  else if (day == 3) days = day + 'rd';
  else days = day + 'th';
  return `${days} ${month} ${year}`;
}

export const calculateNextDate = (currentDateStr: string) => {
  const currentDate = new Date(currentDateStr);
  const nextDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000); // Add 1 day (24 hours) in milliseconds
  return nextDate.toISOString(); // Return next date in YYYY-MM-DD format
};

export const calculatePreviousDate = (currentDateStr: string) => {
  const currentDate = new Date(currentDateStr);
  const previousDate = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000); // Subtract 1 day (24 hours) in milliseconds
  return previousDate.toISOString(); // Return previous date in YYYY-MM-DD format
};

export function processData(data: any) {
  const startTime = new Date(data.startTime);
  const metrics = data.metrics.metricData;

  let currentTime = new Date(startTime);
  let processedData = [];

  for (let key in metrics) {
    if (metrics.hasOwnProperty(key)) {
      const metric = metrics[key];
      const samplingTime = parseInt(key);
      const dateTime = new Date(currentTime);
      const value = metric.value;
      const thumbnail = metric.thumbnail;
      let issueName = '';

      if (samplingTime === 30) {
        issueName = 'SV30';
      } else if (samplingTime === 60) {
        issueName = 'SV60';
      } else if (samplingTime === 90) {
        issueName = 'SV90';
      }

      if (thumbnail) {
        if (issueName !== '') {
          issueName += ' | ' + thumbnail;
        } else {
          issueName = thumbnail;
        }
      }

      processedData.push({
        dateTime: dateTime.toISOString(),
        samplingTime: samplingTime + ' ' + data.metrics.timeUnit,
        value: value + ' ' + data.metrics.sampleUnit,
        issueName: issueName,
      });

      // Increment currentTime by 1 minute
      currentTime.setMinutes(currentTime.getMinutes() + 1);
    }
  }

  return processedData;
}

// Function to convert JSON data to CSV and download it for Sludge Settling Pattern
export function downloadCSV(shiftName: string, startTime: string, data: any) {
  // Prepare CSV content
  const csvContent =
    'data:text/csv;charset=utf-8,' +
    'Date - Time,Sampling Time,Value,Issue Name\n' +
    data
      .map((item: any) => `${item.dateTime},${item.samplingTime},${item.value},"${item.issueName}"`)
      .join('\n');

  // Create a temporary anchor element
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${shiftName}_${startTime}.csv`);

  // Trigger the download
  document.body.appendChild(link); // Required for Firefox
  link.click();

  // Clean up
  document.body.removeChild(link);
}

// Function to convert JSON data to CSV and download it for Sludge Value
export function downloadCSVForBarGraph(flocDetectorDefaultSamplesData: any) {
  const replacer = (key: string, value: any) => (value === null ? '' : value);
  const header: string[] = [
    'Date - Time',
    'Shift Name',
    'Sampling Time',
    'Default Sample',
    'Value',
    'Issue Name',
  ];

  const csv: string[] = flocDetectorDefaultSamplesData
    .map((obj: DateSamples) => {
      return Object.entries(obj)
        .map(([date, times]: [string, TimeOfDaySamples]) => {
          return Object.entries(times)
            .map(([timeOfDay, samples]: [string, TimeSamples]) => {
              return Object.entries(samples)
                .map(([minute, sample]: [string, Sample]) => {
                  if (typeof sample === 'object') {
                    const thumbnailUrl = sample.thumbnail?.url || '';
                    const noSludgeUrl = sample.noSludgeIcon?.url || '';
                    const falseDetectionUrl = sample.falseDetectionIcon?.url || '';

                    // Constructing the "Issue Name" value
                    const issueNameValue = `SV${parseFloat(minute)}${
                      thumbnailUrl ? ` | Thumbnail (${thumbnailUrl})` : ''
                    }${noSludgeUrl ? ` | Floating Sludge (${noSludgeUrl})` : ''}${
                      falseDetectionUrl ? ` | False Detection (${falseDetectionUrl})` : ''
                    }`;

                    return {
                      'Date - Time': `${date} ${sample['Detected Time']}`,
                      'Shift Name': timeOfDay,
                      'Sampling Time': minute,
                      'Default Sample': `${samples['Default Sample']} of ${samples['Total Sample']}`,
                      Value: sample.value,
                      'Issue Name': issueNameValue,
                    };
                  }
                  return;
                })
                .filter(row => row !== undefined) as any[];
            })
            .flat();
        })
        .flat();
    })
    .flat()
    .map((row: any) => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));

  const dates = flocDetectorDefaultSamplesData.map((entry: DateSamples) => Object.keys(entry)[0]);
  const firstDate = dates.reduce((min: any, date: any) => (date < min ? date : min));
  const lastDate = dates.reduce((max: any, date: any) => (date > max ? date : max));

  csv.unshift(header.join(','));
  const csvArray = csv.join('\r\n');

  const blob = new Blob([csvArray], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', `${firstDate}_${lastDate}_SludgeValueData.csv`);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function countWords(sentence: string) {
  // Split the sentence into words
  const words = sentence.split(/\s+/);
  // Count the number of words
  const wordCount = words.length;
  return wordCount;
}

const formatDateTime = (date: Date) => {
  const dateString = date.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
  });
  const timeString = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  });
  return `${dateString} ${timeString}`;
};

export const formatTimeRange = (customTimeRangeSettings: any) => {
  if (customTimeRangeSettings?.startTime && customTimeRangeSettings?.endTime) {
    const startTime = new Date(customTimeRangeSettings.startTime);
    const endTime = new Date(customTimeRangeSettings.endTime);
    return `${formatDateTime(startTime)} - ${formatDateTime(endTime)}`;
  }
  return '';
};

export const handleOnClickMinutesIcon = (label: string, setHiddenLabels: any) => {
  setHiddenLabels((prev: any) => {
    const allVisible = !prev['30 minutes'] && !prev['60 minutes'] && !prev['90 minutes'];

    const newHiddenState: { [key: string]: boolean } = {
      ...prev,
      '30 minutes': prev['30 minutes'],
      '60 minutes': prev['60 minutes'],
      '90 minutes': prev['90 minutes'],
    };

    // If all labels are visible, hide the others when one is clicked
    if (allVisible) {
      newHiddenState['30 minutes'] = label !== '30 minutes';
      newHiddenState['60 minutes'] = label !== '60 minutes';
      newHiddenState['90 minutes'] = label !== '90 minutes';
    } else {
      // If the clicked label is already hidden, make all labels visible
      if (!prev[label]) {
        newHiddenState['30 minutes'] = false;
        newHiddenState['60 minutes'] = false;
        newHiddenState['90 minutes'] = false;
      } else {
        // If a different label is clicked, only enable the clicked label
        newHiddenState[label] = !prev[label];
      }
    }
    return newHiddenState;
  });
};

export const handleOnClickIssueIcon = (
  label: string,
  selectedTypeGraph: any,
  setIssueIcon: any,
  setVisibleIcons: any
) => {
  if (selectedTypeGraph === 'Sludge Value') {
    setIssueIcon((prev: any) => {
      const allVisible =
        !prev['falseDetectionIcon'] && !prev['floatingSludgeIcon'] && !prev['noSludgeIcon'];

      const newHiddenState: { [key: string]: boolean } = {
        ...prev,
        falseDetectionIcon: prev['falseDetectionIcon'],
        floatingSludgeIcon: prev['floatingSludgeIcon'],
        noSludgeIcon: prev['noSludgeIcon'],
      };

      // If all labels are visible, hide the others when one is clicked
      if (allVisible) {
        newHiddenState['falseDetectionIcon'] = label !== 'falseDetectionIcon';
        newHiddenState['floatingSludgeIcon'] = label !== 'floatingSludgeIcon';
        newHiddenState['noSludgeIcon'] = label !== 'noSludgeIcon';
      } else {
        // If the clicked label is already hidden, make all labels visible
        if (!prev[label]) {
          newHiddenState['falseDetectionIcon'] = false;
          newHiddenState['floatingSludgeIcon'] = false;
          newHiddenState['noSludgeIcon'] = false;
        } else {
          // If a different label is clicked, only enable the clicked label
          newHiddenState[label] = !prev[label];
        }
      }
      return newHiddenState;
    });
  }
  if (selectedTypeGraph === 'Sludge Settling Pattern') {
    setVisibleIcons((prev: any) => {
      const allVisible = !prev['falseDetection'] && !prev['issueIcon'] && !prev['noSludge'];

      const newHiddenState: { [key: string]: boolean } = {
        ...prev,
        falseDetection: prev['falseDetection'],
        issueIcon: prev['issueIcon'],
        noSludge: prev['noSludge'],
      };
      // If all labels are visible, hide the others when one is clicked
      if (allVisible) {
        newHiddenState['falseDetection'] = label !== 'falseDetection';
        newHiddenState['issueIcon'] = label !== 'issueIcon';
        newHiddenState['noSludge'] = label !== 'noSludge';
      } else {
        // If the clicked label is already hidden, make all labels visible
        if (!prev[label]) {
          newHiddenState['falseDetection'] = false;
          newHiddenState['issueIcon'] = false;
          newHiddenState['noSludge'] = false;
        } else {
          // If a different label is clicked, only enable the clicked label
          newHiddenState[label] = !prev[label];
        }
      }
      return newHiddenState;
    });
  }
};

export function getDataByDate(date: string, flocDetectorDefaultSamplesData: any) {
  for (const sample of flocDetectorDefaultSamplesData) {
    if (sample.hasOwnProperty(date)) {
      return sample[date];
    }
  }
  return null;
}

export const preloadImages = (imageCache: Record<string, HTMLImageElement>, urls: string[]) => {
  urls.forEach(url => {
    if (!imageCache[url]) {
      const img = new Image();
      img.src = url;
      imageCache[url] = img;
    }
  });
};

export const calculateMaxHeight = (hiddenLabels: any, flocDetectorDefaultSamplesData: any) => {
  let maxHeight = 0;
  flocDetectorDefaultSamplesData.forEach((item: any) => {
    const date = Object.keys(item)[0];
    const shifts = item[date] ? Object.keys(item[date]) : [];
    shifts.forEach(shift => {
      const shiftValues = item[date][shift];
      const values30 = shiftValues['30 minutes'] ? parseInt(shiftValues['30 minutes'].value) : 0;
      const values60 = shiftValues['60 minutes'] ? parseInt(shiftValues['60 minutes'].value) : 0;
      const values90 = shiftValues['90 minutes'] ? parseInt(shiftValues['90 minutes'].value) : 0;
      if (
        !hiddenLabels['90 minutes'] &&
        !hiddenLabels['60 minutes'] &&
        !hiddenLabels['30 minutes']
      ) {
        maxHeight = values30 + 350;
      } else if (!hiddenLabels['90 minutes'] && !hiddenLabels['60 minutes']) {
        maxHeight = values60 + 350;
      } else if (!hiddenLabels['90 minutes'] && !hiddenLabels['30 minutes']) {
        maxHeight = values30 + 350;
      } else if (!hiddenLabels['60 minutes'] && !hiddenLabels['30 minutes']) {
        maxHeight = values30 + 350;
      } else if (!hiddenLabels['90 minutes']) {
        maxHeight = values90 + 130;
      } else if (!hiddenLabels['60 minutes']) {
        maxHeight = values60 + 130;
      } else if (!hiddenLabels['30 minutes']) {
        maxHeight = values30 + 130;
      } else {
        maxHeight = 0;
      }
    });
  });
  return maxHeight;
};

export function extractThumbnails(data: any[]) {
  const thumbnails: string[] = [];
  data.forEach(dayData => {
    Object.values(dayData).forEach((timeData: any) => {
      Object.values(timeData).forEach((sampleData: any) => {
        Object.values(sampleData).forEach((detectedData: any) => {
          if (detectedData.thumbnail?.url) {
            thumbnails.push(detectedData.thumbnail.url);
          }
          if (detectedData.floatingSludgeIcon?.url) {
            thumbnails.push(detectedData.floatingSludgeIcon.url);
          }
          if (detectedData.falseDetectionIcon?.url) {
            thumbnails.push(detectedData.falseDetectionIcon.url);
          }
          if (detectedData.noSludgeIcon?.url) {
            thumbnails.push(detectedData.noSludgeIcon.url);
          }
        });
      });
    });
  });
  return thumbnails;
}

export const calculateBarHeights = (
  values30: number[],
  values60: number[],
  values90: number[],
  hiddenLabels: any
) => {
  const heights30: number[] = [];
  const heights60: number[] = [];
  const heights90: number[] = [];

  for (let i = 0; i < values30.length; i++) {
    if (!hiddenLabels['90 minutes'] && !hiddenLabels['60 minutes'] && !hiddenLabels['30 minutes']) {
      heights90.push(values90[i]);
      heights60.push(values60[i] - values90[i]);
      heights30.push(values30[i] - values60[i]);
    } else if (!hiddenLabels['90 minutes'] && !hiddenLabels['60 minutes']) {
      heights90.push(values90[i]);
      heights60.push(values60[i] - values90[i]);
      heights30.push(0);
    } else if (!hiddenLabels['90 minutes'] && !hiddenLabels['30 minutes']) {
      heights90.push(values90[i]);
      heights60.push(0);
      heights30.push(values30[i] - values90[i]);
    } else if (!hiddenLabels['60 minutes'] && !hiddenLabels['30 minutes']) {
      heights90.push(0);
      heights60.push(values60[i]);
      heights30.push(values30[i] - values60[i]);
    } else if (!hiddenLabels['90 minutes']) {
      heights90.push(values90[i]);
      heights60.push(0);
      heights30.push(0);
    } else if (!hiddenLabels['60 minutes']) {
      heights90.push(0);
      heights60.push(values60[i]);
      heights30.push(0);
    } else if (!hiddenLabels['30 minutes']) {
      heights90.push(0);
      heights60.push(0);
      heights30.push(values30[i]);
    } else {
      heights90.push(0);
      heights60.push(0);
      heights30.push(0);
    }
  }

  return { heights30, heights60, heights90 };
};

export const prepareChartData = (flocDetectorDefaultSamplesData: any, hiddenLabels: any) => {
  const uniqueDates: string[] = [];
  const shiftData: Record<string, any> = {};
  const backgroundColors = {
    '30 minutes': '#EBCDAD',
    '60 minutes': '#DBA770',
    '90 minutes': '#B8742E',
  };

  flocDetectorDefaultSamplesData.forEach((item: any) => {
    const date = Object.keys(item)[0];
    const shifts = item[date] ? Object.keys(item[date]) : [];

    if (!uniqueDates.includes(date)) {
      uniqueDates.push(date);
    }

    shifts.forEach(shift => {
      if (!shiftData[shift]) {
        shiftData[shift] = {
          '30 minutes': [],
          '60 minutes': [],
          '90 minutes': [],
          thumbnails30: [],
          thumbnails60: [],
          thumbnails90: [],
          floatingSludgeIcon30: [],
          floatingSludgeIcon60: [],
          floatingSludgeIcon90: [],
          falseDetectionIcon30: [],
          falseDetectionIcon60: [],
          falseDetectionIcon90: [],
          noSludgeIcon30: [],
          noSludgeIcon60: [],
          noSludgeIcon90: [],
        };
      }

      const shiftValues = item[date][shift];
      shiftData[shift]['30 minutes'].push(
        shiftValues['30 minutes'] ? parseInt(shiftValues['30 minutes'].value) : 0
      );
      shiftData[shift]['60 minutes'].push(
        shiftValues['60 minutes'] ? parseInt(shiftValues['60 minutes'].value) : 0
      );
      shiftData[shift]['90 minutes'].push(
        shiftValues['90 minutes'] ? parseInt(shiftValues['90 minutes'].value) : 0
      );

      shiftData[shift]['thumbnails30'].push(shiftValues['30 minutes']?.thumbnail || '');
      shiftData[shift]['thumbnails60'].push(shiftValues['60 minutes']?.thumbnail || '');
      shiftData[shift]['thumbnails90'].push(shiftValues['90 minutes']?.thumbnail || '');

      shiftData[shift]['floatingSludgeIcon30'].push(
        shiftValues['30 minutes']?.floatingSludgeIcon || ''
      );
      shiftData[shift]['floatingSludgeIcon60'].push(
        shiftValues['60 minutes']?.floatingSludgeIcon || ''
      );
      shiftData[shift]['floatingSludgeIcon90'].push(
        shiftValues['90 minutes']?.floatingSludgeIcon || ''
      );

      shiftData[shift]['falseDetectionIcon30'].push(
        shiftValues['30 minutes']?.falseDetectionIcon || ''
      );
      shiftData[shift]['falseDetectionIcon60'].push(
        shiftValues['60 minutes']?.falseDetectionIcon || ''
      );
      shiftData[shift]['falseDetectionIcon90'].push(
        shiftValues['90 minutes']?.falseDetectionIcon || ''
      );

      shiftData[shift]['noSludgeIcon30'].push(shiftValues['30 minutes']?.noSludgeIcon || '');
      shiftData[shift]['noSludgeIcon60'].push(shiftValues['60 minutes']?.noSludgeIcon || '');
      shiftData[shift]['noSludgeIcon90'].push(shiftValues['90 minutes']?.noSludgeIcon || '');
    });
  });

  const datasets = [];
  for (const shift in shiftData) {
    const { heights30, heights60, heights90 } = calculateBarHeights(
      shiftData[shift]['30 minutes'],
      shiftData[shift]['60 minutes'],
      shiftData[shift]['90 minutes'],
      hiddenLabels
    );
    datasets.push({
      label: '90 minutes',
      data: heights90,
      backgroundColor: backgroundColors['90 minutes'],
      stack: shift,
      hidden: hiddenLabels['90 minutes'],
      thumbnails: shiftData[shift]['thumbnails90'],
      floatingSludgeIcon: shiftData[shift]['floatingSludgeIcon90'],
      falseDetectionIcon: shiftData[shift]['falseDetectionIcon90'],
      noSludgeIcon: shiftData[shift]['noSludgeIcon90'],
      borderRightWidth: 2,
      borderColor: 'transparent',
    });

    datasets.push({
      label: '60 minutes',
      data: heights60,
      backgroundColor: backgroundColors['60 minutes'],
      stack: shift,
      hidden: hiddenLabels['60 minutes'],
      thumbnails: shiftData[shift]['thumbnails60'],
      floatingSludgeIcon: shiftData[shift]['floatingSludgeIcon60'],
      falseDetectionIcon: shiftData[shift]['falseDetectionIcon60'],
      noSludgeIcon: shiftData[shift]['noSludgeIcon60'],
      bordertWidth: {
        right: 2,
      },
      borderColor: 'transparent',
    });

    datasets.push({
      label: '30 minutes',
      data: heights30,
      backgroundColor: backgroundColors['30 minutes'],
      stack: shift,
      hidden: hiddenLabels['30 minutes'],
      thumbnails: shiftData[shift]['thumbnails30'],
      floatingSludgeIcon: shiftData[shift]['floatingSludgeIcon30'],
      falseDetectionIcon: shiftData[shift]['falseDetectionIcon30'],
      noSludgeIcon: shiftData[shift]['noSludgeIcon30'],
      borderRightWidth: 2,
      borderColor: 'transparent',
    });
  }

  return {
    labels: uniqueDates,
    datasets: datasets,
  };
};
