import {
  Box,
  Breadcrumbs,
  Link,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import TwoRowLayout from '../../../../core-components/theme/layouts/TwoLayout/TwoLayoutComponent';
import SearchBox from '../../../../core-components/theme/components/Searchbox/Searchbox';
import { Neutrals, Primary } from '../../../../core-components/theme/color-palette/colors';
import LayoutComponent from '../../../../components/LayoutComponent';
import Table from '../../../../core-components/theme/components/Table/Table';
import OptionsMenu from './OptionMenu';
import RoleIcon from '../../../../core-components/assets/icons/RoleIcon';
import ModuleIcon from '../../../../core-components/assets/icons/ModuleIcon';
import { deleteRole, getRoleList } from '../../../../services/role';
import Button from '../../../../core-components/theme/components/Button/Button';

const RoleListPage = () => {
  // const [roleRowsData, setRoleRowsData] = useState<any>([]);
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/userList">
      <Typography key="3" color="#666666">
        Role List
      </Typography>
    </Link>,
  ];
  const [query, setQuery] = useState('');
  const [asset, setAssets] = useState<any>([]);
  const [administrative, setAdministrative] = useState<any>([]);
  const navigate = useNavigate();
  const handleSearch = (query: string) => {
    setQuery(query);
  };

  const handleClear = () => {
    setQuery('');
  };

  const editRole = (roleId: string) => {
    navigate(`/editRole/${roleId}`, { state: { roleId } });
  };
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (roleId: any) => deleteRole(roleId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['roleList'] });
      toast.success(`Role deleted`);
    },
    onError: (error: any) => {
      toast.error(error.message);
      console.error('Failed to delete role:', error);
    },
  });

  const handleDeleteRole = (roleId: string) => {
    mutation.mutate(roleId);
  };

  const { data: roleListData, status: roleListDataStatus } = useQuery({
    queryKey: ['roleList'],
    queryFn: getRoleList,
    refetchOnWindowFocus: false,
  });
  const getFilterRoles = () => {
    const filteredRows = roleListData?.filter((row: any) =>
      row.name.toLowerCase().includes(query.toLowerCase())
    );
    return filteredRows;
  };
  useEffect(() => {
    if (roleListDataStatus === 'success') {
      // setRoleRowsData(roleListData);
    }
  }, [roleListData, roleListDataStatus]);
  const MAX_VISIBLE_ITEMS = 3;
  const isNotDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const columns = [
    {
      field: 'name',
      headerName: 'Role Name',
      renderCell: (value: 'string') => {
        return (
          <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
            <RoleIcon />
            <Typography ml={2} mr={2} noWrap variant="h4">
              {value}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      renderCell: (value: 'string') => {
        return (
          <Stack
            direction="row"
            sx={{
              width: isNotDesktop ? '250px' : '100%',
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden',
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: Neutrals[500],
                display: '-webkit-box',
                WebkitLineClamp: '3',
                WebkitBoxOrient: 'vertical',
              }}
            >
              {value}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: 'roleType',
      headerName: 'Role Type',
      renderCell: (value: 'string') => {
        return (
          <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
            <Typography noWrap variant="h4">
              {value}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: 'roleScope',
      headerName: 'Role Scope',
      renderCell: (value: 'string') => {
        return (
          <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
            <Typography noWrap variant="h4">
              {value}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: 'modules',
      headerName: 'List of Modules',
      renderCell: (value: any[]) => {
        const modules = value?.slice(0, MAX_VISIBLE_ITEMS);
        const hasMore = value?.length > MAX_VISIBLE_ITEMS;
        return (
          <Stack
            direction="row"
            sx={{ maxWidth: '500px', display: 'flex', alignItems: 'center', overflow: 'hidden' }}
          >
            {modules?.map(module => (
              <Box padding="3px 6px" sx={{ display: 'flex', alignItems: 'center' }}>
                <ModuleIcon color="#999999" />
                <Typography variant="caption2" ml="8px" mr={2} noWrap>
                  {module}
                </Typography>
              </Box>
            ))}
            {hasMore && (
              <Tooltip title={value.slice(MAX_VISIBLE_ITEMS).map(item => item + ', ')}>
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '100%',
                    ml: 1,
                  }}
                >
                  ...
                </Typography>
              </Tooltip>
            )}
          </Stack>
        );
      },
    },
  ];

  return (
    <LayoutComponent factoryResetContainer={true}>
      <TwoRowLayout
        style={{ pl: 4, pr: 4 }}
        child1={
          <Box sx={{ width: '100%' }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Breadcrumbs
                separator={<ArrowForwardIcon fontSize="small" sx={{ color: '#666666' }} />}
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs>
              <Button sx={{ mb: 2 }} onClick={() => navigate('/createRole')} endIcon={<AddIcon />}>
                Create
              </Button>
            </Stack>

            <SearchBox
              backgroundColor={`${Primary[50]}`}
              placeholder="Search for roles"
              sx={{
                width: '100%',
                flexGrow: 1,
                height: '52px',
                borderColor: `${Neutrals[500]}`,
                borderRadius: '4px',
              }}
              onSearch={handleSearch}
              onClear={handleClear}
            />
          </Box>
        }
        child2={
          <Box>
            <Table
              rows={getFilterRoles() || []}
              columns={columns}
              renderOptionsCell={row => (
                <OptionsMenu
                  assets={asset}
                  administrative={administrative}
                  setAssets={setAssets}
                  setAdministrative={setAdministrative}
                  row={row}
                  handleEditRole={editRole}
                  handleDeleteRole={handleDeleteRole}
                />
              )}
            />
          </Box>
        }
      />
    </LayoutComponent>
  );
};

export default RoleListPage;
