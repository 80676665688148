const isValidNameFormat = (name: string) => {
  
  if(name.length > 80)  return false;
  if(name === "") return false;
  
  
  return true;
};

export { isValidNameFormat };
