import { useEffect, useState } from 'react';
//@ts-ignore
import Map from 'mapmyindia-react';
import {
  Box,
  Stack,
  FormControl,
  Select,
  MenuItem,
  Divider,
  Typography,
  Tooltip,
  ListItemText,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { getMapWidgetData, getMapWidgetFiltersList } from '../../services/dashboardPage';
import locationNeutral from '../../assets/icons/locationNeutral.svg';
import './MapWidget.css';
import WidgetAnalyser from '../GeneralisedWidgetComponents/subComponents/WidgetAnalyser';
import AssistantIcon from '@mui/icons-material/Assistant';
import { isFeatureAccessibleByPlantId } from '../../services/plantFeatureAuth';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface markerInterface {
  position: [number, number];
  title: string;
  markerIcon: string;
}

const styles = {
  textStyle: {
    fontSize: '1rem',
    cursor: 'pointer',
  },
  IconStyle: {
    width: '14px',
    height: '14px',
    marginLeft: '12px',
    '& :hover': { cursor: 'pointer' },
  },
};

const getMarkerIconForPlant = (plantHealthStatus: string) => {
  console.log('plantHealthStatus : ', plantHealthStatus);

  if (plantHealthStatus == 'good')
    return 'https://digitalpaani-media.s3.ap-south-1.amazonaws.com/Map/green_location.svg';
  else if (plantHealthStatus == 'neutral')
    return 'https://digitalpaani-media.s3.ap-south-1.amazonaws.com/Map/grey_location.svg';
  else if (plantHealthStatus == 'warning')
    return 'https://digitalpaani-media.s3.ap-south-1.amazonaws.com/Map/yellow_location.svg';
  else if (plantHealthStatus == 'danger')
    return 'https://digitalpaani-media.s3.ap-south-1.amazonaws.com/Map/red_location.svg';
  else return locationNeutral;
};

function generateHtmlTable(headers: any, rows: any, heading: string) {
  let tableHtml = '<div style=" margin-bottom: 20px;">';
  tableHtml += `<h3>${heading}</h3>`;
  tableHtml += '</div>';

  tableHtml += '<table style="width: 250px; border-collapse: collapse;">';

  // Generate table body
  tableHtml += '<tbody>';
  rows.forEach((row: any) => {
    tableHtml += '<tr>';
    headers.forEach((header: any, headerIndex: number) => {
      if (headerIndex == 0)
        tableHtml += `<td style="paddingRight: 8px; paddingTop : 4px; text-align: left; width : 170px; font-weight: 500;">${row[header]}</td>`;
      else
        tableHtml += `<td style="paddingLeft: 8px; paddingTop : 4px; text-align: right;">${row[header]}</td>`;
    });
    tableHtml += '</tr>';
  });
  tableHtml += '</tbody>';

  tableHtml += '</table>';

  return tableHtml;
}

// const rows = [
//   { Metrics: 'Sensor1', Value: 77 },
//   { Metrics: 'Sensor2', Value: 34 },
//   { Metrics: 'Tickets', Value: 13 },
// ];

// const tableHtml = generateHtmlTable(headers, rows);

// markers: [
//   {
//     title: tableHtml,
//     position: [28, 77],
//   },
//   {
//     title: 'Welspun',
//     position: [34, 77],
//   },
// ],

const createMarkers = (data: any) => {
  const headers = ['Metrics', 'Value'];
  let markers = data.map((plantInfo: any, index: number) => {
    let title = generateHtmlTable(headers, plantInfo.tableData, plantInfo?.plantName || 'No Name');
    return {
      title: title,
      position: [plantInfo.lat, plantInfo.long],
      icon: getMarkerIconForPlant(plantInfo.plantStatusForAppliedFilter),
      number: plantInfo.plantFilterScore || 'NA',
      tooltip: plantInfo?.plantName,
      fontColor: '#fff',
      fontWeight: 400,
    };
  });

  return markers;
};

export default function MapWidget(props: any) {
  const { widgetId, timeFrame, refresh, widgetStructure = {} } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [markers, setMarkers] = useState<markerInterface[]>([]);
  const [widgetZoom, setWidgetZoom] = useState<number>(4);
  const [filtersList, setFiltersList] = useState<any[]>([]);
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(1000);
  const [widgetData, setWidgetData] = useState<any>(null);
  const [openAiBox, setOpenAiBox] = useState(false);
  const [aiAnchorEl, setAiAnchorEl] = useState<any>(null);
  const [widgetFeatureAccess, setWidgetFeatureAccess] = useState({
    'AI analysis tool': false,
  });

  useEffect(() => {
    getMapWidgetFiltersList(widgetId).then((data: any) => {
      setFiltersList(data);
      let defaultFilterIndex = data.findIndex((filter: any) => filter.default);
      if (defaultFilterIndex > -1) {
        setSelectedFilterIndex(defaultFilterIndex || 0);
      } else {
        setSelectedFilterIndex(0);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedFilterIndex == 1000) return;

    setLoading(true);
    const newTimeFrame = {
      startDate: new Date(timeFrame?.startDate?.getTime() + 1000 * 60 * 60 * 5.5).toISOString(),
      endDate: new Date(timeFrame?.endDate?.getTime() + 1000 * 60 * 60 * 5.5).toISOString(),
    };

    getMapWidgetData(
      widgetId,
      newTimeFrame,
      filtersList[selectedFilterIndex]?.name || 'Plant Score',
      widgetStructure
    ).then(data => {
      let markersTemp = createMarkers(data.plants);
      setWidgetData(data);
      console.log('markersTemp : ', markersTemp);
      setMarkers(markersTemp);
      setWidgetZoom(data?.widgetLook?.mapZoom || 4);
      setLoading(false);
    });
  }, [widgetId, timeFrame, refresh, selectedFilterIndex]);

  useEffect(() => {
    let tempPlantId = widgetData?.plants?.[0]?.plantId;
    console.log('widgetData 124 : ', widgetData);
    isFeatureAccessibleByPlantId(tempPlantId!, 'AI analysis tool').then(data => {
      setWidgetFeatureAccess({ 'AI analysis tool': data });
    });
  }, [widgetData]);

  console.log(markers);
  return !loading ? (
    <Box sx={{ height: 'inherit', width: 'inherit', overflow: 'hidden' }} borderRadius={4}>
      <Box sx={{ background: '#fff', zIndex: '10000 !important' }}>
        <Stack direction="row" alignItems="center" sx={{ width: '98%' }} px={2} pl={3}>
          <Box>
            {' '}
            <Typography variant="h6" sx={styles.textStyle}>
              {widgetData?.widgetName}
            </Typography>
          </Box>
          {widgetFeatureAccess?.['AI analysis tool'] && (
            <Tooltip title="Ai Assistant">
              <AssistantIcon
                sx={{
                  ...styles.IconStyle,
                  color: openAiBox ? '#038CB2' : '#8a8a8a',
                }}
                color="action"
                onClick={event => {
                  setAiAnchorEl(event.currentTarget);
                  setOpenAiBox(true);
                }}
              />
            </Tooltip>
          )}
          <Divider orientation="vertical" variant="middle" flexItem sx={{ marginLeft: 'auto' }} />
          <FormControl size="small" sx={{ width: '200px' }}>
            {/* <InputLabel>Select a Filter</InputLabel> */}
            <Select
              value={selectedFilterIndex}
              onChange={(e: any) => {
                setSelectedFilterIndex(e.target.value);
              }}
              // label="Select a Filter"
              renderValue={selected => filtersList[selected]?.name}
              sx={{
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 0,
                },
                background: '#fefefe',
                color: '#777',
              }}
              inputProps={{
                MenuProps: {
                  PaperProps: {
                    sx: {
                      backgroundColor: '#fefefe',
                      color: '#777',
                    },
                  },
                },
              }}
            >
              {filtersList?.map((filter: any, index: number) => (
                <MenuItem key={index} value={index}>
                  <ListItemText primary={filter.name} />
                  <Tooltip title={filter.description || ''}>
                    <InfoOutlinedIcon sx={{ fontSize: '18px' }} />
                  </Tooltip>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        {filtersList?.[selectedFilterIndex] && (
          <WidgetAnalyser
            open={openAiBox}
            handleClose={() => {
              console.log('closing');
              setAiAnchorEl(null);
              setOpenAiBox(false);
            }}
            anchorEl={aiAnchorEl}
            widgetData={{ ...widgetData, selectedFilter: filtersList[selectedFilterIndex] }}
            widgetTimeFrame={timeFrame}
          />
        )}
      </Box>
      <Map
        zoom={widgetZoom}
        hybrid={false}
        search={false}
        height={'calc(100% - 37px)'}
        markers={markers}
        id="map"
      />
    </Box>
  ) : (
    <Box sx={{ height: 'inherit', width: 'inherit', overflow: 'hidden' }} borderRadius={4}>
      <Skeleton variant="rectangular" width="inherit" height="inherit" />
    </Box>
  );
}
