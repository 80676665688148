import { useState, useEffect } from 'react';
// import theme from "../../styles/theme/dpTheme";
import Theme from '../../core-components/theme/theme';

interface IProp {
  breakpointValue?: 'md' | 'sm';
}

export default function useIsMobile({ breakpointValue }: IProp = {}) {
  // console.log("breakpointValue", breakpointValue);
  const defaultBreakpointValue = breakpointValue || 'md';
  const [isMobile, setIsMobile] = useState(() => {
    return window.innerWidth <= Theme.breakpoints.values[defaultBreakpointValue];
  });

  useEffect(() => {
    function handleResize() {
      // console.log("defaultBreakpointValue", defaultBreakpointValue);
      setIsMobile(window.innerWidth <= Theme.breakpoints.values[defaultBreakpointValue]);
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
}
