import { useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

// Register the zoom plugin globally
ChartJS.register(zoomPlugin);

const LineChartFlocDetector = ({
  imageCache,
  flocData,
  currentShiftIndex,
  currentSampleIndex,
  handleClickOpen,
  visibleIcons,
}: any) => {
  let graphData: any = Object.values(
    flocData[currentShiftIndex].samples[currentSampleIndex]?.metrics?.metricData
  );

  // console.log("this is the graph data ::" , graphData)
  let minuteValues = Object.keys(
    flocData[currentShiftIndex].samples[currentSampleIndex]?.metrics?.metricData
  ).map(key => parseInt(key));

  const preloadImages = (urls: string[]) => {
    urls.forEach(url => {
      if (!imageCache[url]) {
        const img = new Image();
        img.src = url;
        imageCache[url] = img;
      }
    });
  };

  const data = {
    labels: minuteValues,
    datasets: [
      {
        label: 'thumbnail',
        data: graphData.map((item: { value: number }) => item.value),
        borderColor: 'transparent',
        backgroundColor: 'rgba(202, 107, 2, 0.2)',
        tension: 0.4,
        fill: 'start',
        pointRadius: graphData.map((item: any) => (item.thumbnail ? 3.5 : 0)),
        pointBackgroundColor: graphData.map((item: any) =>
          item.thumbnail ? '#653501' : 'rgba(0,0,0,0)'
        ),
        pointBorderColor: graphData.map((item: any) =>
          item.thumbnail ? '#CA6B02' : 'rgba(0,0,0,0)'
        ),
        pointHoverRadius: graphData.map((item: any) => (item.thumbnail ? 3.5 : 0)),
        pointBorderWidth: 0,
      },
      {
        label: 'falseDetection',
        data: graphData.map((item: { value: number }) => item.value),
        borderColor:
          visibleIcons.falseDetection === false &&
          visibleIcons.issueIcon === true &&
          visibleIcons.noSludge === true
            ? '#CA6B02'
            : 'transparent',
        backgroundColor: 'transparent',
        tension: 0.4,
        fill: 'start',
        pointRadius: graphData.map((item: any) => (item.falseDetection ? 3.5 : 0)),
        pointBackgroundColor: graphData.map((item: any) =>
          item.falseDetection ? '#653501' : 'rgba(0,0,0,0)'
        ),
        pointBorderColor: graphData.map((item: any) =>
          item.falseDetection ? '#CA6B02' : 'rgba(0,0,0,0)'
        ),
        pointHoverRadius: graphData.map((item: any) => (item.falseDetection ? 3.5 : 0)),
        pointBorderWidth: 0,
        hidden: visibleIcons.falseDetection,
      },
      {
        label: 'noSludge',
        data: graphData.map((item: { value: number }) => item.value),
        borderColor:
          visibleIcons.falseDetection === true &&
          visibleIcons.issueIcon === true &&
          visibleIcons.noSludge === false
            ? '#CA6B02'
            : 'transparent',
        backgroundColor: 'transparent',
        tension: 0.4,
        fill: 'start',
        pointRadius: graphData.map((item: any) => (item.noSludge ? 3.5 : 0)),
        pointBackgroundColor: graphData.map((item: any) =>
          item.noSludge ? '#653501' : 'rgba(0,0,0,0)'
        ),
        pointBorderColor: graphData.map((item: any) =>
          item.noSludge ? '#CA6B02' : 'rgba(0,0,0,0)'
        ),
        pointHoverRadius: graphData.map((item: any) => (item.noSludge ? 3.5 : 0)),
        pointBorderWidth: 0,
        hidden: visibleIcons.noSludge,
      },
      {
        label: 'issueIcon',
        data: graphData.map((item: { value: number }) => item.value),
        borderColor:
          (visibleIcons.falseDetection === true &&
            visibleIcons.issueIcon === false &&
            visibleIcons.noSludge === true) ||
          (visibleIcons.falseDetection === true &&
            visibleIcons.issueIcon === false &&
            visibleIcons.noSludge === false) ||
          (visibleIcons.falseDetection === false &&
            visibleIcons.issueIcon === true &&
            visibleIcons.noSludge === false) ||
          (visibleIcons.falseDetection === false &&
            visibleIcons.issueIcon === false &&
            visibleIcons.noSludge === true) ||
          (visibleIcons.falseDetection === false &&
            visibleIcons.issueIcon === false &&
            visibleIcons.noSludge === false)
            ? '#CA6B02'
            : 'transparent',
        backgroundColor: 'transparent',
        tension: 0.4,
        fill: 'start',
        pointRadius: graphData.map((item: any) => (item.issueIcon ? 3.5 : 0)),
        pointBackgroundColor: graphData.map((item: any) =>
          item.issueIcon ? '#653501' : 'rgba(0,0,0,0)'
        ),
        pointBorderColor: graphData.map((item: any) =>
          item.issueIcon ? '#CA6B02' : 'rgba(0,0,0,0)'
        ),
        pointHoverRadius: graphData.map((item: any) => (item.issueIcon ? 3.5 : 0)),
        pointBorderWidth: 0,
        hidden: visibleIcons.issueIcon,
      },
    ],
  };

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const xValue = context.parsed.x;
            const yValue = context.parsed.y;
            const timeUnit =
              flocData[currentShiftIndex].samples[currentSampleIndex]?.metrics?.timeUnit;
            const sampleUnit =
              flocData[currentShiftIndex].samples[currentSampleIndex]?.metrics?.sampleUnit;
            return `${xValue} ${timeUnit}, ${yValue} ${sampleUnit}`;
          },
        },
      },
      zoom: {
        pan: {
          enabled: true, // Enable panning
          mode: 'xy',
        },
        zoom: {
          wheel: {
            enabled: true, // Enable zooming with the mouse wheel
          },
          pinch: {
            enabled: true, // Enable pinch gestures
          },
          mode: 'xy', // Enable zooming in both directions
        },
      },
    },
    scales: {
      x: {
        min: minuteValues[0],
        max: minuteValues[minuteValues.length - 1],
        ticks: {
          stepSize: 30,
          padding: 10,
          callback: function (value: any) {
            if (value % 30 === 0) {
              return (
                value +
                ` ${flocData[currentShiftIndex].samples[currentSampleIndex]?.metrics?.timeUnit}`
              );
            }
            return null;
          },
        },
        grid: {
          display: true, // Hide vertical grid lines
          drawOnChartArea: true, // Ensure grid lines are not drawn on the chart area
          drawTicks: true, // Ensure ticks are not drawn
        },
      },
      y: {
        min: graphData[graphData.length - 1]?.value,
        max: graphData[0]?.value,
        ticks: {
          stepSize: 100,
          // callback: function (value: any, index: number, values: any[]) {
          //   const min = values[0].value;
          //   const max = values[values.length - 1].value;
          //   const mid = (min + max) / 2;
          //   if (value === min || value === max || value === mid) {
          //     return `${value} ${flocData[currentShiftIndex].samples[currentSampleIndex]?.metrics?.sampleUnit}`;
          //   } else {
          //     return `${value}`;
          //   }
          // },
          // color: function (value: any, index: number, values: any[]) {
          //   const min = graphData[graphData.length - 1]?.value;
          //   const max = graphData[0]?.value;
          //   const mid = (min + max) / 2;

          //   return value.tick.value === min || value.tick.value === max || value.tick.value === mid
          //     ? '#989DA6'
          //     : '#989DA64C';
          // },
        },
      },
    },
  };

  const drawImagesPlugin = {
    id: 'drawImagesPlugin',
    beforeDatasetsDraw: (chart: any) => {
      const ctx = chart.ctx;
      const canvas = chart.canvas;

      const images: any[] = [];

      canvas.onclick = (evt: any) => {
        const res = images.map(({ img, x, y, imageId, thumbnail }) => {
          const width = thumbnail ? 30 : 12;
          const height = 30;
          const { offsetX, offsetY } = evt;
          if (
            offsetX >= x - width / 2 &&
            offsetX <= x + width / 2 &&
            offsetY >= y - height - 10 &&
            offsetY <= y - 10
          ) {
            return imageId;
          }
          return null;
        });
        for (let id of res) {
          if (id !== null) {
            handleClickOpen(id);
          }
        }
      };

      canvas.onmousemove = (evt: any) => {
        let hovering = false;
        images.forEach(({ x, y, thumbnail }) => {
          const width = thumbnail ? 30 : 12;
          const height = 30;
          const { offsetX, offsetY } = evt;
          if (
            offsetX >= x - width / 2 &&
            offsetX <= x + width / 2 &&
            offsetY >= y - height - 10 &&
            offsetY <= y - 10
          ) {
            hovering = true;
          }
        });
        canvas.style.cursor = hovering ? 'pointer' : 'default';
      };

      chart.data.labels.forEach((label: any, dataIndex: any) => {
        const datasets = chart.data.datasets;
        datasets.forEach((dataset: any, datasetIndex: number) => {
          const value = dataset?.data[dataIndex];
          const imgUrl = graphData?.[dataIndex]?.[dataset.label] ?? null; // or any fallback value
          // console.log("this is the dataindex and dataIndex.label" , dataIndex , " and " , dataset.label ,)
          // console.log("this is the :" , imgUrl , dataset.label)
          const imageId = graphData?.[dataIndex]?.imageId ?? null; // or any fallback value
          if (imgUrl && value && !dataset.hidden) {
            // console.log("this htting here")
            // console.log("this is the imageurl :" , imgUrl)
            const point = chart.getDatasetMeta(datasetIndex).data[dataIndex].getProps(['x', 'y']);
            // console.log("this is the imgUrl :" , imgUrl)
            // console.log("this is the imageCache[imgUrl] :" , imageCache[imgUrl])

            // const img = imageCache[imgUrl];
            // console.log("finding the image here ::" , img)
            const width = graphData[dataIndex].thumbnail ? 30 : 12;
            const height = 30;
            let img: any;

            if (imageCache[imgUrl]) {
              img = imageCache[imgUrl];
              if (img.complete) {
                ctx.drawImage(img, point.x - width / 2, point.y - height - 10, width, height);
              } else {
                img.onload = () => {
                  ctx.drawImage(img, point.x - width / 2, point.y - height - 10, width, height);
                };
              }
            } else {
              img = new Image();
              img.src = imgUrl;
              img.onload = () => {
                imageCache[imgUrl] = img; // Cache the image for future use
                ctx.drawImage(img, point.x - width / 2, point.y - height - 10, width, height);
              };
            }

            images.push({
              img,
              x: point.x,
              y: point.y,
              imageId: imageId,
              thumbnail: graphData[dataIndex].thumbnail ? true : false,
            });
          }
        });
      });
    },
  };

  useEffect(() => {
    preloadImages(graphData.map((data: any) => data.thumbnail));
    preloadImages(graphData.map((data: any) => data.issueIcon));
    preloadImages(graphData.map((data: any) => data.falseDetection));
    preloadImages(graphData.map((data: any) => data.noSludge));
  }, [imageCache]);

  console.log('Chart data: ', data);
  console.dir(options);

  return <Line data={data} options={options} height="300px" plugins={[drawImagesPlugin]} />;
};

export default LineChartFlocDetector;
