import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  Typography,
} from '@mui/material';
import { RANGE_OBJ } from '../constants/range.constant';

interface AddFilterPopupProps {
  open: boolean;
  onClose: () => void;
  onAdd: (item: any) => void;
  mapFilters: any[];
}

const AddFilterPopup: React.FC<AddFilterPopupProps> = ({ open, onClose, onAdd, mapFilters }) => {
  const [newMenuItem, setNewMenuItem] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [filterDescription, setFilterDescription] = useState<string>('');
  const [errorMessageForDescription, setErrorMessageForDescription] = useState<string>('');

  useEffect(() => {
    if (!open) {
      setNewMenuItem('');
      setErrorMessage('');
    }
  }, [open]);

  const handleAddClick = () => {
    if (newMenuItem.trim() !== '') {
      let existingFilter = mapFilters.find(filter => filter.name === newMenuItem);

      if (existingFilter) {
        setErrorMessage('A filter with this name already exists.');
      } else if (filterDescription.length < 1) {
        setErrorMessageForDescription('Filter description can not be empty!');
      } else {
        onAdd({
          name: newMenuItem.trim(),
          description: filterDescription,
          useFilterRangeAsDefault: true,
          filterType: 'rangeType',
          range: RANGE_OBJ,
        });
        setNewMenuItem('');
        setErrorMessage('');
        onClose();
      }
    } else {
      setErrorMessage('Filter name cannot be empty.');
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add New Map Filter</DialogTitle>
      <DialogContent>
        <DialogContentText>Enter the name of the new filter you want to add.</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Filter Name"
          type="text"
          fullWidth
          value={newMenuItem}
          onChange={e => {
            setNewMenuItem(e.target.value);
            setErrorMessage('');
          }}
        />
        {errorMessage && (
          <Typography color="error" variant="body2">
            {errorMessage}
          </Typography>
        )}
        <TextField
          autoFocus
          margin="dense"
          label="Filter Description"
          type="text"
          fullWidth
          value={filterDescription}
          onChange={e => {
            setFilterDescription(e.target.value);
            setErrorMessage('');
          }}
        />
        {errorMessageForDescription && (
          <Typography color="error" variant="body2">
            {errorMessageForDescription}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAddClick} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddFilterPopup;
