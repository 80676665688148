//@ts-nocheck
import React from 'react';
import { useState, useRef } from 'react';
import {
  Box,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Chip,
  IconButton,
  Typography,
  Divider,
  Menu,
  Fade,
  Button,
  Tooltip,
} from '@mui/material';
import { useEffect } from 'react';
import axiosInstance from '../../constants/axiosInstance';

import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import FormLabel from '@mui/material/FormLabel';
import Autocomplete from '@mui/material/Autocomplete';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { toast } from 'react-toastify';
import TableRowsIcon from '@mui/icons-material/TableRows';
import PinIcon from '@mui/icons-material/Pin';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import MetricConfigComponent from '../metricConfigComponent/metricConfigComponent';
import MetricConfigComponentV2 from './metricConfigComponentv2';
import { pageConfigDataInterface } from '../../Interfaces/dashboardPageInterfaces';
import { widgetInterface } from '../../Interfaces/widgetInterfaces';
import {
  lastMovedWidgetInterface,
  metricDiagnosisDataInterface,
} from '../../Interfaces/pageConfigPageInterfaces';
import GranularitySettings from './granularitySetting';
import TimeRangeSettings from './timeRangeSetting';
import MetricSearchBar from './metricSearchBar';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';
import DeleteConfirmation from '../../globalComponents/dialogs/DeleteConfirmation';
import { IInflatedDashboadPage } from '../../Interfaces/newDashboardInterfaces/inflatedDashbaordPage.interfaces';
import SellIcon from '@mui/icons-material/Sell';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import StandaloneGeneralisedWidget from '../GeneralisedWidgetComponents/StandaloneGeneralisedWidget';
import { useDashboardStore } from '../../store/DashboardStore';
import { usePlantLayoutStore } from '../../store/PlantLayoutStore';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import lodash from 'lodash';
import ArrowCircleDownRoundedIcon from '@mui/icons-material/ArrowCircleDownRounded';
import { ArrowCircleUpRounded } from '@mui/icons-material';
import { ReactComponent as ArrowUpIcon } from '../../constants/Images/ArrowUpIcon.svg';
import { ReactComponent as ArrowDownIcon } from '../../constants/Images/ArrowDownIcon.svg';
import useIsMobile from '../../utilities/customHooks/useIsMobile';
import useIsDesktop from '../../utilities/customHooks/useIsDesktop';
import useOutsideClickDetector from '../../utilities/customHooks/useOutsideClickDetector';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Calculate24HrCycle from '../../globalComponents/dateAndTime/Calculate24HrCycle';
import ConvertTimeInISOFormat from '../../globalComponents/dateAndTime/ConvertTimeInISOFormat';
import convertISOToLocalTime from '../../globalComponents/dateAndTime/ConvertISOIntoLocalTime';
import StaticDateTimePickerComponent from '../../globalComponents/dateAndTime/StaticDateTimePickerComponent';
import ShiftDetailsComponent from './shiftDetailsComponent';
import useIsTablet from '../../utilities/customHooks/useIsTablet';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ValidRangeSettings from './ValidRangeSettings';

const styles = {
  infoIcon: {
    fontSize: 18,
    color: '#999',
    marginLeft: '10px',
    marginTop: '2px',
  },
  widgetConfigComponentAccordion: {
    margin: '10px',
    maxHeight: '544px',
    overflowY: 'scroll',
    background: '#F0F0F0',
    borderRadius: '10px',
    border: '1px solid #BDBDBD',
    '&::-webkit-scrollbar': {
      width: '0', // You can adjust this value to control the width of the scrollbar
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent', // Hide the scrollbar thumb
    },
  },
  widgetConfigSummaryContainer: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
  },
  widgetConfigSummaryActionButtonContainer: {
    display: 'flex',
    marginLeft: 'auto',
    color: '#444',
    alignItems: 'center',
  },
  textBoxStyle: {
    background: 'white',
    '& fieldset': { border: 'none' },
  },

  metricDiagnosisLoading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#eee',
    width: '400px',
    minHeight: '100px',
    borderRadius: '5px',
  },
  metricDiagnosisNotOk: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#FFCCCB',
    width: '400px',
    minHeight: '100px',
    borderRadius: '5px',
  },
  metricDiagnosisOk: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#addfad',
    width: '400px',
    minHeight: '100px',
    borderRadius: '5px',
  },
  expandIconStyle: {
    width: '30px',
    height: '30px',
    color: '#333333',
  },
  addedSensorsStyle: {
    background: '#ddd',
    width: '100%',
    boxSizing: 'border-box',
  },
  standaloneGeneralizedWidget: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '280px',
    background: 'white',
  },
  dropdownMenuStyle: {
    boxShadow: 'none',
  },
  sensorItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  sensorListContainer: {
    height: '200px',
    overflow: 'scroll',
    boxSizing: 'border-box',
    background: 'white',
    width: '100%',
    '&::-webkit-scrollbar': {
      width: '0', // You can adjust this value to control the width of the scrollbar
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent', // Hide the scrollbar thumb
    },
  },
  searchTextField: {
    '& fieldset': { outline: 'none' },
  },
};

interface SensorTagsInterface {
  [tag: string]: {
    checked: boolean;
    dataType: string;
    sensorId: string;
  };
}

interface ChildSensor {
  dateCreated: string;
  isEnabled: boolean;
  parentSensor: string;
  plantId: string;
  sensorName: string;
  sensorNickName: string;
  sensorTag: string;
  _id: string;
}

interface flocDetectorSensorsDataInterface {
  childSensors: ChildSensor[];
  dateCreated: string;
  isEnabled: boolean;
  plantId: string;
  sensorName: string;
  sensorNickName: string;
  sensorTag: string;
  _id: string;
}

interface propsInterface {
  pageData: IInflatedDashboadPage['pageConfig'];
  setPageData: React.Dispatch<React.SetStateAction<IInflatedDashboadPage['pageConfig'] | null>>;
  widgetCluster: string;
  widgetClusterIndex: number;
  widget: widgetInterface;
  widgetIndex: number;
  moveWidgetPosition: (widgetClusterIndec: number, widgetIndex: number, action: string) => void;
  lastMovedWidget: lastMovedWidgetInterface;
  sensorList: any[];
  refresh: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  plantId: string;
  setDeletedWidgets: Function;
  flocDetectorSensorsData: flocDetectorSensorsDataInterface;
  countFlocDetectors: any;
  setIs24hrShiftCompleted: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFlocDetectorShiftAdded: React.Dispatch<React.SetStateAction<boolean>>;
}

interface flocDetectorShiftsInterface {
  serialNumber: number;
  shiftName: string;
  startTime: string;
  endTime: string;
}

const endDate = new Date();
// const midnight = new Date();
// midnight.setHours(0, 0, 0, 0);
const startDate = new Date(endDate.valueOf() - 24 * 60 * 60 * 1000);
export default function WidgetConfigComponentV2(props: propsInterface) {
  const {
    pageData,
    setPageData,
    widgetCluster,
    widgetClusterIndex,
    widget,
    widgetIndex,
    moveWidgetPosition,
    lastMovedWidget,
    refresh,
    setRefresh,
    sensorList,
    plantId,
    setDeletedWidgets,
    flocDetectorSensorsData,
    countFlocDetectors,
    setIs24hrShiftCompleted,
    setIsFlocDetectorShiftAdded,
  } = props;
  const valueTypes = ['raw', 'avg', 'min', 'max', 'timeWeightedSum', 'cumulative'];
  const [search, setSearch] = useState('');
  const [openDiagnosisBox, setOpenDiagnosisBox] = useState(false);
  const [diagnosing, setDiagnosing] = useState(false);
  const [areMultipleSensorSelected, setAreMultipleSensorSelected] = useState(
    widget?.metrics && Object.keys(widget?.metrics).length > 1 ? true : false
  );
  const [metricDiagnosisData, setMetricDiagnosisData] =
    useState<metricDiagnosisDataInterface | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [refreshStandAloneWidget, setRefreshStandAloneWidget] = useState(false);
  const [flocDetectorChecked, setFlocDetectorChecked] = useState(false);
  const nickNameRef = useRef(null);
  const isTablet = useIsTablet();

  // useEffect(() => {
  //   console.log("widget name ", widget.widgetName);
  // }, []);

  const changeWidgetProperty = (
    widgetClusterIndex: number,
    widgetIndex: number,
    property: string,
    value: any
  ) => {
    const temp: IInflatedDashboadPage['pageConfig'] = pageData;
    temp.data[widgetClusterIndex].widgets[widgetIndex][property] = value;
    setPageData(temp);
    setRefresh(!refresh);
    setRefreshStandAloneWidget(!refreshStandAloneWidget);
  };

  const changeAdvancedWidgetProperty = (
    widgetClusterIndex: number,
    widgetIndex: number,
    property: string,
    value: any
  ) => {
    const temp: IInflatedDashboadPage['pageConfig'] = pageData;
    temp.data[widgetClusterIndex].widgets[widgetIndex]['advancedWidgetSettings'][property] = value;
    setPageData(temp);
    setRefresh(!refresh);
    setRefreshStandAloneWidget(lastState => !lastState);
  };

  const changeWidgetMinAllowedGranularitySettings = (
    widgetClusterIndex: string,
    widgetIndex: number,
    property: string,
    value: any
  ) => {
    const temp: IInflatedDashboadPage['pageConfig'] = pageData;
    temp.data[widgetClusterIndex].widgets[widgetIndex]['factoryGranularitySettings'][
      'minAllowedGranularity'
    ][property] = value;
    setPageData(temp);
    setRefresh(!refresh);
    setRefreshStandAloneWidget(!refreshStandAloneWidget);
  };

  const changeWidgetCustomGranularitySettings = (
    widgetClusterIndex: string,
    widgetIndex: number,
    property: string,
    value: any
  ) => {
    const temp: IInflatedDashboadPage['pageConfig'] = pageData;
    temp.data[widgetClusterIndex].widgets[widgetIndex]['customGranularitySettings'][property] =
      value;
    setPageData(temp);
    setRefresh(!refresh);
    setRefreshStandAloneWidget(!refreshStandAloneWidget);
  };

  const changeWidgetMinAllowedTimeRangeSettings = (
    widgetClusterIndex: string,
    widgetIndex: number,
    property: string,
    value: any
  ) => {
    const temp: IInflatedDashboadPage['pageConfig'] = pageData;
    temp.data[widgetClusterIndex].widgets[widgetIndex]['factoryTimeRangeSettings'][
      'minAllowedTimeRange'
    ][property] = value;
    setPageData(temp);
    setRefresh(!refresh);
    setRefreshStandAloneWidget(!refreshStandAloneWidget);
  };

  const changeWidgetCustomTimeRangeSettings = (
    widgetClusterIndex: string,
    widgetIndex: number,
    property: string,
    value: any
  ) => {
    const temp: IInflatedDashboadPage['pageConfig'] = pageData;
    temp.data[widgetClusterIndex].widgets[widgetIndex]['customTimeRangeSettings'][property] = value;
    setPageData(temp);
    setRefresh(!refresh);
    setRefreshStandAloneWidget(!refreshStandAloneWidget);
  };

  const deleteCustomWidget = (widgetClusterIndex: string, widgetIndex: number) => {
    const widgetToBeDeleted = pageData.data[widgetClusterIndex].widgets[widgetIndex];
    let widgetName = widgetToBeDeleted.widgetName;
    if (widgetName.includes('Floc Detector')) {
      countFlocDetectors(pageData.data[widgetClusterIndex].widgets);
    }
    pageData.data[widgetClusterIndex].widgets.splice(widgetIndex, 1);

    const summaryDeletedWidget = {
      id: widgetToBeDeleted._id,
      widgetType: widgetToBeDeleted.widgetType,
    };

    setDeletedWidgets(previousDeletedWidgets => {
      return [...previousDeletedWidgets, summaryDeletedWidget];
    });
    setRefresh(!refresh);
    toast(`${widgetName} deleted Successfully!`);
  };

  const getLastFlocDetectorData = (dataArray: any, targetWidget: any) => {
    for (let i = dataArray.length - 1; i >= 0; i--) {
      if (dataArray[i].widgetOrigin === targetWidget) {
        return dataArray[i];
      }
    }
    return null;
  };

  const initiateMetricDiagnosis = (metricId: string) => {
    setOpenDiagnosisBox(true);
    setDiagnosing(true);
    axiosInstance()
      .get(`/dashboard/getMetricDiagnosis?sensorId=${metricId}`)
      .then(({ data }) => {
        console.log('metric diagnostic data : ', data);
        setMetricDiagnosisData(data.data);
        setDiagnosing(false);
      })
      .catch(err => {});
  };

  const isNameIsProperlyFormatted = (name: string) => {
    if (name === '') return false;
    else return true;
  };

  const cloneWidget = (widget: any, widgetClusterIndex: number) => {
    let cloneWidget = lodash.cloneDeep(widget);
    cloneWidget['cloneOf'] = cloneWidget._id;
    cloneWidget['widgetOrigin'] = 'custom';
    pageData.data[widgetClusterIndex].widgets.push(cloneWidget);
    setRefresh(!refresh);
    toast(`${widget.widgetName} cloned Successfully!`);
  };

  const isMobile = useIsMobile({ breakpointValue: 'sm' });
  const isDesktop = useIsDesktop();

  const [expandIconRotation, setExpandIconRotation] = useState<number>(0);

  const handleExpandIconClick = e => {
    setExpandIconRotation(prevRotation => (prevRotation + 180) % 360);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCheckboxChange = (
    widgetClusterIndex: string,
    widgetIndex: number,
    metric: string,
    checked: any
  ) => {
    let temp: pageConfigDataInterface = pageData;
    temp.data[widgetClusterIndex].widgets[widgetIndex].metrics[metric].checked = checked;
    setPageData(temp);
    setRefresh(!refresh);
    setRefreshStandAloneWidget(lastState => !lastState);
  };

  const [previousWidgetMetricsData, setPreviousWidgetMetricsData] = useState<SensorTagsInterface>(
    widget.metrics
  );
  const [previousSpecialTag, setPreviousSpecialTag] = useState(widget.specialTag);
  const [widgetMetricsData, setWidgetMetricsData] = useState<SensorTagsInterface>(null);
  const [searchQuery, setSearchQuery] = useState(String(widget.specialTag));

  const handleClose = (flocDetectorSensors: flocDetectorSensorsDataInterface) => {
    if (flocDetectorSensors && flocDetectorSensors.childSensors) {
      widget.specialTag = flocDetectorSensors.sensorNickName
        ? flocDetectorSensors.sensorNickName
        : flocDetectorSensors.sensorTag;
      setSearchQuery(
        flocDetectorSensors.sensorNickName
          ? flocDetectorSensors.sensorNickName
          : flocDetectorSensors.sensorTag
      );
      if (previousSpecialTag === widget.specialTag) {
        widget.metrics = previousWidgetMetricsData;
      } else {
        const transformedData = {};
        for (const sensor of flocDetectorSensors.childSensors) {
          const metricKey = sensor.sensorName;
          transformedData[metricKey] = {
            checked: true,
            sensorId: sensor._id,
            dataType: 'number',
          };
        }
        widget.metrics = transformedData;
      }
      setWidgetMetricsData(widget.metrics);
      setAnchorEl(null);
      setShowSensorList(false);
    }
  };

  const [showSensorlist, setShowSensorList] = useState<Boolean>(false);
  const metricSearchBarResultsDivRef = useRef(null);
  useOutsideClickDetector(metricSearchBarResultsDivRef, () => {
    setShowSensorList(false);
  });

  const [flocDetectorShiftsData, setFlocDetectorShiftsData] = useState<any>([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [isStartTimeFocused, setIsStartTimeFocused] = useState(false);
  const [isEndTimeFocused, setIsEndTimeFocused] = useState(false);
  const [flocDetectorShifts, setFlocDetectorShifts] = useState<flocDetectorShiftsInterface>({
    serialNumber: '1',
    shiftName: '',
    startTime: '',
    endTime: '',
  });

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [errorStartTime, setErrorStartTime] = useState('');
  const [error24hrs, setError24hrs] = useState('');
  const [newShiftStartTimeError, setNewShiftStartTimeError] = useState(false);
  const [fillAllFieldsError, setFillAllFieldsError] = useState(true);
  const [editSerialNumber, setEditSerialNumber] = useState(null);
  const [previousEndTime, setPreviousEndTime] = useState('');
  const [previousStartTime, setPreviousStartTime] = useState('');
  const [totalShift24hrs, setTotalShift24hrs] = useState(false);

  const handleChangeFlocDetectorShifts = e => {
    const name = e.target.name;
    const value = e.target.value;
    setFlocDetectorShifts({ ...flocDetectorShifts, [name]: value });
  };
  let previousEnd = null;
  const handleTimePickerChange = newTime => {
    const formattedTime = newTime.format('hh:mm A');
    setSelectedTime(newTime);

    if (flocDetectorShiftsData.length > 0) {
      if (editSerialNumber && editSerialNumber > 1) {
        previousEnd = flocDetectorShiftsData[editSerialNumber - 2].endTime;
      } else {
        previousEnd = flocDetectorShiftsData[flocDetectorShiftsData.length - 1].endTime;
      }
    }

    if (flocDetectorShiftsData.length > 0 && isStartTimeFocused && previousEnd !== formattedTime) {
      setNewShiftStartTimeError(true);
      setErrorStartTime('Starting time of 1st shift and end of last shift should be same');
    } else if (
      flocDetectorShiftsData.length > 0 &&
      isStartTimeFocused &&
      previousEnd === formattedTime
    ) {
      setErrorStartTime('');
      setNewShiftStartTimeError(false);
    }

    setFlocDetectorShifts(prevShifts => ({
      ...prevShifts,
      ...(isStartTimeFocused && { startTime: formattedTime }),
      ...(isEndTimeFocused && { endTime: formattedTime }),
    }));
  };

  const addFlocDetectorShifts = (widgetClusterIndex: string, widgetIndex: number) => {
    setIsFlocDetectorShiftAdded(true);
    if (editSerialNumber != null) {
      const updatedList = flocDetectorShiftsData.map(flocDetectorShiftData => {
        if (flocDetectorShiftData.serialNumber === editSerialNumber) {
          return {
            ...flocDetectorShiftData,
            shiftName: flocDetectorShifts.shiftName,
            startTime: flocDetectorShifts.startTime,
            endTime: flocDetectorShifts.endTime,
          };
        }
        return flocDetectorShiftData;
      });
      if (Calculate24HrCycle(updatedList) > 86400) {
        setTotalShift24hrs(false);
        setIs24hrShiftCompleted(false);
        setError24hrs('Please complete 24 hour shift');
        return;
      }

      pageData.data[widgetClusterIndex].widgets[widgetIndex].shiftDetails = updatedList;

      setFlocDetectorShiftsData(updatedList);
      setEditSerialNumber(null);
    } else {
      if (Calculate24HrCycle([...flocDetectorShiftsData, flocDetectorShifts]) > 86400) {
        setTotalShift24hrs(false);
        setIs24hrShiftCompleted(false);
        setError24hrs('Please complete 24 hour shift');
        return;
      }
      pageData.data[widgetClusterIndex].widgets[widgetIndex].shiftDetails = [
        ...flocDetectorShiftsData,
        flocDetectorShifts,
      ];
      setFlocDetectorShiftsData([...flocDetectorShiftsData, flocDetectorShifts]);
    }
    setPreviousEndTime(flocDetectorShifts.endTime);
    setPreviousStartTime(flocDetectorShifts.startTime);
    setFlocDetectorShifts(prevShifts => ({
      shiftName: '',
      startTime: '',
      endTime: '',
      serialNumber: String(
        Math.max(Number(prevShifts.serialNumber), flocDetectorShiftsData.length) + 1
      ),
    }));
  };

  useEffect(() => {
    if (flocDetectorShiftsData.length > 0 && Calculate24HrCycle(flocDetectorShiftsData) !== 86400) {
      setTotalShift24hrs(false);
      setIs24hrShiftCompleted(false);
      setError24hrs('Please complete 24 hour shift');
    } else if (Calculate24HrCycle(flocDetectorShiftsData) === 86400) {
      widget.shiftDetails = ConvertTimeInISOFormat(flocDetectorShiftsData);
      setTotalShift24hrs(true);
      setIs24hrShiftCompleted(true);
    }
  }, [flocDetectorShiftsData]);

  useEffect(() => {
    const { serialNumber, shiftName, startTime, endTime } = flocDetectorShifts;
    if (serialNumber && shiftName && startTime && endTime) {
      setFillAllFieldsError(false);
    } else {
      setFillAllFieldsError(true);
    }
  }, [flocDetectorShifts]);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleEditItem = serialNumber => {
    setEditSerialNumber(serialNumber);
    const findFlocDetectorShift = flocDetectorShiftsData.find(shiftsData => {
      return shiftsData.serialNumber === serialNumber;
    });
    setFlocDetectorShifts(findFlocDetectorShift);
  };

  const handleSearchChange = (e: any) => {
    setSearchQuery(e.target.value);
  };

  // Filter the sensors based on the search query
  const filteredsensors = flocDetectorSensorsData?.filter(
    (sensor: any) =>
      sensor.sensorTag.toLowerCase().includes(searchQuery.toLowerCase()) ||
      sensor.sensorNickName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    if (widget.shiftDetails) {
      const updatedValue = widget.shiftDetails.map(shift => {
        return {
          ...shift,
          startTime: convertISOToLocalTime(shift.startTime),
          endTime: convertISOToLocalTime(shift.endTime),
        };
      });
      setFlocDetectorShiftsData(updatedValue);
    }
  }, []);

  return (
    <>
      <Accordion
        sx={styles.widgetConfigComponentAccordion}
        elevation={0}
        TransitionProps={{ mountOnEnter: true, timeout: 500 }}
        disableGutters={true}
      >
        <AccordionSummary
          expandIcon={
            !isMobile ? (
              <IconButton>
                <ExpandMoreIcon sx={styles.expandIconStyle} />
              </IconButton>
            ) : null
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Stack
            direction={isMobile ? 'column' : 'row'}
            justifyContent={!isMobile ? 'space-between' : null}
            width="100%"
            flexWrap="wrap"
          >
            <Stack direction="row" alignItems="center">
              <Checkbox
                checked={widget.checked}
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  e.stopPropagation();
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  changeWidgetProperty(
                    widgetClusterIndex,
                    widgetIndex,
                    'checked',
                    e.target.checked
                  );
                }}
              />
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                flexWrap="wrap"
                gap={isMobile ? 0.5 : 1.5}
              >
                <Typography variant="body1">{widget.widgetName}</Typography>
                <Chip
                  icon={<SellIcon sx={{ width: '17px', paddingLeft: '5px' }} />}
                  label={widget.widgetNickName}
                  variant="outlined"
                  sx={{
                    background: 'white',
                    color: '#666666DE',
                    fontWeight: 500,
                  }}
                />
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent={isMobile ? 'space-between' : null}>
              <IconButton
                color="inherit"
                onClick={e => {
                  e.stopPropagation();
                  cloneWidget(widget, widgetClusterIndex);
                }}
              >
                <FileCopyIcon fontSize="small" />
              </IconButton>

              {(widget.widgetOrigin === 'custom' || widget.widgetOrigin === 'flocDetector') && (
                <IconButton
                  color="inherit"
                  onClick={e => {
                    e.stopPropagation();
                    setShowDeleteModal(true);
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              )}
              <IconButton
                color="inherit"
                onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
                  e.stopPropagation();
                  moveWidgetPosition(widgetClusterIndex, widgetIndex, 'up');
                }}
              >
                <ArrowUpIcon
                  fill={
                    widget.widgetName == lastMovedWidget.widgetName &&
                    lastMovedWidget.direction == 'up'
                      ? '#018749'
                      : '#333'
                  }
                  width="18px"
                  height="18px"
                />
              </IconButton>
              <IconButton
                color="inherit"
                onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
                  e.stopPropagation();
                  moveWidgetPosition(widgetClusterIndex, widgetIndex, 'down');
                }}
              >
                <ArrowDownIcon
                  fill={
                    widget.widgetName == lastMovedWidget.widgetName &&
                    lastMovedWidget.direction == 'down'
                      ? '#de1738'
                      : '#333'
                  }
                  width="18px"
                  height="18px"
                />
              </IconButton>

              <DeleteConfirmation
                showDeleteModal={showDeleteModal}
                setShowDeleteModal={setShowDeleteModal}
                alertMessage="Are you sure you want to delete this widget? This action is irreversible!"
                processing={processing}
                setProcessing={setProcessing}
                onConfirmAction={() => {
                  deleteCustomWidget(widgetClusterIndex, widgetIndex);
                }}
              />

              {(widget.widgetType == 'tableD' || widget.widgetType == 'tableC') && (
                <IconButton color="inherit">
                  <TableRowsIcon fontSize="medium" />
                </IconButton>
              )}

              {widget.widgetType == 'graph' && (
                <IconButton color="inherit">
                  <AssessmentIcon fontSize="medium" />
                </IconButton>
              )}
              {widget.widgetType == 'number' && (
                <IconButton color="inherit">
                  <PinIcon fontSize="medium" />
                </IconButton>
              )}
              {isMobile && (
                <IconButton onClick={handleExpandIconClick}>
                  <ExpandMoreIcon
                    sx={{
                      ...styles.expandIconStyle,
                      transform: `rotate(${expandIconRotation}deg)`,
                      transition: 'transform 0.2s ease',
                    }}
                  />
                </IconButton>
              )}
            </Stack>
          </Stack>
        </AccordionSummary>
        {!widget.widgetName.includes('Floc Detector') ? (
          <AccordionDetails sx={{ padding: 0 }}>
            {!isMobile && <Divider />}
            <Grid
              container
              rowGap={isMobile ? 0 : 2}
              flexWrap={isMobile ? 'wrap' : 'nowrap'}
              direction={{ isMobile: 'column' }}
            >
              <Grid item xs={12} sm={4} md={4} lg={4} p={2} mt={isMobile ? -2 : 0} sx={{}}>
                <Stack direction="column" alignItems="center" gap={1.7}>
                  <Box sx={{ width: '100%' }}>
                    <MetricSearchBar
                      sensorList={sensorList}
                      keywordFilterList={widget.keyWords}
                      widgetMetrics={widget.metrics}
                      refreshPage={refresh}
                      setRefreshPage={setRefresh}
                      refreshStandAloneWidget={refreshStandAloneWidget}
                      setRefreshStandAloneWidget={setRefreshStandAloneWidget}
                    />
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <FormLabel component="legend">
                      <Checkbox
                        checked={widget.allowMetricLevelCustomisation}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          changeWidgetProperty(
                            widgetClusterIndex,
                            widgetIndex,
                            'allowMetricLevelCustomisation',
                            e.target.checked
                          );
                        }}
                      />
                      Respect Metric Level Customisation
                    </FormLabel>
                  </Box>
                  <Box p={1} paddingLeft={2} borderRadius={2} sx={styles.addedSensorsStyle}>
                    <FormLabel component="legend">
                      Added Sensors {`(${Object.keys(widget.metrics).length})`}
                    </FormLabel>
                    <Box
                      sx={{
                        height: '250px',
                        overflowY: 'scroll',
                      }}
                    >
                      {Object.keys(widget.metrics).map((metric, metricIndex) => {
                        let metricName = widget.metrics[metric].nickName
                          ? widget.metrics[metric].nickName
                          : metric;
                        if (metricName?.toLowerCase().includes(search.toLowerCase()))
                          return (
                            <MetricConfigComponentV2
                              key={metricName + metricIndex}
                              pageData={pageData}
                              setPageData={setPageData}
                              widgetClusterIndex={widgetClusterIndex}
                              widget={widget}
                              widgetIndex={widgetIndex}
                              metric={metric}
                              initiateMetricDiagnosis={initiateMetricDiagnosis}
                              refresh={refresh}
                              setRefresh={setRefresh}
                              setRefreshStandAloneWidget={setRefreshStandAloneWidget}
                            />
                          ); //// metric config component
                      })}
                    </Box>
                  </Box>
                </Stack>
              </Grid>
              {!isMobile && <Divider orientation="vertical" flexItem />}
              <Grid
                item
                direction="column"
                xs={12}
                sm={4}
                md={4}
                lg={4}
                sx={{ display: 'flex', alignItems: 'center' }}
                p={2}
                pl={isMobile ? 2 : 4}
                pr={2}
              >
                <Stack sx={{ width: '100%' }} direction="column" alignItems="center" gap={2}>
                  <Box sx={{ width: '100%' }}>
                    <TextField
                      fullWidth
                      inputRef={nickNameRef}
                      type="text"
                      defaultValue={widget.widgetNickName ? widget.widgetNickName : ''}
                      variant="outlined"
                      label="Nick Name"
                      multiline
                      maxRows={1}
                      inputProps={{
                        maxLength: 50,
                        step: '0.01',
                      }}
                      style={{}}
                      onBlur={e => {
                        if (isNameIsProperlyFormatted(e.target.value)) {
                          changeWidgetProperty(
                            widgetClusterIndex,
                            widgetIndex,
                            'widgetNickName',
                            e.target.value
                          );
                        } else {
                          toast.error('Nicknames cannot be a empty string!');
                          nickNameRef.current.value = widget.widgetNickName;
                          nickNameRef.focus();
                        }
                      }}
                    />
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <TextField
                      fullWidth
                      type="text"
                      defaultValue={widget.widgetDescription}
                      variant="outlined"
                      label="Description"
                      placeholder="widget description"
                      multiline
                      rows={4}
                      style={{}}
                      onBlur={e => {
                        changeWidgetProperty(
                          widgetClusterIndex,
                          widgetIndex,
                          'widgetDescription',
                          e.target.value
                        );
                      }}
                    />
                  </Box>

                  <Box sx={{ width: '100%' }}>
                    <Autocomplete
                      multiple
                      id="tags-filled"
                      options={widget.allowedValueTypes ? widget.allowedValueTypes : valueTypes}
                      // getOptionLabel={(option) => option.title}
                      // defaultValue={ [valueTypes[0]] }
                      filterSelectedOptions
                      value={widget.valueTypes}
                      onChange={(e, newValue) => {
                        changeWidgetProperty(
                          widgetClusterIndex,
                          widgetIndex,
                          'valueTypes',
                          newValue
                        );
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Select the type of values you want to see"
                          placeholder=""
                        />
                      )}
                    />
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select">
                        Select the type of Widget you want to see
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select the type of Widget you want to see"
                        value={widget.widgetType}
                        onChange={e => {
                          changeWidgetProperty(
                            widgetClusterIndex,
                            widgetIndex,
                            'widgetType',
                            e.target.value
                          );
                        }}
                      >
                        <MenuItem value={'graph'}>graph</MenuItem>
                        <MenuItem value={'number'}>Number</MenuItem>
                        <MenuItem value={'tableD'}>Table(developer version)</MenuItem>
                        <MenuItem value={'tableC'}>Table</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  {widget.widgetType === 'graph' ? (
                    <Box sx={{ width: '100%' }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select">
                          Select the type of graph you want to see
                        </InputLabel>
                        <Select
                          // labelId='demo-simple-select-label'
                          id="demo-simple-select"
                          label="Select the type of graph you want to see"
                          value={widget.graphType}
                          onChange={(e: SelectChangeEvent) => {
                            changeWidgetProperty(
                              widgetClusterIndex,
                              widgetIndex,
                              'graphType',
                              e.target.value
                            );
                          }}
                        >
                          <MenuItem value={'bar'}>Bar</MenuItem>
                          <MenuItem value={'line'}>Line</MenuItem>
                          <MenuItem value={'scatter'}>Scatter</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  ) : null}
                </Stack>
              </Grid>
              <Grid p={2} item xs={12} sm={4} md={4} lg={4} sx={{}} pl={2} pr={isMobile ? 2 : 4}>
                <Stack direction="column" alignItems="center" gap={1.7}>
                  <Box sx={{ width: '100%' }}>
                    {/* <FormLabel style={{marginTop : '20px'}} component='legend'>* Add a link to this widget</FormLabel> */}
                    <TextField
                      fullWidth
                      type="text"
                      defaultValue={widget.moreInfo}
                      variant="outlined"
                      label="Link"
                      placeholder="add a link for see more button"
                      multiline
                      inputProps={{
                        step: '0.01',
                        rows: 1,
                      }}
                      style={styles.textBoxStyle}
                      onBlur={e => {
                        changeWidgetProperty(
                          widgetClusterIndex,
                          widgetIndex,
                          'moreInfo',
                          e.target.value
                        );
                      }}
                    />
                  </Box>
                  <Box
                    borderRadius={1}
                    style={{
                      width: '100%',
                      overflow: 'hidden',
                    }}
                  >
                    <Accordion elevation={0}>
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        // expandIcon={<ExpandMoreIcon />}
                        expandIcon={<SettingsIcon fontSize="small" />}
                      >
                        Advanced Widget Settings
                      </AccordionSummary>
                      <AccordionDetails
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Box m={1}>
                          <FormLabel style={{ marginBottom: '10px' }} component="legend">
                            <Checkbox
                              checked={widget.advancedWidgetSettings?.applyCustomSettings}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                changeAdvancedWidgetProperty(
                                  widgetClusterIndex,
                                  widgetIndex,
                                  'applyCustomSettings',
                                  e.target.checked
                                );
                              }}
                            />
                            Apply Custom Time Settings
                          </FormLabel>
                        </Box>

                        <Box mt={2}>
                          <Accordion>
                            <AccordionSummary expandIcon={<SettingsIcon fontSize="small" />}>
                              TimeRange Settings
                            </AccordionSummary>
                            <AccordionDetails>
                              <Stack gap={2} direction="column">
                                <Box>
                                  <p> Minimum Allowed TimeRange</p>
                                  {widget?.widgetType !== 'flocDetector' && (
                                    <TimeRangeSettings
                                      minAllowedTimeRangeSettings={
                                        widget?.factoryTimeRangeSettings?.minAllowedTimeRange
                                      }
                                      minAllowedGranularitySettings={
                                        widget?.factoryGranularitySettings.minAllowedGranularity
                                      }
                                      changeMinAllowedTimeRangeSettings={
                                        changeWidgetMinAllowedTimeRangeSettings
                                      }
                                      changeWidgetMinAllowedGranularitySettings={
                                        changeWidgetMinAllowedGranularitySettings
                                      }
                                      widgetClusterIndex={widgetClusterIndex}
                                      widgetIndex={widgetIndex}
                                    />
                                  )}
                                </Box>
                              </Stack>
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                        <Box mt={2}>
                          <Accordion>
                            <AccordionSummary expandIcon={<SettingsIcon fontSize="small" />}>
                              Granularity Settings
                            </AccordionSummary>
                            <AccordionDetails>
                              <Stack gap={2} direction="column">
                                <Box>
                                  <p> Minimum Allowed Granularity</p>
                                  <GranularitySettings
                                    minAllowedGranularitySettings={
                                      widget?.factoryGranularitySettings?.minAllowedGranularity
                                    }
                                    changeWidgetMinAllowedGranularitySettings={
                                      changeWidgetMinAllowedGranularitySettings
                                    }
                                    widgetClusterIndex={widgetClusterIndex}
                                    widgetIndex={widgetIndex}
                                    minAllowedTimeRangeSettings={
                                      widget?.factoryTimeRangeSettings?.minAllowedTimeRange
                                    }
                                  />
                                </Box>
                              </Stack>
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                  <Box borderRadius={2} sx={styles.standaloneGeneralizedWidget}>
                    <StandaloneGeneralisedWidget
                      widgetStructure={widget}
                      timeFrame={{
                        startDate,
                        endDate,
                      }}
                      refresh={refreshStandAloneWidget}
                      plantId={plantId}
                    />
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </AccordionDetails>
        ) : (
          <AccordionDetails sx={{ padding: 0 }}>
            {!isMobile && <Divider />}
            <Grid
              container
              direction={{ isMobile: 'column' }}
              flexWrap={isMobile ? 'wrap' : 'nowrap'}
              justifyContent={!isMobile ? 'space-between' : ''}
              {...(isTablet
                ? {
                    sx: {
                      overflowX: 'auto',
                      maxWidth: '100%',
                      scrollbarWidth: 'none',
                    },
                  }
                : {})}
            >
              <Grid
                container
                xs={12}
                sm={4}
                md={4}
                lg={4}
                p={2}
                gap={3}
                mt={isMobile ? -2 : 0}
                sx={{ maxWidth: 'unset !important' }}
              >
                <Grid
                  container
                  borderRadius={1}
                  ref={metricSearchBarResultsDivRef}
                  width={isTablet ? '35vw' : '100%'}
                >
                  <TextField
                    type="text"
                    autoComplete="off"
                    fullWidth
                    value={searchQuery}
                    variant="outlined"
                    label="Choose Special Sensor"
                    sx={styles.searchTextField}
                    onClick={() => {
                      setShowSensorList(true);
                    }}
                    onChange={handleSearchChange}
                    InputProps={{
                      endAdornment: searchQuery && (
                        <IconButton onClick={() => setSearchQuery('')} edge="end">
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      ),
                    }}
                  />
                  {showSensorlist && (
                    <Stack borderTop="0.5px solid #ddd" sx={styles.sensorListContainer}>
                      {filteredsensors.map((sensor: any, index: number) => (
                        <Box
                          key={index}
                          p={1}
                          sx={{
                            ...styles.sensorItem,
                            cursor: 'pointer',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            marginTop: '4px',
                            '&:hover': {
                              background: '#f3f3f3de',
                            },
                          }}
                          onClick={() => {
                            handleClose(sensor);
                          }}
                        >
                          <Stack direction="row" alignItems="center" gap={1}>
                            <span>
                              {sensor.sensorNickName ? sensor.sensorNickName : sensor.sensorTag}
                            </span>
                            {sensor.sensorNickName && (
                              <Tooltip title={sensor.sensorTag}>
                                <InfoOutlinedIcon sx={styles.infoIcon} />
                              </Tooltip>
                            )}
                          </Stack>
                        </Box>
                      ))}
                    </Stack>
                  )}
                </Grid>
                <TextField
                  fullWidth
                  inputRef={nickNameRef}
                  type="text"
                  defaultValue={widget.widgetNickName ? widget.widgetNickName : ''}
                  variant="outlined"
                  label="Nick Name"
                  multiline
                  maxRows={1}
                  inputProps={{
                    maxLength: 50,
                    step: '0.01',
                  }}
                  onBlur={e => {
                    if (isNameIsProperlyFormatted(e.target.value)) {
                      changeWidgetProperty(
                        widgetClusterIndex,
                        widgetIndex,
                        'widgetNickName',
                        e.target.value
                      );
                    } else {
                      toast.error('Nicknames cannot be a empty string!');
                      nickNameRef.current.value = widget.widgetNickName;
                      nickNameRef.focus();
                    }
                  }}
                />
                <TextField
                  fullWidth
                  type="text"
                  defaultValue={widget.widgetDescription}
                  variant="outlined"
                  label="Description"
                  placeholder="Showing the levels of the tanks in % ( Tank levels should be Below 95% to prevent flooding or over flowing)"
                  multiline
                  onBlur={e => {
                    changeWidgetProperty(
                      widgetClusterIndex,
                      widgetIndex,
                      'widgetDescription',
                      e.target.value
                    );
                  }}
                />

                <TextField
                  fullWidth
                  type="text"
                  defaultValue={widget.moreInfo}
                  variant="outlined"
                  label="Link"
                  placeholder="add a link for see more button"
                  multiline
                  inputProps={{
                    step: '0.01',
                    rows: 1,
                  }}
                  onBlur={e => {
                    changeWidgetProperty(
                      widgetClusterIndex,
                      widgetIndex,
                      'moreInfo',
                      e.target.value
                    );
                  }}
                />
              </Grid>
              {!isMobile && <Divider orientation="vertical" flexItem />}
              <Grid
                item
                direction="column"
                xs={12}
                sm={4}
                md={4}
                lg={4}
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  maxWidth: 'unset !important',
                }}
                mt={isMobile ? 0 : 1.8}
                gap={isMobile ? 2.5 : 2.8}
                ml={isMobile ? 2.2 : 0.5}
                pb={isMobile ? 2 : 0}
                mb={isDesktop ? 3 : 0}
              >
                {Object.keys(widget.metrics).map((metric, metricIndex) => {
                  let metricName = widget.metrics[metric].nickName
                    ? widget.metrics[metric].nickName
                    : metric;
                  let checked = widget.metrics[metric].checked;
                  return (
                    <Stack direction="row" alignItems="center" key={metricIndex}>
                      <Checkbox
                        checked={widget.metrics[metric].checked}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          handleCheckboxChange(
                            widgetClusterIndex,
                            widgetIndex,
                            metric,
                            event.target.checked
                          );
                        }}
                      />
                      <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
                        {metricName}
                      </Typography>
                    </Stack>
                  );
                })}
              </Grid>
              <ShiftDetailsComponent
                flocDetectorShiftsData={flocDetectorShiftsData}
                selectedTime={selectedTime}
                setSelectedTime={setSelectedTime}
                isStartTimeFocused={isStartTimeFocused}
                setIsStartTimeFocused={setIsStartTimeFocused}
                isEndTimeFocused={isEndTimeFocused}
                setIsEndTimeFocused={setIsEndTimeFocused}
                flocDetectorShifts={flocDetectorShifts}
                openDialog={openDialog}
                errorStartTime={errorStartTime}
                error24hrs={error24hrs}
                newShiftStartTimeError={newShiftStartTimeError}
                fillAllFieldsError={fillAllFieldsError}
                editSerialNumber={editSerialNumber}
                handleChangeFlocDetectorShifts={handleChangeFlocDetectorShifts}
                handleTimePickerChange={handleTimePickerChange}
                previousEndTime={previousEndTime}
                previousStartTime={previousStartTime}
                totalShift24hrs={totalShift24hrs}
                handleClickOpenDialog={handleClickOpenDialog}
                handleCloseDialog={handleCloseDialog}
                handleEditItem={handleEditItem}
                addFlocDetectorShifts={addFlocDetectorShifts}
                widgetClusterIndex={widgetClusterIndex}
                widgetIndex={widgetIndex}
              />
            </Grid>
          </AccordionDetails>
        )}
      </Accordion>
      <StaticDateTimePickerComponent
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        selectedTime={selectedTime}
        handleTimePickerChange={handleTimePickerChange}
      />

      <Modal
        open={openDiagnosisBox}
        onClose={() => {
          setOpenDiagnosisBox(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {diagnosing ? (
          <Box p={1} sx={styles.metricDiagnosisLoading}>
            <p style={{ textAlign: 'center' }}>running sensor diagnosis.... </p>
          </Box>
        ) : (
          <Box
            p={1}
            sx={
              metricDiagnosisData && metricDiagnosisData?.problems?.length > 0
                ? styles.metricDiagnosisNotOk
                : styles.metricDiagnosisOk
            }
          >
            <Box>
              <h3>Sensor Diagnosis completed</h3>
              <p>sensor exists in Db : {metricDiagnosisData?.sensorExists ? 'YES' : 'No'}</p>
              <p>sensorId : {metricDiagnosisData?.sensorId}</p>
              <p>created on :{metricDiagnosisData?.dateCreated}</p>
              <p>sensorType : {metricDiagnosisData?.sensorType}</p>
              <p>last observed Value : {metricDiagnosisData?.lastValue}</p>
              <p>last Observed time : {metricDiagnosisData?.lastValueTime}</p>
              <p>
                problem Diagnosis -
                {metricDiagnosisData && metricDiagnosisData?.problems?.length > 0 ? (
                  metricDiagnosisData?.problems?.map((problem: string, index: number) => {
                    return <p>{index + 1 + '. ' + problem}</p>;
                  })
                ) : (
                  <span>No problems found, sensor Ok</span>
                )}
              </p>
            </Box>
          </Box>
        )}
      </Modal>
    </>
  );
}
