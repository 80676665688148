import { Box, Grid, Paper, TextField, Stack } from '@mui/material';
import { Checkbox, Autocomplete, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import ReceiverConfig from './ReceiverConfig';
import TriggerResolvation from './TriggerResolvation';
import WorkflowConfig from './WorkflowConfig';
import { Equipment, FixedTasks, User, VarList } from '../../Interfaces/alertTask';
import { ALERT_TYPES, ISSUE_TYPES, PRIORITY_TYPES } from '../../constants/constants';
import { SensorList } from '../../Interfaces/sensors';
import { TICKET_SUB_TYPE_OPTIONS } from '../ManualEntryWidget/constants/tickets.constants';
import TagSelector from '../../globalComponents/tagSystem/TagSelector';

interface IProps {
  task: FixedTasks;
  indexTask: number;
  equipments: Equipment[];
  userList: User[];
  assignRole: string[];
  escaltionOptions: string[];
  handleCheckedCritical: Function;
  updateDirectValue: Function;
  UpdateTask: Function;
  updatePrimaryUser: Function;
  updateCCUserArrayVals: Function;
  UpdateEscaltionLevel: Function;
  addnewSubtask: Function;
  deleteSubTask: Function;
  UpdateSubTask: Function;
  sensorList: SensorList;
  configVarList: VarList | null;
  updateCondition: Function;
  deleteAlertStatement: Function;
  deleteConditionSet: Function;
  addAlertStatement: Function;
  addConditionSet: Function;
  boolSensorTagList: string[];
}

function TaskCardView({
  task,
  indexTask,
  equipments,
  userList,
  assignRole,
  escaltionOptions,
  handleCheckedCritical,
  updateDirectValue,
  UpdateTask,
  updatePrimaryUser,
  updateCCUserArrayVals,
  UpdateEscaltionLevel,
  addnewSubtask,
  deleteSubTask,
  UpdateSubTask,
  sensorList,
  configVarList,
  updateCondition,
  deleteAlertStatement,
  deleteConditionSet,
  addAlertStatement,
  addConditionSet,
  boolSensorTagList,
}: IProps) {
  const priorityValue = Array.isArray(task?.priority) ? task.priority[0] : task?.priority || '';
  return (
    <Box>
      <Box style={{ display: 'flex' }}>
        <div style={{ font: 'Montserrat Bold', fontSize: 20 }}>
          {task ? (task.heading ? task.heading.eng : 'Enter Heading') : 'Enter Heading'}
        </div>
      </Box>

      <Box m={1} style={{ width: '100%' }}>
        <Paper>
          <div>
            <Checkbox
              checked={task.emergency != null ? task.emergency : false}
              onChange={e => {
                handleCheckedCritical(e.target.checked);
              }}
            />
            Critical
          </div>
          <Box m={1} p={1}>
            <div>
              <Autocomplete
                id="tags-standard"
                options={ALERT_TYPES}
                value={task['type']}
                onChange={(event, newValue) => {
                  updateDirectValue('type', newValue);
                }}
                renderInput={params => (
                  <TextField {...params} variant="standard" label="Alert Type" />
                )}
              />
            </div>
          </Box>
          <Box m={1} p={1}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Equipment</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={task.equipments}
                label="Equipment"
                renderValue={value => (value.nickName !== '' ? value.nickName : value.id)}
                onChange={e => updateDirectValue('equipments', e.target.value)}
              >
                {equipments.map((equipment: any) => {
                  return (
                    <MenuItem value={equipment}>
                      {equipment.nickName !== '' ? equipment.nickName : equipment.id}
                    </MenuItem>
                  );
                  // return ( <MenuItem value={equipment}>{equipment.name}</MenuItem>)
                })}
              </Select>
            </FormControl>
          </Box>
          <Box m={1} p={1}>
            <div style={{ font: 'Montserrat Bold', fontSize: 20 }}>
              <div>Problem Statement</div>
              <div style={{ font: 'Montserrat', fontSize: 16 }}>
                <TextField
                  fullWidth
                  required
                  style={{ marginTop: 10 }}
                  value={
                    task ? (task.heading ? task.heading.eng : 'Enter Heading') : 'Enter Heading'
                  }
                  label="Problem Statement in English"
                  onChange={e => {
                    UpdateTask('heading', 'eng', e.target.value);
                  }}
                />
              </div>

              <div style={{ font: 'Montserrat', fontSize: 16 }}>
                <TextField
                  fullWidth
                  required
                  style={{ marginTop: 10 }}
                  value={
                    task
                      ? task.heading
                        ? task.heading.hindi
                        : 'शीर्षक दर्ज करें'
                      : 'शीर्षक दर्ज करें'
                  }
                  label="Problem Statement in Hindi"
                  onChange={e => {
                    UpdateTask('heading', 'hindi', e.target.value);
                  }}
                />
              </div>
            </div>
          </Box>

          <Box m={1} p={1}>
            <div style={{ font: 'Montserrat Bold', fontSize: 20 }}>
              <div>Problem Description</div>
              <div style={{ font: 'Montserrat', fontSize: 16 }}>
                <TextField
                  fullWidth
                  required
                  style={{ marginTop: 10 }}
                  value={
                    task
                      ? task.description
                        ? task.description.eng
                        : 'Enter Description'
                      : 'Enter Description'
                  }
                  label="Description English"
                  onChange={e => {
                    UpdateTask('description', 'eng', e.target.value);
                  }}
                />
              </div>

              <div style={{ font: 'Montserrat', fontSize: 16 }}>
                <TextField
                  fullWidth
                  required
                  value={
                    task
                      ? task.description
                        ? task.description.hindi
                        : 'विवरण दर्ज करें'
                      : 'विवरण दर्ज करें'
                  }
                  style={{ marginTop: 10 }}
                  label="Problem Descriptiion in Hindi"
                  onChange={e => {
                    UpdateTask('description', 'hindi', e.target.value);
                  }}
                />
              </div>
            </div>
          </Box>
          <Box m={1} p={1}>
            <Stack direction="column" gap={3}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    options={ISSUE_TYPES}
                    value={task ? (task['issueType'] ? task['issueType'] : []) : []}
                    onChange={(event, newValue) => {
                      updateDirectValue('issueType', newValue);
                    }}
                    renderInput={params => (
                      <TextField {...params} required variant="standard" label="Issue Type" />
                    )}
                  />
                </Grid>
                <Grid item xs={6} mt={1}>
                  <Autocomplete
                    id="priority-standard"
                    options={PRIORITY_TYPES}
                    value={priorityValue}
                    onChange={(event, newValue) => {
                      updateDirectValue('priority', newValue);
                    }}
                    renderInput={params => (
                      <TextField {...params} required variant="standard" label="Priority" />
                    )}
                  />
                </Grid>
              </Grid>
              <FormControl fullWidth>
                <InputLabel id="subtype-select-label">Sub Type</InputLabel>
                <Select
                  labelId="subtype-select-label"
                  value={task['ticketSubType']}
                  label="Sub Type"
                  onChange={e => updateDirectValue('ticketSubType', e.target.value)}
                >
                  {TICKET_SUB_TYPE_OPTIONS?.map((type: any) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TagSelector
                selectedTags={task['tags'] || []}
                onChange={newTags => {
                  updateDirectValue('tags', newTags);
                }}
              />
            </Stack>
          </Box>

          <Box m={1} p={1}>
            <TriggerResolvation
              task={task}
              indexTask={indexTask}
              sensorList={sensorList}
              configVarList={configVarList}
              boolSensorTagList={boolSensorTagList}
              updateDirectValue={updateDirectValue}
              updateCondition={updateCondition}
              deleteAlertStatement={deleteAlertStatement}
              deleteConditionSet={deleteConditionSet}
              addAlertStatement={addAlertStatement}
              addConditionSet={addConditionSet}
            />
            <ReceiverConfig
              updateDirectValue={updateDirectValue}
              indexTask={indexTask}
              updatePrimaryUser={updatePrimaryUser}
              task={task}
              userList={userList}
              updateCCUserArrayVals={updateCCUserArrayVals}
              updateEscalationLevel={UpdateEscaltionLevel}
              assignRole={assignRole}
              escalationOptions={escaltionOptions}
            />
            <WorkflowConfig
              UpdateTask={UpdateTask}
              addNewSubTask={addnewSubtask}
              deleteSubTask={deleteSubTask}
              updateSubTask={UpdateSubTask}
              updateDirectValue={updateDirectValue}
              indexTask={indexTask}
              task={task}
            />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}

export default TaskCardView;
