function idstoworkspace(items: any[], ids: any[]) {
  type InputType = {
    [key: string]: string[];
  };

  type OutputType = {
    workspace: string;
    assets: string[];
  };

  const result: InputType = ids.reduce((acc, selectedItemId) => {
    items.forEach(workspace => {
      workspace.children.forEach((asset: { itemId: any; workspaceId: any; id: any }) => {
        if (asset.itemId === selectedItemId) {
          const workspaceId = workspace.id;
          if (!acc[workspaceId]) {
            acc[workspaceId] = [];
          }
          acc[workspaceId].push(asset.id);
        }
      });
      if(workspace.children.length === 0) {
        if (workspace.itemId === selectedItemId) {
          const workspaceId = workspace.id;
          if (!acc[workspaceId]) {
            acc[workspaceId] = [];
          }
        }
      }
    });
    return acc;
  }, {});

  const workspaceAsset: OutputType[] = Object.entries(result).map(([workspaceId, assets]) => ({
    workspace: workspaceId,
    assets: assets,
  }));

  return workspaceAsset
}

const getModules = (items: any[], ids: string | any[]) => {
  const result: any[] = [];
  function traverse(item: { type: string; itemId: string; id: any; children: any[] }) {
    if (ids.includes(item.itemId)) {
      if (item.type != 'module') {
        result.push(item.id);
      }
    }
    if (item.children) {
      item.children.forEach(traverse);
    }
  }
  items.forEach(traverse);
  return result;
};

const getPermissions = (items: any[], ids: string | any[]) => {
  const result: any[] = [];
  function traverse(item: { type: string; itemId: string; id: any; children: any[] }) {
    if (ids.includes(item.itemId)) {
      if (item.type !== 'module' && item.type !== 'feature' && item.type !== 'subfeature') {
        result.push(item.id);
      }
    }
    if (item.children) {
      item.children.forEach(traverse);
    }
  }
  items.forEach(traverse);
  return result;
};

const processFeatureTags = (featureTags: any[]) => {
  const checkedItemIds = new Set<string>();

  const traverseFeatures = (features: any[]) => {
    features.forEach((feature: { checked: boolean; itemId: string; children: any[] }) => {
      if (feature.checked) {
        checkedItemIds.add(feature.itemId);
      }
      if (feature.children && feature.children.length > 0) {
        const allChildrenChecked = feature.children.every(
          (child: { checked: boolean }) => child.checked
        );
        if (allChildrenChecked) {
          checkedItemIds.add(feature.itemId);
        }
        traverseFeatures(feature.children);
      }
    });
  };

  traverseFeatures(featureTags);
  return Array.from(checkedItemIds);
};

const processAllTags = (modules: any[]) => {
  const checkedItemIds = new Set<string>();

  const traversePermissions = (permissions: any[]) => {
    let allChecked = true;
    permissions.forEach((permission: { checked: boolean; itemId: string }) => {
      if (permission.checked) {
        checkedItemIds.add(permission.itemId);
      } else {
        allChecked = false;
      }
    });
    return allChecked;
  };

  const traverseSubFeatures = (subFeatures: any[]) => {
    let allChecked = true;
    subFeatures.forEach((subFeature: { itemId: string; children: any[] }) => {
      const allPermissionsChecked = traversePermissions(subFeature.children);
      if (allPermissionsChecked) {
        checkedItemIds.add(subFeature.itemId);
      } else {
        allChecked = false;
      }
    });
    return allChecked;
  };

  const traverseFeatures = (features: any[]) => {
    let allChecked = true;
    features.forEach((feature: { itemId: string; children: any[] }) => {
      const allSubFeaturesChecked = traverseSubFeatures(feature.children);
      if (allSubFeaturesChecked) {
        checkedItemIds.add(feature.itemId);
      } else {
        allChecked = false;
      }
    });
    return allChecked;
  };

  const traverseModules = (modules: any[]) => {
    modules.forEach((module: { itemId: string; children: any[] }) => {
      const allFeaturesChecked = traverseFeatures(module.children);
      if (allFeaturesChecked) {
        checkedItemIds.add(module.itemId);
      }
    });
  };

  traverseModules(modules);

  // Return the item IDs as an array
  return Array.from(checkedItemIds);
};

const processWorkspace = (workspace: any[]) => {
  const checkedItemIds = new Set<string>();
  const traverseFeatures = (workspace: any[]) => {
    workspace.forEach((feature: { checked: boolean; itemId: string; children: any[] }) => {
      if (feature.checked) {
        checkedItemIds.add(feature.itemId);
      }
      if (feature.children && feature.children.length > 0) {
        const allChildrenChecked = feature.children.every(
          (child: { checked: boolean }) => child.checked
        );
        if (allChildrenChecked) {
          checkedItemIds.add(feature.itemId);
        }
        traverseFeatures(feature.children);
      }
    });
  };

  traverseFeatures(workspace);
  console.log(checkedItemIds);
  return Array.from(checkedItemIds);
};

export {
  idstoworkspace,
  getModules,
  processFeatureTags,
  processWorkspace,
  processAllTags,
  getPermissions,
};
