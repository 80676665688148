import axiosInstance from '../constants/axiosInstance';
interface ICheckGlobalPermissionPayload {
  permissionTag: string;
  assetId?: string;
}
export const checkGlobalPermission = async ({
  permissionTag,
  assetId,
}: ICheckGlobalPermissionPayload) => {
  try {
    const response = await axiosInstance().post('/permission/checkGlobalPermission', {
      permissionTag,
      assetId,
    });
    return response.data; // Assume response data is a boolean (true or false)
  } catch (error) {
    console.error('Permission check failed', error);
    return false; // Fallback to false if the request fails
  }
};
