import {
  Breadcrumbs,
  Link,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  ThemeProvider,
  Box,
  InputAdornment,
  IconButton,
  Chip,
  Stack,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { getAllStores, updateStore } from '../services/stores';
import React, { useEffect, useState } from 'react';
import { IStore, IStoreResponse } from '../Interfaces/storeConfigurationInterfaces';
import LayoutComponent from '../components/LayoutComponent';
import dpTheme from '../styles/theme/dpTheme';
import styles from '../styles/pages/Store.module.css';
import Loader from '../globalComponents/loader/DPLoader';
import SearchIcon from '@mui/icons-material/Search';
import OutboundOutlinedIcon from '@mui/icons-material/OutboundOutlined';
import StoreDetailDialog from '../components/StoreConfigurationComponents/StoreDetailDialog';
import { AddAntiBlueButton } from '../styles/global/components/dpButtons';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CreateStoreDialog from '../components/StoreConfigurationComponents/CreateStoreDialog';
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import { toast } from 'react-toastify';
import EditStoreDialog from '../components/StoreConfigurationComponents/EditStoreDialog';
import { useNavigate } from 'react-router-dom';
import useIsMobile from '../utilities/customHooks/useIsMobile';
import usePermissionCheck from '../utilities/customHooks/usePermissionCheck';
import { PERMISSION_TAGS } from '../constants/permissions';

const maxChips = 5;

function StoreConfiguration() {
  const [stores, setStores] = useState<IStore[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [openStoreDetailDialog, setOpenStoreDetailDialog] = useState(false);
  const [selectedStore, setSelectedStore] = useState<IStore | null>(null);
  const [openCreateStoreDialog, setOpenCreateStoreDialog] = useState(false);
  const [menuState, setMenuState] = useState<{
    anchorEl: null | HTMLElement;
    id: string | null;
  }>({ anchorEl: null, id: null });
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [editingStore, setEditingStore] = useState<IStore | null>(null);
  const [finalSearchQuery, setFinalSearchQuery] = useState('');
  const navigate = useNavigate();
  const isMobile = useIsMobile();


  const { hasPermission: canCreateStore, loading: loadingAddFixedTaskButton } = usePermissionCheck(
    PERMISSION_TAGS.StoreConfiguration_Configuration_WE
    // assetId,
  );

  const fetchData = async (
    searchQuery: string = '',
    isSearch: boolean = false,
    isNewSearch: boolean = false,
    isCreate: boolean = false
  ) => {
    if (isSearch) {
      setIsSearchLoading(true);
    } else {
      setIsLoading(true);
    }

    try {
      let tempPage = page;
      let limit = isCreate ? totalItems + 1 - page * rowsPerPage : totalItems - page * rowsPerPage;
      limit = totalItems === 0 || isNewSearch ? 10 : Math.min(Math.max(limit, 1), 10);

      if (page > 0 && isNewSearch) {
        tempPage = 0;
        setPage(0);
      }

      const skip = tempPage * 10;

      const data: IStoreResponse = await getAllStores(skip, limit, searchQuery);
      if (data) {
        setStores(data.stores);
        setTotalItems(data.totalStores);
      }
    } catch (error) {
      console.error('Error fetching stores:', error);
      toast.error('Error fetching stores');
    }

    if (isSearch) {
      setIsSearchLoading(false);
    } else {
      setIsLoading(false);
    }

    setMenuState({ anchorEl: null, id: null }); // Cleanup
  };

  useEffect(() => {
    fetchData(finalSearchQuery, true);
    setSearchQuery(finalSearchQuery);
    return () => {
      setMenuState({ anchorEl: null, id: null });
    };
  }, [page, rowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = () => {
    setFinalSearchQuery(searchQuery);
    fetchData(searchQuery, true, true);
  };

  const handleSearchIconClick = () => {
    handleSearch();
    setIsSearching(!isSearching);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    if (isSearching) {
      setIsSearching(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleOpenStoreDetailDialog = (event: React.MouseEvent<HTMLElement>, store: IStore) => {
    event.stopPropagation();
    setSelectedStore(store);
    setOpenStoreDetailDialog(true);
  };

  const handleStoreDetailDialogClose = () => {
    setOpenStoreDetailDialog(false);
  };

  const handleCreateStoreDialogOpen = () => {
    setOpenCreateStoreDialog(true);
  };

  const handleCreateStoreDialogClose = () => {
    setOpenCreateStoreDialog(false);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, storeId: string) => {
    event.stopPropagation();
    setMenuState({ anchorEl: event.currentTarget, id: storeId });
  };

  const handleMenuClose = () => {
    setMenuState({ anchorEl: null, id: null });
  };

  const handleDetailsMenuItemClick = (event: React.MouseEvent<HTMLElement>, store: IStore) => {
    event.stopPropagation();
    handleMenuClose();
    handleOpenStoreDetailDialog(event, store);
  };

  const handleArchiveUnarchive = async (event: React.MouseEvent<HTMLElement>, store: IStore) => {
    event.stopPropagation();
    const updatedStatus = !store.archived;
    try {
      await updateStore(store._id, { archived: updatedStatus });
      refreshData();
      toast.success(`Store ${updatedStatus ? 'archived' : 'unarchived'} successfully`);
    } catch (error) {
      console.error('Error updating store:', error);
      toast.error(`Error ${updatedStatus ? 'archived' : 'unarchived'} store`);
    }
  };

  const handleOpenEditDialog = (event: React.MouseEvent<HTMLElement>, store: IStore) => {
    event.stopPropagation();
    handleMenuClose();
    setEditingStore(store);
    setIsEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false);
    setEditingStore(null);
  };

  const handleRowClick = (storeId: string, storeName: string) => {
    navigate(`/StoreManagement/${storeId}?storeName=${encodeURIComponent(storeName)}`);
  };

  const refreshData = (isCreate: boolean = false) => {
    fetchData(searchQuery, true, false, isCreate);
  };

  return (
    <ThemeProvider theme={dpTheme}>
      <LayoutComponent factoryResetContainer={true}>
        {isLoading ? (
          <Loader />
        ) : (
          <Box className={styles.StoreConfigurationPage}>
            <Box className={styles.StoreConfigurationPageHeader}>
              <Breadcrumbs aria-label="Stores Breadcrumb">
                <Link className={styles.NoUnderline} color="inherit" href="/StoreConfiguration">
                  Stores
                </Link>
              </Breadcrumbs>
              {canCreateStore && <AddAntiBlueButton
                onClick={() => {
                  handleCreateStoreDialogOpen();
                }}
              >
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  {!isMobile ? <span style={{ marginRight: '10px' }}>Create</span> : ''}
                  <AddOutlinedIcon />
                </Stack>
              </AddAntiBlueButton>}
            </Box>

            <Box className={styles.SearchSection}>
              <TextField
                placeholder="Search for Asset and Store / Store Name / Client Name"
                variant="outlined"
                fullWidth
                value={searchQuery}
                onChange={handleSearchChange}
                onKeyDown={handleKeyDown}
                InputProps={{
                  className: styles.SearchField,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleSearchIconClick}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    fieldset: {
                      border: '1px solid #183650',
                    },
                    '&.Mui-focused fieldset': {
                      border: '1.6px solid #183650',
                    },
                  },
                  marginRight: {
                    xs: '10px',
                    sm: '16px',
                  },
                }}
              />
              <Button variant="outlined" className={styles.SearchButton} onClick={handleSearch}>
                {isMobile ? <SearchIcon fontSize="small" /> : 'Search'}
              </Button>
            </Box>

            <TableContainer component={Paper} className={styles.StoresTable}>
              <Table aria-label="Stores Table">
                <TableHead>
                  <TableRow>
                    <TableCell className={styles.StoreTableHeaderCellBorderBottom}>
                      Name of Store
                    </TableCell>
                    <TableCell className={styles.StoreTableHeaderCell}>User Group Name</TableCell>
                    <TableCell className={styles.StoreTableHeaderCell}>Asset Name</TableCell>
                    <TableCell className={styles.StoreTableHeaderCell}>No. of Items</TableCell>
                    <TableCell align="center" className={styles.StoreTableHeaderCell}>
                      <div className={styles.AlignCenter}>
                        <MenuIcon />
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={styles.StoresTableBody}>
                  {isSearchLoading ? (
                    <TableRow>
                      <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                        <Loader />
                      </TableCell>
                    </TableRow>
                  ) : (
                    stores.map((store: IStore, rowIndex: number) => (
                      <TableRow
                        key={store._id}
                        className={styles.StoresTableRow}
                        onClick={() => handleRowClick(store._id, store.name)}
                      >
                        <TableCell className={styles.StoresTableRowCell}>
                          <Stack>
                            <Typography variant="body2">{store.name}</Typography>
                            <Typography variant="body2" sx={{ fontSize: '10px', color: '#7A848D' }}>
                              {store.storeAbbr}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell className={styles.StoresTableRowCell}>
                          {store.clients.length > 0 ? store.clients[0].name : ''}
                        </TableCell>
                        <TableCell className={styles.StoresTableRowCell}>
                          <Box className={styles.AssetChipsContainer}>
                            {store.assets.slice(0, maxChips).map((asset, index) => (
                              <Chip
                                variant="outlined"
                                key={index}
                                label={asset.plantName}
                                className={styles.AssetChipOutlined}
                              />
                            ))}
                            {store.assets.length > maxChips && (
                              <>
                                <span>...</span>
                                <IconButton
                                  className={styles.IconButton}
                                  onClick={event => handleOpenStoreDetailDialog(event, store)}
                                >
                                  <OutboundOutlinedIcon
                                    style={{
                                      width: '16px',
                                      height: '16px',
                                    }}
                                  />
                                </IconButton>
                              </>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell className={styles.StoresTableRowCell}>
                          <Chip
                            variant="filled"
                            label={store.totalItems}
                            className={styles.ItemChipFilled}
                          />
                        </TableCell>
                        <TableCell align="center" className={styles.StoresTableRowCellShort}>
                          <Button
                            aria-label="more"
                            aria-controls={`store-menu-${store._id}`}
                            aria-haspopup="true"
                            onClick={e => handleMenuOpen(e, store._id)}
                          >
                            <MoreVertIcon />
                          </Button>
                          <Menu
                            id={`store-menu-${store._id}`}
                            anchorEl={menuState.id === store._id ? menuState.anchorEl : null}
                            keepMounted
                            open={menuState.id === store._id}
                            onClose={handleMenuClose}
                          >
                            <MenuItem onClick={event => handleDetailsMenuItemClick(event, store)}>
                              <ListItemIcon>
                                <InfoIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText primary="Details" />
                            </MenuItem>
                            <MenuItem onClick={event => handleOpenEditDialog(event, store)}>
                              <ListItemIcon>
                                <EditIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText primary="Edit" />
                            </MenuItem>
                            <MenuItem onClick={event => handleArchiveUnarchive(event, store)}>
                              <ListItemIcon>
                                <DeleteIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText primary={store.archived ? 'Unarchive' : 'Archive'} />
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        )}
        {openStoreDetailDialog && (
          <StoreDetailDialog
            openStoreDetailDialog={openStoreDetailDialog}
            handleStoreDetailDialogClose={handleStoreDetailDialogClose}
            selectedStore={selectedStore}
          />
        )}
        {openCreateStoreDialog && (
          <CreateStoreDialog
            openCreateStoreDialog={openCreateStoreDialog}
            handleCreateStoreDialogClose={handleCreateStoreDialogClose}
            refreshData={() => {
              refreshData(true);
            }}
          />
        )}
        {isEditDialogOpen && editingStore && (
          <EditStoreDialog
            open={isEditDialogOpen}
            onClose={handleCloseEditDialog}
            store={editingStore}
            refreshData={refreshData}
          />
        )}
      </LayoutComponent>
    </ThemeProvider>
  );
}

export default StoreConfiguration;
