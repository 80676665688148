function ConvertToISO(localTimeString: any) {
  const today = new Date(); // Get the current date
  const [time, meridiem] = localTimeString.split(' '); // Split the time string into time and meridiem (AM/PM)
  const [hours, minutes] = time.split(':'); // Split the time into hours and minutes

  // Adjust hours based on meridiem (12-hour to 24-hour format)
  let isoHours = parseInt(hours, 10);
  if (meridiem === 'PM' && isoHours < 12) {
    isoHours += 12;
  } else if (meridiem === 'AM' && isoHours === 12) {
    isoHours = 0;
  }

  // Set the time components to the current date
  today.setHours(isoHours, minutes);

  // Convert to ISO string
  const isoString = today.toISOString();
  return isoString;
}
export default function ConvertTimeInISOFormat(data: any) {
  let result = [];
  for (let key of data) {
    let startTime = ConvertToISO(key.startTime);
    let endTime = ConvertToISO(key.endTime);
    let serialNumber = key.serialNumber;
    let shiftName = key.shiftName;
    result.push({ serialNumber, shiftName, startTime, endTime });
  }
  return result;
}
