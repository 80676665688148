const handleRoleAdd = (
  treeData: any,
  selectedRoleItemIds: any,
  workspaceAssetRoles: { workspaceId: string; assetId: string; roleIds: string[] }[]
) => {
  // Loop through the workspaces
  treeData?.forEach((workspace: any) => {
    // Loop through the assets (children of the workspace)
    workspace?.children?.forEach((asset: any) => {
      // Filter the roles within the asset based on selectedRoleItemIds
      const matchingRoles = asset.roles.filter((role: any) =>
        selectedRoleItemIds.includes(role.itemId)
      );

      if (matchingRoles.length > 0) {
        // Extract all matching role IDs
        const roleIds = matchingRoles.map((role: any) => role._id);

        // Check if the workspaceId already exists in workspaceAssetRoles
        const existingWorkspace = workspaceAssetRoles.find(
          entry => entry.workspaceId === workspace._id && entry.assetId === asset._id
        );

        if (existingWorkspace) {
          // If the workspace and asset already exist, merge the roleIds
          existingWorkspace.roleIds = Array.from(
            new Set([...existingWorkspace.roleIds, ...roleIds])
          );
        } else {
          // If it doesn't exist, add a new entry
          workspaceAssetRoles.push({
            workspaceId: workspace._id, // Workspace ID
            assetId: asset._id, // Asset ID
            roleIds, // Role IDs associated with the asset
          });
        }
      }
    });
  });

  return workspaceAssetRoles;
};

const handleRoleRemove = (
  treeData: any,
  selectedRoleItemIds: any,
  workspaceAssetRoles: { workspaceId: string; assetId: string; roleIds: string[] }[]
) => {
  // Loop through the assets within the workspace
  treeData?.forEach((workspace: any) => {
    workspace?.children?.forEach((asset: any) => {
      // Find roles that need to be removed based on `selectedRoleItemIds`
      const roleIdsToRemove = asset.roles
        .filter((role: { itemId: any }) => selectedRoleItemIds?.includes(role.itemId))
        .map((role: { _id: any }) => role._id); // Extract the `_id` for the roles to be removed

      if (roleIdsToRemove?.length > 0) {
        // Find the existing workspace-asset pair in `workspaceAssetRoles`
        const existingEntry = workspaceAssetRoles.find(
          entry => entry.workspaceId === workspace._id && entry.assetId === asset._id
        );

        if (existingEntry) {
          // Remove the roles from `roleIds`
          existingEntry.roleIds = existingEntry.roleIds.filter(
            roleId => !roleIdsToRemove.includes(roleId)
          );

          // If no roles left, remove the entire workspace-asset entry
          if (existingEntry.roleIds.length === 0) {
            const index = workspaceAssetRoles.indexOf(existingEntry);
            workspaceAssetRoles.splice(index, 1);
          }
        }
      }
    });
  });

  return workspaceAssetRoles;
};

const handleRoleAddForEditPage = (workspacePermissionTree: any) => {
  let workspaceAssetRoles: { workspaceId: string; assetId: string; roleIds: string[] }[] = [];
  // Loop through the workspaces
  workspacePermissionTree?.forEach((workspace: any) => {
    // Loop through the assets (children of the workspace)
    workspace?.children?.forEach((asset: any) => {
      workspaceAssetRoles.push({
        workspaceId: workspace._id, // Workspace ID
        assetId: asset._id, // Asset ID
        roleIds: asset.roles
          .filter((role: any) => role?.selected === true)
          .map((role: any) => role._id), // Role IDs associated with the asset
      });
    });
  });
  return workspaceAssetRoles;
};
export { handleRoleAdd, handleRoleAddForEditPage, handleRoleRemove };

// const handleRoleAdd = (treeData: any, selectedRoleItemIds: any, workspaceAssetRoles: any) => {
//   // const workspaceAssetRoles: any = [];

//   // Loop through the workspaces
//   treeData.forEach((workspace: any) => {
//     // Loop through the assets (children of the workspace)
//     workspace.children.forEach((asset: any) => {
//       // Filter the roles within the asset based on selectedRoleItemIds
//       const matchingRoles = asset.roles.filter((role: any) =>
//         selectedRoleItemIds.includes(role.itemId)
//       );

//       if (matchingRoles.length > 0) {
//         // Extract all matching role IDs
//         const roleIds = matchingRoles.map((role: any) => role._id);

//         // Push the workspace and asset data into workspaceAssetRoles
//         workspaceAssetRoles.push({
//           workspaceId: workspace._id, // Workspace ID
//           assetId: asset._id, // Asset ID
//           roleIds, // Role IDs associated with the asset
//         });
//       }
//     });
//   });

//   return workspaceAssetRoles;
// };

// export { handleRoleAdd };

// const handleRoleAdd = (
//   treeData: any,
//   selectedRoleItemIds: any,
//   workspaceAssetRoles: { workspaceId: string; assetId: string; roleIds: string[] }
// ) => {
//   // Loop through the workspaces
//   treeData.forEach((workspace: any) => {
//     // Loop through the assets (children of the workspace)
//     workspace.children.forEach((asset: any) => {
//       // Filter the roles within the asset based on selectedRoleItemIds
//       const matchingRoles = asset.roles.filter((role: any) =>
//         selectedRoleItemIds.includes(role.itemId)
//       );

//       if (matchingRoles.length > 0) {
//         // Extract all matching role IDs
//         const roleIds = matchingRoles.map((role: any) => role._id);

//         // Check if the workspace already exists in workspaceAssetRoles
//         const existingWorkspaceIndex = workspaceAssetRoles.findIndex(
//           (item: any) => item.workspaceId === workspace._id
//         );

//         if (existingWorkspaceIndex !== -1) {
//           // Workspace exists, now check if the asset exists within the workspace
//           const existingAssetIndex = workspaceAssetRoles[existingWorkspaceIndex].assets.findIndex(
//             (item: any) => item.assetId === asset._id
//           );

//           if (existingAssetIndex !== -1) {
//             // Asset already exists, just update its roleIds (merge unique roleIds)
//             const existingRoleIds =
//               workspaceAssetRoles[existingWorkspaceIndex].assets[existingAssetIndex].roleIds;
//             workspaceAssetRoles[existingWorkspaceIndex].assets[existingAssetIndex].roleIds = [
//               ...new Set([...existingRoleIds, ...roleIds]),
//             ];
//           } else {
//             // Asset does not exist, add the asset and roleIds
//             Object.assign(workspaceAssetRoles[existingWorkspaceIndex], {
//               assetId: asset._id,
//               roleIds,
//             });
//           }
//         } else {
//           // Workspace does not exist, push the workspace, asset, and roleIds
//           workspaceAssetRoles.push({
//             workspaceId: workspace._id,
//             assetId: asset._id,
//             roleIds,
//           });
//         }
//       }
//     });
//   });

//   return workspaceAssetRoles;
// };

// export { handleRoleAdd };
