import React, { useEffect, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Menu, MenuItem, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import DialogBox from '../../../../core-components/theme/components/DialogBox/DialogBox';
import DetailIcon from '../../../../core-components/assets/icons/DetailIcon';
import DeleteFilledIcon from '../../../../core-components/assets/icons/DeleteIconFilled';
import EditIcon from '../../../../core-components/assets/icons/EditIcon';
import CustomIconButton from '../../../../core-components/theme/components/Button/Iconbutton';
import CustomTreeComponent from '../../../../core-components/theme/components/Tree/CustomTree';
import TwoRowLayout from '../../../../core-components/theme/layouts/TwoLayout/TwoLayoutComponent';
import ConfirmationDialog from '../../../../core-components/theme/components/DialogBox/ConfirmationDialog';
import { Neutrals } from '../../../../core-components/theme/color-palette/colors';
import { getUserItemlabel } from '../../helpers/customUserTreeHelpers';
import CustomUserGroupTreeItem from '../../../userGroup/component/CustomUserGroupTree';
import { getPermissionListForUser } from '../../../../services/role';

const OptionsMenu: React.FC<{
  row: any;
  handleEditRole: any;
  handleDeleteRole: any;
  assets: any[];
  administrative: any[];
  setAssets: any;
  setAdministrative: any;
}> = ({
  row,
  handleEditRole,
  assets,
  administrative,
  setAssets,
  setAdministrative,
  handleDeleteRole,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const [Confirmationopen, setConfirmationOpen] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    handleMenuClose();
  };
  const handleConfirmationOpen = () => {
    setConfirmationOpen(true);
  };
  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
    handleMenuClose();
  };
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAssets([]);
    setAdministrative([]);
    setAnchorEl(null);
  };
  const {
    data: rolepermissionList,
    status: permissionListStatus,
    refetch,
  } = useQuery({
    queryKey: ['permissionList'],
    queryFn: () => getPermissionListForUser({ mode: 'details', roleId: row._id }),
    enabled: false,
  });
  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open]);
  useEffect(() => {
    if (permissionListStatus === 'success') {
      const { assets, administrative } = rolepermissionList.data[0];
      setAssets(assets);
      setAdministrative(administrative);
    }
  }, [rolepermissionList, permissionListStatus]);
  return (
    <>
      <CustomIconButton onClick={handleMenuOpen} icon={MoreVertIcon} />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        slotProps={{
          paper: {
            style: {
              boxShadow: '0px 2px 8px 0px #1A335626',
            },
          },
        }}
        MenuListProps={{
          sx: {
            border: `1px solid ${Neutrals[300]}`,
            py: 0,
            backgroundColor: '#ffffff',
            borderRadius: '4px',
          },
        }}
      >
        <MenuItem
          onClick={handleOpen}
          sx={{
            paddingLeft: '8px',
            height: '36px',
            borderBottom: `1px solid ${Neutrals[300]}`,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <DetailIcon />
          <Typography ml={2} pr={2} color={Neutrals[500]}>
            Details
          </Typography>
        </MenuItem>
        <MenuItem
          sx={{
            height: '36px',
            paddingLeft: '8px',
            borderBottom: `1px solid ${Neutrals[300]}`,
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={() => handleEditRole(row._id)}
        >
          <EditIcon />
          <Typography ml={2} pr={2} color={Neutrals[500]}>
            Edit
          </Typography>
        </MenuItem>
        <MenuItem
          sx={{
            height: '36px',
            paddingLeft: '8px',
            borderBottom: `1px solid ${Neutrals[300]}`,
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={handleConfirmationOpen}
        >
          <DeleteFilledIcon />
          <Typography ml={2} pr={2} color={Neutrals[500]}>
            Delete
          </Typography>
        </MenuItem>
        <ConfirmationDialog
          open={Confirmationopen}
          handleClose={handleConfirmationClose}
          title="Delete User Role"
          description={
            <Typography variant="h4" lineHeight="20px">
              Are you sure you want to delete{' '}
              <Typography component="span" variant="highlight">
                {row.name}
              </Typography>
              ? You can’t undo this action
            </Typography>
          }
          actions={[
            {
              text: 'Cancel',
              color: 'white',
              textColor: '#8B8B8B',
              handler: () => console.log('Cancel clicked'), // Todo: Please fix the handler
            },
            {
              text: 'Delete',
              color: 'SemanticError.dark',
              textColor: 'white',
              handler: () => handleDeleteRole(row._id),
            },
          ]}
        ></ConfirmationDialog>
        <DialogBox open={open} handleClose={handleClose} title={row.name}>
          <Box>
            <TwoRowLayout
              style={{ height: '60vh' }}
              child1={
                <Stack direction="column" gap={2}>
                  <Typography lineHeight="20px" variant="h4">
                    {row.description}
                  </Typography>
                  <Stack mt={2} direction={isMobile ? 'column' : 'row'} gap={2}>
                    <Typography variant="h4">
                      <b>Role Type:</b> {row.roleType}
                    </Typography>
                    <Typography variant="h4">
                      <b>Role Scope:</b> {row.roleScope}
                    </Typography>
                  </Stack>
                </Stack>
              }
              child2={
                <CustomTreeComponent
                  CustomTreeItem={CustomUserGroupTreeItem}
                  getItemId={item => item.itemId}
                  getItemlabel={getUserItemlabel}
                  items={row.roleType === 'Asset' ? assets : administrative}
                />
              }
            />
          </Box>
        </DialogBox>
      </Menu>
    </>
  );
};

export default OptionsMenu;
