import React from 'react';
import { Box, Stack, IconButton } from '@mui/material';

interface IconConfig {
  icon: React.ReactElement;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  showIcon?: boolean;
}

interface GeneralisedPopupToolBarProps {
  open: boolean;
  iconConfigs: IconConfig[];
  enableToolBar?: boolean;
}

export default function GeneralisedPopupToolBar(props: GeneralisedPopupToolBarProps) {
  const { open, iconConfigs, enableToolBar = true } = props;

  if (open && enableToolBar)
    return (
      <Box
        position="relative"
        sx={{
          width: 'calc(100% - 1px)',
        }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          py={1}
          sx={{
            background: '#F4F5F5',
            width: 'inherit',
            top: 1,
            left: 1,
            zIndex: 1000,
            position: 'absolute',
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
          }}
        >
          {iconConfigs.map((config, index) =>
            config.showIcon !== false ? (
              <IconButton key={index} onClick={config.onClick}>
                {config.icon}
              </IconButton>
            ) : null
          )}
        </Stack>
      </Box>
    );
  else {
    return null;
  }
}
