// ^ Unified Dashboard Page Configuration Page
import React from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Box, Stack, IconButton, TextField, Tooltip } from '@mui/material';
import LayoutComponent from '../components/LayoutComponent';
import { useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import lodash from 'lodash';
import { toast } from 'react-toastify';
import WidgetConfigComponentV3 from '../components/widgetConfigComponentsV3/widgetConfigComponentV3';
import { lastMovedWidgetInterface } from '../Interfaces/pageConfigPageInterfaces';
import { BlackButton } from '../styles/global/components/dpButtons';
import {
  findClusterIdByPageId,
  getPageConfigDataV2,
  saveUnifiedPageConfigDataV2,
} from '../services/unifiedPageConfig/unifiedPageConfig';
import {
  AnalyticsWidget,
  HeadingWidget,
  IInflatedDashboadPage,
  IInflatedWidgetCluster,
  MapWidget,
  SankeyWidget,
} from '../Interfaces/newDashboardInterfaces/inflatedDashbaordPage.interfaces';
import { getSensorListForUserId } from '../services/sensors';
import { getMasterCustomWidget, getMasterMapWidget } from '../services/dashboard/widget';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteConfirmation from '../globalComponents/dialogs/DeleteConfirmation';
import { deleteDashboardPage, clonePageConfig } from '../services/DashboardPageList';
import { delay } from '../utils/time.utils';
import { TIME_UNITS } from '../utils/time.utils';
import HeadingWidgetConfig from '../components/widgetConfigComponentsV2/HeadingWidgetConfig';
import RefreshPageComponent from '../utilities/RefreshPage/RefreshPageComponent';
import useIsMobile from '../utilities/customHooks/useIsMobile';
import useIsTablet from '../utilities/customHooks/useIsTablet';
import RefreshPageVariables from '../utilities/RefreshPage/RefreshPageVariables';
// import WidgetSelectPopup from '../components/widgetConfigComponentsV3/WidgetSelectPopup';
// import WidgetSelectPopup from '../components/widgetConfigComponentsV3/WidgetSelectPopup';
import WidgetSelectPopup from '../components/DnDWidgetConfig/WidgetSelectPopup';
import MapWidgetConfig from '../components/MapWidgetConfig/MapWidgetConfig';
import SankeyWidgetConfig from '../components/SankeyWidgetConfig/SankeyWidgetConfig';

const styles = {
  pageConfigLoadingView: {
    width: '100%',
    height: '200px',
    display: 'grid',
    placeItems: 'center',
  },
  pageConfigHeader: {
    background: '#eee',
  },
  pageConfigHeaderLeftPart: {
    width: { xs: '90%', sm: '75%' },
    background: 'red',
  },
  addWidgetIcon: {
    marginRight: '20px',
  },
  widgetClusterHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '10px',
    boxSizing: 'border-box',
  },
  saveButtonLoadingCircle: {
    marginLeft: '10px',
    color: '#ddd',
  },
};

export default function UnifiedPageConfig() {
  const {
    expireTime,
    setExpireTime,
    checkboxChecked,
    setCheckboxChecked,
    customRefreshInterval,
    setCustomRefreshInterval,
    enableCustomRefreshOnly,
    setEnableCustomRefreshOnly,
  } = RefreshPageVariables();

  const refreshProps = {
    checkboxChecked,
    customRefreshInterval,
    enableCustomRefreshOnly,
  };

  const { pageId } = useParams();
  const navigate = useNavigate();
  const [pageData, setPageData] = useState<IInflatedDashboadPage['pageConfig'] | null>(null);
  const [completePageData, setCompletePageData] = useState<any>(null);
  //@ts-ignore
  const [previousPageState, setPreviousPageState] = useState<any>(null);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [lastMovedWidget, setLastMovedWidget] = useState<lastMovedWidgetInterface>({
    widgetName: '',
    direction: '',
  });
  const [sensorList, setSensorList] = useState<any[]>([]);
  const [customWidget, setCustomWidget] = useState<any>(null);
  const [initialPageData, setInitialPageData] = useState<any>(null);
  const [deletedWidgets, setDeletedWidgets] = useState<any[]>([]);
  const [showPageDeleteModal, setShowPageDeleteModal] = useState<boolean>(false);
  const [
    //@ts-ignore
    processingDeleteConfirmationCallBackBack,
    setProcessingDeleteConfirmationCallBack,
  ] = useState<boolean>(false);
  const [clusterId, setClusterId] = useState<string | null>(null);
  const [openWidgetSelectPopup, setOpenWidgetSelectPopup] = useState(false);
  const [selectedWidgetClusterIndex, setSelectedWidgetClusterIndex] = useState(0);
  const [masterMapWidget, setMasterMapWidget] = useState<any>(null);
  const [masterSankeyWidget, setMasterSankeyWidget] = useState<any>(null);

  useEffect(() => {
    getPageConfigDataV2(pageId!, 'pageConfig', 'ALL').then(data => {
      setCheckboxChecked(data?.refreshProps?.checkboxChecked);
      setCustomRefreshInterval(data?.refreshProps?.customRefreshInterval);
      setEnableCustomRefreshOnly(
        localStorage.getItem('enableCustomRefreshOnly') === 'true'
          ? true
          : data?.refreshProps?.enableCustomRefreshOnly
      );
      setCompletePageData(data);
      setPageData(data?.pageConfig);
      setInitialPageData(lodash.cloneDeep(data?.pageConfig));
      findClusterIdByPageId(data?._id).then(data => {
        setClusterId(data);
      });
      setLoading(false);
      setRefresh(!refresh);
    });

    getSensorListForUserId().then((data: any) => {
      setSensorList(data);
      setRefresh(!refresh);
    });
    getMasterCustomWidget('graph', 'ALL').then(data => {
      setCustomWidget(data);
    });
    getMasterMapWidget().then(data => {
      setMasterMapWidget(data);
    });
    getMasterCustomWidget('SankeyWidget', 'ALL_GRID').then(data => {
      setMasterSankeyWidget(data);
    });
    setRefresh(!refresh);
  }, []);

  const savePageConfig = () => {
    if (checkboxChecked && (customRefreshInterval < 60 || customRefreshInterval > 600)) {
      toast.error('Refresh range should be between 60 to 600 seconds');
      return;
    }
    setSaving(true);
    const isStateChanged = lodash.isEqual(initialPageData, pageData);
    const saveHistory = !isStateChanged;

    saveUnifiedPageConfigDataV2(
      pageId!,
      completePageData,
      deletedWidgets,
      saveHistory,
      refreshProps
    ).then(data => {
      setSaving(false);
      navigate(`/UnifiedDashboardPageList`);
    });
  };

  const changePageProperty = (property: string, value: any) => {
    if (pageData != null) {
      pageData[property] = value;
      setPageData(pageData);
      setRefresh(!refresh);
    }
  };

  const addCustomWidget = (
    widgetClusterIndex: number,
    plantIds: string[],
    plantName: string,
    selectedWidgetType: string
  ) => {
    console.log('widgetClusterIndex : ', widgetClusterIndex);
    let csWidgetCount = pageData!.data[widgetClusterIndex].widgets.reduce(
      (total: any, currentWidget: any) => {
        if (currentWidget.widgetOrigin == 'custom') return total + 1;
        else return total;
      },
      0
    );

    let newCustomWidget = lodash.cloneDeep(customWidget);
    newCustomWidget['widgetName'] = 'Custom Widget ' + (csWidgetCount + 1);
    newCustomWidget['widgetNickName'] = 'Custom Widget' + (csWidgetCount + 1);
    newCustomWidget['plantIds'] = plantIds;
    newCustomWidget['plantName'] = plantName;
    newCustomWidget['widgetType'] = selectedWidgetType;

    pageData?.data[widgetClusterIndex].widgets.push(newCustomWidget);
    console.log('pageData : ', pageData);
    toast(`Added new custom widget`);
    setRefresh(!refresh);
  };

  const addCustomMapWidget = (widgetClusterIndex: number, plantsForUser: any[]) => {
    console.log('inside add map widget : ', plantsForUser);
    let csWidgetCount = pageData!.data[widgetClusterIndex].widgets.reduce(
      (total: any, currentWidget: any) => {
        if (currentWidget.widgetType == 'MapWidget') return total + 1;
        else return total;
      },
      0
    );

    let newMapWidget = lodash.cloneDeep(masterMapWidget);
    newMapWidget['widgetName'] = 'Map Widget ' + (csWidgetCount + 1);
    newMapWidget.plants = lodash.cloneDeep(plantsForUser);

    pageData?.data[widgetClusterIndex].widgets.push(newMapWidget);
    toast(`Added new custom Map widget`);
    setRefresh(!refresh);
  };

  const moveWidgetPosition = (
    widgetClusterIndex: number,
    widgetIndex: number,
    action: string
  ): void => {
    let totalWidgetCount = pageData?.data[widgetClusterIndex].widgets.length || 0;
    let tempPageData = pageData!;
    let currWidget = lodash.clone(tempPageData.data[widgetClusterIndex].widgets[widgetIndex]);
    if (action == 'up') {
      if (widgetIndex > 1) {
        // because widget 0 is heading
        let upWidget = lodash.clone(tempPageData.data[widgetClusterIndex].widgets[widgetIndex - 1]);
        tempPageData.data[widgetClusterIndex].widgets[widgetIndex] = upWidget;
        tempPageData.data[widgetClusterIndex].widgets[widgetIndex - 1] = currWidget;
      } else {
        toast('Cannot go any higher');
      }
      setLastMovedWidget({
        widgetName: currWidget.widgetName,
        direction: 'up',
      });
    } else if (action == 'down') {
      if (widgetIndex < totalWidgetCount - 1) {
        let downWidget = lodash.clone(
          tempPageData.data[widgetClusterIndex].widgets[widgetIndex + 1]
        );
        tempPageData.data[widgetClusterIndex].widgets[widgetIndex] = downWidget;
        tempPageData.data[widgetClusterIndex].widgets[widgetIndex + 1] = currWidget;
      } else {
        toast('Cannot go any lower');
      }
      setLastMovedWidget({
        widgetName: currWidget.widgetName,
        direction: 'down',
      });
    }

    /// reset widgetOrder
    tempPageData.data[widgetClusterIndex].widgets.forEach((widget, widgetIndex: number) => {
      widget.widgetOrder = widgetIndex;
    });

    setPageData(tempPageData);

    setRefresh(!refresh);
  };

  const changeWidgetClusterProperty = (
    widgetClusterIndex: number,
    property: string,
    value: any
  ) => {
    if (pageData) {
      pageData.data[widgetClusterIndex][property] = value;
      setPageData(pageData);
      setRefresh(!refresh);
    }
  };

  const handleSelectAllCheckboxChange = (event: any) => {
    let previousPageState: any = lodash.clone(pageData);
    if (pageData !== null) {
      pageData.data.forEach((widgetCluster: any) => {
        widgetCluster.showWidgetCluster = event.target.checked;
        widgetCluster.widgets.forEach((widget: any) => {
          widget.checked = event.target.checked;
        });
      });
    }
    setPreviousPageState(previousPageState);
    setPageData(pageData);
  };

  // const changeDefaultGranularitySettings = (property: string, value: any) => {
  //   if (pageData != null) {
  //     pageData.defaultGranularitySettings[property] = value;
  //     setPageData(pageData);
  //     setRefresh(!refresh);
  //   }
  // };
  const changeDefaultTimeRangeSettings = (property: string, value: any) => {
    if (pageData != null) {
      pageData.defaultTimeRangeSettings[property] = value;
      setPageData(pageData);
      setRefresh(!refresh);
    }
  };

  const deletePage = async () => {
    let res: any = await deleteDashboardPage(clusterId!, pageId!);
    if (res.data) {
      let { data } = res;
      toast(data.data.message);
      await delay(1000);
      navigate(`/UnifiedDashboardPageList`);
    }
  };

  const clonePage = async () => {
    let res: any = await clonePageConfig(clusterId!, pageId!);
    if (res.data) {
      let { data } = res;
      toast(data?.data?.message);
      await delay(1000);
      navigate(`/UnifiedDashboardPageList`);
    }
  };

  const changeHeadingWidgetHeading = async (
    widgetClusterIndex: number,
    widgetIndex: number,
    language: string,
    value: string
  ) => {
    if (pageData != null) {
      pageData.data[widgetClusterIndex].widgets[widgetIndex].heading[language] = value;
      setPageData(pageData);
      setRefresh(!refresh);
    }
  };

  const addCustomSankeyWidget = (widgetClusterIndex: number) => {
    let csWidgetCount = pageData!.data[widgetClusterIndex].widgets.reduce(
      (total: any, currentWidget: any) => {
        if (currentWidget.widgetType == 'SankeyWidget') return total + 1;
        else return total;
      },
      0
    );

    let newWidget = lodash.cloneDeep(masterSankeyWidget);
    newWidget['widgetName'] = 'Sankey Widget ' + (csWidgetCount + 1);
    pageData?.data[widgetClusterIndex].widgets.push(newWidget);
    toast(`Added new custom Sankey widget`);
    setRefresh(!refresh);
  };

  const isMobile = useIsMobile({ breakpointValue: 'sm' });
  const isTablet = useIsTablet();

  if (loading)
    return (
      <LayoutComponent plantId={null}>
        <h1>Page Configuration</h1>
        <Box sx={styles.pageConfigLoadingView}>
          <CircularProgress />
        </Box>
      </LayoutComponent>
    );
  else if (pageData)
    return (
      <LayoutComponent plantId={null}>
        {isMobile ? (
          <>
            <Stack direction="row" justifyContent="space-between" alignItems="center" mt={3} mb={2}>
              <h1
                style={{
                  margin: 0,
                  marginTop: '-4px',
                  fontSize: '1.5rem',
                  color: '#1A3356',
                }}
              >
                Page Configuration
              </h1>
              <Stack direction="row">
                <Tooltip title="Clone page">
                  <IconButton
                    onClick={() => {
                      clonePage();
                    }}
                    color="inherit"
                  >
                    <FileCopyIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete page">
                  <IconButton
                    onClick={() => {
                      setShowPageDeleteModal(true);
                    }}
                    color="inherit"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
            <Stack mb={2}>
              <RefreshPageComponent
                expireTime={expireTime}
                setExpireTime={setExpireTime}
                checkboxChecked={checkboxChecked}
                setCheckboxChecked={setCheckboxChecked}
                customRefreshInterval={customRefreshInterval}
                setCustomRefreshInterval={setCustomRefreshInterval}
                enableCustomRefreshOnly={enableCustomRefreshOnly}
                setEnableCustomRefreshOnly={setEnableCustomRefreshOnly}
              />
            </Stack>
          </>
        ) : (
          <Stack direction="row" justifyContent="space-between" alignItems="center" mt={3} mb={2}>
            <Stack direction="row" gap={isTablet ? 2 : 4}>
              <h1
                style={{
                  margin: '0',
                  marginTop: isTablet ? '6px' : '2px',
                  fontSize: isTablet ? '1.6rem' : '2rem',
                  color: '#1A3356',
                }}
              >
                Page Configuration
              </h1>
              <RefreshPageComponent
                expireTime={expireTime}
                setExpireTime={setExpireTime}
                checkboxChecked={checkboxChecked}
                setCheckboxChecked={setCheckboxChecked}
                customRefreshInterval={customRefreshInterval}
                setCustomRefreshInterval={setCustomRefreshInterval}
                enableCustomRefreshOnly={enableCustomRefreshOnly}
                setEnableCustomRefreshOnly={setEnableCustomRefreshOnly}
              />
            </Stack>
            <Stack direction="row">
              <Tooltip title="Clone page">
                <IconButton
                  onClick={() => {
                    clonePage();
                  }}
                  color="inherit"
                >
                  <FileCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete page">
                <IconButton
                  onClick={() => {
                    setShowPageDeleteModal(true);
                  }}
                  color="inherit"
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        )}
        <Stack
          borderRadius={5}
          border="1px solid #BDBDBD"
          direction={{ xs: 'column', md: 'row' }}
          justifyContent={{ xs: 'flex-start', sm: 'space-around' }}
          gap={{ xs: 1, md: 2 }}
          alignItems="center"
          mb={2}
          padding={2}
          sx={styles.pageConfigHeader}
          className="pageConfigHeader"
        >
          <TextField
            fullWidth
            inputProps={{ style: { fontSize: '16px' } }}
            id="outlined-basic"
            label="Page Name"
            variant="outlined"
            key="pageName"
            defaultValue={pageData.pageName}
            onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
              changePageProperty('pageName', e.target.value);
            }}
          />
          <Box
            sx={{
              width: { xs: '100%', md: '500px' },
            }}
          >
            <FormControl
              sx={{
                width: '100%',
              }}
            >
              <InputLabel id="demo-simple-select-label">Time</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={pageData.defaultTimeInDays}
                value={pageData.defaultTimeInDays}
                label="page"
                onChange={e => {
                  let days: any = e.target.value;
                  let timeInMinutes: any = TIME_UNITS['days'].timeInMinutes * Math.abs(days); // timeInMinutes is always positive
                  changePageProperty('defaultTimeInDays', e.target.value);

                  changeDefaultTimeRangeSettings('unit', 'days');
                  changeDefaultTimeRangeSettings('unitMultiplier', days);
                  changeDefaultTimeRangeSettings('timeRangeInMins', timeInMinutes);
                }}
                disabled={pageData?.defaultTimeRangeSettings?.timeRangeType === 'absolute'}
                style={{ fontSize: '16px', fontWeight: 'normal' }}
              >
                <MenuItem value={-1}>Yesterday</MenuItem>
                <MenuItem value={0}>Today</MenuItem>
                <MenuItem value={1}>Last 24 Hours</MenuItem>
                <MenuItem value={3}>3 Days</MenuItem>
                <MenuItem value={7}>7 Days</MenuItem>
                <MenuItem value={10}>10 Days</MenuItem>
                <MenuItem value={30}>1 Months</MenuItem>
                <MenuItem value={180}>6 Months</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Stack
            m={1}
            direction="column"
            style={{
              minWidth: '220px',
              marginLeft: 'auto',
            }}
          >
            <Box>
              <Checkbox
                checked={pageData.public}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  changePageProperty('public', e.target.checked);
                }}
              />
              <span>Is this a public page ?</span>
            </Box>
            <Box>
              <Checkbox
                checked={pageData.showEmbeddedLinks}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  changePageProperty('showEmbeddedLinks', e.target.checked);
                }}
              />
              <span>Show Embedded Links ?</span>
            </Box>
            {/* <Box borderRadius={5} sx={{ overflow: "hidden" }}>
              <Accordion elevation={0}>
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  // expandIcon={<ExpandMoreIcon />}
                  expandIcon={<SettingsIcon fontSize="small" />}
                >
                  Default Time Range Settings
                </AccordionSummary>
                <AccordionDetails>
                  <PageLevelTimeRangeSettings
                    timeRangeSettings={pageData?.defaultTimeRangeSettings}
                    changeTimeRangeSettings={changeDefaultTimeRangeSettings}
                  />
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box borderRadius={5} sx={{ overflow: "hidden" }}>
              <Accordion>
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  // expandIcon={<ExpandMoreIcon />}
                  expandIcon={<SettingsIcon fontSize="small" />}
                >
                  Default Granularity Settings
                </AccordionSummary>
                <AccordionDetails>
                  <PageLevelGranularitySettings
                    granularitySettings={pageData?.defaultGranularitySettings}
                    changeGranularitySettings={changeDefaultGranularitySettings}
                    timeRangeSettings={pageData?.defaultTimeRangeSettings}
                  />
                </AccordionDetails>
              </Accordion>
            </Box> */}
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={1}>
          <Checkbox onChange={handleSelectAllCheckboxChange} />
          <Typography variant="body1" sx={{ fontWeight: '500' }}>
            Select All
          </Typography>
        </Stack>

        {pageData.data.map((widgetCluster: IInflatedWidgetCluster, widgetClusterIndex: number) => {
          return (
            <>
              {widgetCluster.widgets.map(
                (
                  widget: AnalyticsWidget | HeadingWidget | MapWidget | SankeyWidget,
                  widgetIndex: number
                ) => {
                  if (widget.widgetType === 'heading') {
                    return (
                      <Box sx={styles.widgetClusterHeader}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Checkbox
                            checked={widgetCluster.showWidgetCluster}
                            onChange={event => {
                              changeWidgetClusterProperty(
                                widgetClusterIndex,
                                'showWidgetCluster',
                                event.target.checked
                              );
                            }}
                          />
                          <HeadingWidgetConfig
                            widgetClusterIndex={widgetClusterIndex}
                            widgetIndex={widgetIndex}
                            text={widget.heading?.english}
                            changeHeadingWidgetHeading={changeHeadingWidgetHeading}
                          />
                        </Stack>

                        <Tooltip title="Add Widget">
                          <IconButton style={styles.addWidgetIcon}>
                            <AddIcon
                              fontSize="small"
                              onClick={() => {
                                // addCustomWidget(widgetClusterIndex);
                                setSelectedWidgetClusterIndex(widgetClusterIndex);
                                setOpenWidgetSelectPopup(true);
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    );
                  } else if (widget.widgetType == 'MapWidget') {
                    return (
                      <MapWidgetConfig
                        key={widget?.widgetName + widgetIndex}
                        pageData={pageData}
                        setPageData={setPageData}
                        widgetCluster={widgetCluster.clusterName}
                        widgetClusterIndex={widgetClusterIndex}
                        //@ts-ignore
                        widget={widget}
                        widgetIndex={widgetIndex}
                        moveWidgetPosition={moveWidgetPosition}
                        lastMovedWidget={lastMovedWidget}
                        sensorList={sensorList}
                        refresh={refresh}
                        setRefresh={setRefresh}
                        setDeletedWidgets={setDeletedWidgets}
                      />
                    );
                  } else if (widget.widgetType == 'SankeyWidget') {
                    return (
                      <SankeyWidgetConfig
                        key={widget?.widgetName + widgetIndex}
                        pageData={pageData}
                        setPageData={setPageData}
                        widgetCluster={widgetCluster.clusterName}
                        widgetClusterIndex={widgetClusterIndex}
                        //@ts-ignore
                        widget={widget}
                        widgetIndex={widgetIndex}
                        moveWidgetPosition={moveWidgetPosition}
                        lastMovedWidget={lastMovedWidget}
                        sensorList={sensorList}
                        refresh={refresh}
                        setRefresh={setRefresh}
                        setDeletedWidgets={setDeletedWidgets}
                      />
                    );
                  } else {
                    return (
                      <WidgetConfigComponentV3
                        key={widget?.widgetName + widgetIndex}
                        pageData={pageData}
                        setPageData={setPageData}
                        widgetCluster={widgetCluster.clusterName}
                        widgetClusterIndex={widgetClusterIndex}
                        //@ts-ignore
                        widget={widget}
                        widgetIndex={widgetIndex}
                        moveWidgetPosition={moveWidgetPosition}
                        lastMovedWidget={lastMovedWidget}
                        sensorList={sensorList}
                        refresh={refresh}
                        setRefresh={setRefresh}
                        setDeletedWidgets={setDeletedWidgets}
                      />
                    );
                  }
                  // end of return for widget
                }
              )}
            </>
          ); //  end of return for widget cluster
        })}

        <BlackButton
          style={{ minWidth: '100px' }}
          onClick={() => {
            savePageConfig();
          }}
        >
          {saving ? (
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <span>Saving</span>
              <CircularProgress size="20px" thickness={5} sx={styles.saveButtonLoadingCircle} />
            </span>
          ) : (
            <span>SAVE</span>
          )}
        </BlackButton>

        <DeleteConfirmation
          showDeleteModal={showPageDeleteModal}
          setShowDeleteModal={setShowPageDeleteModal}
          alertMessage="Are you sure you want to delete this page ?"
          processing={false}
          setProcessing={setProcessingDeleteConfirmationCallBack}
          onConfirmAction={() => {
            deletePage();
          }}
        />
        {/* <WidgetSelectPopup
          open={openWidgetSelectPopup}
          onSelect={(selectedWidgetType: string, selectedPlants: any, plantName: string) => {
            console.log(selectedWidgetType);
            if (selectedWidgetType == 'GeneralisedWidget')
              addCustomWidget(selectedWidgetClusterIndex, selectedPlants, plantName);
            else addCustomMapWidget(selectedWidgetClusterIndex, selectedPlants);
          }}
          onClose={() => {
            setOpenWidgetSelectPopup(false);
          }}
        /> */}
        <WidgetSelectPopup
          open={openWidgetSelectPopup}
          onSelect={(
            selectedWidgetType: string,

            selectedPlants: any,
            plantName: string
          ) => {
            console.log(selectedWidgetType);
            if (
              ['graph', 'tableC', 'number', 'number2', 'NumberGauge'].includes(selectedWidgetType)
            )
              addCustomWidget(
                selectedWidgetClusterIndex,
                selectedPlants,
                plantName,
                selectedWidgetType
              );
            else if (selectedWidgetType == 'MapWidget')
              addCustomMapWidget(selectedWidgetClusterIndex, selectedPlants);
            else if (selectedWidgetType == 'SankeyWidget') {
              addCustomSankeyWidget(selectedWidgetClusterIndex);
            }
          }}
          onClose={() => {
            setOpenWidgetSelectPopup(false);
          }}
          widgetOptions={[
            { label: 'Graph Widget', value: 'graph' },
            { label: 'Table Widget', value: 'tableC' },
            { label: 'Number Widget', value: 'number' },
            { label: 'Range Number Widget', value: 'number2' },
            { label: 'Gauge Number Widget', value: 'NumberGauge' },
            { label: 'Map Widget', value: 'MapWidget' },
            { label: 'Sankey Widget', value: 'SankeyWidget' },
          ]}
        />
      </LayoutComponent>
    );
  else return null; //  end of return for page component
}
