import {
  Hibiscus,
  Greeny,
  Primary,
  PremiumAddUp,
} from '../core-components/theme/color-palette/colors';

const WIDGET_SIZE_OPTIONS: any = {
  number: [{ label: '2 x 2', w: 4, h: 4 }],
  number2: [{ label: '2 x 2', w: 4, h: 4 }],
  NumberGauge: [{ label: '2 x 2', w: 4, h: 4 }],
  graph: [
    { label: '4 x 2', w: 8, h: 4 },
    { label: '4 x 4', w: 8, h: 8 },
    { label: '6 x 4', w: 12, h: 8 },
    { label: '8 x 4', w: 16, h: 8 },
  ],
  tableC: [
    { label: '4 x 2', w: 8, h: 4 },
    { label: '4 x 4', w: 8, h: 8 },
    { label: '6 x 4', w: 12, h: 8 },
    { label: '8 x 4', w: 16, h: 8 },
    { label: '4 x 6', w: 8, h: 12 },
    { label: '4 x 8', w: 8, h: 16 },
  ],
  MapWidget: [
    { label: '4 x 2', w: 8, h: 4 },
    { label: '4 x 4', w: 8, h: 8 },
    { label: '6 x 4', w: 12, h: 8 },
    { label: '8 x 4', w: 16, h: 8 },
  ],
  SankeyWidget: [
    { label: '4 x 2', w: 8, h: 4 },
    { label: '4 x 4', w: 8, h: 8 },
    { label: '6 x 4', w: 12, h: 8 },
    { label: '8 x 4', w: 16, h: 8 },
  ],
  ManualEntryWidget: [
    { label: '6 x 4', w: 12, h: 8 },
    { label: '8 x 4', w: 16, h: 8 },
    { label: '12 x 4', w: 24, h: 8 },
  ],
};

const WIDGET_THEMES: any = {
  Hibiscus: {
    fontColor: Hibiscus[900],
    backgroundColor: Hibiscus[500],
    borderColor: Hibiscus[500],
    lightBorderColor: Hibiscus[200],
    lightBackgroundColor: Hibiscus[50],
    displayName: 'Hibiscus',
  },
  OceanGreen: {
    fontColor: Greeny[900],
    backgroundColor: Greeny[500],
    borderColor: Greeny[500],
    lightBorderColor: Greeny[200],
    lightBackgroundColor: Greeny[50],
    displayName: 'Ocean Green',
  },
  VividVoilet: {
    fontColor: PremiumAddUp[900],
    backgroundColor: PremiumAddUp[500],
    borderColor: PremiumAddUp[500],
    lightBorderColor: PremiumAddUp[200],
    lightBackgroundColor: PremiumAddUp[50],
    displayName: 'Vivid Voilet',
  },
  Blue: {
    fontColor: Primary[900],
    backgroundColor: Primary[500],
    borderColor: Primary[500],
    lightBorderColor: Primary[200],
    lightBackgroundColor: Primary[50],
    displayName: 'Blue',
  },
  // default: {
  //   fontColor: Greeny[900],
  //   backgroundColor: Greeny[500],
  //   borderColor: Greeny[500],
  //   lightBorderColor: Greeny[200],
  //   lightBackgroundColor: Greeny[50],
  //   displayName: 'default',
  // },
};

export { WIDGET_SIZE_OPTIONS, WIDGET_THEMES };
