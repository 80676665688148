/* eslint-disable @typescript-eslint/no-unused-vars */
// import React from "react";
import { useEffect, useState } from 'react';
import './Map.css';
import { Grid, ThemeProvider } from '@mui/material';
import Box from '@mui/material/Box';

import LayoutComponent from '../components/LayoutComponent';
// import { useNavigate } from "react-router";
import axiosInstance from '../constants/axiosInstance';
import GeneralisedWidget from '../components/GeneralisedWidgetComponents/GeneralisedWidget';
import TaskViewForDp from '../components/TaskViewForDp/TaskViewForDp';
import CustomSwipableDrawer from '../components/CustomSwipableDrawer/CustomSwipableDrawer';
import { useLocation, useParams } from 'react-router-dom';
import waterQuantityIcon from '../constants/Images/waterQuantityIcon.svg';
import waterQualityIcon from '../constants/Images/waterQualityIcon.svg';
import safetyIcon from '../constants/Images/safetyIcon.svg';
import biologicalProcessIcon from '../constants/Images/biologicalProcessIcon.svg';
import energyIcon from '../constants/Images/energyIcon.svg';
import equipmentMaintenanceIcon from '../constants/Images/equipmentMaintenanceIcon.svg';
import CircularProgress from '@mui/material/CircularProgress';
import classes from '../styles/dashboardPage.module.css';
import ErrorBoundary from '../components/errorBoundary/errorBoundary';
// import {
//   dashboardPageInterface,
//   pageDropdownInterface,
//   timeFrame,
// } from "../Interfaces/dashboardPageInterfaces";
import AIWidget from '../components/GeneralisedWidgetComponents/AIWidget';
import { getDashboardClusters, getDashboardPageData } from '../services/dashboardPage';
import { createNewDashboard } from '../services/dashboardPage';
import { toast } from 'react-toastify';
import { usePlantLayoutStore } from '../store/PlantLayoutStore';
import DashboardToolbar from '../components/Dashboard2/DashboardToolbar';
import CustomDialog from '../globalComponents/dialogs/CustomDialog';
import DateAndTime from '../globalComponents/dateAndTime/DateAndTime';
import BottomBar from '../components/BottomBarComponent/BottomBarComponent';
import EditComponentDialog from '../globalComponents/dialogs/EditComponentDialog';
import { calculateTimeFrame } from '../utilities/helpers';
import { GRANULARITY, TIME_RANGE } from '../constants/constants';
import dpTheme from '../styles/theme/dpTheme';
import { add530Hours } from '../utils/dashboard.utils';
import { updateTimeFrameForRefId } from '../services/commonApis';
import { getPlantData } from '../services/plant';
import RefreshPageVariables from '../utilities/RefreshPage/RefreshPageVariables';
import RefreshPage from '../utilities/RefreshPage/RefreshPage';
import { isFeatureAccessibleByPlantId } from '../services/plantFeatureAuth';
import usePermissionCheck from '../utilities/customHooks/usePermissionCheck';
import { PERMISSION_TAGS } from '../constants/permissions';

const breakpoints = {
  mobile: '@media (max-width: 767px)',
  tablet: '@media (min-width: 768px) and (max-width: 1024px)',
};

const styles = {
  LayoutStyle: {
    background: '#F5F6F9',
    minHeight: window.innerHeight,
    padding: '20px 24px',
    [breakpoints.tablet]: {
      padding: '12px 10px',
    },
    [breakpoints.mobile]: {
      padding: '0px !important',
    },
  },
};

export default function DashboardPage() {
  const {
    expireTime,
    setExpireTime,
    checkboxChecked,
    setCheckboxChecked,
    customRefreshInterval,
    setCustomRefreshInterval,
    enableCustomRefreshOnly,
    setEnableCustomRefreshOnly,
  } = RefreshPageVariables();

  const { plantId } = useParams<{ plantId: string }>();

  //Fetching selected pageId from query param
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageId = queryParams.get('pageId') || null;

  //EditDialogBox is opened when SAVE AS is clicked !, Bottom bar is opened on every action
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isBottomBarOpen, setIsBottomBarOpen] = useState(false);

  //Loading and refreshing
  const [refresh, setRefresh] = useState<boolean>(false);
  const [refreshWidgets, setRefreshWidgets] = useState(false);
  const [loading, setLoading] = useState(true);

  //Show tasks
  const drawerWidth = 340;
  const [tasks, setTasks] = useState(null);
  //@ts-ignore
  const [controller, setController] = useState(new AbortController());

  //Dashboard toolbar
  const [dashboardClusters, setDashboardClusters] = useState<any>(null);

  //Dashboard related data
  const [dashboardPageData, setDashboardPageData] = useState<any>(null);
  const [pageWidgetCluster, setPageWidgetCluster] = useState<any>([]);
  const [showEmbeddedLinks, setShowEmbeddedLinks] = useState(false);
  const [embeddedLinks, setEmbeddedLinks] = useState([]);

  //User Actions
  const [selectedDashboardPage, setSelectedDashboardPage] = useState<any>(null);
  const [changedWidgets, setChangedWidgets] = useState<any>([]);
  const [dashboardSaveData, setDashboardSaveData] = useState<any>(null);
  const [dashboardDateAndTime, setDashboardDateAndTime] = useState({});
  const [newDashboardPage, setNewDashboardPage] = useState<any>({});
  const [selectedGranularity, setSelectedGranularity] = useState(GRANULARITY[1].label);
  const [selectedRelativeTimeRange, setSelectedTimeRange] = useState(TIME_RANGE[0].label);
  //Timeframe will be affected when time is set from global or widget level
  const [timeFrame, setTimeFrame] = useState<any | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [parent, setParent] = useState('dashboard');

  //Zustand
  const { setPlantId } = usePlantLayoutStore();

  const userId = localStorage.getItem('userid') || '';
  const [plantName, setPlantName] = useState<string>('');
  const [openAiSearchBar, setOpenAiSearchBar] = useState(false);
  const [showAiWidget, setShowAiWidget] = useState(false);
  const [featureAccess, setFeatureAccess] = useState({
    'AI analysis tool': false,
  });
  // const sidebarData = JSON.parse(localStorage.getItem("sideBar") || "[]");

  // if (
  //   Array.isArray(sidebarData) &&
  //   sidebarData.length > 0 &&
  //   typeof sidebarData[0] === "object" &&
  //   "name" in sidebarData[0]
  // ) {
  //   plantName = sidebarData[0].name;
  // }

  async function updatePlantName(plantId: string) {
    try {
      let response = await getPlantData(plantId);
      let plantNameToUpdate = response?.plantName;
      setPlantName(plantNameToUpdate);
    } catch (e) {
      const sidebarData = JSON.parse(localStorage.getItem('sideBar') || '[]');
      if (
        Array.isArray(sidebarData) &&
        sidebarData.length > 0 &&
        typeof sidebarData[0] === 'object' &&
        'name' in sidebarData[0]
      ) {
        let plantNameToUpdate = sidebarData[0].name;
        setPlantName(plantNameToUpdate);
      }
    }
  }
  const updatePageData = (
    dashboardPageData: any,
    defaultDashboardClusterId: any,
    defaultClusterName: any,
    defaultDashboardPage: any
  ) => {
    setPageWidgetCluster(dashboardPageData?.pageConfig?.data);
    setDashboardPageData(dashboardPageData);
    setShowEmbeddedLinks(dashboardPageData?.pageConfig?.showEmbeddedLinks);
    setEmbeddedLinks(dashboardPageData?.pageConfig?.embeddedLinks);

    let defaultGranularitySettings = dashboardPageData?.pageConfig?.defaultGranularitySettings;
    let defaultTimeRange = dashboardPageData?.pageConfig?.defaultTimeRangeSettings;
    let timeFrame = calculateTimeFrame(defaultTimeRange);
    setDashboardDateAndTime({
      dashboardId: defaultDashboardPage.pageId,
      defaultTimeRange,
    });

    setDashboardSaveData({
      dashboardPageId: defaultDashboardPage.pageId || '',
      selectedGranularity: {
        unit: defaultGranularitySettings.unit,
        unitMultiplier: 1,
      },
      selectedTimeRange: {
        unit: defaultTimeRange.unit,
        unitMultiplier: defaultTimeRange.unitMultiplier,
        timeRangeType: defaultTimeRange.timeRangeType,
        startTime: timeFrame ? timeFrame.startDate : new Date(),
        endTime: timeFrame ? timeFrame.endDate : new Date(),
      },
    });

    setNewDashboardPage({
      dashboardClusterId: defaultDashboardClusterId,
      currentClusterName: defaultClusterName,
      dashboardPageId: defaultDashboardPage.pageId,
      newPageName: defaultDashboardPage.label,
      changes: {
        dashboard: dashboardSaveData,
        widgets: changedWidgets,
      },
    });

    const { startDate: startDate530Ahead, endDate: endDate530Ahead } = add530Hours(
      timeFrame?.startDate,
      timeFrame?.endDate
    );
    updateTimeFrameForRefId(
      startDate530Ahead,
      endDate530Ahead,
      defaultDashboardPage.pageId,
      'dashboardPage'
    ).then(() => {});

    setTimeFrameDetails(dashboardPageData, defaultTimeRange, timeFrame);
  };

  const setTimeFrameDetails = (dashboardPageData: any, defaultTimeRange: any, timeFrame: any) => {
    let granularityValue = GRANULARITY[1].value;
    let granularityLabel = GRANULARITY[1].label;
    GRANULARITY.forEach(granularity => {
      if (
        granularity.value ==
        dashboardPageData?.pageConfig?.defaultGranularitySettings.unit.toLowerCase()
      ) {
        setSelectedGranularity(granularity.label);
        granularityValue = granularity.value;
        granularityLabel = granularity.label;
      }
    });

    let timeRangeLabel = TIME_RANGE[0].label;
    if (defaultTimeRange.timeRangeType == 'relative') {
      TIME_RANGE.forEach((obj: any) => {
        if (obj.value == defaultTimeRange.unitMultiplier && defaultTimeRange.unit == obj.unit) {
          setSelectedTimeRange(obj.label);
          timeRangeLabel = obj.label;
        }
      });
    } else {
      setSelectedTimeRange('Custom');
    }

    setTimeFrame({
      ...timeFrame,
      timeRangeType: defaultTimeRange.timeRangeType,
      granularity: granularityValue,
      granularityLabel,
      timeRangeLabel,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setPlantId(plantId!);

      const dashboardClusters = await getDashboardClusters(plantId!, userId!);
      setDashboardClusters(dashboardClusters);

      let defaultDashboardCluster: any = null;
      let defaultDashboardClusterId: string | null = null;
      let defaultClusterName: any = null;
      let defaultDashboardPage: any = null;

      outerLoop: for (let i = 0; i < dashboardClusters.length; i++) {
        const dashboardCluster = dashboardClusters[i];

        if (dashboardCluster?.files && dashboardCluster?.files.length > 0) {
          for (let j = 0; j < dashboardCluster.files.length; j++) {
            const file = dashboardCluster.files[j];

            if (pageId) {
              if (pageId === file.pageId) {
                defaultDashboardCluster = dashboardCluster;
                defaultDashboardClusterId = defaultDashboardCluster?.folderId;
                defaultClusterName = defaultDashboardCluster?.label;
                defaultDashboardPage = dashboardCluster.files[j];
                break outerLoop; // breaks out of both loops
              }
            } else {
              if (file.default) {
                defaultDashboardCluster = dashboardCluster;
                defaultDashboardClusterId = defaultDashboardCluster?.folderId;
                defaultClusterName = defaultDashboardCluster?.label;
                defaultDashboardPage = dashboardCluster.files[j];
                break outerLoop; // breaks out of both loops
              }
            }
          }
        } else {
          if (pageId) {
            if (pageId === dashboardCluster.pageId) {
              defaultDashboardCluster = dashboardCluster;
              defaultDashboardClusterId = defaultDashboardCluster?.folderId;
              defaultClusterName = defaultDashboardCluster?.label;
              defaultDashboardPage = dashboardCluster;
              break;
            }
          } else {
            if (dashboardCluster.default) {
              defaultDashboardCluster = dashboardCluster;
              defaultDashboardClusterId = defaultDashboardCluster?.folderId;
              defaultClusterName = defaultDashboardCluster?.label;
              defaultDashboardPage = dashboardCluster;
              break;
            }
          }
        }
      }

      if (!defaultDashboardCluster && !defaultDashboardPage) {
        defaultDashboardCluster = dashboardClusters[0];
        defaultDashboardClusterId = defaultDashboardCluster?.folderId;
        defaultClusterName = defaultDashboardCluster?.label;
        defaultDashboardPage =
          defaultDashboardCluster?.files && defaultDashboardCluster?.files.length > 0
            ? defaultDashboardCluster?.files[0]
            : defaultDashboardCluster;
      }

      setSelectedDashboardPage(defaultDashboardPage);

      console.log('defaultDashboardPage : ', defaultDashboardPage);

      if (defaultDashboardPage?.pageId) {
        const dashboardPageData = await getDashboardPageData(defaultDashboardPage.pageId);

        setCheckboxChecked(dashboardPageData?.refreshProps?.checkboxChecked);
        setCustomRefreshInterval(dashboardPageData?.refreshProps?.customRefreshInterval);
        setEnableCustomRefreshOnly(
          localStorage.getItem('enableCustomRefreshOnly') === 'true'
            ? true
            : dashboardPageData?.refreshProps?.enableCustomRefreshOnly
        );

        updatePageData(
          dashboardPageData,
          defaultDashboardClusterId,
          defaultClusterName,
          defaultDashboardPage
        );
      } else {
        setDashboardPageData(null);
        setDashboardClusters([]);
        setDashboardSaveData({
          dashboardPageId: '',
          selectedGranularity: {
            unit: 'hours',
            unitMultiplier: 1,
          },
          selectedTimeRange: {
            unit: 'days',
            unitMultiplier: 1,
            timeRangeType: 'relative',
            startTime: new Date(),
            endTime: new Date(),
          },
        });
        setTimeFrame({
          startDate: new Date(),
          endDate: new Date(),
        });
      }
      await updatePlantName(plantId!);
      axiosInstance()
        .get(`/tasks/tasks?index=${0}`)
        .then(({ data }) => {
          setTasks(data.body.tasks);
          setRefresh(!refresh);
        });

      await isFeatureAccessibleByPlantId(plantId!, 'AI analysis tool').then(data => {
        setFeatureAccess({ 'AI analysis tool': data });
      });

      setLoading(false);
      setRefresh(!refresh);
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plantId]);

  const resetPage = () => {
    setDashboardPageData(null);
    setPageWidgetCluster(null);
    setShowEmbeddedLinks(false);
    setEmbeddedLinks([]);
    setChangedWidgets([]);
    setDashboardSaveData(null);
    setDashboardDateAndTime({});
    setNewDashboardPage({});
    setSelectedGranularity(GRANULARITY[1].label);
    setSelectedTimeRange(TIME_RANGE[0].label);
    setTimeFrame(null);
    setIsEditDialogOpen(false);
    setIsBottomBarOpen(false);
  };

  const handlePageMenuChange = async (selectedDashboardPage: any) => {
    setLoading(true);
    resetPage();

    const dashboardPageData = await getDashboardPageData(selectedDashboardPage.pageId);

    let currentClusterName: any = null;

    if (dashboardClusters && dashboardClusters?.length) {
      outerLoop: for (let i = 0; i < dashboardClusters.length; i++) {
        const dashboardCluster = dashboardClusters[i];

        if (dashboardCluster?.files && dashboardCluster?.files.length > 0) {
          for (let j = 0; j < dashboardCluster.files.length; j++) {
            const file = dashboardCluster.files[j];

            if (selectedDashboardPage.pageId) {
              if (selectedDashboardPage.pageId === file.pageId) {
                currentClusterName = dashboardCluster?.label;
                break outerLoop; // breaks out of both loops
              }
            } else {
              if (file.default) {
                currentClusterName = dashboardCluster?.label;
                break outerLoop; // breaks out of both loops
              }
            }
          }
        } else {
          if (selectedDashboardPage.pageId) {
            if (selectedDashboardPage.pageId === dashboardCluster.pageId) {
              currentClusterName = dashboardCluster?.label;
              break;
            }
          } else {
            if (dashboardCluster.default) {
              currentClusterName = dashboardCluster?.label;
              break;
            }
          }
        }
      }
    }

    updatePageData(
      dashboardPageData,
      selectedDashboardPage.folderId,
      currentClusterName,
      selectedDashboardPage
    );

    setLoading(false);
  };

  const getIconForWidgetCluster = (widgetClusterName: string) => {
    if (widgetClusterName === 'Water Quantity and Availability') return waterQuantityIcon;
    else if (widgetClusterName === 'Inlet and Outlet Water Quality') return waterQualityIcon;
    else if (widgetClusterName === 'Biological Process Health') return biologicalProcessIcon;
    else if (widgetClusterName === 'Safety') return safetyIcon;
    else if (widgetClusterName === 'Energy Consumption') return energyIcon;
    else if (widgetClusterName === 'Equipment Performance and Maintenance')
      return equipmentMaintenanceIcon;
    else return waterQuantityIcon; // should rather be some fall back icon
  };

  const refreshSelectedPageData = () => {
    if (dashboardPageData !== null && selectedDashboardPage !== null) {
      let defaultTimeRange: any = {};

      if (dashboardSaveData?.selectedTimeRange) {
        if (dashboardSaveData.selectedTimeRange.timeRangeType === 'relative') {
          // Calculate the difference in minutes between endTime and startTime
          const timeRangeInMins =
            (dashboardSaveData.selectedTimeRange.endTime -
              dashboardSaveData.selectedTimeRange.startTime) /
            (1000 * 60);

          // Calculate the new startTime and endTime
          const currentDateAndTime = new Date();
          let startTime = new Date(currentDateAndTime.getTime() - timeRangeInMins * 60 * 1000);
          let endTime = currentDateAndTime;

          //Add 530 Hours in time
          startTime = new Date(startTime?.getTime() + 1000 * 60 * 60 * 5.5);
          endTime = new Date(endTime?.getTime() + 1000 * 60 * 60 * 5.5);

          defaultTimeRange = {
            endTime,
            startTime,
            timeRangeInMins,
            timeRangeType: 'relative', // Set to 'relative'
            unit: dashboardSaveData.selectedTimeRange.unit,
            unitMultiplier: dashboardSaveData.selectedTimeRange.unitMultiplier,
          };
        } else {
          defaultTimeRange = {
            endTime: dashboardSaveData.selectedTimeRange.endTime,
            startTime: dashboardSaveData.selectedTimeRange.startTime,
            timeRangeInMins: dashboardSaveData.selectedTimeRange.timeRangeInMins,
            timeRangeType: dashboardSaveData.selectedTimeRange.timeRangeType,
            unit: dashboardSaveData.selectedTimeRange.unit,
            unitMultiplier: dashboardSaveData.selectedTimeRange.unitMultiplier,
          };
        }
      } else {
        defaultTimeRange = dashboardPageData?.pageConfig?.defaultTimeRangeSettings;
      }

      let timeFrame2 = calculateTimeFrame(defaultTimeRange);

      setTimeFrame({
        ...timeFrame,
        startDate: timeFrame2?.startDate,
        endDate: timeFrame2?.endDate,
      });
      setRefresh(!refresh);
      setRefreshWidgets(!refreshWidgets);
      setIsBottomBarOpen(false);

      const { startDate: startDate530Ahead, endDate: endDate530Ahead } = add530Hours(
        timeFrame2?.startDate,
        timeFrame2?.endDate
      );
      updateTimeFrameForRefId(
        startDate530Ahead,
        endDate530Ahead,
        dashboardSaveData.dashboardPageId,
        'dashboardPage'
      ).then(() => {});
    }
  };

  const onSubmitFunc = async (data: any) => {
    setLoading(true);
    const newClusterName = data.input1;
    const newPageName = data.input2 ? data.input2 : `${newDashboardPage.newPageName} Clone`;

    const editedNewData = {
      ...newDashboardPage,
      newClusterName,
      newPageName,
      setPageAsDefault: data.setAsDefault,
      changes: {
        dashboard: dashboardSaveData,
        widgets: changedWidgets,
      },
      plantId,
    };

    const resp = await createNewDashboard(editedNewData);
    if (resp.updateStatus === 'Success') {
      const dashboardClusters = await getDashboardClusters(plantId!, userId!);
      setDashboardClusters(dashboardClusters);
      toast.success(`Created ${newPageName} Page Successfully !`);
    } else {
      toast.error('Error in saving !');
    }
    setLoading(false);
  };

  const openDateAndTimeDialog = () => {
    setOpenDialog(true);
    setParent('dashboard');
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const { hasPermission: canManageDashboard, loading: loadingManageDashboard } = usePermissionCheck(
    PERMISSION_TAGS.DashboardList_Manage_AE,
    plantId
  );
  const { hasPermission: canEditPage, loading: loadingEditPage } = usePermissionCheck(
    PERMISSION_TAGS.DashboardPage_Configuration_WE,
    plantId
  );
  const { hasPermission: canDownloadAndRefreshReport, loading: loadingDownloadAndRefreshReport } =
    usePermissionCheck(PERMISSION_TAGS.DashboardPage_View_R, plantId);

  return (
    <ThemeProvider theme={dpTheme}>
      <LayoutComponent style={styles.LayoutStyle} factoryResetContainer={true} plantId={plantId}>
        {checkboxChecked && (
          <RefreshPage
            expireTime={expireTime}
            setExpireTime={setExpireTime}
            checkboxChecked={checkboxChecked}
            setCheckboxChecked={setCheckboxChecked}
            customRefreshInterval={customRefreshInterval}
            setCustomRefreshInterval={setCustomRefreshInterval}
            enableCustomRefreshOnly={enableCustomRefreshOnly}
            setEnableCustomRefreshOnly={setEnableCustomRefreshOnly}
          />
        )}
        {dashboardClusters && dashboardSaveData ? (
          <DashboardToolbar
            heading={`${plantName} Analytics`}
            dashboardClusters={dashboardClusters}
            openDateAndTimeDialog={openDateAndTimeDialog}
            refreshSelectedPageData={refreshSelectedPageData}
            plantId={plantId}
            handlePageMenuChange={handlePageMenuChange}
            setTimeFrame={setTimeFrame}
            selectedGranularity={selectedGranularity}
            setSelectedGranularity={setSelectedGranularity}
            selectedRelativeTimeRange={selectedRelativeTimeRange}
            setSelectedTimeRange={setSelectedTimeRange}
            timeFrame={timeFrame}
            selectedDashboardPage={selectedDashboardPage}
            setSelectedDashboardPage={setSelectedDashboardPage}
            setIsBottomBarOpen={setIsBottomBarOpen}
            dashboardDateAndTime={dashboardDateAndTime}
            dashboardSaveData={dashboardSaveData}
            setDashboardSaveData={setDashboardSaveData}
            setParent={setParent}
            newDashboardPage={newDashboardPage}
            setNewDashboardPage={setNewDashboardPage}
            setOpenAiSearchBar={setOpenAiSearchBar}
            navigationLinks={{
              dashboardPageList: `/DashboardPageList/${plantId}`,
              pageConfig: `/pageConfigv2/${plantId}/${selectedDashboardPage?.pageId}`,
            }}
            canManageDashboard={canManageDashboard}
            canEditPage={canEditPage}
            canDownloadAndRefreshReport={canDownloadAndRefreshReport}
            loadingManageDashboard={loadingManageDashboard}
            loadingEditPage={loadingEditPage}
            loadingDownloadAndRefreshReport={loadingDownloadAndRefreshReport}
          />
        ) : null}
        <CustomDialog open={openDialog} onClose={handleCloseDialog}>
          <DateAndTime
            parent="dashboard"
            setDashboardSaveData={setDashboardSaveData}
            onClose={handleCloseDialog}
            timeFrame={timeFrame}
            setTimeFrame={setTimeFrame}
            setIsBottomBarOpen={setIsBottomBarOpen}
            dashboardDateAndTime={dashboardDateAndTime}
            setSelectedGranularity={setSelectedGranularity}
            setSelectedTimeRange={setSelectedTimeRange}
            setParent={setParent}
          />
        </CustomDialog>
        {!loading && dashboardPageData && pageWidgetCluster ? (
          <Box className={classes.dashboardPage}>
            <Box
              className={classes.analyticsView}
              // sx={{
              //   width: {
              //     xs: "100%",
              //     sm: "100%",
              //     md: `calc(100% - ${drawerWidth + 20}px)`,
              //     lg: `calc(100% - ${drawerWidth + 20}px)`,
              //     xl: `calc(100% - ${drawerWidth * 1.5}px)`,
              //   },
              // }}

              //  m={2}
            >
              <Box className={classes.analyticsViewBody}>
                {pageWidgetCluster && pageWidgetCluster.length > 0
                  ? pageWidgetCluster.map((widgetCluster: any, clusterIndex: number) => {
                      const { clusterName, customClusterName } = widgetCluster;
                      if (widgetCluster.showWidgetCluster)
                        return (
                          <>
                            <Box className={classes.widgetClusterheader}>
                              <img
                                src={getIconForWidgetCluster(clusterName)}
                                className={classes.widgetClusterImg}
                                alt="widgetClusterImg"
                              ></img>
                              <p>{customClusterName}</p>
                            </Box>
                            <Box className={classes.widgetClusterBody} m={1}>
                              {clusterIndex === 0 && (
                                <Box
                                  className={classes.graphAndTableWidgetWrapper}
                                  sx={{ display: showAiWidget ? 'block' : 'none' }}
                                >
                                  <AIWidget
                                    timeFrame={timeFrame}
                                    plantId={plantId as string}
                                    openAiSearchBar={openAiSearchBar}
                                    setOpenAiSearchBar={setOpenAiSearchBar}
                                    setShowAiWidget={setShowAiWidget}
                                  />
                                </Box>
                              )}
                              {widgetCluster.widgets.map((widget: any) => {
                                return widget.checked &&
                                  widget.widgetType !== 'number' &&
                                  widget.widgetType !== 'heading' ? (
                                  <Box className={classes.graphAndTableWidgetWrapper}>
                                    <ErrorBoundary>
                                      <GeneralisedWidget
                                        widgetId={widget.widgetId}
                                        widgetStructure={widget}
                                        controller={controller}
                                        timeFrame={timeFrame}
                                        refreshDashboard={refresh}
                                        refresh={refreshWidgets}
                                        plantId={plantId!}
                                        setOpenDialog={setOpenDialog}
                                        parent={parent}
                                        isBottomBarOpen={isBottomBarOpen}
                                        setIsBottomBarOpen={setIsBottomBarOpen}
                                        changedWidgets={changedWidgets}
                                        setChangedWidgets={setChangedWidgets}
                                        setTimeFrame={setTimeFrame}
                                        setParent={setParent}
                                        featureAccess={featureAccess}
                                      />
                                    </ErrorBoundary>
                                  </Box>
                                ) : null;
                              })}
                              {widgetCluster?.numberClusters?.map((numberCluster: any) => {
                                return (
                                  <Box
                                    className={
                                      numberCluster.widgets.length > 2
                                        ? classes.numberClusterWrapperForMultipleWidget
                                        : classes.numberClusterWrapperForOneWidget
                                    }
                                  >
                                    {numberCluster.widgets.map((widget: any) => {
                                      return widget?.checked ? (
                                        <Box
                                          className={
                                            numberCluster.widgets.length > 2
                                              ? classes.numberWidgetWrapperType1
                                              : classes.numberWidgetWrapperType2
                                          }
                                        >
                                          <ErrorBoundary>
                                            <GeneralisedWidget
                                              widgetId={widget.widgetId}
                                              widgetStructure={widget}
                                              controller={controller}
                                              timeFrame={timeFrame}
                                              refresh={refreshWidgets}
                                              plantId={plantId!}
                                              setOpenDialog={setOpenDialog}
                                              parent={parent}
                                              setIsBottomBarOpen={setIsBottomBarOpen}
                                              changedWidgets={changedWidgets}
                                              setChangedWidgets={setChangedWidgets}
                                              setTimeFrame={setTimeFrame}
                                              setParent={setParent}
                                              background={widget?.background}
                                              featureAccess={featureAccess}
                                            />
                                          </ErrorBoundary>
                                        </Box>
                                      ) : null;
                                    })}
                                  </Box>
                                );
                              })}
                            </Box>
                          </>
                        );
                      else {
                        return null;
                      }
                    })
                  : ''}
                {showEmbeddedLinks === true ? (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    {embeddedLinks?.map((embeddedLink: any) => {
                      return (
                        <Box m={1} p={1} className={classes.embeddedLinksHeader}>
                          <p>{embeddedLink.name}</p>
                          <iframe
                            title={embeddedLink.name}
                            width="100%"
                            height="500"
                            src={embeddedLink.link}
                            style={{ border: 'none' }}
                            allowFullScreen={true}
                          ></iframe>
                        </Box>
                      );
                    })}
                  </Grid>
                ) : null}
              </Box>
            </Box>

            <CustomSwipableDrawer
              width={drawerWidth}
              buttonName="Upcoming Tasks"
              background="#E6ECF3"
            >
              {tasks != null ? (
                <TaskViewForDp tasks={tasks} />
              ) : (
                <Box className={classes.loadingTaskView}>
                  <CircularProgress color="primary" />
                </Box>
              )}
            </CustomSwipableDrawer>
          </Box>
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '300px',
              display: 'grid',
              placeItems: 'center',
              fontSize: 20,
              color: '#777',
            }}
          >
            <Box>
              You don't have any analytics page configured please click on page icon to get one!
            </Box>
          </Box>
        )}

        {isBottomBarOpen ? (
          <BottomBar
            isBottomBarOpen={isBottomBarOpen}
            setIsBottomBarOpen={setIsBottomBarOpen}
            data={changedWidgets}
            dashboardSaveData={dashboardSaveData}
            parent={parent}
            setIsEditDialogOpen={setIsEditDialogOpen}
          />
        ) : null}
        {isEditDialogOpen && (
          <EditComponentDialog
            isEditDialogOpen={isEditDialogOpen}
            setIsEditDialogOpen={setIsEditDialogOpen}
            heading={'Edit Cluster'}
            title1={'Cluster'}
            title2={'Dashboard'}
            input1={newDashboardPage.currentClusterName || ''}
            input2={''}
            showSetAsDefault={true}
            createdOn={new Date().toISOString()}
            modifiedOn={new Date().toISOString()}
            onSubmitFunc={onSubmitFunc}
          />
        )}
      </LayoutComponent>
    </ThemeProvider>
  );
}
