import {
  Box,
  Grid,
  Stack,
  TextField,
  useMediaQuery,
  Theme,
  Typography,
  IconButton,
  Collapse,
  Divider,
  Link,
  Breadcrumbs,
} from '@mui/material';

import TwoRowLayout from '../../../core-components/theme/layouts/TwoLayout/TwoLayoutComponent';
import SearchBox from '../../../core-components/theme/components/Searchbox/Searchbox';
import { Neutrals, Primary } from '../../../core-components/theme/color-palette/colors';
import Tabs from '../../../core-components/theme/components/Tabs/Tabs';
import CustomTreeComponent from '../../../core-components/theme/components/Tree/CustomTree';
import React, { useEffect, useRef, useState } from 'react';
import LayoutComponent from '../../../components/LayoutComponent';
import Button from '../../../core-components/theme/components/Button/Button';
import CheckboxList from '../../../core-components/theme/components/List/List';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  getModuleItemId,
  getUserItemlabel,
} from '../../user-management/helpers/customUserTreeHelpers';
import { filterItemsByCriteria } from '../../user-management/permissions/utility/helper';
import CustomUserGroupTreeItem from './CustomUserGroupTree';
import CustomWorkspaceTreeItem from '../../workspace-management/component/CustomWorkspaceTree';
import { getWorkspaceItemlabel } from '../../workspace-management/helpers/customtreehelpers';
import {
  getModules,
  idstoworkspace,
  processFeatureTags,
  processWorkspace,
} from '../hooks/moduleHandler';
import { useMutation, UseMutationResult, useQuery } from '@tanstack/react-query';
import {
  getFeatureList,
  getUserGroupDetails,
  getUserList,
  getWorkspaceList,
  saveUserGroup,
} from '../../../services/userGroup/userGroup';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isValidNameFormat } from '../../../utilities/name.utils';
import { isDescriptionProperlyFormatted } from '../../../utilities/description.utils';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTreeStore } from '../../../store/TreeStore';

interface FormData {
  name: string;
  description: string;
  abbr: string;
  users: string[];
  workspaceAssets: WorkspaceAsset[] | string[];
  featureTags: string[];
}

interface WorkspaceAsset {
  workspace: string;
  assets: string[];
}

const GroupCreatePage = () => {
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/userGroupList">
      <Typography key="3" color="#666666">
        User Group List
      </Typography>
    </Link>,
    <Typography key="3" color="#666666">
      Edit User Group
    </Typography>,
  ];
  const [users, setUsers] = useState<any[]>([]);
  const [value, setValue] = useState(0);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [OriginalfilteredData, setOriginalfilteredData] = useState<any[]>();
  const [mode] = useState('edit');
  const [isUserDetailsFetched, setUserDetailsFetched] = useState(false);
  const [filterItems, setFilterItems] = useState<any[]>([]);
  const [originalItems, setOriginalItems] = useState<any[]>([]);
  const [filterWorkspace, setFilterWorkspace] = useState<any[]>([]);
  const [originalWorkspace, setOriginalWorkspace] = useState<any[]>([]);

  const [, setQuery] = useState('');

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const setIds = useTreeStore(state => state.setIds);
  const setWorkspace = useTreeStore(state => state.setWorkspace);
  const clearStore = useTreeStore(state => state.cancel);
  // ^ Getting the id from the URL params
  const { id } = useParams();
  const userGroupId = id ? id : '';
  const [formData, setFormData] = useState<FormData>({
    ...(id ? { userGroupId: id } : {}),
    name: '',
    abbr: '',
    description: '',
    users: [],
    workspaceAssets: [],
    featureTags: [],
  });

  const { data: userDetails, status: userStatus } = useQuery({
    queryKey: ['userDetails', id],
    queryFn: () => getUserList({ mode: mode, userGroupId: id }),
    initialData: users,
    enabled: !formData.users.length,
    refetchOnWindowFocus: false,
  });

  const { data: moduleList, status: modulesStatus } = useQuery({
    queryKey: ['moduleDetails', id],
    queryFn: () =>
      getFeatureList({
        mode: mode,
        userGroupId: id,
      }),
    enabled: !formData.featureTags.length,
    refetchOnWindowFocus: false,
  });

  const { data: userGroupData, status: userGroupDataStatus } = useQuery({
    queryKey: ['userGroupDetails', id],
    queryFn: () => getUserGroupDetails(userGroupId),
    enabled: !isUserDetailsFetched,
    refetchOnWindowFocus: false,
  });
  const { data: workspaceList, status: workspaceStatus } = useQuery({
    queryKey: ['workspaceDetails', id],
    queryFn: () => getWorkspaceList({ mode: mode, userGroupId: id }),
    enabled: !formData.workspaceAssets.length,
    refetchOnWindowFocus: false,
  });

  function handleModuleSelected(val: string[]) {
    setFormData({
      ...formData,
      featureTags: val,
    });
    setIds(val);
  }

  function handleWorkspaceSelected(val: string[]) {
    setFormData({
      ...formData,
      workspaceAssets: val,
    });
    setWorkspace(val);
  }
  const mutation: UseMutationResult<void, unknown, FormData> = useMutation({
    mutationFn: (formData: FormData) => saveUserGroup(formData),
    onSuccess: data => {
      toast.success(`User Group saved`);
      clearStore();
      navigate(`/userGroupList/`);
    },
    onError: (error: any) => {
      toast.error(error);
      console.error('Mutation failed:', error);
    },
  });

  const handlePost = (): void => {
    const updatedFormData: FormData = { ...formData };
    updatedFormData.name = updatedFormData.name;
    if (!isValidNameFormat(updatedFormData.name)) {
      toast.error('Name cannot be empty and max length 80');
      return;
    }
    updatedFormData.description = updatedFormData.description?.trim() || '';
    if (!isDescriptionProperlyFormatted(updatedFormData.description)) {
      toast.error(`Description cannot be this long, max length is 200 characters`);
      return;
    }
    updatedFormData.featureTags = getModules(originalItems, formData.featureTags);
    updatedFormData.workspaceAssets = idstoworkspace(originalWorkspace, formData.workspaceAssets);
    mutation.mutate(updatedFormData);
  };
  const searchBoxRef = useRef(null);
  const handleChange = (newValue: React.SetStateAction<number>) => {
    setValue(newValue);
    if (searchBoxRef.current) {
      //@ts-ignore
      searchBoxRef.current.handleClear();
    }
    setQuery('');
  };

  const placeHolder = (index: number) => {
    switch (index) {
      case 0:
        return 'Search for user';
      case 1:
        return 'Search for module';
      case 2:
        return 'Search for workspace';
      default:
        return '';
    }
  };

  const navigate = useNavigate();
  const handleCancel = () => {
    navigate(`/userGroupList/`);
  };
  function handlestateChange(val: string[]) {
    const updatedUsers = filteredData.map(user => ({
      ...user,
      checked: val.includes(user._id),
    }));
    setFormData({
      ...formData,
      users: val,
    });
    setUsers(val);
    setFilteredData(updatedUsers);
  }
  const handleSearch = (query: string) => {
    setQuery(query);
    if (value === 0) {
      if (OriginalfilteredData) {
        const filteredRows = OriginalfilteredData.filter(item =>
          item.name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredData(filteredRows);
      }
    }
    if (value === 1) {
      setFilterItems(filterItemsByCriteria(originalItems, query, []));
    }
    if (value === 2) {
      setFilterWorkspace(filterItemsByCriteria(originalWorkspace, query, []));
    }
  };
  console.log(formData);
  useEffect(() => {
    if (userGroupDataStatus === 'success') {
      const { name, description, abbr } = userGroupData;
      setFormData(prevData => ({
        ...prevData,
        name: name || '',
        description: description || '',
        abbr: abbr || '',
      }));
      setUserDetailsFetched(true);
    } else if (userStatus === 'error') {
      setUserDetailsFetched(false);
    }
  }, [userGroupData, userGroupDataStatus]);
  useEffect(() => {
    if (userStatus === 'success') {
      if (!formData.users.length) {
        formData.users = userDetails
          .filter((user: { checked: any }) => user.checked)
          .map((user: { _id: any }) => user._id);
        setOriginalfilteredData(userDetails);
        setFilteredData(userDetails);
      }
    } else if (userStatus === 'error') {
    }
  }, [userDetails, userStatus]);
  useEffect(() => {
    if (modulesStatus === 'success') {
      if (!formData.featureTags.length) {
        formData.featureTags = processFeatureTags(moduleList);
        setIds(formData.featureTags);
        setFilterItems(moduleList);
        setOriginalItems(moduleList);
      }
    } else if (modulesStatus === 'error' || modulesStatus === 'pending') {
    }
  }, [modulesStatus, moduleList]);

  useEffect(() => {
    if (workspaceStatus === 'success') {
      if (!formData.workspaceAssets.length) {
        formData.workspaceAssets = processWorkspace(workspaceList);
        setWorkspace(formData.workspaceAssets);
        setFilterWorkspace(workspaceList);
        setOriginalWorkspace(workspaceList);
      }
    } else if (workspaceStatus === 'error' || workspaceStatus === 'pending') {
    }
  }, [workspaceStatus, workspaceList]);

  const handleClear = () => {
    setQuery('');
    if (value === 0) {
      setFilteredData(filteredData);
    }
    if (value === 1) {
      setFilterItems(originalItems);
    }
    if (value === 2) {
      setFilterWorkspace(originalWorkspace);
    }
  };

  const tabs = [
    {
      label: 'User List',
      Component: (
        <CheckboxList
          initialChecked={formData.users}
          items={filteredData}
          onToggle={handlestateChange}
        />
      ),
    },
    {
      label: 'Module List',
      Component: (
        <CustomTreeComponent
          CustomTreeItem={CustomUserGroupTreeItem}
          selectedIds={formData.featureTags || []}
          handleTreeSelected={handleModuleSelected}
          checkboxSelection={true}
          multiSelect={true}
          getItemId={getModuleItemId}
          getItemlabel={getUserItemlabel}
          items={filterItems || []}
          isFeatureList={true}
        />
      ),
    },
    {
      label: 'Workspace List',
      Component: (
        <CustomTreeComponent
          CustomTreeItem={CustomWorkspaceTreeItem}
          //@ts-ignore
          selectedIds={formData.workspaceAssets || []}
          handleTreeSelected={handleWorkspaceSelected}
          checkboxSelection={true}
          multiSelect={true}
          getItemId={(item: { itemId: string }) => item.itemId}
          getItemlabel={getWorkspaceItemlabel}
          items={filterWorkspace || []}
          isWorkspaceList={true}
        />
      ),
    },
  ];

  function handleFormData(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  const [open, setOpen] = useState(true);

  const handleToggle = () => {
    setOpen(!open);
  };
  return (
    <LayoutComponent factoryResetContainer={true}>
      <TwoRowLayout
        style={{
          pl: isMobile ? 2 : 4,
          pr: isMobile ? 2 : 4,
        }}
        child1={
          <Stack direction="column" width="100%">
            <Breadcrumbs
              separator={<ArrowForwardIcon fontSize="small" sx={{ color: '#666666' }} />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
            <Box
              sx={{
                mt: 2,
                mb: 2,
                backgroundColor: `${Primary[50]}`,
                borderRadius: 1,
                padding: isMobile ? '16px 24px' : '33px 23px',
                border: '1px solid #bdbdbd',
              }}
            >
              <Grid container direction="column" borderRadius="4px">
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  onClick={handleToggle}
                >
                  {isMobile && (
                    <Typography
                      lineHeight="24px"
                      letterSpacing="0.15px"
                      color="#666"
                      variant="body"
                    >
                      User Group Details
                    </Typography>
                  )}
                  {isMobile && <IconButton>{open ? <ExpandLess /> : <ExpandMore />}</IconButton>}
                </Grid>
                {isMobile && open && (
                  <Divider variant="fullWidth" sx={{ mt: 1, mb: '24px', color: '#bdbdbd' }} />
                )}
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Grid
                    container
                    direction={isMobile ? 'column' : 'row'}
                    borderRadius="4px"
                    justifyContent="space-around"
                    alignItems="center"
                    gap={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      width={isMobile ? '100%' : undefined}
                      p={isMobile ? 2 : undefined}
                    >
                      <TextField
                        label="Group Name"
                        placeholder="User Group Name"
                        value={formData.name}
                        name="name"
                        onChange={handleFormData}
                        variant="outlined"
                        required
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      p={isMobile ? 2 : undefined}
                      width={isMobile ? '100%' : undefined}
                    >
                      <TextField fullWidth disabled value={formData?.abbr} label="Abbreviation" />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      p={isMobile ? 2 : undefined}
                      width={isMobile ? '100%' : undefined}
                    >
                      <TextField
                        label="Description"
                        name="description"
                        value={formData.description}
                        placeholder="User Group Description"
                        onChange={handleFormData}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </Box>
            <Box sx={{ width: '100%' }}>
              <SearchBox
                placeholder={placeHolder(value)}
                backgroundColor={`${Primary[50]}`}
                sx={{
                  width: '100%',
                  flexGrow: 1,
                  mt: 2,
                  mb: 2,
                  height: '52px',
                  borderColor: `${Neutrals[500]}`,
                  borderRadius: '4px',
                }}
                ref={searchBoxRef}
                onSearch={handleSearch}
                onClear={handleClear}
              />
            </Box>
          </Stack>
        }
        child2={
          <Box p={0}>
            <Tabs
              tabs={tabs}
              value={value}
              height="50vh"
              overflow="auto"
              handleChange={handleChange}
            />
            <Box
              pt={4}
              gap={6}
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: isMobile ? 'center' : 'flex-end',
              }}
            >
              <Button
                variant="contained"
                sx={{
                  border: `1px solid ${Neutrals[500]}`,
                  borderRadius: '4px',
                  padding: isMobile ? '10px 40px' : '10px 70px',
                  backgroundColor: 'white',
                  color: `${Neutrals[500]}`,
                  flex: isMobile ? 1 : 0,
                  boxShadow: 'none',
                  ':hover': { background: 'none', boxShadow: 'none' },
                }}
                onClick={handleCancel}
              >
                <Typography variant="body" color="Neutrals.500" lineHeight="24px">
                  Cancel
                </Typography>
              </Button>
              <Button
                disabled={formData.name.length === 0 || mutation.isPending}
                variant="contained"
                onClick={handlePost}
                sx={{
                  padding: isMobile ? '10px 40px' : '10px 70px',
                  flex: isMobile ? 1 : 0,
                  borderRadius: '4px',
                  backgroundColor: `${Primary[500]}`,
                  boxShadow: 'none',
                  ':hover': { boxShadow: 'none' },
                }}
              >
                <Typography variant="body" lineHeight="24px" color="white">
                  Save
                </Typography>
              </Button>
            </Box>
          </Box>
        }
      />
    </LayoutComponent>
  );
};

export default GroupCreatePage;
