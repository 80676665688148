import { Box as MuiBox, Typography } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  value: number;
  index: number;
  height?: string;
  overflow?: string;
}

const TabPanel: React.FC<TabPanelProps> = props => {
  const { children, value, index, height, overflow , ...other } = props;

  return (
    <MuiBox
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <MuiBox sx={{ pt: 3, overflow: overflow, width: 'auto', height: height}}>
          <Typography>{children}</Typography>
        </MuiBox>
      )}
    </MuiBox>
  );
};

export default TabPanel;
