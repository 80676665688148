import React from 'react';
import { Tabs as MuiTabs, Tab as MuiTab, Box as MuiBox } from '@mui/material';
import TabPanel from './Tabpanel';

interface TabInfo {
  label: string;
  Component: React.ReactNode;
}

interface TabProps {
  tabs: TabInfo[];
  value?: number;
  handleChange: (newValue: number) => void;
  variant?: 'standard' | 'scrollable' | 'fullWidth' | undefined;
  scroll?: boolean;
  height?: string;
  overflow?: string;
}

const Tabs: React.FC<TabProps> = ({
  tabs,
  variant = 'scrollable',
  value = 0,
  handleChange,
  scroll = false,
  height = '',
  overflow = 'auto',
}) => {
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    handleChange(newValue);
  };
  return (
    <MuiBox sx={{ width: 'inherit', mt: 6 }}>
      <MuiBox>
        <MuiTabs
          variant={variant}
          scrollButtons={scroll}
          value={value}
          onChange={handleTabChange}
          aria-label="Tabs"
          TabIndicatorProps={{
            style: {
              color: '#2196F3',
              background: '#2196F3',
            },
          }}
        >
          {tabs.map(({ label }: TabInfo, i: number) => (
            <MuiTab label={label} key={i} />
          ))}
        </MuiTabs>
      </MuiBox>
      {tabs.map(({ Component }: TabInfo, i: number) => (
        <TabPanel overflow={overflow} value={value} index={i} key={i} height={height}>
          {Component}
        </TabPanel>
      ))}
    </MuiBox>
  );
};

export default Tabs;
