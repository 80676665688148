import axiosInstance from '../constants/axiosInstance';

const fetchPlantConfig = async (id: any) => {
  console.log('calloing this fetchplantconfig function');
  console.log(`/plantManagement/getPlantConfig?id=${id}`);
  try {
    const { data } = await axiosInstance().get(`/plantManagement/getPlantConfig?id=${id}`);
    console.log('this is the plant config', data);
    return data;
  } catch (err) {
    console.error('ERROR | fetchPlantConfig | ', err);
  }
};

const savePlantConfig = async (plantId: string, plantConfig: any) => {
  try {
    console.log('plantId : ', plantId);
    console.log('Plant Config', plantConfig);
    const { data } = await axiosInstance().post(
      `/plantManagement/savePlantConfig?id=${plantId}`,
      plantConfig
    );
    return data;
  } catch (err) {
    console.error('ERROR | savePlantConfig | ', err);
  }
};

const getPostFixTagForPlant = async (plantId: any) => {
  try {
    const { data } = await axiosInstance().get(
      `/plantManagement/getPostFixTagForPlantId?plantId=${plantId}`
    );
    return data;
  } catch (err) {
    console.error('ERROR | getPostFixTagForPlant | ', err);
  }
};
const getOtherPlantsForClient = async (plantId: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/plantManagement/getSubPlantsToBeAddedByPlantConfig?plantId=${plantId}`
    );
    return data;
  } catch (err) {
    console.error('ERROR | getOtherPlantsForClient | ', err);
  }
};
const getSensorsForPlant = async (plantId: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/sensors/getSensorListByPlantId?plantId=${plantId}`
    );
    return data;
  } catch (err) {
    console.error('ERROR | getSensorsForPlant | ', err);
  }
};
const getClonedPlantConfig = async (plantId: string, preExistingPlantId: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/plantManagement/getPlantCinfigTemplateForPlantIdBasedOnPreExistingPlant?plantId=${plantId}&preExistingPlantId=${preExistingPlantId}`
    );
    return data;
  } catch (err) {
    console.error('ERROR | getClonedPlantConfig | ', err);
  }
};

export {
  fetchPlantConfig,
  savePlantConfig,
  getPostFixTagForPlant,
  getOtherPlantsForClient,
  getSensorsForPlant,
  getClonedPlantConfig,
};
