import { memo, useState } from 'react';

// import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import GeneralisedExpandedWidget from './ExpandedGeneralisedWidget';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import TableDWidget from './subComponents/tableDWidget';
import TableCWidget from './subComponents/tableCWidget';
import NumberWidget from './subComponents/numberWidget';
import GeneralisedGraphWidget from './subComponents/generalisedGraphWidget';
// import GeneralisedGraphWidget2 from './subComponents/generalisedGraphWidget2';
import axios from 'axios';
import GeneralisedPopupToolBar from '../../core-components/theme/components/HeaderComponents/GeneralisedPopupToolBar';
import DownloadIcon from '@mui/icons-material/Download';
import CallMadeIcon from '@mui/icons-material/CallMade';
import ChartIcon from '../../assets/icons/ChartIcon.svg';
import LinkIcon from '../../core-components/assets/icons/linkIcon.svg';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import RangeNumberWidget from './subComponents/RangeNumberWidget';

///// interfaces
//@ts-ignore
import { widgetDataInterface } from '../../Interfaces/widgetInterfaces';
// import { timeFrame } from '../../Interfaces/dashboardPageInterfaces';
import {
  calculateTimeFrame,
  isAbsoluteURL,
  isRelativeURLWithoutSlash,
} from '../../utilities/helpers';
import { getWidgetData } from '../../services/dashboardPage';
// import { useDashboardStore } from '../../store/DashboardStore';
import CustomDialog from '../../globalComponents/dialogs/CustomDialog';
import DateAndTime from '../../globalComponents/dateAndTime/DateAndTime';
import {
  GRANULARITY,
  GRAPH_TYPE,
  TIME_RANGE_MAPPINGS,
  WIDGET_TYPE,
} from '../../constants/constants';
import lodash from 'lodash';
import FlocDetectorVisualizationChart from '../FlocDetector/FlocDetectorVisualizationChart';
import SettingsDialog from '../FlocDetector/SettingDialogueBox/SettingsDialog';
import { isFeatureAccessibleByPlantId } from '../../services/plantFeatureAuth';
import Loader from '../../globalComponents/loader/DPLoader';
import { Box, Menu, MenuItem } from '@mui/material';
import { handleDownloadCSV, handleDownloadCSVForRangeWidget } from '../../utilities/helpers';

SwiperCore.use([Navigation, Pagination, Autoplay]);

//   ChartJS.register(
//     LinearScale,
//     CategoryScale,
//     BarElement,
//     PointElement,
//     LineElement,
//     Legend,
//   );

// interface generalisedWidgetProps {
//   widgetId?: string;
//   widgetStructure?: any;
//   controller?: any;
//   timeFrame: timeFrame | null;
//   refresh: boolean;
//   plantId: string;
// }

const styles = {
  iconStyle: { width: '16px', height: '16px', color: '#ACB3B9' },
};

const GeneralisedWidget = memo(
  (props: any) => {
    const add530HoursInTimeFrame = (timeFrame: any | null) => {
      if (!timeFrame)
        return {
          startDate: new Date(),
          endDate: new Date(),
          granularity: GRANULARITY[1].value,
          timeRangeType: 'absolute',
        };
      const newTimeFrame = {
        startDate: new Date(timeFrame?.startDate?.getTime() + 1000 * 60 * 60 * 5.5).toISOString(),
        endDate: new Date(timeFrame?.endDate?.getTime() + 1000 * 60 * 60 * 5.5).toISOString(),
      };

      return {
        ...newTimeFrame,
        granularity: timeFrame.granularity,
        timeRangeType: timeFrame.timeRangeType,
      };
    };

    const [showClock, setShowClock] = useState<any>('off');

    const [expandedViewPopup, setExpandedViewPopup] = useState<boolean>(false);
    const {
      widgetId,
      widgetStructure,
      timeFrame,
      plantId,
      controller,
      refresh,
      setIsBottomBarOpen,
      changedWidgets,
      setChangedWidgets,
      setTimeFrame,
      setParent,
      // background,
      isBottomBarOpen,
      featureAccess,
      enableNewToolBar = false,
    } = props;
    const [widgetData, setWidgetData] = useState<any>(null);
    console.log(props);
    //Loading & Error Concern
    const [loading, setLoading] = useState(false);
    const [loadingFlocDetector, setLoadingFlocDetector] = useState(true);
    //@ts-ignore
    const [isError, setIsError] = useState(false);

    //User Action
    const [openDialog, setOpenDialog] = useState(false);
    const [widgetDateAndTime, setWidgetDateAndTime] = useState(null);
    const [customTimeRangeSettings, setCustomTimeRangeSettings] = useState<any>(null); // Step 2: Create customTimeRangeSettings state
    const [customGranularity, setCustomGranularity] = useState(null);
    const [selectChart, setSelectedChart] = useState<string | null>(null);
    const [previousSelectChart, setPreviousSelectedChart] = useState<string | null>(null);
    const [widgetTimeFrame, setWidgetTimeFrame] = useState<any>(null);
    const [sendParentInGetWidgeDataApi, setSendParentInGetWidgetDataApi] = useState('dashboard');
    const [widgetFeatureAccess, setWidgetFeatureAccess] = useState({
      'AI analysis tool': false,
    });
    const [showPopupToolBar, setShowPopupToolBar] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [widgetName, setWidgetName] = useState('');
    const [timeRangeVal, setTimeRangeVal] = useState<any>(null);
    const [showAllSampleVal, setShowAllSampleVal] = useState<any>(null);
    const [makingSampleDefault, setMakingSampleDefault] = useState(false);

    const handleCloseDialog = () => {
      setOpenDialog(false);
    };

    const handleEditClick = (widgetName: string = '') => {
      if (widgetName === 'flocDetector') {
        setWidgetName('flocDetector');
      }
      setOpenDialog(true);
      setSendParentInGetWidgetDataApi('widget');
      if (isBottomBarOpen) {
        setIsBottomBarOpen(false);
      }
    };

    const handleClockClick = (value: any) => {
      setShowClock(value);
      setSendParentInGetWidgetDataApi('clockWidget');
      //handleWidgetUpdate();
      setParent('widget');
    };

    const handleWidgetUpdate = () => {
      const updatedWidget = {
        dashboardId: widgetData.dashboardPageId,
        widgetId: widgetId || '',
        selectedGranularity: showClock == 'on' ? customGranularity : timeFrame.granularity,
        selectedChart: {
          widgetType: WIDGET_TYPE[selectChart || ''],
          graphType: GRAPH_TYPE[selectChart || ''],
        },
        selectedTimeRange:
          showClock == 'on' && customTimeRangeSettings
            ? customTimeRangeSettings
            : // : null
              (function () {
                let mapping = TIME_RANGE_MAPPINGS[timeFrame?.timeRangeLabel];
                let unit = mapping?.unit;
                let unitMultiplier = mapping?.unitMultiplier;
                return {
                  unit: unit,
                  unitMultiplier: unitMultiplier,
                  timeRangeType: timeFrame.timeRangeType,
                  startTime: timeFrame.startDate,
                  endTime: timeFrame.endDate,
                };
              })(),
        applyCustomSettings: showClock == 'on' ? true : false,
      };

      updateChangedWidgets(updatedWidget);
    };

    const updateChangedWidgets = (updatedWidget: any) => {
      const tempChangedWidgets = [...changedWidgets];
      const existingWidgetIndex = tempChangedWidgets.findIndex(
        widget => widget.widgetId === updatedWidget.widgetId
      );

      let showBottomBar: any = false;
      let clone1;
      let clone2;
      if (existingWidgetIndex !== -1 && showClock == 'on') {
        clone1 = lodash.cloneDeep(tempChangedWidgets[existingWidgetIndex]);
        clone2 = lodash.cloneDeep(updatedWidget);
        if (clone1 && clone2) {
          if (
            clone1.selectedTimeRange?.timeRangeType == 'relative' &&
            clone2.selectedTimeRange?.timeRangeType == 'relative'
          ) {
            delete clone1.selectedTimeRange.startTime;
            delete clone1.selectedTimeRange.endTime;
            delete clone2.selectedTimeRange.startTime;
            delete clone2.selectedTimeRange.endTime;
          } else {
            clone1.selectedTimeRange.startTime = new Date(
              clone1.selectedTimeRange.startTime
            ).toISOString();
            clone1.selectedTimeRange.endTime = new Date(
              clone1.selectedTimeRange.endTime
            ).toISOString();
            clone2.selectedTimeRange.startTime = new Date(
              clone2.selectedTimeRange.startTime
            ).toISOString();
            clone2.selectedTimeRange.endTime = new Date(
              clone2.selectedTimeRange.endTime
            ).toISOString();
          }
        }
        showBottomBar = !lodash.isEqual(clone1, clone2);
      } else if (showClock == 'disabled' || (previousSelectChart && selectChart)) {
        showBottomBar =
          tempChangedWidgets[existingWidgetIndex]?.applyCustomSettings !=
          updatedWidget?.applyCustomSettings;
        if (previousSelectChart && selectChart && !showBottomBar)
          showBottomBar = previousSelectChart != selectChart;
      }

      // if (showBottomBar) {
      //   console.log("parent widgetId ", widgetId);
      //   console.log("parent tempChangediwdges[exis]", clone1);
      //   console.log("parent updatedWidget", clone2);
      //   console.log(
      //     "parent lodashequal,showBottomBa,showClock",
      //     lodash.isEqual(clone1, clone2),
      //     showBottomBar,
      //     showClock
      //   );
      // }

      if (existingWidgetIndex !== -1) {
        let newUpdatedWidget = {
          ...tempChangedWidgets[existingWidgetIndex],
          ...updatedWidget,
        };
        tempChangedWidgets.splice(existingWidgetIndex, 1);
        tempChangedWidgets.push(newUpdatedWidget);
      } else {
        tempChangedWidgets.push(updatedWidget);
      }

      setChangedWidgets(tempChangedWidgets);
      // if (!lodash.isEqual(tempChangedWidgets, changedWidgets))
      if (showBottomBar) {
        setParent('widget');
        setPreviousSelectedChart(selectChart);
        setIsBottomBarOpen(true);
      }
    };

    useEffect(() => {
      if (selectChart === 'flocDetector') {
        setLoadingFlocDetector(true);
      }
      const getTimeData = (currentFrame: any) => {
        const time = add530HoursInTimeFrame(currentFrame);
        return {
          startDate: time.startDate,
          endDate: time.endDate,
          granularity: time.granularity,
          timeRangeType: time.timeRangeType,
        };
      };

      const fetchWidgetData = async () => {
        try {
          setIsError(false);
          setLoading(true);

          let timeData;
          if (widgetTimeFrame !== null && showClock == 'on') {
            let tempTime: any = add530HoursInTimeFrame(calculateTimeFrame(customTimeRangeSettings));

            timeData = {
              ...getTimeData(widgetTimeFrame),
              startDate: tempTime.startDate,
              endDate: tempTime.endDate,
            };
          } else {
            timeData = getTimeData(timeFrame);
          }

          let widgetData = null;

          if (selectChart) {
            widgetData = await getWidgetData({
              plantId,
              widgetId,
              parent: sendParentInGetWidgeDataApi,
              clock: showClock,
              selectedChart: GRAPH_TYPE[selectChart],
              widgetType: WIDGET_TYPE[selectChart],
              ...timeData,
              timeRangeVal,
              showAllSampleVal,
            });
            handleWidgetUpdate();
            setLoadingFlocDetector(false);
            setMakingSampleDefault(false);
          } else {
            widgetData = await getWidgetData({
              plantId,
              widgetId,
              parent: sendParentInGetWidgeDataApi,
              clock: showClock,
              ...timeData,
              timeRangeVal,
              showAllSampleVal,
            });
            // console.log('this is the widgetData :' , widgetData)
          }
          if (!selectChart && widgetData.widgetType !== 'number') {
            if (['tableC', 'tableD', 'NumberGauge', 'number2'].includes(widgetData.widgetType))
              setSelectedChart(widgetData.widgetType);
            else setSelectedChart(widgetData.graphType);
          }

          //Handle condition to show custom settings
          if (showClock === 'off' && widgetData?.advancedWidgetSettings?.applyCustomSettings) {
            const { startDate: startTime, endDate: endTime }: any = calculateTimeFrame(
              widgetData.customTimeRangeSettings
            );
            let customTimeRangeSettings = {
              ...widgetData.customTimeRangeSettings,
              startTime,
              endTime,
            };
            if (widgetData.widgetType !== 'flocDetector') {
              const widgetTimeFrame: any = {
                startDate: startTime,
                endDate: endTime,
                granularity: widgetData.customGranularitySettings.unit.toLowerCase(),
                timeRangeType: customTimeRangeSettings.timeRangeType,
                granularityLabel: `${widgetData.customGranularitySettings.unit
                  .charAt(0)
                  .toUpperCase()}${widgetData.customGranularitySettings.unit.slice(1)}`,
              };
              setWidgetTimeFrame(widgetTimeFrame);
            } else {
              const widgetTimeFrame: any = {
                startDate: startTime,
                endDate: endTime,
                timeRangeType: customTimeRangeSettings.timeRangeType,
              };
              setWidgetTimeFrame(widgetTimeFrame);
            }
            setShowClock('on');
            setCustomGranularity(widgetData.customGranularitySettings);
            setCustomTimeRangeSettings(customTimeRangeSettings);
          } else {
            let startTime;
            let endTime;
            let newCustomTimeRangeSettings;

            if (
              widgetData.customTimeRangeSettings?.timeRangeType == 'relative' &&
              widgetData?.advancedWidgetSettings?.applyCustomSettings &&
              customTimeRangeSettings == null
            ) {
              let tempTime: any = add530HoursInTimeFrame(
                calculateTimeFrame(customTimeRangeSettings)
              );
              startTime = new Date(tempTime?.startDate)?.getTime() - 1000 * 60 * 60 * 5.5;
              endTime = new Date(tempTime?.endDate)?.getTime() - 1000 * 60 * 60 * 5.5;

              newCustomTimeRangeSettings = {
                ...customTimeRangeSettings,
                startTime,
                endTime,
              };
              setCustomTimeRangeSettings(newCustomTimeRangeSettings);
            } else if (
              widgetData.customTimeRangeSettings?.timeRangeType == 'absolute' &&
              widgetData?.advancedWidgetSettings?.applyCustomSettings &&
              customTimeRangeSettings == null
            ) {
              setCustomTimeRangeSettings(widgetData.customTimeRangeSettings);
            } else if (refresh) {
              startTime = new Date(timeData?.startDate)?.getTime() - 1000 * 60 * 60 * 5.5;
              endTime = new Date(timeData?.endDate)?.getTime() - 1000 * 60 * 60 * 5.5;
              if (customTimeRangeSettings == null)
                newCustomTimeRangeSettings = {
                  ...widgetData.customTimeRangeSettings,
                  startTime,
                  endTime,
                };
              else
                newCustomTimeRangeSettings = {
                  ...customTimeRangeSettings,
                  startTime,
                  endTime,
                };

              setCustomTimeRangeSettings(newCustomTimeRangeSettings);
            }
          }
          const widgetDateAndTime: any = {
            widgetId,
            factoryGranularitySettings: widgetData.factoryGranularitySettings,
            factoryTimeRangeSettings: widgetData.factoryTimeRangeSettings,
            customGranularitySettings: widgetData.customGranularitySettings,
            customTimeRangeSettings: widgetData.customTimeRangeSettings,
            advancedWidgetSettings: widgetData.advancedWidgetSettings,
          };

          setWidgetDateAndTime(widgetDateAndTime);
          setWidgetData(widgetData);
          setTimeRangeVal(widgetData?.timeRange);
          setLoading(false);
        } catch (err) {
          if (!axios.isCancel(err)) {
            console.log('getting this error :', err);
            setIsError(true);
          }
          console.log('getting this error :', err);
        }
      };

      fetchWidgetData();

      return () => controller.abort(); // cleanup function
    }, [
      controller,
      widgetStructure,
      widgetTimeFrame,
      timeFrame,
      selectChart,
      showClock,
      refresh,
      makingSampleDefault,
    ]);

    useEffect(() => {
      if (!plantId && widgetData?.plantIds) {
        let tempPlantId = widgetData?.plantIds?.[0];
        isFeatureAccessibleByPlantId(tempPlantId!, 'AI analysis tool').then(data => {
          setWidgetFeatureAccess({ 'AI analysis tool': data });
        });
      }
    }, [widgetData]);

    useEffect(() => {
      if (showClock) setSendParentInGetWidgetDataApi('clockWidget');
    }, []);

    let linkOnWidget = widgetData?.moreInfo || '';
    let showLinkIcon = true;

    if (linkOnWidget === 'link' || linkOnWidget == '') {
      showLinkIcon = false;
    } else {
      if (!isAbsoluteURL(linkOnWidget)) {
        const location = window.location.origin;
        if (isRelativeURLWithoutSlash(linkOnWidget)) {
          linkOnWidget = `${location}/${linkOnWidget}`;
        } else {
          linkOnWidget = `${location}${linkOnWidget}`;
        }
      }
    }

    //@ts-ignore
    const selectChartFunc = (chartType: any) => {
      if (setSelectedChart) {
        setPreviousSelectedChart(selectChart);
        setSelectedChart(chartType);
      }
      setAnchorEl(null);
    };

    const navigateToLink = () => (window.location.href = linkOnWidget!);

    if (loadingFlocDetector && selectChart === 'flocDetector') {
      return <Loader />;
    }
    {
      // console.log('Rendering widget:', {
      //   widgetType: widgetStructure?.widgetType,
      //   widgetData,
      //   plantId,
      //   widgetId,
      //   timeFrame,
      //   isBottomBarOpen,
      // })
    }
    // console.log('may enter the condtion');
    return (
      <>
        {/* {console.log('may enter the condtion widgetStructure')} */}
        {widgetStructure?.widgetType === 'flocDetector' && widgetData !== null ? (
          <>
            {console.log('entering the condition')}
            <Box
              sx={{
                width: '80vw', // or '80%' based on your need
                marginBottom: '20px', // Set an actual value for margin to notice the effect
                margin: 'auto', // Use this to center if needed
                display: 'block', // Ensure it's block-level to respect width settings
                // height: 'inherit'
              }}
            >
              {' '}
              <FlocDetectorVisualizationChart
                expandedViewPopup={expandedViewPopup}
                setMakingSampleDefault={setMakingSampleDefault}
                loadingFlocDetector={loadingFlocDetector}
                widgetStructure={widgetStructure}
                widgetData={widgetData}
                setExpandedViewPopup={setExpandedViewPopup}
                isLoading={loading}
                headerType="normal"
                showLinkIcon={showLinkIcon}
                linkOnWidget={linkOnWidget}
                enableZoom={false}
                viewEveryNthTick={2}
                ticksColor="light"
                setOpenDialog={setOpenDialog}
                handleEditClick={handleEditClick}
                showClock={showClock}
                setShowClock={setShowClock}
                customTimeRangeSettings={widgetData.customTimeRangeSettings}
                timeFrame={timeFrame}
                setTimeFrame={setTimeFrame}
                handleClockClick={handleClockClick}
                customGranularity={customGranularity}
                selectChart={selectChart}
                setSelectedChart={setSelectedChart}
                setPreviousSelectedChart={setPreviousSelectedChart}
                isBottomBarOpen={isBottomBarOpen}
                setIsBottomBarOpen={setIsBottomBarOpen}
                widgetId={widgetId}
                plantId={plantId}
                add530HoursInTimeFrame={add530HoursInTimeFrame}
                controller={controller}
                widgetTimeFrame={widgetTimeFrame}
                refresh={refresh}
              />
            </Box>
          </>
        ) : null}

        {/* {console.log('exitted the condition')} */}
        <Box
          sx={{
            width: 'inherit',
            height: 'inherit',
            boxSizing: 'border-box',
            borderRadius: '16px',
            // overflow: 'hidden',
            // border: `1px solid ${WIDGET_THEMES[widgetData?.widgetLook?.theme]?.backgroundColor}`,
          }}
          onMouseEnter={(event: any) => {
            console.log('mouse enter');
            setShowPopupToolBar(true);
          }}
          onMouseLeave={(event: any) => {
            setShowPopupToolBar(false);
          }}
        >
          {/* <Box sx={{ width: '100%', zIndex: 100 }}> */}

          <GeneralisedPopupToolBar
            open={showPopupToolBar}
            enableToolBar={
              enableNewToolBar ||
              (widgetData?.widgetType != 'graph' && widgetData?.widgetType != 'tableC')
            }
            iconConfigs={[
              {
                icon: <DownloadIcon sx={styles.iconStyle} />,
                onClick: event => {
                  event.stopPropagation();
                  if (
                    widgetData?.widgetType == 'number2' ||
                    widgetData?.widgetType == 'NumberGauge'
                  ) {
                    handleDownloadCSVForRangeWidget(
                      widgetData?.metricsData,
                      customTimeRangeSettings,
                      timeFrame
                    );
                  } else {
                    handleDownloadCSV(widgetData?.metricsData);
                  }
                },
              },
              {
                icon: <CallMadeIcon sx={styles.iconStyle} />,
                onClick: event => {
                  event.stopPropagation();
                  setExpandedViewPopup(true);
                },
                showIcon: !['number', 'number2', 'NumberGauge'].includes(widgetData?.widgetType)
                  ? true
                  : false,
              },
              {
                icon: <img src={ChartIcon} style={styles.iconStyle} />,
                onClick: (event: any) => {
                  event.stopPropagation();
                  setIsBottomBarOpen(false);
                  setAnchorEl(event.currentTarget);
                },
                showIcon: !['number', 'number2', 'NumberGauge'].includes(widgetData?.widgetType)
                  ? true
                  : false,
              },
              {
                icon: <img src={LinkIcon} style={styles.iconStyle} />,
                onClick: event => {
                  event.stopPropagation();
                  navigateToLink();
                },
                showIcon: showLinkIcon,
              },
              {
                icon: <ModeEditOutlineIcon sx={styles.iconStyle} />,
                onClick: event => {
                  event.stopPropagation();
                  handleEditClick();
                },
                showIcon: !['number'].includes(widgetData?.widgetType) ? true : false,
              },
            ]}
          />
          {/* </Box> */}

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null);
            }}
          >
            {selectChart === 'tableC' ? null : (
              <MenuItem
                onClick={() => {
                  setSelectedChart('tableC');
                }}
              >
                Table
              </MenuItem>
            )}
            {selectChart === 'bar' ? null : (
              <MenuItem
                onClick={() => {
                  setSelectedChart('bar');
                }}
              >
                Bar Graph
              </MenuItem>
            )}
            {selectChart === 'line' ? null : (
              <MenuItem
                onClick={() => {
                  setSelectedChart('line');
                }}
              >
                Line Graph
              </MenuItem>
            )}
          </Menu>
          {(selectChart &&
            ['bar', 'line'].includes(selectChart) &&
            widgetStructure?.widgetType !== 'flocDetector') ||
          (selectChart === null &&
            widgetStructure?.widgetType === 'graph' &&
            widgetData !== null) ? (
            <GeneralisedGraphWidget
              widgetStructure={widgetStructure}
              widgetData={widgetData}
              setExpandedViewPopup={setExpandedViewPopup}
              isLoading={loading}
              headerType="normal"
              showLinkIcon={showLinkIcon}
              linkOnWidget={linkOnWidget}
              enableZoom={false}
              viewEveryNthTick={2}
              ticksColor="light"
              setOpenDialog={setOpenDialog}
              handleEditClick={handleEditClick}
              showClock={showClock}
              setShowClock={setShowClock}
              customTimeRangeSettings={customTimeRangeSettings}
              setTimeFrame={setTimeFrame}
              handleClockClick={handleClockClick}
              customGranularity={customGranularity}
              selectChart={selectChart}
              setSelectedChart={setSelectedChart}
              setPreviousSelectedChart={setPreviousSelectedChart}
              isBottomBarOpen={isBottomBarOpen}
              setIsBottomBarOpen={setIsBottomBarOpen}
              widgetTimeFrame={widgetTimeFrame}
              featureAccess={plantId ? featureAccess : widgetFeatureAccess}
            />
          ) : null}

          {(selectChart !== null &&
            selectChart === 'tableC' &&
            widgetStructure?.widgetType !== 'flocDetector') ||
          (selectChart == null &&
            widgetStructure?.widgetType === 'tableC' &&
            widgetData !== null) ? (
            <TableCWidget
              widgetStructure={widgetStructure}
              widgetData={widgetData}
              setExpandedViewPopup={setExpandedViewPopup}
              isLoading={loading}
              headerType="normal"
              showLinkIcon={showLinkIcon}
              linkOnWidget={linkOnWidget}
              setOpenDialog={setOpenDialog}
              handleEditClick={handleEditClick}
              showClock={showClock}
              setShowClock={setShowClock}
              customTimeRangeSettings={customTimeRangeSettings}
              setTimeFrame={setTimeFrame}
              handleClockClick={handleClockClick}
              customGranularity={customGranularity}
              selectChart={selectChart}
              setSelectedChart={setSelectedChart}
              setPreviousSelectedChart={setPreviousSelectedChart}
              isBottomBarOpen={isBottomBarOpen}
              setIsBottomBarOpen={setIsBottomBarOpen}
              featureAccess={featureAccess}
            />
          ) : null}

          {widgetStructure?.widgetType === 'tableD' && widgetData !== null ? (
            <TableDWidget
              widgetStructure={widgetStructure}
              widgetData={widgetData}
              setExpandedViewPopup={setExpandedViewPopup}
              isLoading={loading}
              headerType="normal"
              showLinkIcon={showLinkIcon}
              linkOnWidget={linkOnWidget}
              setOpenDialog={setOpenDialog}
              handleEditClick={handleEditClick}
              showClock={showClock}
              setShowClock={setShowClock}
              customTimeRangeSettings={customTimeRangeSettings}
              setTimeFrame={setTimeFrame}
              handleClockClick={handleClockClick}
              customGranularity={customGranularity}
            />
          ) : null}

          {widgetStructure?.widgetType === 'number' && widgetData !== null ? (
            <NumberWidget
              widgetData={widgetData}
              isLoading={loading}
              showLinkIcon={showLinkIcon}
              linkOnWidget={linkOnWidget}
              background={widgetData.background}
            />
          ) : null}

          {(widgetStructure?.widgetType === 'number2' ||
            widgetStructure?.widgetType === 'NumberGauge') &&
          widgetData !== null ? (
            <RangeNumberWidget
              widgetData={widgetData}
              isLoading={loading}
              showLinkIcon={showLinkIcon}
              linkOnWidget={linkOnWidget}
              background={widgetData.background}
              theme={widgetData.widgetLook?.theme}
              timeFrame={timeFrame}
              showClock={showClock}
              setShowClock={setShowClock}
              handleClockClick={handleClockClick}
              customTimeRangeSettings={customTimeRangeSettings}
            />
          ) : null}

          <GeneralisedExpandedWidget
            open={expandedViewPopup}
            handleClose={() => {
              setExpandedViewPopup(false);
            }}
            widgetDataForGenWidget={widgetData}
            widgetStructure={widgetData}
            timeFrame={timeFrame}
            refresh={props.refresh}
            setIsOpen={setExpandedViewPopup}
            showLinkIcon={showLinkIcon}
            linkOnWidget={linkOnWidget}
          />

          <CustomDialog open={openDialog} onClose={handleCloseDialog}>
            {widgetName === 'flocDetector' ? (
              <SettingsDialog
                showAllSampleVal={showAllSampleVal}
                setShowAllSampleVal={setShowAllSampleVal}
                timeRangeVal={timeRangeVal}
                setTimeRangeVal={setTimeRangeVal}
                parent="widget"
                onClose={handleCloseDialog}
                widget={widgetData}
                widgetDateAndTime={widgetDateAndTime}
                setIsBottomBarOpen={setIsBottomBarOpen}
                setChangedWidgets={setChangedWidgets}
                changedWidgets={changedWidgets}
                showClock={showClock}
                setShowClock={setShowClock}
                setWidgetTimeFrame={setWidgetTimeFrame}
                timeFrame={timeFrame}
                setTimeFrame={setTimeFrame}
                setCustomTimeRangeSettings={setCustomTimeRangeSettings}
                setSelectedTimeRange={setWidgetTimeFrame}
                setParent={setParent}
                dashboardId={widgetData?.dashboardPageId}
              />
            ) : (
              <DateAndTime
                parent="widget"
                onClose={handleCloseDialog}
                widgetDateAndTime={widgetDateAndTime}
                setWidgetDateAndTime={setWidgetDateAndTime}
                changedWidgets={changedWidgets}
                setChangedWidgets={setChangedWidgets}
                setIsBottomBarOpen={setIsBottomBarOpen}
                setCustomTimeRangeSettings={setCustomTimeRangeSettings}
                setWidgetTimeFrame={setWidgetTimeFrame}
                showClock={showClock}
                setShowClock={setShowClock}
                timeFrame={timeFrame}
                setCustomGranularity={setCustomGranularity}
                setParent={setParent}
                selectChart={selectChart || widgetData?.graphType}
                setSelectedChart={setSelectedChart}
                dashboardId={widgetData?.dashboardPageId}
              />
            )}
          </CustomDialog>
        </Box>
      </>
    );
  },
  (prevProps, nextProps) => {
    if (
      prevProps.timeFrame.startDate != nextProps.timeFrame.startDate ||
      prevProps.timeFrame.endDate != nextProps.timeFrame.endDate ||
      prevProps.timeFrame.granularity != nextProps.timeFrame.granularity ||
      prevProps.isBottomBarOpen != nextProps.isBottomBarOpen ||
      !lodash.isEqual(prevProps.changedWidgets, nextProps.changedWidgets)
    ) {
      return false;
    }
    return true;
  }
);

export default GeneralisedWidget;
