import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';

export const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: 'Today',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('day'), today];
    },
  },
  {
    label: 'Last 24 Hours',
    getValue: () => {
      const now = dayjs();
      const yesterday = now.subtract(24, 'hours');
      return [yesterday, now];
    },
  },
  {
    label: 'Yesterday',
    getValue: () => {
      const yesterday = dayjs().subtract(1, 'day');
      return [yesterday.startOf('day'), yesterday.endOf('day')];
    },
  },
  {
    label: 'Last 3 Days',
    getValue: () => {
      const today = dayjs();
      const threeDaysAgo = today.subtract(3, 'day');
      return [threeDaysAgo.startOf('day'), today];
    },
  },
  {
    label: 'Last 7 Days',
    getValue: () => {
      const today = dayjs();
      const sevenDaysAgo = today.subtract(7, 'day');
      return [sevenDaysAgo.startOf('day'), today];
    },
  },
  {
    label: 'Last 10 Days',
    getValue: () => {
      const today = dayjs();
      const tenDaysAgo = today.subtract(10, 'day');
      return [tenDaysAgo.startOf('day'), today];
    },
  },
  {
    label: 'Last 30 Days',
    getValue: () => {
      const today = dayjs();
      const thirtyDaysAgo = today.subtract(30, 'day');
      return [thirtyDaysAgo.startOf('day'), today];
    },
  },
  {
    label: 'Last 3 Months',
    getValue: () => {
      const today = dayjs();
      const threeMonthsAgo = today.subtract(3, 'month');
      return [threeMonthsAgo.startOf('month'), today];
    },
  },
  {
    label: 'Last 6 Months',
    getValue: () => {
      const today = dayjs();
      const sixMonthsAgo = today.subtract(6, 'month');
      return [sixMonthsAgo.startOf('month'), today];
    },
  },
];

export const flocDetectorGraphs = [
  {
    value: 'Sludge Settling Pattern',
    label: 'Sludge Settling Pattern',
  },
  {
    value: 'Sludge Value',
    label: 'Sludge Value',
  },
];
