import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { OtherSensor, Sensor } from '../../Interfaces/PlantConfigurationInterface';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { PlantConfigStyle } from '../plantConfiguration/PlantConfigStyle';
import DeleteIcon from '@mui/icons-material/Delete';
import useIsMobile from '../../utilities/customHooks/useIsMobile';

interface IProps {
  sensor: OtherSensor;
  index: number;
  setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  setEquipmentIndex: any;
  setEquipmentName: React.Dispatch<React.SetStateAction<string>>;
  setEquipment: React.Dispatch<React.SetStateAction<string>>;
  updateOtherSensorTag: Function;
}

const styles = {
  headingStyle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flexStart',
    alignItems: 'center',
    padding: '0.6rem',
    gap: '1.2rem',
    flexWrap: 'wrap',
  },
  bgNone: {
    background: 'none !important',
  },
};

const FlocDetector: React.FC<IProps> = ({
  sensor,
  index,
  setShowDeleteModal,
  setEquipmentIndex,
  setEquipmentName,
  setEquipment,
  updateOtherSensorTag,
}) => {
  const [accordionExpanded, setAccordionExpanded] = useState(false);

  const handleExpandClick = () => {
    setAccordionExpanded(!accordionExpanded);
  };

  const handleStopExpansion = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const isMobile = useIsMobile();

  return (
    <>
      <Accordion TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={handleExpandClick}
          sx={styles.bgNone}
        >
          <Box sx={styles.headingStyle}>
            <Typography variant="h4">
              {sensor.name} {sensor.count}
            </Typography>

            {sensor['sensors'].map((sensor: Sensor, sensorIndex: number) => {
              return (
                <>
                  {sensor['sensorName'] === 'Floc Detector Special Tag' && (
                    <>
                      {!isMobile && (
                        <>
                          <TextField
                            onClick={handleStopExpansion}
                            type="String"
                            value={sensor['sensorTag']}
                            variant="outlined"
                            label={sensor['sensorName']}
                            onChange={e =>
                              updateOtherSensorTag(index, e.target.value, 'sensorTag', sensorIndex)
                            }
                          />
                          <TextField
                            onClick={handleStopExpansion}
                            type="String"
                            value={sensor['sensorNickName']}
                            variant="outlined"
                            label={
                              sensor['sensorName'] !== 'Floc Detector Special Tag'
                                ? sensor['sensorName'] + ' Nickname'
                                : 'Nickname'
                            }
                            onChange={e =>
                              updateOtherSensorTag(
                                index,
                                e.target.value,
                                'sensorNickName',
                                sensorIndex
                              )
                            }
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              );
            })}
          </Box>
        </AccordionSummary>
        <AccordionSummary sx={styles.bgNone}>
          <Grid container m={1} p={1}>
            {sensor['sensors'].map((sensor: Sensor, sensorIndex: number) => {
              if (sensor['sensorName'] === 'Floc Detector Special Tag' && !isMobile) return <></>;
              else {
                return (
                  <>
                    <Grid item gap={4} mb={4} mr={4}>
                      <TextField
                        onClick={handleStopExpansion}
                        type="String"
                        value={sensor['sensorTag']}
                        variant="outlined"
                        label={sensor['sensorName']}
                        onChange={e =>
                          updateOtherSensorTag(index, e.target.value, 'sensorTag', sensorIndex)
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item gap={4} mb={4} mr={4}>
                      <TextField
                        onClick={handleStopExpansion}
                        type="String"
                        value={sensor['sensorNickName']}
                        variant="outlined"
                        label={
                          sensor['sensorName'] !== 'Floc Detector Special Tag'
                            ? sensor['sensorName'] + ' Nickname'
                            : 'Nickname'
                        }
                        onChange={e =>
                          updateOtherSensorTag(index, e.target.value, 'sensorNickName', sensorIndex)
                        }
                        fullWidth
                      />
                    </Grid>
                  </>
                );
              }
            })}
            <Grid item width="100%" textAlign="center" mb={3}>
              <Button
                variant="contained"
                style={PlantConfigStyle.deleteButton}
                onClick={() => {
                  setShowDeleteModal(true);
                  setEquipmentIndex(index);
                  setEquipmentName(`${sensor.name} ${sensor.count}`);
                  setEquipment('Other Sensors');
                }}
              >
                <DeleteIcon style={PlantConfigStyle.deleteIcon} />
              </Button>
            </Grid>
          </Grid>
        </AccordionSummary>
      </Accordion>
    </>
  );
};

export default FlocDetector;
