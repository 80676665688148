import UnifiedDashboardPage from './UnifiedDashboardPage';
import DnDUnifiedPageConfig from './DnDUnifiedPageConfig';
import { useUnifiedDashboardStore } from '../store/UnifiedDashboardStore';
import { ThemeProvider } from '@mui/material';
import LayoutComponent from '../components/LayoutComponent';
import dpTheme from '../styles/theme/dpTheme';
const breakpoints = {
  mobile: '@media (max-width: 767px)',
  tablet: '@media (min-width: 768px) and (max-width: 1024px)',
};

const styles = {
  LayoutStyle: {
    background: '#F5F6F9',
    minHeight: window.innerHeight,
    padding: '20px 24px',
    [breakpoints.tablet]: {
      padding: '12px 10px',
    },
    [breakpoints.mobile]: {
      padding: '0px !important',
    },
  },
};

export default function UnifiedDashboardPage2() {
  const { pageMode } = useUnifiedDashboardStore();
  return (
    <LayoutComponent style={styles.LayoutStyle} plantId={null} factoryResetContainer={true}>
      <ThemeProvider theme={dpTheme}>
        {pageMode == 'view' ? <UnifiedDashboardPage /> : <DnDUnifiedPageConfig />}
      </ThemeProvider>
    </LayoutComponent>
  );
}
