import { Box, Menu, MenuItem, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import DialogBox from '../../../core-components/theme/components/DialogBox/DialogBox';
import Table from '../../../core-components/theme/components/Table/Table';
import DetailIcon from '../../../core-components/assets/icons/DetailIcon';
import DeleteFilledIcon from '../../../core-components/assets/icons/DeleteIconFilled';
import EditIcon from '../../../core-components/assets/icons/EditIcon';
import CustomIconButton from '../../../core-components/theme/components/Button/Iconbutton';
import CustomTreeComponent from '../../../core-components/theme/components/Tree/CustomTree';
import Tabs from '../../../core-components/theme/components/Tabs/Tabs';
import TwoRowLayout from '../../../core-components/theme/layouts/TwoLayout/TwoLayoutComponent';
import ConfirmationDialog from '../../../core-components/theme/components/DialogBox/ConfirmationDialog';
import { Neutrals } from '../../../core-components/theme/color-palette/colors';
import CustomWorkspaceTreeItem from '../../workspace-management/component/CustomWorkspaceTree';
import {
  getWorkspaceItemId,
  getWorkspaceItemlabel,
} from '../../workspace-management/helpers/customtreehelpers';
import { getUserItemId, getUserItemlabel } from '../../user-management/helpers/customUserTreeHelpers';
import CustomUserGroupTreeItem from './CustomUserGroupTree';
import {
  getFeatureList,
  getUserList,
  getWorkspaceList,
} from '../../../services/userGroup/userGroup';

const OptionsMenu: React.FC<{ row: any; handleDeleteUserGroup: any; handleEditUserGroup: any }> = ({
  row,
  handleDeleteUserGroup,
  handleEditUserGroup,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const [Confirmationopen, setConfirmationOpen] = useState(false);

  function handleOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
    handleMenuClose();
  }
  function handleConfirmationOpen() {
    setConfirmationOpen(true);
  }
  function handleConfirmationClose() {
    setConfirmationOpen(false);
    handleMenuClose();
  }
  const [value, setValue] = useState(0);
  const handleChange = (newValue: React.SetStateAction<number>) => {
    setValue(newValue);
  };
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const columns = [
    {
      field: 'userName',
      headerName: 'User Name',
    },
    {
      field: 'email',
      headerName: 'Email',
    },
  ];
  type UserData = {
    name?: string;
    email?: string;
  };

  const transformData = (data: UserData[] = []): { userName: string; email: string }[] => {
    if (!data || !Array.isArray(data)) {
      return [];
    }

    return data.map(item => {
      return {
        userName: item.name || '',
        email: item.email || '',
      };
    });
  };

  const { data: userDetails, isLoading: userDetailsLoading } = useQuery({
    queryKey: ['userDetails', open, value],
    queryFn: () => getUserList({ mode: 'details', userGroupId: row._id }),
    enabled: open && value === 0,
  });

  const { data: moduleList, isLoading: moduleListLoading } = useQuery({
    queryKey: ['moduleDetails', open, value],
    queryFn: () => getFeatureList({ mode: 'details', userGroupId: row._id }),
    enabled: open && value === 1,
  });

  const { data: workspaceList, isLoading: workspaceListLoading } = useQuery({
    queryKey: ['workspaceDetails', open, value],
    queryFn: () => getWorkspaceList({ mode: 'details', userGroupId: row._id }),
    enabled: open && value === 2,
  });

  const tabs = [
    {
      label: 'User List',
      Component: userDetailsLoading ? (
        <Typography textAlign="center">Loading...</Typography>
      ) : (
        <Table rows={transformData(userDetails)} columns={columns} />
      ),
    },
    {
      label: 'Module List',
      Component: moduleListLoading ? (
        <Typography textAlign="center">Loading...</Typography>
      ) : (
        <CustomTreeComponent
          getItemId={getUserItemId}
          getItemlabel={getUserItemlabel}
          CustomTreeItem={CustomUserGroupTreeItem}
          items={moduleList || []}
        />
      ),
    },
    {
      label: 'Workspace List',
      Component: workspaceListLoading ? (
        <Typography textAlign="center">Loading...</Typography>
      ) : (
        <CustomTreeComponent
          getItemId={getWorkspaceItemId}
          getItemlabel={getWorkspaceItemlabel}
          CustomTreeItem={CustomWorkspaceTreeItem}
          items={workspaceList || []}
        />
      ),
    },
  ];

  useEffect(() => {
    if (userDetails) {
      transformData(userDetails);
    }
  }, [userDetails, userDetailsLoading]);

  return (
    <>
      <CustomIconButton onClick={handleMenuOpen} icon={MoreVertIcon} />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        slotProps={{
          paper: {
            style: {
              boxShadow: '0px 2px 8px 0px #1A335626',
            },
          },
        }}
        MenuListProps={{
          sx: {
            border: `1px solid ${Neutrals[300]}`,
            py: 0,
            backgroundColor: '#ffffff',
            borderRadius: '4px',
          },
        }}
      >
        {/* for details */}
        <MenuItem
          sx={{
            height: '36px',
            paddingLeft: '8px',
            borderBottom: `1px solid ${Neutrals[300]}`,
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={handleOpen}
        >
          <DetailIcon />
          <Typography ml={2} pr={2} color={Neutrals[500]}>
            Details
          </Typography>
        </MenuItem>
        {/* for edit page */}
        <MenuItem
          sx={{
            height: '36px',
            borderBottom: `1px solid ${Neutrals[300]}`,
            display: 'flex',
            paddingLeft: '8px',
            alignItems: 'center',
          }}
          onClick={() => handleEditUserGroup(row._id)}
        >
          <EditIcon />
          <Typography ml={2} pr={2} color={Neutrals[500]}>
            Edit
          </Typography>
        </MenuItem>
        {/* for delete */}
        <MenuItem
          sx={{ height: '36px', paddingLeft: '8px', display: 'flex', alignItems: 'center' }}
          onClick={handleConfirmationOpen}
        >
          <DeleteFilledIcon />
          <Typography ml={2} pr={2} color={Neutrals[500]}>
            Delete
          </Typography>
        </MenuItem>
        {/* for details */}
        <DialogBox open={open} title={row.name} handleClose={handleClose}>
          <Box>
            <TwoRowLayout
              gap={0}
              style={{ height: '60vh' }}
              child1={
                <>
                  <Typography variant="h4">{row.description}</Typography>
                </>
              }
              child2={
                <Tabs variant="scrollable" tabs={tabs} value={value} handleChange={handleChange} />
              }
            />
          </Box>
        </DialogBox>
        {/* for delete */}
        <ConfirmationDialog
          open={Confirmationopen}
          handleClose={handleConfirmationClose}
          title="Delete User Group"
          description={
            <Typography variant="h4">
              Are you sure you want to delete{' '}
              <Typography component="span" variant="highlight">
                {row.name}
              </Typography>
              ? You can’t undo this action
            </Typography>
          }
          actions={[
            {
              text: 'Cancel',
              color: 'white',
              textColor: '#8B8B8B',
              handler: () => console.log('cancel clicked'),
            },
            {
              text: 'Delete',
              color: 'SemanticError.dark',
              textColor: 'white',
              handler: () => handleDeleteUserGroup(row._id),
            },
          ]}
        ></ConfirmationDialog>
      </Menu>
    </>
  );
};

export default OptionsMenu;
