import {
  Box,
  Breadcrumbs,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Stack,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useMutation, useQuery } from '@tanstack/react-query';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import TwoRowLayout from '../../../../core-components/theme/layouts/TwoLayout/TwoLayoutComponent';
import { Neutrals, Primary } from '../../../../core-components/theme/color-palette/colors';
import CustomTreeComponent from '../../../../core-components/theme/components/Tree/CustomTree';
import LayoutComponent from '../../../../components/LayoutComponent';
import Button from '../../../../core-components/theme/components/Button/Button';
import {
  getPermissionListForUser,
  getUserGroupsForUser,
  saveRole,
} from '../../../../services/role';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { getPermissions } from '../../../userGroup/hooks/moduleHandler';
import CustomUserGroupTreeItem from '../../../userGroup/component/CustomUserGroupTree';
import { getUserItemId, getUserItemlabel } from '../../helpers/customUserTreeHelpers';
import { isDescriptionProperlyFormatted } from '../../../../utilities/description.utils';
import { isValidNameFormat } from '../../../../utilities/name.utils';
import { FormData } from '../interfaces/FormData';

const RoleCreatePage = () => {
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/roleList">
      <Typography key="3" color="#666666">
        Role List
      </Typography>
    </Link>,
    <Typography key="3" color="#666666">
      Create Role
    </Typography>,
  ];
  const navigate = useNavigate();
  const mode = 'create';
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [formData, setFormData] = useState<FormData>({
    name: '',
    description: '',
    groupName: '',
    assignedGroup: null,
    defaultHomepage: 'Unified Dashboard',
    roleScope: 'Group',
    roleType: 'Administrative',
    permissionTags: [],
    assets: [],
    administrative: [],
  });
  function handleTreeSelected(val: any) {
    const key = formData.roleType === 'Asset' ? 'assets' : 'administrative';
    setFormData({
      ...formData,
      [key]: val,
    });
  }

  function handleFormData(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }
  const [open, setOpen] = useState(true);

  const handleToggle = () => {
    setOpen(!open);
  };
  function handleCancel() {
    navigate('/rolelist');
  }
  const [assignedUserGroup, setAssignedUserGroup] = useState<any>([]);
  const [assets, setAssets] = useState<any>([]);
  const [administrative, setAdministrative] = useState<any>([]);
  const [isPermissionListFetched, setisPermissionListFetched] = useState(false);
  const { data: assignedUserGroupDetails, status: assignedUserGroupDetailsStatus } = useQuery({
    queryKey: ['userGroupList'],
    queryFn: () => getUserGroupsForUser(),
    refetchOnWindowFocus: false,
  });
  const { data: rolepermissionList, status: permissionListStatus } = useQuery({
    queryKey: ['permissionList'],
    queryFn: () => getPermissionListForUser({ mode: mode }),
    enabled: !isPermissionListFetched,
  });
  useEffect(() => {
    if (assignedUserGroupDetailsStatus === 'success') {
      if (assignedUserGroupDetails.length > 0 && !formData.name) {
        setFormData(prevFormData => ({
          ...prevFormData,
          groupName: assignedUserGroupDetails[0].name,
        }));
        setAssignedUserGroup(assignedUserGroupDetails);
      }
    }
  }, [assignedUserGroupDetails, assignedUserGroupDetailsStatus]);
  useEffect(() => {
    if (permissionListStatus === 'success') {
      const { assets = [], administrative = [] } = rolepermissionList.data[0];
      setAssets(assets);
      setAdministrative(administrative);
      setisPermissionListFetched(true);
    } else if (permissionListStatus === 'error') {
      setisPermissionListFetched(false);
    }
  }, [rolepermissionList, permissionListStatus]);
  const descriptionLimit = 200;
  const handleSaveWorkspaceData = () => {
    const updateForm = { ...formData };
    updateForm.name = updateForm.name.trim();
    updateForm.description = updateForm.description?.trim() || '';
    if (!isDescriptionProperlyFormatted(updateForm.description)) {
      toast.error(`Description cannot be this long, max length is ${descriptionLimit} characters`);
      return;
    }
    if (!isValidNameFormat(updateForm.name)) {
      toast.error('Name cannot be empty and max length 80');
      return;
    }
    if (formData.roleScope === 'Group') {
      updateForm.assignedGroup = assignedUserGroup.filter(
        (item: { name: string }) => item.name.trim() === formData.groupName.trim()
      )[0]._id;
    }
    updateForm.permissionTags =
      formData.roleType === 'Asset'
        ? getPermissions(assets, formData.assets || [])
        : getPermissions(administrative, formData.administrative || []);
    if (updateForm.permissionTags.length === 0) {
      toast.error('Please selected atleast one permission');
      return;
    }
    mutation.mutate(updateForm);
  };

  const mutation = useMutation({
    mutationFn: (formData: FormData) => saveRole(formData),
    onSuccess: () => {
      // Check if the response indicates success or failure
      toast.success(`Role saved`);
      navigate('/rolelist'); // Redirect to another page
    },
    onError: error => {
      toast.error(error.message);
      toast.error(`Problem in saving role`);
      console.error('Error saving role:', error);
    },
  });
  return (
    <LayoutComponent factoryResetContainer={true}>
      <TwoRowLayout
        style={{
          pl: isMobile ? 2 : 4,
          pr: isMobile ? 2 : 4,
        }}
        child1={
          <Stack direction="column" width="100%">
            <Breadcrumbs
              separator={<ArrowForwardIcon fontSize="small" sx={{ color: '#666666' }} />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
            <Box
              sx={{
                mt: 2,
                mb: 2,
                backgroundColor: `${Primary[50]}`,
                borderRadius: 3,
                padding: isMobile ? '16px 24px' : '33px 23px',
                border: '1px solid #bdbdbd',
              }}
            >
              <Grid container direction="column" borderRadius="12px">
                <Grid
                  container
                  onClick={handleToggle}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {isMobile && (
                    <Typography
                      variant="body"
                      letterSpacing="0.15px"
                      color="#666"
                      lineHeight="24px"
                    >
                      Role Details
                    </Typography>
                  )}
                  {isMobile && (
                    <IconButton onClick={handleToggle}>
                      {open ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  )}
                </Grid>
                {isMobile && open && (
                  <Divider variant="fullWidth" sx={{ mt: 1, mb: '24px', color: '#bdbdbd' }} />
                )}
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Grid
                    container
                    direction={isMobile ? 'column' : 'row'}
                    justifyContent="space-around"
                    alignItems="center"
                    gap={2}
                  >
                    <Grid item xs={12} sm={5} width={isMobile ? '100%' : undefined}>
                      <TextField
                        label="Role Name"
                        placeholder="Admin/CSM/Client"
                        value={formData.name}
                        name="name"
                        onChange={handleFormData}
                        variant="outlined"
                        required
                        fullWidth
                        sx={{
                          paddingBottom: '16px',
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} width={isMobile ? '100%' : undefined}>
                      <TextField
                        label="Description"
                        name="description"
                        placeholder="Description"
                        value={formData.description}
                        onChange={handleFormData}
                        variant="outlined"
                        fullWidth
                        sx={{
                          paddingBottom: '16px',
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction={isMobile ? 'column' : 'row'}
                    justifyContent="space-around"
                    alignItems="center"
                    gap={2}
                    mt={2}
                  >
                    <Grid item xs={12} sm={2} width={isMobile ? '100%' : undefined}>
                      <TextField
                        label="Default Homepage"
                        select
                        value={formData.defaultHomepage}
                        name="defaultHomepage"
                        onChange={handleFormData}
                        variant="outlined"
                        fullWidth
                        sx={{
                          paddingBottom: '16px',
                        }}
                      >
                        <MenuItem value="Unified Dashboard">Unified Dashboard</MenuItem>
                        <MenuItem value="Group">Task</MenuItem>
                        <MenuItem value="Dashboard">Dashboard</MenuItem>
                        <MenuItem value="Plant List">Plant List</MenuItem>
                        <MenuItem value="User List">User List</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={2} width={isMobile ? '100%' : undefined}>
                      <TextField
                        label="Scope"
                        select
                        value={formData.roleScope}
                        name="roleScope"
                        onChange={handleFormData}
                        variant="outlined"
                        fullWidth
                        sx={{
                          paddingBottom: '16px',
                        }}
                      >
                        <MenuItem value="Group">Group</MenuItem>
                        <MenuItem value="System">System</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={3} width={isMobile ? '100%' : undefined}>
                      <TextField
                        label="Group Name"
                        select
                        placeholder="Name"
                        value={
                          formData.roleScope === 'System' ? 'N/A for System' : formData.groupName
                        }
                        name="groupName"
                        onChange={handleFormData}
                        variant="outlined"
                        fullWidth
                        sx={{
                          paddingBottom: '16px',
                        }}
                        disabled={formData.roleScope === 'System'}
                      >
                        {assignedUserGroup.length > 0 &&
                          assignedUserGroup.map((userGroup: any) => {
                            return (
                              <MenuItem key={userGroup._id} value={userGroup.name}>
                                {userGroup.name}
                              </MenuItem>
                            );
                          })}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={3} width={isMobile ? '100%' : undefined}>
                      <TextField
                        label="Role Type"
                        select
                        value={formData.roleType}
                        name="roleType"
                        onChange={handleFormData}
                        variant="outlined"
                        fullWidth
                        sx={{
                          paddingBottom: '16px',
                        }}
                      >
                        <MenuItem value="Administrative">Administrative</MenuItem>
                        <MenuItem value="Asset">Asset</MenuItem>
                      </TextField>
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </Box>
          </Stack>
        }
        child2={
          <Box p={0} borderRadius="8px">
            <CustomTreeComponent
              heading="Permission List"
              show={true}
              CustomTreeItem={CustomUserGroupTreeItem}
              getItemId={getUserItemId}
              getItemlabel={getUserItemlabel}
              selectedIds={formData.roleType == 'Asset' ? formData.assets : formData.administrative}
              handleTreeSelected={handleTreeSelected}
              checkboxSelection={true}
              multiSelect={true}
              items={formData.roleType == 'Asset' ? assets : administrative}
            />
            <Box
              gap={6}
              pt={4}
              sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
              pb={2}
            >
              <Button
                variant="contained"
                sx={{
                  border: `1px solid ${Neutrals[500]}`,
                  borderRadius: '4px',
                  padding: isMobile ? '10px 40px' : '10px 70px',
                  backgroundColor: 'white',
                  color: `${Neutrals[500]}`,
                  flex: isMobile ? 1 : 0,
                  boxShadow: 'none',
                  ':hover': { background: 'none', boxShadow: 'none' },
                }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                disabled={formData.name.length === 0}
                variant="contained"
                sx={{
                  padding: isMobile ? '10px 40px' : '10px 70px',
                  flex: isMobile ? 1 : 0,
                  borderRadius: '4px',
                  backgroundColor: `${Primary[500]}`,
                  boxShadow: 'none',
                  ':hover': { boxShadow: 'none' },
                }}
                onClick={handleSaveWorkspaceData}
              >
                Save
              </Button>
            </Box>
          </Box>
        }
      />
    </LayoutComponent>
  );
};

export default RoleCreatePage;
