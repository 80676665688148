
// ^ These are the permissions that are used in the application. They are used to check if a user has access to a certain feature or not.

export const PERMISSION_TAGS = {
  FixedTask_Templating_WE: 'FixedTask_Templating_WE',
  UnifiedDashboardList_Manage_AE: 'UnifiedDashboardList_Manage_AE',
  UnifiedDashboardPage_Configuration_WE: 'UnifiedDashboardPage_Configuration_WE',
  UnifiedDashboardPage_View_R: 'UnifiedDashboardPage_View_R',
  DashboardPage_View_R: 'DashboardPage_View_R',
  DashboardList_Manage_AE: 'DashboardList_Manage_AE',
  DashboardPage_Configuration_WE: 'DashboardPage_Configuration_WE',
  Visualisation_Configuration_WE: 'Visualisation_Configuration_WE',
  PlantsList_Configuration_A: 'PlantsList_Manage_A',
  StoreConfiguration_Configuration_WE: 'StoreConfiguration_Configuration_WE',
  AlertTask_Templating_WE: 'AlertTask_Templating_WE',
  WaterQualityTestResults_Usage_W: 'WaterQualityTestResults_Usage_W',
  PlantLabTestResults_Usage_W: 'PlantLabTestResults_Usage_W',
  PlantMaintenance_Usage_W: 'PlantMaintaince_Usage_W',
  ManualTickets_Configuration_WE: 'ManualTickets_Configuration_WE',
  PLC_Configuration_R: 'PLC_Manage_R',
  PlantsList_Configuration_E: 'PlantsList_Manage_E',
  PlantsList_Configuration_D: 'PlantsList_Manage_D',
  HMI_Configuration_R: 'HMI_Manage_R',
  HMI_Configuration_A: 'HMI_Manage_A',
  HMI_Configuration_D: 'HMI_Manage_D',
  PLC_Configuration_AE: 'PLC_Manage_AE',
  PLC_Configuration_D: 'PLC_Manage_D',
};
