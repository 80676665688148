import React, { useEffect } from 'react';
import { Box, FormControl, FormLabel, FormControlLabel, Checkbox } from '@mui/material';
import useIsMobile from '../../../utilities/customHooks/useIsMobile';

function TimeRange({ timeRangeMarks, handleTimeRangeMarkChange }: any) {
  const isMobile = useIsMobile({ breakpointValue: 'sm' });
  const [checked, setChecked] = React.useState({
    '30Mark': false,
    '60Mark': false,
    '90Mark': false,
  });

  const handleChange = (event: any) => {
    setChecked({
      ...checked,
      [event.target.name]: event.target.checked,
    });

    let newTimeRange = [...timeRangeMarks];

    if (event.target.checked) {
      newTimeRange.push(event.target.value);
    } else {
      const index = newTimeRange.indexOf(event.target.value);
      if (index > -1) {
        newTimeRange.splice(index, 1);
      }
    }

    handleTimeRangeMarkChange(newTimeRange);
  };

  useEffect(() => {
    const checkedObj: any = { ...checked };

    timeRangeMarks.forEach((mark: string) => {
      checkedObj[mark] = true;
    });
    setChecked(checkedObj);
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        border: '1px solid #BDBDBD',
        borderRadius: '12px',
        padding: '20px 0px',
        marginTop: isMobile ? '0px' : '30px',
      }}
    >
      <FormControl
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <FormLabel
          id="time-range-checkbox-group"
          sx={{ color: '#1A3356', fontSize: '14px', fontWeight: '600', marginRight: '40px' }}
        >
          Time Range
        </FormLabel>
        <FormControlLabel
          sx={{ marginRight: '20px' }}
          control={
            <Checkbox
              checked={checked['30Mark']}
              onChange={handleChange}
              name="30Mark"
              value="30Mark"
            />
          }
          label="30 Minutes"
        />
        <FormControlLabel
          sx={{ marginRight: '20px' }}
          control={
            <Checkbox
              checked={checked['60Mark']}
              onChange={handleChange}
              name="60Mark"
              value="60Mark"
            />
          }
          label="60 Minutes"
        />
        <FormControlLabel
          sx={{ marginRight: '20px' }}
          control={
            <Checkbox
              checked={checked['90Mark']}
              onChange={handleChange}
              name="90Mark"
              value="90Mark"
            />
          }
          label="90 Minutes"
        />
      </FormControl>
    </Box>
  );
}

export default TimeRange;
