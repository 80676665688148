import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Chip, Box, Button, ThemeProvider, Tooltip, Menu, MenuItem } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import CallMadeIcon from '@mui/icons-material/CallMade';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import LayoutComponent from '../components/LayoutComponent';
import dpTheme from '../styles/theme/dpTheme';
import AddWorkspacePopup from '../components/workspace/AddWorkspacePopup';
import { getWorkspaceConfigList, deleteWorkspace } from '../services/workspace';
import DeleteConfirmation from '../globalComponents/dialogs/DeleteConfirmation';
import moment from 'moment';
import usePermissionCheck from '../utilities/customHooks/usePermissionCheck';
import { PERMISSION_TAGS } from '../constants/permissions';

export default function WorkspaceConfigList() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [showAddWorkspacePopup, setShowAddWorkspacePopup] = useState<boolean>(false);
  const [workspaceData, setWorkspaceData] = useState<any[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [rowToDelete, setRowToDelete] = useState<string>('');
  const [redirectMenuAnchorEl, setRedirectMenuAnchorEl] = useState<null | HTMLElement>(null);
  useEffect(() => {
    setLoading(true);
    getWorkspaceConfigList().then(data => {
      setWorkspaceData(data);
      setLoading(false);
    });
  }, [refresh]);

  const { hasPermission: canCreateWorkspace, loading: loadingAddFixedTaskButton } = usePermissionCheck(
    PERMISSION_TAGS.Visualisation_Configuration_WE
  );

  const columns: GridColDef[] = [
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params: any) => {
        const handleDelete = () => {
          setShowDeleteModal(true);
          // Store the ID of the row to be deleted
          setRowToDelete(params.row.id);
        };

        return (
          <>
            {canCreateWorkspace && <>
              <Button onClick={() => navigate(`/workspaceConfig/${params.row.id}`)}>
                <SettingsIcon sx={{ color: 'black' }} />
              </Button>
              <Button onClick={handleDelete}>
                <DeleteIcon sx={{ color: 'black' }} />
              </Button>
              <Button
                onClick={event => {
                  setRedirectMenuAnchorEl(event.currentTarget);
                }}
              >
                <CallMadeIcon sx={{ color: 'black' }} />
              </Button>
              <WorkspaceConfigMenu
                anchorEl={redirectMenuAnchorEl}
                setAnchorEl={setRedirectMenuAnchorEl}
                params={params}
              />
            </>}
          </>
        );
      },
      width: 250,
    },
    // {
    //   field: "id",
    //   headerName: "Id",
    //   width: 250,
    // },
    {
      field: 'workspaceName',
      headerName: 'Workspace Name',
      width: 250,
    },
    {
      field: 'clientName',
      headerName: 'Client',
      width: 200,
    },
    {
      field: 'plants',
      headerName: 'Plants',
      width: 300,
      renderCell: (params: any) => {
        if (params.row.plants?.length > 1)
          return (
            <Tooltip
              title={params.row.plants.map((p: any) => {
                return <div>{p.plantName}</div>;
              })}
            >
              <Stack direction="row" gap={1}>
                <Chip label={params.row.plants?.[0]?.plantName} variant="outlined" />{' '}
                <Chip label={'...'} variant="outlined" />
              </Stack>
            </Tooltip>
          );
        else if (params.row.plants?.length == 1)
          return <Chip label={params.row.plants?.[0]?.plantName} variant="outlined" />;
        else return <Chip label="No Plant Selected" variant="outlined" />;
      },
    },
    {
      field: 'lastUpdated',
      headerName: 'Last Updated',
      width: 200,
      renderCell: (params: any) => {
        return (
          <Box>
            {moment(params.row.lastUpdated, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format(
              'DD MMM YYYY | hh:mm a'
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <LayoutComponent>
      <ThemeProvider theme={dpTheme}>
        {/* <LayoutComponent> */}
        <Box m={1} p={1}>
          <div
            style={{
              fontFamily: 'Montserrat Bold',
              fontSize: 30,
              alignItems: 'center',
              verticalAlign: 'middle',
            }}
          >
            Workspaces
            <span style={{ float: 'right' }}>
              {canCreateWorkspace && <Button
                variant="contained"
                onClick={() => {
                  setShowAddWorkspacePopup(true);
                }}
              >
                <AddIcon />
                Add
              </Button>}
            </span>
          </div>
        </Box>
        <Box m={1} p={1}>
          <div style={{ height: 420 }}>
            <DataGrid rows={workspaceData} columns={columns} loading={loading} />
          </div>
        </Box>
        <AddWorkspacePopup
          open={showAddWorkspacePopup}
          handleClose={() => {
            setShowAddWorkspacePopup(false);
          }}
        />
        <DeleteConfirmation
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          alertMessage="This is an irreversible step, are you sure you want to continue?"
          processing={false}
          setProcessing={() => { }}
          onConfirmAction={() => {
            deleteWorkspace(rowToDelete).then(data => {
              setRefresh(!refresh);
            }); // Use the stored row ID
          }}
        />
        {/* </LayoutComponent> */}
      </ThemeProvider>
    </LayoutComponent>
  );
}
function WorkspaceConfigMenu(props: any) {
  const { anchorEl, setAnchorEl, params } = props;

  const navigate = useNavigate();
  const redirectTo = (link: string) => {
    navigate(link);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={anchorEl && params.hasFocus ? true : false}
      onClose={() => {
        setAnchorEl(null);
      }}
    >
      <MenuItem
        onClick={() => {
          redirectTo(`/WorkspaceAlertConfig/${params.row.id}`);
        }}
      >
        Workspace Alert Config
      </MenuItem>
      <MenuItem
        onClick={() => {
          redirectTo(`/WorkspaceFormulaConfig/${params.row.id}`);
        }}
      >
        Workspace Formula Config
      </MenuItem>

      <MenuItem
        onClick={() => {
          redirectTo(`/WorkspaceEscalationConfig/${params.row.id}`);
        }}
      >
        Workspace Escalation Config
      </MenuItem>
    </Menu>
  );
}
